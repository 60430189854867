import React from 'react';
import EmailOutlineIcon from 'mdi-react/ChevronLeftIcon';
import { useNavigate } from 'react-router-dom';

const BackButtonComponent = ({ route = -1 }) => {
  const navigate = useNavigate();

  return (
    <div className="back-btn-wrapper">
      <a href="" onClick={() => navigate(route)}>
        <div className="back-btn-wrapper__back-btn">
          <EmailOutlineIcon />
        </div>
        <span className="back-btn-wrapper__label-text">Previous Screen</span>
      </a>
    </div>
  );
};

export default BackButtonComponent;
