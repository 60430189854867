import React from 'react';
import { Table } from 'reactstrap';
import { formatPreferredDateRange } from '../utils';

export function PreferredDatesTable({ preferredDates }) {
  const tableHeaders = [
    { key: 'date', name: 'Date' },
    { key: 'startDate', name: 'Start Time' },
    { key: 'endDate', name: 'End Time' },
  ];

  const renderRowValues = (dateInfo) => {
    const formattedDateRange = formatPreferredDateRange(dateInfo, 'object');

    return tableHeaders.map((header) => (
      <td
        key={header.key}
        title={formattedDateRange[header.key]}
        className="custom-no-over-flow-td"
      >
        {formattedDateRange[header.key]}
      </td>
    ));
  };

  return (
    <Table striped className="custom-table loyal-table">
      <thead>
        <tr className="header-tr">
          {tableHeaders.map((header) => (
            <th key={header.key}>
              <p>{header.name}</p>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {preferredDates.map((dateInfo, index) => (
          <tr className="body-tr" key={index}>
            {renderRowValues(dateInfo)}
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
