import React, { useState } from 'react';
import moment from 'moment';
import { FormGroup, Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { Calendar } from 'react-date-range';
import MaskedInput from '../../../shared/components/inputs/Masked';
import { DEFAULT_TIMESTAMP_FORMAT } from '../../../constants/constants';

export default function DatePickerComponent({
  handleChange,
  date,
  viewMode,
  minDate,
  maxDate,
}) {
  const [dropdownOpen, setIsDropdownpen] = useState(false);

  const toggle = () => setIsDropdownpen((state) => !state);

  const handleDate = (e) => handleChange(e.target.value);

  const selectDate = (date) => {
    const dateString = moment(date).format(DEFAULT_TIMESTAMP_FORMAT);
    handleChange(dateString);
    toggle();
  };

  const displayDate = date && moment(date).isValid() ? date : null;

  return (
    <Dropdown
      className="custom-date-picker"
      isOpen={dropdownOpen}
      toggle={toggle}
    >
      <DropdownToggle
        disabled={!!viewMode}
        className="form-control dropdown-toggle-btn"
        onClick={() => toggle()}
      >
        <FormGroup>
          <MaskedInput
            mask="date"
            placeholder="MM/DD/YYYY"
            value={date || ''}
            onClick={() => toggle()}
            onChange={handleDate}
            autoComplete="off"
          />
        </FormGroup>
      </DropdownToggle>
      <DropdownMenu>
        {minDate && !maxDate && (
          <Calendar
            date={displayDate ? new Date(displayDate) : new Date()}
            minDate={new Date()}
            onChange={selectDate}
          />
        )}
        {!minDate && maxDate && (
          <Calendar
            date={displayDate ? new Date(displayDate) : new Date()}
            maxDate={new Date()}
            onChange={selectDate}
          />
        )}
      </DropdownMenu>
    </Dropdown>
  );
}
