export const validationRules = {
  required: [
    'name',
    'displayName',
    'address',
    'city',
    'state',
    'zipCode',
    'billingAddress',
    'billingCity',
    'billingState',
    'billingZipCode',
    'email',
    'appointmentRequestEmail',
    'prescriptionRequestEmail',
    'appointmentRequestEmail',
  ],
  email: [
    'billingEmail',
    'appointmentRequestEmail',
    'email',
    'prescriptionRequestEmail',
  ],
  number: ['zipCode', 'billingZipCode', 'tabletsPurchased'],
  price: ['subscription'],
  url: ['website', 'pharmacyWeb'],
};

export const defaultLogo = `${process.env.PUBLIC_URL}/img/rectangle-no-image.png`;
export const defaultIcon = `${process.env.PUBLIC_URL}/img/oval-no-image.png`;

export const defaultFacilityInputs = {
  logo: null,
  logoIcon: null,
  name: '',
  displayName: '',
  abbreviation: '',
  groupAffiliation: '',
  address: '',
  city: '',
  state: '',
  zipCode: '',
  placeId: '',
  billingAddress: '',
  billingCity: '',
  billingState: '',
  billingZipCode: '',
  billingSameAddress: false,
  billingTier: '',
  subscription: '',
  notes: '',
  tabletsPurchased: '',

  website: '',
  pharmacyWeb: '',
  staffWeb: '',

  fax: '',
  phone: '',
  pharmacyPhone: '',

  email: '',
  appointmentRequestEmail: '',
  prescriptionRequestEmail: '',
  billingEmail: '',
};

export const defaultFacilityErrors = {
  name: { tab: 1, error: null, display: 'facility name' },
  displayName: { tab: 1, error: null, display: 'display name' },
  abbreviation: { tab: 1, error: null, display: 'abbreviation' },
  groupAffiliation: { tab: 1, error: null, display: 'group affiliation' },
  address: { tab: 2, error: null, display: 'address' },
  city: { tab: 2, error: null, display: 'city' },
  state: { tab: 2, error: null, display: 'state' },
  zipCode: { tab: 2, error: null, display: 'zip code' },
  billingAddress: { tab: 3, error: null, display: 'address' },
  billingCity: { tab: 3, error: null, display: 'city' },
  billingState: { tab: 3, error: null, display: 'state' },
  billingZipCode: { tab: 3, error: null, display: 'zip code' },
  billingEmail: { tab: 3, error: null, display: 'billing email' },
  billingTier: { tab: 3, error: null, display: 'billing tier' },

  subscription: { tab: 3, error: null, display: 'subscription' },
  notes: { tab: 3, error: null, display: 'notes' },
  tabletsPurchased: { tab: 3, error: null, display: 'tablets purchased' },
  billingSameAddress: { tab: 3, error: null, display: '' },

  website: { tab: 5, error: null, display: 'website' },
  pharmacyWeb: { tab: 5, error: null, display: 'web url' },
  staffWeb: { tab: 5, error: null, display: 'staffWeb' },

  fax: { tab: 5, error: null, display: 'website' },
  phone: { tab: 5, error: null, display: 'phone number' },
  pharmacyPhone: { tab: 5, error: null, display: 'pharmacy phone' },

  email: { tab: 5, error: null, display: 'email' },
  appointmentRequestEmail: {
    tab: 5,
    error: null,
    display: 'appointment request email',
  },
  prescriptionRequestEmail: {
    tab: 5,
    error: null,
    display: 'prescription request email',
  },
};

export const tabs = [
  {
    value: '1',
    label: 'Basic Details',
  },
  {
    value: '2',
    label: 'Physical Address',
  },
  {
    value: '3',
    label: 'Billing Information',
  },
  {
    value: '4',
    label: 'Misc',
  },
  {
    value: '5',
    label: 'Contact Details',
  },
];

export const FACILITY_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  REMOVED: 'removed',
};

export const heads = [
  {
    key: 'displayName',
    name: 'Facility Name',
    sortable: true,
    filterable: false,
    capitalize: true,
  },
  {
    key: 'city',
    name: 'City',
    sortable: true,
    filterable: false,
    capitalize: true,
  },
  {
    key: 'state',
    name: 'State',
    sortable: true,
    filterable: false,
    capitalize: true,
  },
  {
    key: 'zipCode',
    name: 'Zip Code',
    width: '100px',
    sortable: true,
    filterable: false,
    capitalize: true,
  },
  {
    key: 'admin',
    name: 'Primary Admin',
    sortable: false,
    filterable: true,
    filterValue: [],
    capitalize: true,
  },
  {
    key: 'status',
    name: 'Status',
    width: '100px',
    sortable: false,
    filterable: true,
    filterValue: [{ active: 'active' }, { inactive: 'inactive' }],
    capitalize: true,
  },
];
