import React from 'react';
import { Table } from 'reactstrap';
import { getRefillRequestsHeaders } from '../utils';
import classNames from 'classnames';

export function AddressField({ addressData }) {
  const fullAddress = addressData.filter(Boolean).join(', ');

  if (!fullAddress.trim()) return null;

  return (
    <div
      className="address modal-main-info-field"
      style={{ gridArea: 'address' }}
    >
      <p className="field-label">Shipping Address</p>
      <p className="field-data">{fullAddress}</p>
    </div>
  );
}

export function StatusHistoryTable({ refillRequestData }) {
  const heads = getRefillRequestsHeaders();
  const statusHistoryTableHeaders = [
    ...heads.filter(({ key }) => ['date', 'time', 'status'].includes(key)),
    {
      key: 'staffComments',
      name: 'Staff Comments',
    },
  ];

  const renderRowValues = (statusInfo) =>
    statusHistoryTableHeaders.map((head) => (
      <td
        key={head.key}
        title={statusInfo[head.key]}
        className={classNames('custom-no-over-flow-td', {
          'text-capitalize': head.capitalize,
        })}
      >
        {statusInfo[head.key]}
      </td>
    ));

  return (
    <Table striped className="custom-table loyal-table">
      <thead>
        <tr className="header-tr">
          {statusHistoryTableHeaders.map((head) => (
            <th key={head.key}>
              <p>{head.name}</p>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {refillRequestData.statusHistory.map((statusIfo, index) => (
          <tr className="body-tr" key={index}>
            {renderRowValues(statusIfo)}
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
