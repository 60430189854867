import React from 'react';
import { FormGroup, Input } from 'reactstrap';

export default function TimeRangeSelect({ handleTypeSelect }) {
  return (
    <FormGroup>
      <Input
        className="with-custom-arrow"
        type="select"
        name="select"
        id="exampleSelect"
        onChange={handleTypeSelect}
      >
        <option value="daily">Daily (Last 30 days)</option>
        <option value="weekly">Weekly (Last 12 weeks)</option>
        <option value="monthly">Monthly (Last 6 months)</option>
      </Input>
    </FormGroup>
  );
}
