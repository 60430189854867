import React from 'react';
import { appointmentRequestPageTypes } from '../../../../config/app.config';
import {
  ClientPageWrapper,
  DefaultWrapper,
} from '../../Common/PageWithTableWrapper';

const appointmentClassName = 'appointment-requests-container';

export const appointmentComponentsWrapperMap = {
  [appointmentRequestPageTypes.default]: (props) => (
    <DefaultWrapper className={appointmentClassName} {...props} />
  ),
  [appointmentRequestPageTypes.clientPageModal]: (props) => (
    <ClientPageWrapper className={appointmentClassName} {...props} />
  ),
};
