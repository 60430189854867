import React from 'react';

export function AddFacilityErrorsView({ successResponse, errorResponse }) {
  return (
    <>
      {successResponse !== null && (
        <div className="alert alert-success fade show" role="alert">
          <div className="alert__content">
            <p>{successResponse}</p>
          </div>
        </div>
      )}
      {errorResponse !== null && (
        <div className="alert alert-danger fade show" role="alert">
          <div className="alert__content">
            <p>{errorResponse}</p>
          </div>
        </div>
      )}
    </>
  );
}
