/* eslint-disable no-useless-escape */
const commonHelper = {
  getParameterByName: (name, url) => {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  },

  formatPhone: (phoneNumber, pattern) => {
    phoneNumber = phoneNumber.toString().split('');
    return pattern.replace(/#/g, () => phoneNumber.shift());
  },
};

module.exports = commonHelper;
