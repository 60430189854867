import React, { Component } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { connect } from 'react-redux';

import RegisteredPets from '../Common/SelectPet';
import RegisterParent from './Components/RegisterParent';
import RegisterNewPet from './Components/RegisterNewPet';
import {
  addNewParent,
  clearClientProps,
  clearPetList,
  getParent,
} from '../../../redux/actions/petActions';
import { getSpecies } from '../../../redux/actions/breedAction';
import {
  clearUpdateProps,
  reinvite,
} from '../../../redux/actions/clientActions';
import { toggleLoading } from '../../../redux/actions/commonActions';
import { reset, validate } from '../../../libs/validationHelper';
import MessageModal from '../../../shared/components/Modals/MessageModal';
import config, { POPUP_TIMEOUT_TIME } from '../../../config/app.config';
import withRouter from '../../../shared/components/withRouter';

class RegisterClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      successResponse: null,
      errorResponse: null,
      speciesList: [],
      selectedParent: null,
      confirmPopup: {
        showResponse: false,
        responseMessage: '',
        responseType: '',
        responseAlertType: '',
        action: null,
        parent: null,
        status: null,
        footer: false,
        okText: 'Yes',
        cancelText: 'No',
      },
      inputs: {
        parent: {
          firstName: '',
          lastName: '',
          phoneEmail: '',
        },
        pets: [],
      },
      error: false,
      errors: {
        parent: {
          firstName: { error: null, display: 'first name' },
          lastName: { error: null, display: 'last name' },
          phoneEmail: { error: null, display: 'mobile phone' },
        },
      },
    };
  }

  componentDidMount() {
    this.props.getSpecies();
    this.props.clearPetList();
  }

  petListHasValues(pets) {
    if (pets.length < 1) return false;
    for (const pet of pets) {
      const {
        data: { name, birthday, breed, gender },
      } = pet;
      if (
        name.trim() !== '' ||
        birthday.trim() !== '' ||
        breed.trim() !== '' ||
        gender !== ''
      ) {
        return true;
      }
    }
    return false;
  }

  formHasValues() {
    const { inputs } = this.state;
    const { parent, pets } = inputs;
    const { firstName, lastName, phoneEmail } = parent;
    return (
      firstName.trim() !== '' ||
      lastName.trim() !== '' ||
      phoneEmail !== '' ||
      this.petListHasValues(pets)
    );
  }

  UNSAFE_componentWillReceiveProps(np) {
    if (np.breeds.species) {
      this.setState({
        speciesList: np.breeds.species.map((data) => ({
          _id: data._id,
          name: data.name,
        })),
      });
    }
    if (np.pets.newParentResponse && !this.state.refresh) {
      if (
        np.pets.newParentResponse.data &&
        np.pets.newParentResponse.data.status
      ) {
        const { confirmPopup } = this.state;
        confirmPopup.subMessage = '';
        let { selectedParent, inputs } = this.state;
        let errorResponse = null;
        const successResponse = null;
        switch (np.pets.newParentResponse.data.status) {
          case 'parent-added-success':
            // eslint-disable-next-line no-case-declarations
            let responseMessage = 'Parent successfully invited';
            if (np.pets.newParentResponse.data.newPets) {
              responseMessage = 'Pet profiles created successfully';
              const newPetData = np.pets.newParentResponse.data.newPets.map(
                (pet) => ({ ...pet }),
              );
              if (newPetData.length === 1) {
                const pet = newPetData.shift();
                responseMessage = `${pet.name}'s profile created successfully`;
              }
            }
            confirmPopup.showResponse = true;
            confirmPopup.responseMessage = responseMessage;
            confirmPopup.responseType = 'alert';
            confirmPopup.responseAlertType = 'success';
            confirmPopup.footer = true;
            confirmPopup.action = 'parent-added-success';
            confirmPopup.okText = 'OK';
            confirmPopup.cancelText = 'Ok';
            confirmPopup.parent = np.pets.newParentResponse.data.data;

            inputs = {
              parent: {
                firstName: '',
                lastName: '',
                phoneEmail: '',
              },
              pets: [],
            };

            this.props.clearClientProps();
            break;
          case 'parent-exist-facility-active':
            confirmPopup.showResponse = true;
            confirmPopup.responseMessage =
              'This Parent is already affiliated with your Facility';
            confirmPopup.responseType = 'confirm';
            confirmPopup.responseAlertType = 'info';
            confirmPopup.footer = true;
            confirmPopup.okText = 'OK';
            confirmPopup.cancelText = 'Cancel';
            confirmPopup.action = 'parent-exist-facility-active';
            break;
          case 'parent-exist-facility-inactive':
            confirmPopup.showResponse = true;
            confirmPopup.responseMessage =
              'This parent already exists. Please activate by visiting parent profile';
            confirmPopup.responseType = 'confirm';
            confirmPopup.responseAlertType = 'info';
            confirmPopup.footer = true;
            confirmPopup.okText = 'View Profile';
            confirmPopup.cancelText = 'Cancel';
            confirmPopup.action = 'parent-exist-facility-inactive';
            confirmPopup.parent = np.pets.newParentResponse.data;
            break;
          case 'parent-exist-facility-not-exist':
            // eslint-disable-next-line no-case-declarations
            confirmPopup.showResponse = true;
            confirmPopup.responseMessage = `The user associated with the Mobile Number you entered is already registered with Loyal.`;
            confirmPopup.subMessage = `Would you like to invite this registered Loyal user to be connected to your facility, including being able to use the Loyal mobile app to communicate with your facility?`;
            confirmPopup.responseType = 'confirm';
            confirmPopup.responseAlertType = 'info';
            confirmPopup.footer = true;
            confirmPopup.okText = 'Yes';
            confirmPopup.cancelText = 'No';
            confirmPopup.action = 'parent-exist-facility-not-exist';
            confirmPopup.parent = np.pets.newParentResponse.data;
            break;
          case 'parent-already-invited':
            confirmPopup.showResponse = true;
            confirmPopup.responseMessage =
              np.pets.newParentResponse.data.message;
            confirmPopup.responseType = 'alert';
            confirmPopup.responseAlertType = 'success';
            confirmPopup.footer = true;
            confirmPopup.okText = 'OK';
            confirmPopup.cancelText = 'Ok';
            break;
          default:
            errorResponse = 'This parent already exists';
            break;
        }
        this.setState({
          confirmPopup,
          selectedParent,
          inputs,
          errorResponse,
          successResponse,
        });

        if (np.pets.newParentResponse.data.status === 'parent-added-success') {
          setTimeout(() => this.clear(), POPUP_TIMEOUT_TIME);
        }
      }
    }
    if (np.pets.newParentErrorResponse) {
      if (np.pets.newParentErrorResponse.errorMessage) {
        this.setState({
          errorResponse: np.pets.newParentErrorResponse.errorMessage,
          successResponse: null,
        });
      }
    }
    if (np.client.statusUpdated) {
      const { confirmPopup, selectedParent } = this.state;
      confirmPopup.showResponse = true;
      confirmPopup.responseMessage = `Invitation resent to ${selectedParent.firstName} ${selectedParent.lastName}`;
      confirmPopup.responseType = 'alert';
      confirmPopup.responseAlertType = 'success';
      confirmPopup.okText = 'OK';
      confirmPopup.cancelText = 'OK';
      confirmPopup.footer = true;
      confirmPopup.action = '';
      this.setState({ confirmPopup });
    }
  }

  submit = () => {
    window.scrollTo(0, 0);

    const { inputs } = this.state;
    this.setState({
      errorResponse: null,
      successResponse: null,
      refresh: false,
    });

    if (!this.validate(inputs)) {
      const data = Object.assign({}, inputs);

      data.pets = data.pets.map((newPet) => newPet.data);
      this.props.toggleLoading(true);
      this.props.addNewParent(data);
    }
  };

  validate = (inputs) => {
    const { refreshState, errors } = this.state;

    let error = false;

    const validationParentRules = { required: ['phoneEmail'] };

    if (inputs.pets.length > 0) {
      validationParentRules.required.push('firstName', 'lastName');
    }
    errors.parent = reset(errors.parent);
    const validationParentResponse = validate(
      validationParentRules,
      inputs.parent,
      errors.parent,
    );
    error = validationParentResponse.error;
    errors.parent = validationParentResponse.errors;

    const validationPetRules = {
      required: ['name', 'species'],
      date: ['birthday'],
      maxToday: ['birthday'],
    };

    if (inputs.pets && inputs.pets.length > 0) {
      inputs.pets = inputs.pets.map((newPet) => {
        newPet.errors = reset(newPet.errors);

        const validationPetResponse = validate(
          validationPetRules,
          newPet.data,
          newPet.errors,
        );

        newPet.errors = validationPetResponse.errors;
        error = error || validationPetResponse.error;
        return newPet;
      });
    }

    this.setState({
      errors,
      error,
      errorResponse: null,
      successResponse: null,
      refreshState: !refreshState,
    });

    return error;
  };

  clear() {
    window.scrollTo(0, 0);

    this.setState({
      successResponse: null,
      errorResponse: null,
      speciesList: [],
      selectedParent: null,
      confirmPopup: {
        showResponse: false,
        responseMessage: '',
        responseType: '',
        responseAlertType: '',
        subMessage: '',
        action: null,
        parent: null,
        status: null,
        footer: false,
      },
      inputs: {
        parent: {
          firstName: '',
          lastName: '',
          phoneEmail: '',
        },
        pets: [],
      },
      error: false,
      errors: {
        parent: {
          firstName: { error: null, display: 'first name' },
          lastName: { error: null, display: 'last name' },
          phoneEmail: { error: null, display: 'mobile phone' },
        },
      },
    });
    this.props.clearClientProps();
  }

  goToClientPage() {
    this.props.navigate('/client-list');
  }

  cancel = () => {
    this.clear();
  };

  onCancelRegister = () => {
    if (this.formHasValues()) {
      const { confirmPopup } = this.state;
      confirmPopup.showResponse = true;
      confirmPopup.responseMessage =
        'Are you sure you want to leave this page?';
      confirmPopup.responseType = 'confirm';
      confirmPopup.responseAlertType = 'info';
      confirmPopup.footer = true;
      confirmPopup.okText = 'Yes';
      confirmPopup.cancelText = 'No';
      confirmPopup.action = 'clear-all';
      this.setState({ confirmPopup });
    } else {
      return this.goToClientPage();
    }
  };

  removePet = (id) => {
    const { inputs } = this.state;
    inputs.pets = inputs.pets.filter((pet) => pet.id !== id);
    this.setState({ inputs });
  };

  addNewPet = () => {
    const { inputs } = this.state;
    if (inputs.pets.length < 2) {
      inputs.pets.push({
        id: Math.random(),
        display: false,
        data: {
          name: '',
          species: '',
          breed: '',
          gender: '',
          birthday: '',
        },
        errors: {
          name: { error: null, display: 'pet name' },
          species: { error: null, display: 'species' },
          birthday: { error: null, display: 'birthday' },
        },
      });
      this.setState({ inputs }, () => {
        window.scrollTo(0, document.body.scrollHeight);
      });
    }
  };

  setToParentInput = (parent) => {
    this.setState({ inputs: { ...this.state.inputs, parent } });
  };

  setToPetInput = (pet, index) => {
    const { inputs } = this.state;
    inputs.pets[index].data = pet;
    this.setState({ inputs });
  };

  closePopup = () => {
    const { confirmPopup } = this.state;
    if (confirmPopup?.action === 'parent-exist-facility-active') {
      this.props.navigate('/client-list');
    }

    this.setState({
      confirmPopup: { ...confirmPopup, showResponse: false },
    });
    this.props.clearUpdateProps();
  };

  onSuccessPopup = () => {
    const { confirmPopup, inputs } = this.state;
    const _this = this;
    confirmPopup.showResponse = false;
    if (confirmPopup?.action === 'parent-exist-facility-not-exist') {
      inputs.parent.addToCurrentAccount = true;
      const data = Object.assign({}, inputs);
      data.pets = data.pets.map((newPet) => newPet.data);
      this.props.addNewParent(data);
    }
    if (confirmPopup?.action === 'parent-exist-facility-inactive') {
      this.props.clearUpdateProps();
      this.props.history.push(
        `/client-list/profile/${confirmPopup.parent.data._id}`,
      );
    }
    this.setState(
      {
        showResponse: false,
        confirmPopup,
        inputs,
      },
      () => {
        if (confirmPopup.action && confirmPopup.action === 'clear-all') {
          _this.goToClientPage();
        }
      },
    );
  };

  gotoParentProfile = () => {
    const { selectedParent } = this.state;
    this.props.history.push(`/client-list/profile/${selectedParent._id}`);
  };

  reInvite = (params) => {
    this.props.toggleLoading(true);
    params.facility = this.props.user.profile.facility._id;
    this.props.reinvite(params);
  };

  render() {
    const {
      selectedParent,
      speciesList,
      inputs,
      errors,
      refreshState,
      userType,
      successResponse,
      errorResponse,
      confirmPopup,
    } = this.state;

    return (
      <div className="create-appointment">
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              {successResponse !== null && (
                <div className="alert alert-success fade show" role="alert">
                  <div className="alert__content">
                    <p>{successResponse}</p>
                  </div>
                </div>
              )}
              {errorResponse !== null && (
                <div
                  className="alert alert-danger fade show"
                  role="alert"
                  style={{ zIndex: 10 }}
                >
                  <div className="alert__content">
                    <p>{errorResponse}</p>
                  </div>
                </div>
              )}

              <div className="content-wrapper">
                <RegisterParent
                  collapse
                  refreshState={refreshState}
                  parentInputs={inputs.parent}
                  parentErrors={errors.parent}
                  parentInputsSet={this.setToParentInput}
                />

                <RegisteredPets
                  selectedParent={selectedParent}
                  collapse
                  refreshState={refreshState}
                  title=""
                  showNewPetButton
                  showNewPetAction={this.addNewPet}
                />
                {inputs.pets.map((newPet, index) => (
                  <RegisterNewPet
                    key={newPet.id}
                    index={index}
                    removePetAction={this.removePet}
                    speciesList={speciesList}
                    refreshState={refreshState}
                    pet={newPet}
                    petInputs={newPet.data}
                    petErrors={newPet.errors}
                    petInputsSet={this.setToPetInput}
                    collapse={newPet.display}
                  />
                ))}
                <div className="row">
                  <div className="col-md-4 text-left"></div>
                  <div
                    className="col-md-8 text-right"
                    style={{ display: 'flex' }}
                  >
                    <button
                      className="btn loyal-btn-clear"
                      onClick={this.onCancelRegister}
                    >
                      Cancel
                    </button>

                    <button
                      className="btn loyal-btn-save-update"
                      onClick={this.submit}
                    >
                      Invite
                    </button>

                    {userType === config.USER_TYPES.STAFF && (
                      <button
                        className="btn loyal-btn-save-update"
                        onClick={this.gotoParentProfile}
                      >
                        View Parent Profile
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </CardBody>
            <MessageModal
              show={confirmPopup.showResponse}
              type={confirmPopup.responseType}
              alertType={confirmPopup.responseAlertType}
              footer={confirmPopup.footer}
              okText={confirmPopup.okText}
              cancelText={confirmPopup.cancelText}
              onSuccess={this.onSuccessPopup}
              onClose={this.closePopup}
              message={confirmPopup.responseMessage}
              subMessage={confirmPopup.subMessage}
            />
          </Card>
        </Col>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = {
  getParent,
  getSpecies,
  addNewParent,
  toggleLoading,
  clearClientProps,
  reinvite,
  clearUpdateProps,
  clearPetList,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RegisterClient),
);
