import React, { createContext, useContext, useState } from 'react';

const responseBoxInitialState = {
  showResponse: false,
  responseType: null,
  responseAlertType: null,
  responseFooter: false,
  message: null,
};

export const StatusChangeModalContext = createContext();

export const StatusChangeModalProvider = ({ children, handleStatusChange }) => {
  const [inputValue, setInputValue] = useState('');
  const [responseBox, setResponseBox] = useState(responseBoxInitialState);
  const [statusChangeData, setStatusChangeData] = useState({});

  const prepareConfirmationMessage = ({
    currentStatus,
    newStatus,
    newStatusId,
    prescriptionId,
  }) => {
    if (currentStatus === newStatus) {
      return;
    }

    setStatusChangeData({ newStatusId, prescriptionId });

    setResponseBox({
      showResponse: true,
      responseType: 'confirm',
      responseAlertType: 'danger',
      responseFooter: true,
      message: `Are you sure you want to change the status<br/>from <strong>${currentStatus}</strong/> to <strong>${newStatus}</strong>?`,
    });
  };

  const onSuccess = async () => {
    const { newStatusId, prescriptionId } = statusChangeData;
    handleStatusChange(newStatusId, prescriptionId, inputValue);
    onClose();
  };

  const onClose = () => {
    setInputValue('');
    setStatusChangeData({});
    setResponseBox(responseBoxInitialState);
  };

  return (
    <StatusChangeModalContext.Provider
      value={{
        statusChangeData,
        inputValue,
        setInputValue,
        responseBox,
        setResponseBox,
        prepareConfirmationMessage,
        onSuccess,
        onClose,
      }}
    >
      {children}
    </StatusChangeModalContext.Provider>
  );
};

export const useStatusChangeModalContext = () => {
  return useContext(StatusChangeModalContext);
};
