import React from 'react';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { refillRequestDefaultStatuses } from '../../../../config/app.config';

export const getFilteredStatuses = (deliveryType, allStatuses) => {
  let dropdownStatuses = [...allStatuses];

  if (deliveryType === 'pickup') {
    dropdownStatuses = dropdownStatuses.filter(
      (status) => status.label !== refillRequestDefaultStatuses.shipped,
    );
  } else if (deliveryType === 'delivery') {
    dropdownStatuses = dropdownStatuses.filter(
      (status) => status.label !== refillRequestDefaultStatuses.readyForPickup,
    );
  }

  return dropdownStatuses;
};

export function StatusDropdown({
  currentStatus,
  statuses,
  onStatusChange,
  className,
}) {
  const noStatus = currentStatus === '-';

  return (
    <UncontrolledDropdown className={className}>
      <DropdownToggle
        id="dropdown-toggle"
        className={noStatus ? 'no-status' : ''}
        caret
      >
        {currentStatus}
      </DropdownToggle>
      <DropdownMenu className="dropdown__menu">
        {statuses.map((status, index) => (
          <DropdownItem
            tag="div"
            key={index}
            onClick={() => onStatusChange(status)}
          >
            <a
              style={{
                fontWeight: currentStatus === status.label ? '600' : '400',
              }}
            >
              {status.label}
            </a>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}
