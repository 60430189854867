import React, { useState } from 'react';
import Avatar from 'react-avatar-edit';
import { Button, ButtonToolbar, Modal, ModalBody } from 'reactstrap';

export function AvatarModal({
  isOpen,
  onCancel = () => {},
  onDone = () => {},
  withCrop = true,
  src,
}) {
  const [preview, setPreview] = useState();
  const [selectedFile, setSelectedFile] = useState();

  const onClose = () => {
    setPreview(null);
    setSelectedFile(null);
  };

  const onBeforeFileLoad = (elem) => {
    const [file] = elem.target.files;

    if (file.size > 9000000) {
      alert('File is too big!');
      elem.target.value = '';
      return;
    }

    if (!withCrop) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setSelectedFile(event.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      size="md"
      backdrop="static"
      className="modal-dialog modal-dialog--confirm modal-dialog--header alert-modal crop-images"
      style={{
        zIndex: 1060,
      }}
    >
      <ModalBody
        style={{
          minHeight: '670px',
          overflow: 'visible',
        }}
      >
        <div className="modal__body">
          {((!withCrop && !selectedFile) || withCrop) && (
            <Avatar
              width={440}
              height={295}
              onCrop={(image) => {
                withCrop && setPreview(image);
              }}
              onClose={onClose}
              onBeforeFileLoad={onBeforeFileLoad}
              src={src}
            />
          )}

          <img
            src={preview || selectedFile}
            style={{
              width: '200px',
              visibility: preview || selectedFile ? 'visible' : 'hidden',
              marginTop: '10px',
            }}
            alt="Preview"
          />
        </div>
        <ButtonToolbar className="modal__footer">
          <Button
            className="btn btn-no btn-secondary pull-left"
            onClick={() => {
              onCancel();
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            className="btn btn-yes btn-success pull-right"
            onClick={() => {
              onDone(withCrop ? preview : selectedFile);
              onClose();
            }}
          >
            {withCrop ? 'Crop' : 'Select'}
          </Button>
        </ButtonToolbar>
      </ModalBody>
    </Modal>
  );
}
