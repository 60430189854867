import React from 'react';
import { Input, Label, FormGroup } from 'reactstrap';
import PropTypes from 'prop-types';

const ToggleSwitch = ({ label, checked, handleToggle }) => {
  return (
    <FormGroup switch className="toggle-wrapper">
      <Label className="toggle-label">{label}</Label>
      <Input
        id="toggle-switch"
        type="switch"
        role="switch"
        checked={checked}
        onChange={handleToggle}
      />
    </FormGroup>
  );
};

ToggleSwitch.propTypes = {
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  handleToggle: PropTypes.func.isRequired,
};

export default ToggleSwitch;
