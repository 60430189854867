import React from 'react';
import CustomSelect from '../CustomSelect/CustomSelect';

export default function SpeciesSelect({ value, options, error, onChange }) {
  return (
    <CustomSelect
      value={value}
      options={options}
      handleOptionSelect={onChange}
      error={error}
      placeholder="Select Species"
    />
  );
}
