import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { FormGroup } from 'reactstrap';
import debounce from 'lodash.debounce';
import {
  getBreed,
  getFilteredBreeds,
} from '../../../../redux/actions/breedAction';
import Error from '../../form/Error';

const COMMON_BREEDS = ['Mixed', 'Other', 'Unknown'];

export default function BreedsSelect({ value, onChange, species, error }) {
  const [breeds, setBreeds] = useState([]);
  const [search, setSearch] = useState();
  const [breedValue, setBreedValue] = useState();

  const debouncedSearch = debounce((value) => {
    setSearch(value);
  }, 300);

  useEffect(() => {
    async function getBreeds() {
      const speciesFilter = Array.isArray(species) ? species : [species];
      const breeds = await getFilteredBreeds({
        filter: { species: speciesFilter },
        search,
      });

      setBreeds(
        breeds.map((value) => ({
          value: value._id,
          label: value.name,
        })),
      );
    }

    getBreeds();
  }, [species, search]);

  useEffect(() => {
    if (!value) return;
    const [breedValue] = breeds.filter((option) => option.value === value);
    if (breedValue) {
      setBreedValue(breedValue);
    } else {
      getBreed(value).then(({ _id, name }) => {
        setBreedValue({
          value: _id,
          label: name,
        });
      });
    }
  }, [breeds, value]);

  return (
    <FormGroup>
      <Select
        value={breedValue}
        onChange={onChange}
        className="custom-select-control"
        options={breeds}
        placeholder="Search"
        onInputChange={(search) => debouncedSearch(search)}
        styles={{
          container: (base, props) => ({
            ...base,
            width: '180px',
            zIndex: props.isFocused ? 102 : 1,
          }),
          input: (base) => ({
            ...base,
            minWidth: '82px !important',
          }),
          option: (base, props) => ({
            ...base,
            fontWeight: COMMON_BREEDS.includes(props.data.label)
              ? 'bold'
              : 'inherit',
          }),
        }}
        matchProp="_id"
        isClearable={false}
        name="breed"
        id="breed"
      />
      {error && <Error text={error} />}
    </FormGroup>
  );
}
