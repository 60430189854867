import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import { NavLink, useLocation } from 'react-router-dom';

const SidebarLinkCustomIcon = ({ title, icon, newLink, route, link }) => {
  const internal = SidebarEntryInternal(icon, title, newLink);
  const location = useLocation();

  if (link) {
    return (
      <a href={link} target="_blank" rel="noopener noreferrer">
        {internal}
      </a>
    );
  }

  return (
    <NavLink
      to={route}
      state={{ from: location }}
      className={({ isActive }) => (isActive ? 'sidebar__link-active' : '')}
    >
      {internal}
    </NavLink>
  );
};

const SidebarEntryInternal = (icon, title, newLink) => (
  <li className="sidebar__link custom_sidebar_link">
    {icon ? (
      <img
        src={`/img/icons/${icon}.png`}
        className={`icons8-${icon}`}
        alt="Icon"
      />
    ) : (
      ''
    )}
    <p className="sidebar__link-title">
      {title}
      {newLink && (
        <Badge className="sidebar__link-badge">
          <span>New</span>
        </Badge>
      )}
    </p>
  </li>
);

SidebarLinkCustomIcon.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  newLink: PropTypes.bool,
  route: PropTypes.string,
};

SidebarLinkCustomIcon.defaultProps = {
  icon: '',
  newLink: false,
  route: '/',
};

export default SidebarLinkCustomIcon;
