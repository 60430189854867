import React, { Component } from 'react';

let isDefaultSet = false;

export default class CustomSelectComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allFilters: [],
      filteredList: [],
      isFilterDropdownVisible: false,
      isAllSelected: false,
      searchValue: '',
      tempSelectedFilters: [],
    };
  }

  componentDidMount() {
    this.setState({
      allFilters: this.props.allFilters,
      filteredList: this.props.allFilters,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.allFilters) {
      this.setState({
        allFilters: nextProps.allFilters,
        filteredList: nextProps.allFilters,
      });
    }

    if (
      nextProps.defaultList &&
      !isDefaultSet &&
      nextProps.allFilters &&
      nextProps.allFilters.length > 0
    ) {
      this.setState(
        {
          tempSelectedFilters: nextProps.defaultList
            ? nextProps.defaultList
            : [],
        },
        () => {
          isDefaultSet = true;
          this.handleSelectAll();
        },
      );
    }
  }

  UNSAFE_componentWillMount() {
    document.addEventListener('mousedown', this.handleOutsideClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick, false);
    isDefaultSet = false;
  }

  handleOutsideClick = (e) => {
    if (this.node && !this.node.contains(e.target)) {
      this.setState({
        isFilterDropdownVisible: false,
      });
    }
  };

  handleSelectAll = () => {
    const { allFilters, isAllSelected } = this.state;

    const selectedStates = isAllSelected
      ? []
      : allFilters.map((filter) => filter.id);

    this.setState(
      {
        isAllSelected: !isAllSelected,
        tempSelectedFilters: selectedStates,
      },
      () => {
        this.handlePlaceholderText();
        this.handleApplyStatesFilter();
      },
    );
  };

  handleStateChangeFocus = () => {
    this.setState({
      isFilterDropdownVisible: true,
    });
  };

  handlePlaceholderText = () => {
    const { allFilters, tempSelectedFilters } = this.state;
    const { filterTypeText, filterTypeTextPlural } = this.props;
    const searchElement = this.refs.searchInput;

    let placeholderText = `Select ${filterTypeText}`;

    if (tempSelectedFilters.length === 1) {
      placeholderText = `${tempSelectedFilters.length} ${filterTypeText} Selected`;
    }

    if (tempSelectedFilters.length > 1) {
      placeholderText = `${tempSelectedFilters.length} ${filterTypeTextPlural} Selected`;
    }

    if (tempSelectedFilters.length === allFilters.length) {
      placeholderText = `All ${filterTypeTextPlural} Selected`;
    }

    searchElement.placeholder = placeholderText;
  };

  handleApplyStatesFilter = () => {
    const { allFilters, tempSelectedFilters } = this.state;
    this.handlePlaceholderText();
    this.props.action(tempSelectedFilters);

    this.setState({ filteredList: allFilters });
  };

  handleSelectState = (state) => {
    const { isAllSelected, tempSelectedFilters, allFilters } = this.state;
    const selectedIndex = tempSelectedFilters.indexOf(state);

    if (selectedIndex === -1) {
      tempSelectedFilters.push(state);
    } else {
      tempSelectedFilters.splice(selectedIndex, 1);

      if (isAllSelected) {
        this.handleSelectAll();
      }
    }
    if (allFilters.length === tempSelectedFilters.length) {
      this.handleSelectAll();
    }

    this.setState({
      tempSelectedFilters,
    });
    this.handlePlaceholderText();
    this.handleApplyStatesFilter();
  };

  isStateSelected = (state) => {
    const { tempSelectedFilters } = this.state;
    return tempSelectedFilters.indexOf(state) !== -1;
  };

  handleSearch = (e) => {};

  render() {
    const {
      isFilterDropdownVisible,
      filteredList,
      isAllSelected,
      searchValue,
    } = this.state;
    const { filterTypeText } = this.props;

    const states = filteredList.map((state) => {
      return (
        <div className="states-block" key={state.id}>
          <input
            type="checkbox"
            className="checkbox-input"
            id={state.id}
            checked={this.isStateSelected(state.id)}
            onChange={() => {
              this.handleSelectState(state.id);
            }}
          />
          <label htmlFor={state.id} className="checkbox-label">
            {state.name}
          </label>
        </div>
      );
    });

    return (
      <div
        className="custom-select-box-wrapper clearfix"
        ref={(node) => (this.node = node)}
      >
        <input
          type="text"
          className="form-control search-state"
          placeholder={`Select ${filterTypeText}`}
          ref="searchInput"
          value={searchValue}
          onFocus={this.handleStateChangeFocus}
          onChange={(e) => {
            this.handleSearch(e);
          }}
        />
        {isFilterDropdownVisible && filteredList.length > 0 && (
          <div className="states-result">
            <div className="blocks-wrapper">
              {filteredList.length === 1 ? null : (
                <div className="states-block">
                  <input
                    type="checkbox"
                    className="checkbox-input"
                    checked={isAllSelected}
                    id="select_all"
                    onChange={this.handleSelectAll}
                  />
                  <label
                    htmlFor="select_all"
                    className="checkbox-label select-all"
                  >
                    All
                  </label>
                </div>
              )}
              {states}
            </div>
          </div>
        )}
      </div>
    );
  }
}
