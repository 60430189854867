import React, { Component } from 'react';
import { Button, ButtonToolbar, Modal } from 'reactstrap';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { toggleMessageModel } from '../../../redux/actions/commonActions';
import config from '../../../config/app.config';
import ConfirmPopupDetails from '../../../containers/Web/CreateAppointment/Components/ConfirmPopupDetails';

const TYPE = { ALERT: 'alert' };

let checkImage = `${process.env.PUBLIC_URL}/img/check-line-icon.png`;

class MessageModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  componentDidMount() {
    this.setState({ show: this.props.show });
  }

  UNSAFE_componentWillReceiveProps(np) {
    this.setState({ show: np.show }, () => {
      if (np.show && np.type === TYPE.ALERT) {
        setTimeout(() => {
          this.props.onClose();
        }, config.POPUP_TIMEOUT_TIME);
      }
    });
  }

  onClose = () => {
    this.props.onClose();
  };

  onSuccess = () => {
    this.props.onSuccess();
  };

  render() {
    const {
      type,
      alertType,
      message,
      okText,
      cancelText,
      colored = false,
      header = true,
      footer = false,
      appointment = null,
      subMessage = '',
      bodyExtraComponent: Component = null,
    } = this.props;

    switch (type) {
      case 'alert':
        switch (alertType) {
          case 'danger':
            checkImage = `${process.env.PUBLIC_URL}/img/error_icon.png`;
            break;
          case 'info':
            checkImage = `${process.env.PUBLIC_URL}/img/info_icon.png`;
            break;
          default:
            checkImage = `${process.env.PUBLIC_URL}/img/check-line-icon.png`;
            break;
        }
        break;
      case 'ok':
        switch (alertType) {
          case 'danger':
            checkImage = `${process.env.PUBLIC_URL}/img/error_icon.png`;
            break;
          case 'info':
            checkImage = `${process.env.PUBLIC_URL}/img/info_icon.png`;
            break;
          default:
            checkImage = `${process.env.PUBLIC_URL}/img/check-line-icon.png`;
            break;
        }
        break;
      default:
        break;
    }
    const modalClass = classNames({
      'modal-dialog--colored': colored,
      'modal-dialog--header': header,
    });
    const closeButtonText =
      cancelText || (type === 'alert' || type === 'ok' ? 'OK' : 'No');
    const closeButtonClass =
      type === 'alert' || type === 'ok' ? 'btn-yes' : 'btn-no';
    const okButtonText = okText || 'Yes';

    return (
      <Modal
        isOpen={this.state.show}
        toggle={this.onClose}
        className={`modal-dialog--${type} ${modalClass} alert-modal`}
      >
        <div className="modal__body">
          {(type === 'alert' || type === 'ok') && (
            <img src={checkImage} alt="check" />
          )}
          {appointment && <ConfirmPopupDetails {...appointment} />}
          <p>{message}</p>
          {subMessage && <span>{subMessage}</span>}
          {Component}
        </div>
        {footer && (
          <ButtonToolbar className="modal__footer">
            <Button className={closeButtonClass} onClick={this.onClose}>
              {closeButtonText}
            </Button>
            {type === 'confirm' && (
              <Button className="btn-yes" onClick={this.onSuccess}>
                {okButtonText}
              </Button>
            )}
          </ButtonToolbar>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = {
  toggleMessageModel,
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageModal);
