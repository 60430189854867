import { handleRequest } from '../services/APIService';

export const FETCH_APPOINTMENT_REQUESTS = 'FETCH_APPOINTMENT_REQUESTS';
export const FETCH_APPOINTMENT_REQUESTS_ERROR =
  'FETCH_APPOINTMENT_REQUESTS_ERROR';
export const RESET_APPOINTMENT_REQUESTS = 'RESET_APPOINTMENT_REQUESTS';
export const SET_STATUS_TOGGLE_STATE = 'SET_STATUS_TOGGLE_STATE';

export const getAllAppointmentRequests = (params) => {
  return async (dispatch) => {
    try {
      const results = await handleRequest(
        'get',
        `appointment-requests`,
        true,
        {},
        params,
      );

      dispatch({
        type: FETCH_APPOINTMENT_REQUESTS,
        payload: results.data,
      });
    } catch (error) {
      dispatch({
        type: FETCH_APPOINTMENT_REQUESTS_ERROR,
        payload: error.response || error,
      });
    }
  };
};

export const resetAppointmentRequests = () => ({
  type: RESET_APPOINTMENT_REQUESTS,
});

export const setStatusToggleState = (pageType, status) => ({
  type: SET_STATUS_TOGGLE_STATE,
  payload: { pageType, status },
});
