import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, ModalFooter, Alert } from 'reactstrap';
import * as userActions from '../../../redux/actions/userActions';
import VerifyForm from './components/VerifyForm';
import withRouter from '../../../shared/components/withRouter';

const icon = `${process.env.PUBLIC_URL}/img/loyal-logo.png`;

class Verify extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const search = this.props.location.search;
    const searchParams = new URLSearchParams(search);
    const token = searchParams.get('token');
    if (!searchParams) {
      this.props.history.push('/login');
      return;
    }

    this.setState({ token });
    this.props.getSupporterInfo(searchParams);
  }

  render() {
    return (
      <div className="account user-flow">
        <div className="account__wrapper">
          <div className="account__card__onboarding p-5">
            <div className="account__card--inner">
              <div className="account__head">
                <h2 className="mb-4">
                  Accept invite
                  <span className="account__logo">
                    <img src={icon} alt="" />
                  </span>
                </h2>
                {!this.props.success && (
                  <VerifyForm
                    user={this.props.info}
                    token={this.state.token}
                    hideConfirm={this.props.success}
                  />
                )}
              </div>
              <br />
              {(this.props.success || this.props.error) && (
                <Alert color={this.props.success ? 'success' : 'danger'}>
                  {this.props.success ? 'Accepted.' : this.props.error}
                </Alert>
              )}
            </div>
          </div>
        </div>
        <Modal
          isOpen={this.props.supporterError}
          contentClassName="p-0"
          toggle={() => window.close()}
          className="p-3"
        >
          <div className="p-3">The token has expired</div>
          <ModalFooter>
            <Button
              size="sm"
              className="m-0"
              color="primary"
              onClick={() => window.close()}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  info: { ...state.user.supporterUser },
  error: state.user.acceptInvitationError,
  success: state.user.acceptInvitation,
  supporterError: state.user.supporterError,
});

const mapDispatchToProps = {
  getSupporterInfo: userActions.getSupporterInfo,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Verify));
