import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col, Button } from 'reactstrap';
import Checkbox from 'rc-checkbox';
import { connect } from 'react-redux';
import 'rc-checkbox/assets/index.css';

import Error from '../../shared/components/form/Error';
import Confirm from '../../shared/components/Modals/MessageModal';
import FacilitySelect from '../../shared/components/inputs/FacilitySelect/FacilitySelect';
import { REGEX_EMAIL, STATUS } from '../../constants/constants';
import {
  changeStatus,
  reinvite,
  setAdminIsPrimary,
} from '../../redux/actions/adminActions';

class NewAdminModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPrimary: false,
      isUpdate: false,
      isConfirm: false,
      message: null,
      buttonText: 'Save and Invite',
      email: '',
      facilityId: '',
      errors: {
        firstName: null,
        lastName: null,
        email: null,
        facility: null,
      },
      successResponse: null,
      errorResponse: null,
      status: null,
      joined: false,
    };
  }

  componentDidMount() {
    if (this.props.data.updateData) {
      const {
        firstName,
        lastName,
        email,
        facility,
        id,
        facilityId,
        isPrimary,
        status,
        joined,
      } = this.props.data.updateData;

      this.setState({
        firstName,
        lastName,
        email,
        facility,
        id,
        facilityId,
        isPrimary,
        status,
        isUpdate: true,
        buttonText: 'Update',
        joined,
      });
    }

    this.setState({ data: this.props.data });
  }

  UNSAFE_componentWillReceiveProps(np) {
    if (np.data.invitationError) {
      this.setState({
        errorResponse: np.data.invitationError,
        successResponse: null,
      });
    }
    if (np.successResponse) {
      this.setState({
        successResponse: np.successResponse,
        errorResponse: null,
      });
    }
  }

  onSuccess = () => {
    this.setState({ isPrimary: true, isConfirm: false });
  };

  setAdminPrimary = async ({ isPrimary }) => {
    try {
      const { message } = await setAdminIsPrimary({
        adminId: this.state.id,
        isPrimary,
      });

      this.setState({
        isPrimary,
        successResponse: message,
        isConfirm: false,
        onConfirmSuccess: null,
      });
    } catch (err) {
      this.setState({
        errorResponse: err?.error || err?.message || err,
        isConfirm: false,
        successResponse: null,
        onConfirmSuccess: null,
      });
    }
  };

  onChangeCheckbox = async (e) => {
    const checked = e.target.checked;
    const { facilityId, isUpdate } = this.state;

    if (!facilityId) {
      const errors = { facility: 'Facility cannot be empty' };
      this.setState({ errors });
      return false;
    } else {
      const errors = { facility: null };
      this.setState({ errors });
    }

    if (!isUpdate) {
      this.setState({ isPrimary: checked, isConfirm: false });
    } else {
      this.setState({
        onConfirmSuccess: () => {
          this.setAdminPrimary({ isPrimary: checked });
        },
      });
      const message = checked
        ? `Are you sure you want to change the primary admin to ${this.state.email} ?`
        : `Are you sure you want to set the ${this.state.email} admin as a regular?`;

      this.setState({
        isConfirm: true,
        message,
      });
    }
  };

  save = () => {
    const { firstName, lastName, email, isPrimary, isUpdate, id, facilityId } =
      this.state;
    const data = {
      firstName,
      lastName,
      email,
      isPrimary,
      facility: facilityId,
    };
    if (!this.validate()) {
      if (isUpdate) {
        data.adminId = id;
        data.facility = facilityId;
        this.props.update(data);
      } else {
        this.props.saveAndInvite(data);
      }
    }
  };

  validate() {
    const { firstName, lastName, email, facilityId } = this.state;
    let error = false;

    const errors = {
      firstName: null,
      lastName: null,
      email: null,
      facility: null,
    };

    if (!firstName) {
      errors.firstName = 'First name cannot be empty';
      error = true;
    } else if (/\s/.test(firstName)) {
      errors.firstName = 'Invalid first name';
      error = true;
    } else if (firstName.length > 50) {
      errors.firstName = 'Invalid first name';
      error = true;
    }

    if (!lastName) {
      errors.lastName = 'Last name cannot be empty';
      error = true;
    } else if (/\s/.test(lastName) || lastName.length > 50) {
      errors.lastName = 'Invalid last name';
      error = true;
    }

    if (email && !REGEX_EMAIL.test(email)) {
      errors.email = 'Invalid email';
      error = true;
    } else if (!email) {
      errors.email = 'Email cannot be empty';
      error = true;
    }
    if (facilityId === 0 || !facilityId) {
      errors.facility = 'Facility cannot be empty';
      error = true;
    }

    this.setState({ errors, isConfirm: false });
    return error;
  }

  onChangeFields = (e) => {
    this.setState({ [e.target.name]: e.target.value, isConfirm: false });
    if (e.target.name === 'facilityId') {
      this.setState({ isPrimary: false });
    }
  };

  handleSelectFacility = (value) => {
    this.setState({ facilityId: value, isPrimary: false });
  };

  onClose = () => {
    this.setState({ isConfirm: false, isPrimary: false });
  };

  handleActivateDeactivate = (status) => {
    this.props.changeStatus({
      id: this.state.id,
      status: status === STATUS.ACTIVE ? STATUS.INACTIVE : STATUS.ACTIVE,
    });

    this.props.toggleModal.call(this, false);
  };

  handleReinvite = () => {
    this.props.reinvite({ adminId: this.state.id });

    this.props.toggleModal.call(this, false);
  };

  render() {
    const {
      errors,
      isPrimary,
      firstName,
      lastName,
      email,
      facilityId,
      isUpdate,
      message,
      isConfirm,
      status,
      buttonText,
      successResponse,
      joined,
      errorResponse,
    } = this.state;

    return (
      <>
        <Confirm
          show={isConfirm}
          type="confirm"
          footer
          message={message}
          onSuccess={this.state.onConfirmSuccess || this.onSuccess}
          onClose={this.onClose}
        />

        <Modal isOpen toggle={false} className="model-form modal-lg">
          <ModalHeader className="header-form" toggle={this.toggle}>
            {!isUpdate && 'Invite New '}Admin
          </ModalHeader>
          <ModalBody>
            <div className="form">
              {successResponse !== null && (
                <div className="alert alert-success fade show" role="alert">
                  <div className="alert__content">
                    <p>{successResponse}</p>
                  </div>
                </div>
              )}
              {errorResponse !== null && (
                <div className="alert alert-danger fade show" role="alert">
                  <div className="alert__content">
                    <p>{errorResponse}</p>
                  </div>
                </div>
              )}
              <Row>
                <Col>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      First Name<span className="loyal-required-star">*</span>
                    </span>
                    <div className="form__form-group-field">
                      <input
                        name="firstName"
                        type="text"
                        placeholder=""
                        value={firstName}
                        onChange={this.onChangeFields}
                        maxLength={50}
                        autoComplete="off"
                      />
                    </div>
                    {errors.firstName && <Error text={errors.firstName} />}
                  </div>
                </Col>
                <Col>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      Last Name<span className="loyal-required-star">*</span>
                    </span>
                    <div className="form__form-group-field">
                      <input
                        name="lastName"
                        type="text"
                        placeholder=""
                        value={lastName}
                        onChange={this.onChangeFields}
                        maxLength={50}
                        autoComplete="off"
                      />
                    </div>
                    {errors.lastName && <Error text={errors.lastName} />}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      Email<span className="loyal-required-star">*</span>
                    </span>
                    <div className="form__form-group-field">
                      <input
                        name="email"
                        type="text"
                        placeholder=""
                        value={email}
                        onChange={this.onChangeFields}
                        autoComplete="off"
                      />
                    </div>
                    {errors.email && <Error text={errors.email} />}
                  </div>
                </Col>
                <Col>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      Facility<span className="loyal-required-star">*</span>
                    </span>

                    <FacilitySelect
                      value={facilityId}
                      onChange={this.handleSelectFacility}
                      disabled={isUpdate}
                      error={errors.facility}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label
                    title={
                      (this.state.disabled ||
                        (isUpdate && this.state.status === 'inactive') ||
                        (isUpdate && this.props.data.updateData.isPrimary)) &&
                      'You cannot change the primary admin here. If you want to change, please select another admin as the primary admin'
                    }
                  >
                    <Checkbox
                      checked={isPrimary}
                      onChange={this.onChangeCheckbox}
                      disabled={
                        this.state.disabled ||
                        (isUpdate && status === 'inactive')
                      }
                    />
                    &nbsp; Primary Admin
                  </label>
                  {status && (
                    <div style={{ marginTop: '20px' }}>
                      Status: {joined ? status : 'invited'}
                    </div>
                  )}
                </Col>
                <Col>
                  <div>
                    <Button
                      className="loyal-btn-dark"
                      onClick={this.props.toggleModal.bind(this, false)}
                      color="primary"
                    >
                      Cancel
                    </Button>
                    <Button onClick={this.save} color="primary">
                      {buttonText}
                    </Button>
                  </div>

                  <div style={{ marginTop: '20px' }}>
                    {status && !joined && (
                      <Button
                        className="loyal-btn-dark"
                        onClick={() => this.handleReinvite()}
                        color="primary"
                      >
                        Reinvite
                      </Button>
                    )}

                    {!isPrimary && status === STATUS.ACTIVE && (
                      <Button
                        className="loyal-btn-dark"
                        onClick={() =>
                          this.handleActivateDeactivate(STATUS.ACTIVE)
                        }
                        color="primary"
                      >
                        Deactivate
                      </Button>
                    )}

                    {status === STATUS.INACTIVE && (
                      <Button
                        className="loyal-btn-dark"
                        onClick={() =>
                          this.handleActivateDeactivate(STATUS.INACTIVE)
                        }
                        color="primary"
                      >
                        Activate
                      </Button>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

const mapDispatchToProps = {
  changeStatus,
  reinvite,
};

export default connect(null, mapDispatchToProps)(NewAdminModal);
