import React, { Component } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import TimeRangeSelect from '../../shared/components/inputs/TimeRangeSelect/TimeRangeSelect';

const totalCountByType = {
  daily: 30,
  weekly: 12,
  monthly: 6,
};

export default class NewUserComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newUser: null,
      type: 'daily',
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.newUser !== null) {
      this.setState({ newUser: this.props.newUser });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.newUser !== null) {
      this.setState({ newUser: nextProps.newUser });
    }
  }

  handleType = (e) => {
    const type = e.target.value;
    this.setState({ type }, () => {
      this.props.handleNewUserType(type);
    });
  };

  render() {
    const { newUser, type } = this.state;
    const { processedDate } = this.props;

    const totalCount = totalCountByType[type] || 30;

    return (
      <div className="total-average new-user">
        <div className="header-container">
          <h3>New Users</h3>
          <TimeRangeSelect handleTypeSelect={this.handleType} />
          <p className="loyal-last-process-date">
            Last updated {processedDate}
          </p>
        </div>
        <div className="chart">
          <ResponsiveContainer width="100%" height={240}>
            <LineChart
              data={newUser && newUser.data}
              syncId="anyId"
              margin={{ top: 10, right: 20, left: 0, bottom: 0 }}
            >
              <CartesianGrid strokeDasharray="0 0" vertical={false} />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Line
                type="linear"
                dataKey="pet"
                stroke="#11a5a5"
                fill="#11a5a5"
              />
              <Line
                type="linear"
                dataKey="provider"
                stroke="#1983c3"
                fill="#1983c3"
              />
              <Line
                type="linear"
                dataKey="client"
                stroke="#fbc756"
                fill="#fbc756"
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div className="total-average-wrapper">
          <div className="label">
            <span> Total</span>
            <span> Average</span>
          </div>
          <div className="no-padding client">
            <span>{newUser && newUser.totalClient}</span>
            <span>
              {newUser && (newUser.totalClient / totalCount).toFixed(2)}
            </span>
          </div>
          <div className="no-padding pet">
            <span>{newUser && newUser.totalPet}</span>
            <span>{newUser && (newUser.totalPet / totalCount).toFixed(2)}</span>
          </div>
          <div className="no-padding provider">
            <span>{newUser && newUser.totalProvider}</span>
            <span>
              {newUser && (newUser.totalProvider / totalCount).toFixed(2)}
            </span>
          </div>
          <div className="bullet-wrapper">
            <div className="bullets">
              <span key="client">
                <span className="client"></span>&nbsp;&nbsp; Clients
              </span>
              <span key="pet">
                <span className="pet"></span>&nbsp;&nbsp; Pets
              </span>
              <span key="provider">
                <span className="provider"></span>&nbsp;&nbsp; Provider
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
