import React, { Component } from 'react';
import { Collapse, FormGroup } from 'reactstrap';
import moment from 'moment';
import { connect } from 'react-redux';
import {
  updatePetStatus,
  clearUpdatePetStatus,
  updatePet,
  openPetAppointmentRequestsModal,
  closePetAppointmentRequestsModal,
  setPetAppointmentRequestsNumber,
  resetPetAppointmentRequestsState,
} from '../../../../redux/actions/petActions';
import { toggleLoading } from '../../../../redux/actions/commonActions';
import {
  getAllAppointmentRequests,
  resetAppointmentRequests,
} from '../../../../redux/actions/appointmentRequestActions';

import Error from '../../../../shared/components/form/Error';
import { formatPhone } from '../../../../libs/commonHelper';
import config from '../../../../config/app.config';
import MessageModal from '../../../../shared/components/Modals/MessageModal';
import { reset, validate } from '../../../../libs/validationHelper';
import DatePickerComponent from '../../Common/DatePickerComponent';
import { getBreeds } from '../../../../redux/actions/breedAction';
import GenderSelect from '../../../../shared/components/inputs/GenderSelect/GenderSelect';
import BreedsSelect from '../../../../shared/components/inputs/BreedsSelect/BreedsSelect';
import SpeciesSelect from '../../../../shared/components/inputs/SpeciesSelect/SpeciesSelect';
import NewPetAppointment from './NewPetAppointment';
import {
  DEFAULT_TIMESTAMP_FORMAT,
  STATUS,
} from '../../../../constants/constants';
import { PetAppointmentRequestsModal } from './PetAppointmentRequestsModal';

const defaultAvatar = `${process.env.PUBLIC_URL}/img/default-animal-black.png`;

class PetItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pet: {},
      collapse: false,
      updateRequested: false,
      isCreateAppointmentModalOpen: false,
      inputs: {
        name: '',
        species: '',
        breed: '',
        gender: '',
        birthday: '',
      },
      errors: {
        name: { error: null, display: 'pet name' },
        species: { error: null, display: 'species' },
        birthday: { error: null, display: 'birthday' },
      },
      error: false,
      refreshState: false,
      confirmPopup: {
        showResponse: false,
        responseMessage: '',
        responseAlertType: '',
        responseType: '',
        responseTitle: '',
        pet: null,
        status: null,
        confirmType: null,
      },
    };
  }

  async componentDidMount() {
    const { inputs } = this.state;
    const pet = this.props.pet;

    inputs.name = pet.name;
    inputs.species = pet.speciesId;
    inputs.breed = pet.breedId || '';
    inputs.birthday = pet.birthday || '';
    inputs.gender = pet.gender || '';

    const supporters = pet.supporters;
    const activeSupporters = supporters.filter((supporter) =>
      supporter?.status === config.CO_PARENT_STATUS.JOINED ? supporter : false,
    );
    pet.activeSuppoterCount = activeSupporters.length || 0;

    this.setState({ pet, inputs });

    await this.props.getAllAppointmentRequests({
      parentId: this.props.parentId,
      petId: pet._id,
      status: [config.appointmentRequestStatus.pending],
    });

    this.props.setPetAppointmentRequestsNumber({
      [pet._id]: this.props.appointmentRequests?.total ?? 0,
    });
  }

  UNSAFE_componentWillReceiveProps(np) {
    const { updateRequested } = this.state;

    if (np.pets.petUpdateStatus && updateRequested) {
      const { refreshState, confirmPopup, pet } = this.state;

      confirmPopup.showResponse = true;
      if (np.pets.petUpdateStatus.errorMessage) {
        confirmPopup.responseType = 'alert';
        confirmPopup.responseAlertType = 'danger';
        confirmPopup.responseMessage = np.pets.petUpdateStatus.errorMessage;
      } else {
        if (np.pets.petUpdateStatus?.status === 'confirm') {
          confirmPopup.responseMessage = np.pets.petUpdateStatus.message;
          confirmPopup.responseType = 'confirm';
          confirmPopup.confirmType = 'request_confirmed';
        } else {
          confirmPopup.responseMessage = `${pet.name} ${
            confirmPopup.status === STATUS.ACTIVE ? 'activated' : 'deactivated'
          }`;
          confirmPopup.responseType = 'alert';
          confirmPopup.responseAlertType = 'success';
        }
      }
      this.setState({
        confirmPopup,
        refreshState: !refreshState,
        updateRequested: false,
        pet,
      });
    }
    if (np.pets.petProfileUpdateResponse && updateRequested) {
      const { refreshState, confirmPopup, pet } = this.state;
      confirmPopup.showResponse = true;
      confirmPopup.responseMessage = 'Pet profile updated';
      confirmPopup.responseType = 'alert';
      confirmPopup.responseAlertType = 'success';
      if (np.pets.petProfileUpdateResponse.errorMessage) {
        confirmPopup.responseAlertType = 'danger';
        confirmPopup.responseMessage =
          np.pets.petProfileUpdateResponse.errorMessage;
      } else {
        this.props.inputChangeStatus(this.state.pet._id, false);
      }
      this.setState({
        confirmPopup,
        refreshState: !refreshState,
        updateRequested: false,
        pet,
      });
    }
  }

  componentWillUnmount() {
    this.props.resetPetAppointmentRequestsState();
    this.props.resetAppointmentRequests();
  }

  onChangeFields = (e) => {
    const property = e.target.name;
    const value = e.target.value;
    let { inputs, refreshState } = this.state;
    refreshState = !refreshState;
    inputs[property] = value;
    if (property === 'species') {
      inputs.breed = '';
    }
    this.props.inputChangeStatus(this.state.pet._id, true);
    this.setState({ inputs, refreshState });
  };

  onChangeCalender = (e) => {
    const { inputs } = this.state;
    inputs.birthday = e;
    this.props.inputChangeStatus(this.state.pet._id, true);
    this.setState({ inputs });
  };

  toggle = () => {
    this.setState((state) => ({ collapse: !state.collapse }));
  };

  closePopup = () => {
    const { confirmPopup } = this.state;
    let { refreshState } = this.state;
    confirmPopup.showResponse = false;
    refreshState = !refreshState;
    this.setState({ confirmPopup, refreshState });
    this.props.clearUpdatePetStatus();
  };

  onSuccess = () => {
    const { confirmPopup, pet } = this.state;
    if (confirmPopup.confirmType === 'change_status') {
      this.props.toggleLoading(true);
      this.props.updatePetStatus({ status: confirmPopup.status, id: pet._id });
    }
    if (confirmPopup.confirmType === 'request_confirmed') {
      confirmPopup.requestConfirm = false;
      this.props.toggleLoading(true);
      this.props.updatePetStatus({
        status: confirmPopup.status,
        id: pet._id,
        confirmed: true,
      });
    }
    confirmPopup.showResponse = false;
    this.setState({ confirmPopup, updateRequested: true });
  };

  validate = (inputs) => {
    const { refreshState } = this.state;
    let { errors } = this.state;
    errors = reset(errors);
    const validationPetRules = {
      required: ['name', 'species'],
      date: ['birthday'],
      maxToday: ['birthday'],
    };
    const validationPetResponse = validate(validationPetRules, inputs, errors);
    errors = validationPetResponse.errors;
    const error = validationPetResponse.error;
    this.setState({ errors, error, refreshState: !refreshState });
    return error;
  };

  handleGenderSelect = (value) => {
    const { inputs } = this.state;
    this.setState({
      inputs: {
        ...inputs,
        gender: value,
      },
    });
  };

  updatePet = () => {
    const { inputs, pet } = this.state;
    if (!this.validate(inputs)) {
      this.props.toggleLoading(true);
      this.props.updatePet(inputs, pet._id);
      this.setState({ updateRequested: true });
    }
  };

  onChangeSelect = (e) => {
    if (e) {
      const { inputs } = this.state;

      this.setState({
        inputs: {
          ...inputs,
          breed: e.value,
        },
      });
      this.props.inputChangeStatus(this.state.pet._id, true);
    }
  };

  handleOpenCreateAppointmentModal = () => {
    const {
      petsAppointmentRequestsNumber,
      isPetAppointmentRequestsModalOpen,
      openPetAppointmentRequestsModal,
      closePetAppointmentRequestsModal,
      pet,
    } = this.props;

    const appointmentRequestsExist = !!petsAppointmentRequestsNumber[pet._id];

    if (appointmentRequestsExist && !isPetAppointmentRequestsModalOpen) {
      openPetAppointmentRequestsModal(pet._id);
    } else if (appointmentRequestsExist && isPetAppointmentRequestsModalOpen) {
      closePetAppointmentRequestsModal();
      this.setState({ isCreateAppointmentModalOpen: true });
    } else {
      this.setState({ isCreateAppointmentModalOpen: true });
    }
  };

  handleCloseCreateAppointmentModal = () => {
    this.setState({ isCreateAppointmentModalOpen: false });
  };

  handleSpeciesChange = (value) => {
    const { inputs } = this.state;

    this.setState({
      inputs: {
        ...inputs,
        species: value,
      },
    });
  };

  render() {
    const {
      collapse,
      inputs,
      errors,
      pet,
      confirmPopup,
      isCreateAppointmentModalOpen,
    } = this.state;

    const {
      profileId,
      openPetAppointmentRequestsModal,
      closePetAppointmentRequestsModal,
      petsAppointmentRequestsNumber,
      isPetAppointmentRequestsModalOpen,
      modalId,
      parentId,
    } = this.props;

    const arrowImage = collapse
      ? `${process.env.PUBLIC_URL}/img/icon-arrow-up.png`
      : `${process.env.PUBLIC_URL}/img/icon-arrow-down.png`;

    const petImage = pet.image || defaultAvatar;

    if (moment(inputs.birthday).isValid()) {
      inputs.birthday = moment(inputs.birthday).format(
        DEFAULT_TIMESTAMP_FORMAT,
      );
    }

    return (
      <div id={pet._id} className="content-holder pet-update-card">
        <div className="row pet-content-wrapper">
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <div className="image-wrapper col-sm-12 text-center">
              <img className="loyal-parent-image" src={petImage} alt="avatar" />
            </div>
            <div className="pet-inputs-item-wrapper">
              <div className="form__form-group">
                <span className="form__form-group-label">
                  Pet Name <span className="required">*</span>
                </span>
                <div className="form__form-group-field">
                  <FormGroup>
                    <input
                      className="form-control"
                      name="name"
                      type="text"
                      placeholder="Enter Name"
                      value={inputs.name}
                      maxLength={50}
                      onChange={this.onChangeFields}
                    />
                    {errors.name.error && <Error text={errors.name.error} />}
                  </FormGroup>
                </div>
              </div>

              <div className="form__form-group form-group">
                <span className="form__form-group-label">Birthday</span>
                <div className="form__form-group-field">
                  <FormGroup>
                    <DatePickerComponent
                      viewMode={false}
                      maxDate
                      minDate={false}
                      is
                      date={inputs.birthday}
                      handleChange={this.onChangeCalender}
                    />
                    {errors.birthday.error && (
                      <Error text={errors.birthday.error} />
                    )}
                  </FormGroup>
                </div>
              </div>

              <div className="form__form-group">
                <span className="form__form-group-label">
                  Species <span className="required">*</span>
                </span>
                <div className="form__form-group-field">
                  <SpeciesSelect
                    value={inputs.species}
                    options={this.props.speciesList}
                    error={errors.species.error}
                    onChange={this.handleSpeciesChange}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Breed</span>
                <div className="form__form-group-field">
                  <BreedsSelect
                    value={inputs.breed}
                    onChange={this.onChangeSelect}
                    species={inputs.species}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Gender</span>
                <div className="form__form-group-field">
                  <GenderSelect
                    value={inputs.gender}
                    onChange={this.handleGenderSelect}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="button-wrapper">
            <button
              className="btn loyal-btn-save-update"
              onClick={this.handleOpenCreateAppointmentModal}
            >
              Create Appointment
            </button>
            <button
              className="btn loyal-btn-save-update"
              onClick={this.updatePet}
            >
              Update
            </button>
          </div>
          <div
            className={`view-contact-label ${collapse ? 'expanded' : ''}`}
            onClick={this.toggle}
          >
            View Contacts
            <img src={arrowImage} alt="toggle" />
          </div>
        </div>
        <Collapse isOpen={collapse}>
          <div
            className="row co-parent-container"
            style={{ marginTop: '50px' }}
          >
            <div className="col-md-5">
              <p>Co-Parents</p>
              <table className="custom-table loyal-table table table-striped">
                <thead>
                  <tr className="header-tr">
                    <th>Name</th>
                    <th>Mobile Number</th>
                  </tr>
                </thead>
                <tbody>
                  {pet.coParents?.map((coParent) => (
                    <tr key={coParent._id} className="body-tr">
                      <td className="text-capitalize">{`${coParent.parent.firstName} ${coParent.parent.lastName}`}</td>
                      <td>
                        {coParent.parent.phone
                          ? formatPhone(coParent.parent.phone, '(###) ###-####')
                          : coParent.parent.email}
                      </td>
                    </tr>
                  ))}
                  {!pet.coParents?.length && (
                    <tr className="body-tr">
                      <td colSpan={3} className="text-capitalize">
                        No Co-Parents designated
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="col-md-6 offset-md-1">
              <p>Supporters</p>
              {pet.activeSuppoterCount > 0 && (
                <p className="support-count">
                  <span>
                    {pet.activeSuppoterCount < 10
                      ? `0${pet.activeSuppoterCount}`
                      : pet.activeSuppoterCount}{' '}
                  </span>
                  Active Supporter{pet.activeSuppoterCount > 1 ? 's' : ''}
                </p>
              )}
              {pet.activeSuppoterCount === 0 && (
                <p className="support-count">No Active Supporters</p>
              )}
            </div>
          </div>
        </Collapse>

        <MessageModal
          show={confirmPopup.showResponse}
          type={confirmPopup.responseType}
          alertType={confirmPopup.responseAlertType}
          footer
          onSuccess={this.onSuccess}
          onClose={this.closePopup}
          message={confirmPopup.responseMessage}
        />

        <NewPetAppointment
          isOpen={isCreateAppointmentModalOpen}
          pet={pet}
          handleClose={this.handleCloseCreateAppointmentModal}
          parentId={profileId}
          appointmentRequestsExist={!!petsAppointmentRequestsNumber[pet._id]}
          openPetAppointmentRequestsModal={openPetAppointmentRequestsModal}
        />

        <PetAppointmentRequestsModal
          isOpen={isPetAppointmentRequestsModalOpen && modalId === pet._id}
          openCreateNewAppointmentModal={this.handleOpenCreateAppointmentModal}
          petId={pet._id}
          handleClose={closePetAppointmentRequestsModal}
          parentId={parentId}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
  isPetAppointmentRequestsModalOpen:
    state.pets.isPetAppointmentRequestsModalOpen,
  petsAppointmentRequestsNumber: state.pets.petsAppointmentRequestsNumber,
  modalId: state.pets.petAppointmentRequestsModalId,
  appointmentRequests: state.appointmentRequests.appointmentRequestsList,
});

const mapDispatchToProps = {
  openPetAppointmentRequestsModal,
  closePetAppointmentRequestsModal,
  setPetAppointmentRequestsNumber,
  resetPetAppointmentRequestsState,
  getAllAppointmentRequests,
  resetAppointmentRequests,
  updatePetStatus,
  clearUpdatePetStatus,
  toggleLoading,
  getBreeds,
  updatePet,
};

export default connect(mapStateToProps, mapDispatchToProps)(PetItem);
