import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Navigate, Routes } from 'react-router-dom';
import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';
import localStorage from '../../libs/storageHelper';
import config, { USER_TYPES } from '../../config/app.config';

import LogIn from '../Account/LogIn/index';
import ResetPassword from '../Account/ResetPassword';
import ChangePassword from '../Account/ChangePassword/index';
import Unsubscribe from '../Account/Unsubscribe/index';
import Subscribe from '../Account/Unsubscribe/Subscribe';
import RefDoctorSubscribe from '../Account/Unsubscribe/refDoctorSubscribe';

import ManageHospitalStaff from '../Staff/Staff';
import SendResetPasswordLink from '../Account/SendResetPasswordLink';
import ManageFacility from '../facility/Facility';
import ManageClient from '../client/Client';
import UserProfile from '../Account/Profile/index';
import ManageAdmins from '../admin';
import Dashboard from '../dashboard/Dashboard';
// import ReferralDoctor from '../referralDoctor';
import ManageBreed from '../breed';
import RegisterParent from '../Web/RegisterParent';
import ParentProfile from '../Web/ParentProfile';

import ManagePatients from '../Web/ManagePatients';
import PetProfile from '../Web/PetProfile';
import Broadcast from '../Web/Broadcast';

import PetTrackingBoard from '../Web/PetTrackingBoard';
import Onboarding from '../Account/Onboarding';
import Verify from '../Account/Verify';
import RescheduleAppointment from '../Web/CreateAppointment/Components/RescheduleAppointment';
import CreateAppointment from '../Web/CreateAppointment/Components/CreateAppointment';
import { routes } from '../../constants/routes';
import { getUserProfile } from '../../redux/actions/userActions';
import { initBeacon, destroyBeacon } from '../../utils/helpScoutBeacon';
import Contacts from '../Contacts';
import { Appointments } from '../Web/Appointments';
import { RefillRequests } from '../Web/RefillRequests';
import { AppointmentRequests } from '../Web/AppointmentRequests';

let user = localStorage.getFromStorage('loggedUser');

const Auth = {
  authenticate() {
    user = localStorage.getFromStorage('loggedUser');
    return !!user;
  },
  isAdmin() {
    user = localStorage.getFromStorage('loggedUser');
    return [config.USER_TYPES.SUPER_ADMIN, config.USER_TYPES.ADMIN].includes(
      user.user.type,
    );
  },
  isSuperAdmin() {
    user = localStorage.getFromStorage('loggedUser');
    return user.user.type === config.USER_TYPES.SUPER_ADMIN;
  },
  isStaff() {
    user = localStorage.getFromStorage('loggedUser');
    return user.user.type === config.USER_TYPES.STAFF;
  },
};

/**
 * Staff routes
 *
 * @param Component
 * @param rest
 * @returns {*}
 * @constructor
 */
function LoggedRoute({ component: Component }) {
  const isAuthenticated = Auth.authenticate();

  if (!isAuthenticated) {
    <Navigate to={{ pathname: '/login' }} />;
  }

  return (
    <>
      <Layout />
      <div className="container__wrap">
        <Component />
      </div>
    </>
  );
}

/**
 * Super admin and admin routes
 *
 * @param Component
 * @param rest
 * @returns {*}
 * @constructor
 */
function StaffOrAdminRoute({ component: Component }) {
  const isAllowed =
    Auth.authenticate() &&
    !Auth.isSuperAdmin() &&
    (Auth.isAdmin() || Auth.isStaff());

  if (!isAllowed) {
    <Navigate to={{ pathname: '/login' }} />;
  }

  return (
    <>
      <Layout />
      <div className="container__wrap">
        <Component />
      </div>
    </>
  );
}

/**
 * Super admin and admin routes
 *
 * @param Component
 * @param rest
 * @returns {*}
 * @constructor
 */
function PrivateRoute({ component: Component }) {
  const isAllowed = Auth.authenticate() && Auth.isAdmin();

  if (!isAllowed) {
    <Navigate to={{ pathname: '/login' }} />;
  }

  return (
    <>
      <Layout />
      <div className="container__wrap">
        <Component />
      </div>
    </>
  );
}

/**
 * Super admin only routes
 *
 * @param Component
 * @param rest
 * @returns {*}
 * @constructor
 */
function PrivateProtectedRoute({ component: Component }) {
  const isAllowed = Auth.authenticate() && Auth.isSuperAdmin();

  if (!isAllowed) {
    <Navigate to={{ pathname: '/login' }} />;
  }

  return (
    <>
      <Layout />
      <div className="container__wrap">
        <Component />
      </div>
    </>
  );
}

const Router = () => {
  const dispatch = useDispatch();
  const type = useSelector((state) => state.user?.profile?.type);
  const loggedUser = useSelector((state) => state.user?.profile);

  useEffect(() => {
    if (loggedUser) {
      initBeacon(loggedUser);
    } else {
      destroyBeacon();
    }
  }, [loggedUser]);

  useEffect(() => {
    const userId = localStorage.getFromStorage('loggedUser')?.user?._id;
    if (userId) {
      dispatch(getUserProfile(userId));
    }
  }, [dispatch]);

  return (
    <MainWrapper>
      <main>
        <Routes>
          <Route path="/onboarding" element={<Onboarding />} />
          <Route path="/verify" element={<Verify />} />
          <Route exact path="/" element={<LogIn />} />
          <Route exact path={routes.login.absolute} element={<LogIn />} />
          <Route path="/unsubscribe" element={<Unsubscribe />} />
          <Route path="/Subscribe" element={<Subscribe />} />
          <Route
            path="/RefDoctor/Subscribe/:facilityObjId/:type/:facilityName"
            element={<RefDoctorSubscribe />}
          />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/forgot-password" element={<SendResetPasswordLink />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/empty" element={null} />
          <Route path="/pet-profile/:petId" element={<PetProfile />} />
          <Route
            path="/user-profile"
            element={<LoggedRoute component={UserProfile} />}
          />

          {/* admin and staff only routes */}
          <Route
            path="/tracking-board"
            element={<StaffOrAdminRoute component={PetTrackingBoard} />}
          />
          <Route
            path="/client-list/profile/:parentId"
            element={<StaffOrAdminRoute component={ParentProfile} />}
          />
          <Route
            path="/client-list"
            element={<StaffOrAdminRoute component={ManagePatients} />}
          />
          <Route
            path={routes.appointments.absolute}
            element={<StaffOrAdminRoute component={Appointments} />}
          />

          {/* admin only routes */}
          <Route
            path="/tracking-board"
            element={<StaffOrAdminRoute component={PetTrackingBoard} />}
          />
          <Route
            path="/appointment"
            element={<StaffOrAdminRoute component={CreateAppointment} />}
          />
          <Route
            path="/appointments/:appointmentId"
            element={<StaffOrAdminRoute component={RescheduleAppointment} />}
          />
          <Route
            path="/client-list/profile/:parentId"
            element={<StaffOrAdminRoute component={ParentProfile} />}
          />
          <Route
            path="/client-list"
            element={<StaffOrAdminRoute component={ManagePatients} />}
          />
          <Route
            path={routes.refillRequests}
            element={<StaffOrAdminRoute component={RefillRequests} />}
          />
          <Route
            path={routes.appointmentRequests}
            element={<StaffOrAdminRoute component={AppointmentRequests} />}
          />

          {/* admin only routes */}
          <Route
            path="/metrics-dashboard"
            element={<PrivateRoute component={Dashboard} />}
          />
          <Route
            path="/manage-staff"
            element={<PrivateRoute component={ManageHospitalStaff} />}
          />
          {/* <Route
            path="/manage-referring"
            element={<PrivateRoute component={ReferralDoctor} />}
          /> */}
          <Route
            path="/broadcast-news"
            element={<PrivateRoute component={Broadcast} />}
          />

          <Route
            path="/manage-admins"
            element={<PrivateProtectedRoute component={ManageAdmins} />}
          />
          <Route
            path="/manage-facilities"
            element={<PrivateProtectedRoute component={ManageFacility} />}
          />
          <Route
            path="/manage-breeds"
            element={<PrivateProtectedRoute component={ManageBreed} />}
          />

          {/* super admin, admin and user common route */}
          {type === USER_TYPES.SUPER_ADMIN ? (
            <Route
              path="/manage-users"
              element={<PrivateProtectedRoute component={ManageClient} />}
            />
          ) : (
            <Route
              path={routes.manageClients.absolute}
              element={<StaffOrAdminRoute component={RegisterParent} />}
            />
          )}
        </Routes>
      </main>
    </MainWrapper>
  );
};

export default Router;
