import React, { useEffect } from 'react';
import {
  Table,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  appointmentStatus,
  appointmentActions,
} from '../../../../config/app.config';
import { SortingField } from '../../../common/SortingCell';
import TableFilterComponent from '../../../../shared/components/form/TableFilterComponent';
import { getDefaultSortOrder } from '../../Common/SortBy/utils';
import { getStaffDoctorsByFacility } from '../../../../redux/actions/staffActions';
import { useAppointmentActionContext } from '../utils';

const { reschedule, cancel, remove } = appointmentActions;

export function AppointmentsTable({
  heads,
  rows,
  sortField,
  sortOrder,
  stickyTop,
  handleFilter,
  handleSort,
}) {
  const dispatch = useDispatch();

  const doctors = useSelector(
    (state) => state.staff.staffDoctorsByFacility || [],
  );
  const facilityId = useSelector((state) => state.user?.profile?.facility?._id);

  const { handleActions } = useAppointmentActionContext();

  useEffect(() => {
    if (facilityId) {
      dispatch(getStaffDoctorsByFacility());
    }
  }, [dispatch, facilityId]);

  const renderRowValues = (row) => {
    const actionColumn = (row, key) => {
      const actionItems = [];

      if (row.status === appointmentStatus.upcoming) {
        actionItems.push(
          <DropdownItem tag="div" key="reschedule">
            <a onClick={() => handleActions(reschedule, row)}>Reschedule</a>
          </DropdownItem>,
        );
        actionItems.push(
          <DropdownItem tag="div" key="cancel">
            <a onClick={() => handleActions(cancel, row.id)}>Cancel</a>
          </DropdownItem>,
        );
      } else {
        actionItems.push(
          <DropdownItem tag="div" key="remove">
            <a onClick={() => handleActions(remove, row.id)}>Remove</a>
          </DropdownItem>,
        );
      }

      return (
        <td id="action-cell" key={key}>
          <UncontrolledDropdown>
            <DropdownToggle>
              <span
                className={`six-layers ${row.status.replace(/\s+/g, '-')}`}
              />
            </DropdownToggle>
            <DropdownMenu className="dropdown__menu">
              {actionItems}
            </DropdownMenu>
          </UncontrolledDropdown>
        </td>
      );
    };

    const defaultColumn = (head, value, capitalizeClass, link) => (
      <td
        key={head.key}
        title={value}
        className={`custom-no-over-flow-td ${capitalizeClass}`}
      >
        {link ? (
          <Link className="full-width" to={link}>
            {value}
          </Link>
        ) : (
          value
        )}
      </td>
    );

    return heads.map((head) => {
      const value = row[head.key];
      const capitalizeClass = head.capitalize ? 'text-capitalize' : '';
      const link = head.link?.(row);

      if (head.key === 'action') {
        return actionColumn(row, head.key);
      }

      return defaultColumn(head, value, capitalizeClass, link);
    });
  };

  const renderTableHeaders = () => {
    const renderSortableHeader = (head) => {
      const sortKey = head.key;
      const defaultOrder = getDefaultSortOrder(sortKey);

      return (
        <th key={head.key} className={head.className ?? ''}>
          {sortField === sortKey ? (
            <SortingField
              icon={sortOrder === 1 ? 'triangle-up' : 'triangle-down'}
              name={head.name}
              onSort={() => handleSort({ order: sortOrder === 1 ? -1 : 1 })}
            />
          ) : (
            <SortingField
              name={head.name}
              icon="diamond-narrow"
              onSort={() => handleSort({ field: sortKey, order: defaultOrder })}
            />
          )}
        </th>
      );
    };

    const renderFilterableHeader = (head) => (
      <th key={head.key} className={head.className ?? ''}>
        <TableFilterComponent
          allFilters={head.filterValue}
          action={(list) => {
            handleFilter(head.key, list);
          }}
          label={head.name}
        />
      </th>
    );

    const renderDefaultHeader = (head) => (
      <th key={head.key} className={head.className ?? ''}>
        <p>{head.name}</p>
      </th>
    );

    const renderHeader = (head) => {
      if (!head) return null;

      if (head.sortable) {
        return renderSortableHeader(head);
      } else if (head.filterable) {
        if (head.key === 'doctor') {
          if (doctors.length === 0) return renderDefaultHeader(head);

          const filterValues = doctors.map(({ _id, name }) => ({
            [_id]: `Dr. ${name}`,
          }));

          filterValues.push({ NA: 'N/A' });

          head.filterValue = filterValues;
        }

        return renderFilterableHeader(head);
      } else {
        return renderDefaultHeader(head);
      }
    };

    return (
      <tr className="header-tr" style={stickyTop ? { top: stickyTop } : {}}>
        {heads.map((head) => renderHeader(head))}
      </tr>
    );
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <Table striped className="custom-table loyal-table">
          <thead>{renderTableHeaders()}</thead>
          <tbody>
            {rows.map((row, index) => (
              <tr className="body-tr" key={index}>
                {renderRowValues(row)}
              </tr>
            ))}
          </tbody>
        </Table>

        {rows.length === 0 && (
          <div className="no-data-message">No Appointments</div>
        )}
      </div>
    </div>
  );
}
