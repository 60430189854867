import React, { PureComponent } from 'react';
import {
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import { connect } from 'react-redux';
import {
  updateStaffMember,
  resetUpdatePopupNP,
} from '../../redux/actions/staffActions';
import config, { USER_TYPES } from '../../config/app.config';
import DesignationSelect from '../../shared/components/inputs/DesignationSelect/DesignationSelect';
import { ResponseMessage } from '../../shared/components/ResponseMessage';
import { FormInput } from './components/FormInput';
import { PermissionsSelect } from './components/PermissionsSelect';
import { isSuperAdmin } from '../../utils/Utils';
import FacilitySelect from '../../shared/components/inputs/FacilitySelect/FacilitySelect';
import { InputLabel } from '../../shared/components/InputLabel';
import localStorage from '../../libs/storageHelper';
import validateStaffForm from './staffValidate';

class ViewStaff extends PureComponent {
  constructor() {
    super();
    this.modalTimeOutRef = null;
    this.loggedUser = localStorage.getFromStorage('loggedUser');

    this.state = {
      modal: false,
      selectedMember: {},
      inputs: {
        firstName: '',
        lastName: '',
        email: '',
        facility: '',
        designation: '',
        username: '',
        phone: '',
        extension: '',
        type: USER_TYPES.STAFF,
        privateCredentials: false,
      },
      error: false,
      errors: {
        firstName: null,
        lastName: null,
        email: null,
        facility: null,
        designation: null,
        username: null,
        phone: null,
        extension: null,
        privateCredentials: null,
      },
      successResponse: null,
      errorResponse: null,
      refreshState: true,
    };
  }

  onChangeFields = (e) => {
    const property = e.target.name;
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    let { inputs, refreshState } = this.state;
    refreshState = !refreshState;
    inputs[property] = value;
    this.setState({ inputs, refreshState });
  };

  onSubmit = () => {
    this.setState({
      error: null,
      errors: {},
    });
    const { inputs } = this.state;
    const staff = {
      ...inputs,
      username: inputs.privateCredentials ? inputs.email : inputs.username,
    };

    const { errors, error } = validateStaffForm(staff, false);

    if (error) {
      return this.setState({
        errors,
        error,
        errorResponse: null,
        successResponse: null,
      });
    }

    staff.phone = staff.phone
      ? staff.phone.replace(/[^A-Z0-9]+/gi, '')
      : staff.phone;

    this.props.updateStaffMember(this.state.selectedId, inputs);
  };

  closeModal = () => {
    if (this.modalTimeOutRef) {
      clearTimeout(this.modalTimeOutRef);
    }
    const inputs = {
      firstName: '',
      lastName: '',
      email: '',
      facility: '',
      designation: '',
      username: '',
      phone: '',
      extension: '',
      privateCredentials: false,
    };
    this.setState({ inputs });
    this.toggle(false);
  };

  toggle(isOpen = !this.state.modal) {
    const error = false;
    const errors = {
      firstName: null,
      lastName: null,
      email: null,
      facility: null,
      designation: null,
      username: null,
      phone: null,
      extension: null,
      privateCredentials: null,
    };
    this.setState({
      modal: isOpen,
      error,
      errors,
      successResponse: null,
      errorResponse: null,
    });
  }

  UNSAFE_componentWillReceiveProps(np) {
    const { selectedId, selectedMember, view } = np;

    if (np.staff.updateStaffResponse) {
      this.setState({
        successResponse: 'Staff member updated successfully',
        selectedMember: this.state.input,
        errorResponse: null,
      });
      this.closeModalWithTimeOut();
    }
    if (np.staff.updateStaffErrorResponse) {
      if (np.staff.updateStaffErrorResponse.errorMessage) {
        this.setState({
          errorResponse: np.staff.updateStaffErrorResponse.errorMessage,
          successResponse: null,
        });
      }
    }
    if (view && !this.state.modal) {
      const inputs = {
        firstName: selectedMember.firstName,
        lastName: selectedMember.lastName,
        email: selectedMember.email,
        facility: selectedMember.facilityId,
        designation: selectedMember.designationId,
        username: selectedMember.username,
        phone: selectedMember.phone,
        type: selectedMember.type,
        extension: selectedMember.extension,
        privateCredentials: selectedMember.privateCredentials,
      };
      this.props.resetUpdatePopupNP();
      this.setState({
        selectedId,
        selectedMember,
        inputs,
        modal: true,
        successResponse: null,
        errorResponse: null,
      });
    }
  }

  handleSelectFacility = (facility) => {
    let { inputs, refreshState } = this.state;
    refreshState = !refreshState;
    inputs.facility = facility;
    this.setState({ inputs, refreshState });
  };

  closeModalWithTimeOut = () => {
    this.modalTimeOutRef = setTimeout(() => {
      this.closeModal();
    }, config.POPUP_TIMEOUT_TIME);
  };

  render() {
    const { inputs, errors, successResponse, errorResponse } = this.state;

    const { handleSubmit } = this.props;

    const FacilityBlock = isSuperAdmin(this.loggedUser.user) ? (
      <div className="col-md-6">
        <div className="form__form-group">
          <InputLabel required>Facility</InputLabel>
          <div className="form__form-group-field">
            <FacilitySelect
              onChange={this.handleSelectFacility}
              value={inputs.facility}
              error={errors.facility}
              disabled
            />
          </div>
        </div>
      </div>
    ) : null;

    return (
      <Modal
        isOpen={this.state.modal}
        size="lg"
        backdrop="static"
        className="model-form"
      >
        <ModalHeader className="header-form">
          {inputs.privateCredentials ? 'Staff Member' : 'Assigned Credential'}
        </ModalHeader>
        <ModalBody>
          <Card>
            <CardBody>
              <ResponseMessage
                type={successResponse ? 'success' : 'danger'}
                message={successResponse || errorResponse}
              />
              <form className="form staff-form" onSubmit={handleSubmit}>
                <div className="container-fluid">
                  {inputs.privateCredentials && (
                    <>
                      <div className="row">
                        <FormInput
                          wrapperClassName="col-md-6"
                          label="First Name"
                          name="firstName"
                          value={inputs.firstName}
                          onChange={this.onChangeFields}
                          error={errors.firstName}
                        />
                        <FormInput
                          wrapperClassName="col-md-6"
                          label="Last Name"
                          name="lastName"
                          value={inputs.lastName}
                          onChange={this.onChangeFields}
                          error={errors.lastName}
                        />
                      </div>
                      <div className="row">
                        {FacilityBlock}
                        <div className="col-md-6">
                          <DesignationSelect
                            onChange={this.onChangeFields}
                            value={inputs.designation}
                            error={errors.designation}
                            required={inputs.privateCredentials}
                          />
                        </div>
                        <div className="col-md-6">
                          <PermissionsSelect
                            value={inputs.type}
                            onChange={this.onChangeFields}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <FormInput
                          wrapperClassName="col-md-6"
                          label="Email"
                          name="email"
                          onChange={this.onChangeFields}
                          value={inputs.email}
                          error={errors.email}
                          autoComplete="off"
                          required
                          disabled
                        />
                        <FormInput
                          wrapperClassName="col-md-6"
                          label="Username"
                          name="username"
                          onChange={this.onChangeFields}
                          value={inputs.email}
                          error={errors.username}
                          disabled
                        />
                      </div>
                    </>
                  )}

                  {!inputs.privateCredentials && (
                    <>
                      <div className="row">
                        <FormInput
                          wrapperClassName="col-md-6"
                          label="Username"
                          name="username"
                          onChange={this.onChangeFields}
                          value={inputs.username}
                          error={errors.username}
                          disabled
                          required
                        />
                        <FormInput
                          wrapperClassName="col-md-6"
                          label="Password"
                          name="password"
                          onChange={this.onChangeFields}
                          value={inputs.password}
                          error={errors.password}
                          required
                        />
                      </div>
                      <div className="row">
                        {FacilityBlock}
                        <FormInput
                          wrapperClassName="col-md-6"
                          label="Name"
                          name="lastName"
                          value={inputs.username}
                          error={errors.lastName}
                          readOnly
                        />
                        <div className="col-md-6">
                          <PermissionsSelect
                            value={inputs.type}
                            onChange={this.onChangeFields}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  <div className="row">
                    <div className="col-md-6"></div>
                    <div className="col-md-6 text-right">
                      <Button
                        className="clear"
                        color="secondary"
                        onClick={this.closeModal}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="save-update"
                        color="primary"
                        onClick={this.onSubmit}
                      >
                        Update
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = {
  updateStaffMember,
  resetUpdatePopupNP,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewStaff);
