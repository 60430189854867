import { useEffect, useRef, useState } from 'react';

export const formatFilterText = (key, value, entityLookup) => {
  switch (key) {
    case 'doctorId': {
      if (value === 'NA') return 'Doctor - N/A';

      const doctor = entityLookup.doctors.find(
        ({ doctorId }) => doctorId === value,
      );

      return doctor ? `Dr. ${doctor.name}` : value;
    }
    default:
      return value;
  }
};

export const useHover = () => {
  const [isHovered, setIsHovered] = useState(false);
  const hoverRef = useRef(null);

  useEffect(() => {
    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);

    const node = hoverRef.current;

    if (node) {
      node.addEventListener('mouseenter', handleMouseEnter);
      node.addEventListener('mouseleave', handleMouseLeave);
    }

    return () => {
      if (node) {
        node.removeEventListener('mouseenter', handleMouseEnter);
        node.removeEventListener('mouseleave', handleMouseLeave);
      }
    };
  }, []);

  return [hoverRef, isHovered];
};
