import { handleRequest, handleUploadRequest } from '../services/APIService';

export const CREATE_NEWS = 'CREATE_NEWS';
export const CREATE_NEWS_ERROR = 'CREATE_NEWS_ERROR';
export const IMAGE_UPLOADED = 'IMAGE_UPLOADED';
export const IMAGE_UPLOAD_ERROR = 'IMAGE_UPLOAD_ERROR';
export const IMAGE_DELETED = 'IMAGE_DELETED';
export const IMAGE_DELETED_ERROR = 'IMAGE_DELETED_ERROR';
export const CLEAR_ALL = 'CLEAR_ALL';

export const save = (data) => (dispatch) => {
  handleRequest('post', 'broadcast-message', true, data)
    .then((results) => {
      return dispatch({
        type: CREATE_NEWS,
        payload: results.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: CREATE_NEWS_ERROR,
        payload: error,
      });
    });
};

export const uploadImage = (file, uploader) => (dispatch) => {
  handleUploadRequest(file, 'newsfeed')
    .then((results) => {
      return dispatch({
        type: IMAGE_UPLOADED,
        payload: { ...results.data, uploader },
      });
    })
    .catch((error) => {
      return dispatch({
        type: IMAGE_UPLOAD_ERROR,
        payload: error,
      });
    });
};

export const clearAll = () => (dispatch) => {
  return dispatch({
    type: CLEAR_ALL,
    payload: null,
  });
};

export const removeImage = (image) => (dispatch) => {
  handleRequest('post', 'image-delete', true, image)
    .then((results) => {
      return dispatch({
        type: IMAGE_DELETED,
        payload: { ...results.data, image },
      });
    })
    .catch((error) => {
      return dispatch({
        type: IMAGE_DELETED_ERROR,
        payload: error,
      });
    });
};
