import React, { useCallback } from 'react';
import { conformToMask } from 'react-text-mask';
import { MASKS } from '../../../constants/constants';

/**
 *  Mask one of  ['phone', 'ext']
 * @default phone
 * */
export default function MaskedInput({
  mask = 'phone',
  value,
  onChange,
  ...props
}) {
  const handleChange = useCallback(
    (e) => {
      if (mask === 'phone') {
        e.target.value = e.target.value.replace(/[^A-Z0-9]+/gi, '');
      }
      onChange(e);
    },
    [mask, onChange],
  );

  return (
    <input
      className="form-control"
      name="phone"
      value={
        conformToMask(value, MASKS[mask], {
          guide: false,
        }).conformedValue
      }
      maxLength={14}
      {...props}
      onChange={handleChange}
      type="text"
    />
  );
}
