import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Router from './Router';
import store from './store';
import ScrollToTop from './ScrollToTop';
import Loading from '../../shared/Loading';
import 'bootstrap/dist/css/bootstrap.css';
import '../../scss/app.scss';

class App extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      loaded: false,
    };
  }

  componentDidMount() {
    window.addEventListener('load', () => {
      this.setState({ loading: false });
      setTimeout(() => this.setState({ loaded: true }), 500);
    });
  }

  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <ScrollToTop>
            <Loading />
            <Router />
          </ScrollToTop>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
