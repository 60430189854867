import React, { Component } from 'react';
import { connect } from 'react-redux';
import EyeIcon from 'mdi-react/EyeOffOutlineIcon';
import AccountIcon from 'mdi-react/AccountIcon';
import EmailOutlineIcon from 'mdi-react/EmailOutlineIcon';
import CellphoneIcon from 'mdi-react/CellphoneIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import * as userActions from '../../../../redux/actions/userActions';

class OnboardingForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      user: {},
    };
  }

  submit = (e) => {
    e.preventDefault();
    const { firstName, lastName, email, password, phone } = this.state.user;
    this.props.register({
      data: {
        firstName,
        lastName,
        email,
        password,
        phone,
      },
      token: this.props.token,
      facility: this.props.facility,
    });
  };

  onChange = ({ target }) => {
    this.setState((prev) => ({
      ...prev,
      user: { ...prev.user, [target.name]: target.value },
    }));
  };

  showPassword = () => {
    this.setState((prev) => ({ ...prev, showPassword: !prev.showPassword }));
  };

  componentDidUpdate() {
    if (this.props.user.phone && !this.state.user.phone) {
      this.setState((prev) => ({ ...prev, user: this.props.user }));
    }
  }

  render() {
    return (
      <form className="form">
        <div className="form__form-group">
          <div className="form__form-group-field">
            <div className="form__form-group-icon form__form-group-icon--left">
              <CellphoneIcon />
            </div>
            <input
              name="phone"
              type="number"
              onChange={this.onChange}
              readOnly
              disabled
              value={this.state.user.phone}
              placeholder="Phone number"
              className="form__custom-field form__custom-field--icon-left"
            />
          </div>
        </div>
        <div className="form__form-group">
          <div className="form__form-group-field">
            <div className="form__form-group-icon form__form-group-icon--left">
              <EmailOutlineIcon />
            </div>
            <input
              name="email"
              type="email"
              onChange={this.onChange}
              placeholder="Email"
              className="form__custom-field form__custom-field--icon-left"
            />
          </div>
        </div>
        <div className="form__form-group">
          <div className="form__form-group-field">
            <div className="form__form-group-icon form__form-group-icon--left">
              <AccountIcon />
            </div>
            <input
              name="firstName"
              type="text"
              value={this.state.user.firstName}
              onChange={this.onChange}
              placeholder="First name"
              className="form__custom-field form__custom-field--icon-left"
            />
          </div>
        </div>
        <div className="form__form-group">
          <div className="form__form-group-field">
            <div className="form__form-group-icon form__form-group-icon--left">
              <AccountIcon />
            </div>
            <input
              name="lastName"
              type="text"
              onChange={this.onChange}
              value={this.state.user.lastName}
              placeholder="Last name"
              className="form__custom-field form__custom-field--icon-left"
            />
          </div>
        </div>
        <div className="form__form-group">
          <div className="form__form-group-field">
            <div className="form__form-group-icon form__form-group-icon--left">
              <KeyVariantIcon />
            </div>
            <input
              name="password"
              type={this.state.showPassword ? 'text' : 'password'}
              placeholder="Password"
              onChange={this.onChange}
              className="form__custom-field form__custom-field--icon-lr"
            />
            <button
              className={`form__form-group-button form__form-group-icon--right${
                this.state.showPassword ? ' active' : ''
              }`}
              onClick={(e) => this.showPassword(e)}
              type="button"
            >
              <EyeIcon />
            </button>
          </div>
        </div>
        <div className="form__form-group">
          <div className="form__form-group-field">
            <div className="form__form-group-icon form__form-group-icon--left">
              <KeyVariantIcon />
            </div>
            <input
              name="confirmPassword"
              type={this.state.showPassword ? 'text' : 'password'}
              placeholder="Confirm Password"
              onChange={this.onChange}
              className="form__custom-field form__custom-field--icon-lr"
            />
            <button
              className={`form__form-group-button form__form-group-icon--right${
                this.state.showPassword ? ' active' : ''
              }`}
              onClick={(e) => this.showPassword(e)}
              type="button"
            >
              <EyeIcon />
            </button>
          </div>
        </div>
        <div className="account__btns">
          <button
            className="btn btn-primary account__btn"
            onClick={this.submit}
          >
            Register
          </button>
        </div>
      </form>
    );
  }
}

const mapDispatchToProps = {
  register: userActions.registerOnboarding,
};

export default connect(null, mapDispatchToProps)(OnboardingForm);
