import React, { Component } from 'react';
import EyeIcon from 'mdi-react/EyeOffOutlineIcon';
import EmailOutlineIcon from 'mdi-react/EmailOutlineIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';

import Error from '../../../../shared/components/form/Error';
import ResponseMessages from '../../../../constants/responseMessages';
import constants from '../../../../constants/constants';

export default class LogInForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      errorUserName: '',
      errorPassword: null,
      username: '',
      password: '',
      commonError: null,
    };
  }

  UNSAFE_componentWillReceiveProps(np) {
    const { formType } = this.props;

    if (np.user.loginErrorData) {
      const error = np.user.loginErrorData.data.errorMessage;
      let errorMsg = null;

      switch (error) {
        case 'Invalid password':
          errorMsg = ResponseMessages.ERRORS.INVALID_CREDENTIALS;
          break;
        case 'not found':
          errorMsg =
            formType === constants.userRole.superAdmin
              ? ResponseMessages.ERRORS.CANNOT_FIND_EMAIL
              : ResponseMessages.ERRORS.CANNOT_FIND_USER;
          break;
        case 'deactivated':
          errorMsg =
            formType === constants.userRole.superAdmin
              ? ResponseMessages.ERRORS.ACCOUNT_NOT_ASSOCIATED
              : ResponseMessages.ERRORS.USERNAME_NOT_ASSOCIATED;
          break;
        default:
          errorMsg = ResponseMessages.ERRORS.LOGIN_SYSTEM_ERROR;
          break;
      }

      this.setState({ commonError: errorMsg });
    }
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  onChange = (e) => {
    if (e.target.name === 'password') {
      const password = e.target.value.replace(/\s/g, '');
      this.setState({ password });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };

  validate = () => {
    const { formType } = this.props;
    const { username, password } = this.state;
    let errorUserName = null;
    let errorPassword = null;
    let error = false;
    const emailRegEx =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const usernameRegEx = /^[a-zA-Z0-9]+$/;

    if (!username) {
      errorUserName =
        formType === constants.userRole.admin
          ? ResponseMessages.ERRORS.EMPTY_EMAIL
          : ResponseMessages.ERRORS.EMPTY_EMAIL_OR_USERNAME;
      error = true;
    } else if (
      !emailRegEx.test(username) &&
      formType === constants.userRole.admin
    ) {
      errorUserName = ResponseMessages.ERRORS.INVALID_EMAIL;
      error = true;
    } else if (
      !usernameRegEx.test(username) &&
      !emailRegEx.test(username) &&
      formType === constants.userRole.staff
    ) {
      errorUserName = ResponseMessages.ERRORS.INVALID_EMAIL_OR_USERNAME;
      error = true;
    }

    if (!password) {
      errorPassword = ResponseMessages.ERRORS.PASSWORD_EMPTY;
      error = true;
    }

    this.setState({ errorUserName, errorPassword, commonError: null });
    return error;
  };

  login = (e) => {
    e.preventDefault();
    if (!this.validate()) {
      this.props.handleSubmit(this.state);
    }
  };

  render() {
    const { errorPassword, errorUserName, commonError } = this.state;

    return (
      <form className="form">
        <div className="form__form-group">
          <div className="form__form-group-field">
            <div className="form__form-group-icon form__form-group-icon--left">
              <EmailOutlineIcon />
            </div>
            <input
              name="username"
              type="text"
              onChange={this.onChange}
              placeholder="Username"
              className="form__custom-field form__custom-field--icon-left"
            />
          </div>
          {errorUserName && <Error text={errorUserName} />}
        </div>
        <div className="form__form-group">
          <div className="form__form-group-field">
            <div className="form__form-group-icon form__form-group-icon--left">
              <KeyVariantIcon />
            </div>
            <input
              name="password"
              type={this.state.showPassword ? 'text' : 'password'}
              placeholder="Password"
              onChange={this.onChange}
              className="form__custom-field form__custom-field--icon-lr"
            />
            <button
              className={`form__form-group-button form__form-group-icon--right${
                this.state.showPassword ? ' active' : ''
              }`}
              onClick={(e) => this.showPassword(e)}
              type="button"
            >
              <EyeIcon />
            </button>
          </div>
          {errorPassword && <Error text={errorPassword} />}
          {commonError && <Error text={commonError} />}
          <div className="account__forgot-password clearfix">
            <a href="/forgot-password">Forgot password?</a>
          </div>
        </div>
        <div className="account__btns">
          <button className="btn btn-primary account__btn" onClick={this.login}>
            Login
          </button>
        </div>
      </form>
    );
  }
}
