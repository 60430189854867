import React, { Component, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import classNames from 'classnames';
import { connect } from 'react-redux';
import TableStatusFilterComponent from '../../shared/components/form/TableFilterComponent';
import localStorage from '../../libs/storageHelper';

import { toggleLoading } from '../../redux/actions/commonActions';
import { SortingField } from '../common/SortingCell';
import { isSuperAdmin } from '../../utils/Utils';
import { FACILITY_INVITATION_STATUS } from '../../constants/constants';

const TableRow = ({
  heads,
  item,
  viewProfile = () => {},
  handleActiveStatus,
}) => {
  if (!item.username) {
    item.username = item.email;
  }

  const handleStatusChange = useCallback(
    (status) => {
      if (!handleActiveStatus) return;
      handleActiveStatus(status, item.id, item.joined, item.fullName);
    },
    [handleActiveStatus, item],
  );

  return (
    <tr className="body-tr" key={item.id}>
      {heads.filter(Boolean).map(({ key, capitalize, transform }) => (
        <td
          key={key}
          className={classNames({
            lastName: key === 'fullName',
            'text-capitalize': !!capitalize,
          })}
          onClick={() => (key === 'fullName' ? viewProfile(item) : null)}
        >
          {typeof transform === 'function' ? transform(item[key]) : item[key]}
        </td>
      ))}
      {handleActiveStatus && (
        <td>
          <UncontrolledDropdown>
            <DropdownToggle>
              <span className="six-layers" style={{ marginLeft: '12px' }} />
            </DropdownToggle>
            <DropdownMenu className="dropdown__menu">
              {item.status.toLowerCase() !==
                FACILITY_INVITATION_STATUS.active && (
                <DropdownItem tag="span">
                  <a
                    onClick={() =>
                      handleStatusChange(FACILITY_INVITATION_STATUS.active)
                    }
                  >
                    Activate
                  </a>
                </DropdownItem>
              )}
              {item.status.toLowerCase() !==
                FACILITY_INVITATION_STATUS.inactive && (
                <DropdownItem tag="span">
                  <a
                    onClick={() =>
                      handleStatusChange(FACILITY_INVITATION_STATUS.inactive)
                    }
                  >
                    Deactivate
                  </a>
                </DropdownItem>
              )}
              <DropdownItem tag="span">
                <a
                  onClick={() =>
                    handleStatusChange(FACILITY_INVITATION_STATUS.removed)
                  }
                >
                  Remove
                </a>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </td>
      )}
    </tr>
  );
};

class StaffTableComponent extends Component {
  static propTypes = {
    heads: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string,
        sortable: PropTypes.bool,
      }),
    ).isRequired,
    rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      loggedUser: localStorage.getFromStorage('loggedUser'),
    };
  }

  UNSAFE_componentWillMount() {
    this.props.toggleLoading(true);
  }

  componentWillUnmount() {
    if (this.props.common.loading) {
      this.props.toggleLoading(false);
    }
  }

  handleStatusFilter = (list) => {
    this.props.handleGridFilter('status', list);
  };

  render() {
    const {
      rows,
      heads,
      sortField,
      sortOrder,
      viewProfile,
      handleActiveStatus,
    } = this.props;
    const { loggedUser } = this.state;

    const headers = heads.filter(Boolean).map((head, key) => {
      return head.sortable && !head.filterable ? (
        <th key={key}>
          {sortField === head.key ? (
            <SortingField
              icon={sortOrder === 1 ? 'triangle-up' : 'triangle-down'}
              name={head.name}
              onSort={() => this.props.handleGridSort(head.key)}
            />
          ) : (
            <div
              className="sort-field"
              onClick={() => this.props.handleGridSort(head.key)}
            >
              <p>{head.name}</p>

              <div>
                {(isSuperAdmin(loggedUser) &&
                  head.key === 'facilityDisplayName') || (
                  <span className="diamond-narrow" />
                )}
              </div>
            </div>
          )}
        </th>
      ) : head.key === 'status' ? (
        <th key={key}>
          <TableStatusFilterComponent
            allFilters={head.filterValue}
            action={(list) => this.handleStatusFilter(list)}
            label={head.name}
          />
        </th>
      ) : (
        <th key={key}>
          <p>{head.name}</p>
        </th>
      );
    });

    if (handleActiveStatus) {
      headers.push(
        <th key='action' style={{ width: '50px' }}>
          <p>Action</p>
        </th>,
      );
    }

    return (
      <div className="row">
        <div className="col-md-12">
          <Table striped className="custom-table loyal-table">
            <thead>
              <tr className="header-tr" style={{ top: '180px' }}>
                {headers}
              </tr>
            </thead>
            <tbody>
              {rows.map((row) => (
                <TableRow
                  key={row.id}
                  heads={heads}
                  item={row}
                  viewProfile={viewProfile}
                  handleActiveStatus={handleActiveStatus}
                />
              ))}
            </tbody>
          </Table>
          {rows.length === 0 && this.props.common.loading === false && (
            <div className="no-data-message">No results found</div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  common: state.common,
});

const mapDispatchToProps = {
  toggleLoading,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StaffTableComponent);
