import React, { useEffect } from 'react';
import { getAllDesignations } from '../../../../redux/actions/staffActions';
import { useDispatch, useSelector } from 'react-redux';
import { InputLabel } from '../../InputLabel';
import { FormGroup } from 'reactstrap';
import Error from '../../form/Error';

export default function DesignationSelect({
  value,
  onChange,
  name = 'designation',
  required,
  label = 'Designation',
  className = '',
  error,
}) {
  const designations = useSelector((state) => state.staff.designationAll);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllDesignations);
  }, [dispatch]);

  return (
    <div className={`form__form-group ${className}`}>
      <InputLabel required={required}>{label}</InputLabel>
      <div className="form__form-group-field">
        <FormGroup>
          <select
            name={name}
            value={value}
            id="designation"
            className="form-control form-select"
            onChange={onChange}
          >
            <option key="" value="">
              Select
            </option>
            {designations.map((value) => (
              <option key={value._id} value={value._id}>
                {value.name}
              </option>
            ))}
          </select>
          {error && <Error text={error} />}
        </FormGroup>
      </div>
    </div>
  );
}
