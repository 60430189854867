import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import NewAppointmentModal from '../../CreateAppointment/Components/NewAppointmentModal';
import MessageModal from '../../../../shared/components/Modals/MessageModal';
import { createAppointment } from '../../../../redux/actions/appointmentAction';
import { DEFAULT_TIMESTAMP_FORMAT } from '../../../../constants/constants';

const defaultState = {
  shareUpdates: null,
  appointmentDate: null,
  appointmentTime: null,
  time: null,
  appointmentType: null,
  doctor: null,
  referringDoctor: null,
};

function NewPetAppointment({
  pet,
  isOpen,
  createPetAppointment,
  handleClose,
  appointmentCreateError,
  appointmentRequestsExist,
  openPetAppointmentRequestsModal,
}) {
  const params = useParams();
  const [formErrors, setFormErrors] = useState({});
  const [appointment, setAppointment] = useState(defaultState);

  const [responseBox, setResponseBox] = useState({
    showResponse: false,
    responseType: '',
    responseAlertType: '',
    responseFooter: '',
    message: '',
    onSuccess: null,
    appointment: null,
  });

  useEffect(() => {
    if (!appointmentCreateError) return;
    const {
      statusText,
      status,
      data: { errorMessage },
    } = appointmentCreateError;

    setResponseBox({
      showResponse: true,
      message: `${statusText}(${status}) - ${errorMessage}`,
      responseType: 'alert',
      responseAlertType: 'danger',
      responseFooter: false,
    });
  }, [appointmentCreateError]);

  const onCloseAppointmentModal = (type = 'close-modal') => {
    handleClose();
    setAppointment(defaultState);

    if (type !== 'appointment-created' && appointmentRequestsExist) {
      openPetAppointmentRequestsModal(pet._id);
    }
  };

  const createAppointment = () => {
    const {
      shareUpdates,
      appointmentDate,
      appointmentTime,
      appointmentType: type,
      doctor,
      referringDoctor,
      appointmentTypeOther: typeOther,
    } = appointment;

    if (!type || !appointmentDate) {
      return setFormErrors({
        appointmentType: type ? null : 'Appointment type is a required field',
        appointmentDate: appointmentDate ? null : 'Date is a required field',
      });
    }

    onClose();

    createPetAppointment({
      parentId: params.parentId,
      pet: pet._id,
      appointmentDate: moment(appointmentDate).format(DEFAULT_TIMESTAMP_FORMAT),
      time: appointmentTime.format('hh:mm A'),
      type,
      typeOther: typeOther?.trim(),
      doctor: doctor === '' ? null : doctor,
      referringDoctor: referringDoctor === '' ? null : referringDoctor,
      shareUpdates: shareUpdates ?? false,
    });

    onCloseAppointmentModal('appointment-created');

    setResponseBox({
      showResponse: true,
      message: `Appointment is successfully created.`,
      responseType: 'ok',
      responseFooter: false,
    });

    setTimeout(() => {
      setResponseBox((prev) => ({
        ...prev,
        showResponse: false,
      }));
    }, 3000);
  };

  const onChangeAppointmentType = (event) => {
    setAppointment((prev) => ({
      ...prev,
      appointmentType: event.currentTarget.value,
    }));
    setFormErrors({ appointmentType: '' });
  };

  const onChangeSelect = (e, type, resetShareUpdates = false) => {
    setAppointment((prev) => ({
      ...prev,
      [type]: e.value,
      shareUpdates:
        type === 'referringDoctor' ? !resetShareUpdates : prev.shareUpdates,
    }));
  };

  const handleShareUpdates = () => {
    setAppointment((prev) => ({
      ...prev,
      shareUpdates: !prev.shareUpdates,
    }));
  };

  const handledateChange = (appointmentDate) => {
    setAppointment((prev) => ({
      ...prev,
      appointmentDate,
    }));
  };

  const handleTimeChange = (time) => {
    if (time) {
      const appointmentTime = moment(time);

      setAppointment((prev) => ({
        ...prev,
        appointmentTime,
      }));
    }
  };

  const onClose = () => {
    setResponseBox({
      shareUpdates: null,
      appointmentDate: null,
      appointmentTime: null,
      time: null,
      appointmentType: null,
      doctor: null,
      referringDoctor: null,
    });
  };

  return (
    <>
      <MessageModal
        show={responseBox.showResponse}
        type={responseBox.responseType}
        alertType={responseBox.responseAlertType}
        footer={responseBox.responseFooter}
        onSuccess={responseBox.onSuccess}
        onClose={onClose}
        appointment={responseBox.appointment}
        message={responseBox.message}
      />

      <NewAppointmentModal
        isOpen={isOpen}
        petId={pet?._id}
        reschedule={false}
        createData={{
          shareUpdates: appointment.shareUpdates,
          appointmentDate: appointment.appointmentDate,
          appointmentTime: appointment.appointmentTime,
          time: appointment.time,
          appointmentType: appointment.appointmentType,
          doctor: appointment.doctor,
          referringDoctor: appointment.referringDoctor,
          errors: formErrors,
          collapse: isOpen,
        }}
        handledateChange={handledateChange}
        handleTimeChange={handleTimeChange}
        onChangeSelect={onChangeSelect}
        handleShareUpdates={handleShareUpdates}
        appointmentDate={appointment.appointmentDate}
        appointmentTime={appointment.appointmentTime}
        viewMode
        onClose={onCloseAppointmentModal}
        handleChange={onChangeAppointmentType}
        submit={createAppointment}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  ...state,
  appointmentCreated: state.appointments.createAppointment,
  appointmentCreateError: state.appointments.createAppointmentError,
});

const mapDispatchToProps = {
  createPetAppointment: createAppointment,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewPetAppointment);
