import React, { Component } from 'react';
import { connect } from 'react-redux';
import ChangePasswordForm from './components/Form';
import { changePassword } from '../../../redux/actions/userActions';
import localStorage from '../../../libs/storageHelper';
import MessageModal from '../../../shared/components/Modals/MessageModal';
import withRouter from '../../../shared/components/withRouter';
const icon = `${process.env.PUBLIC_URL}/img/loyal-logo.png`;

export class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showResponse: false,
    };
    this.changePassword = this.changePassword.bind(this);
  }

  closePopup = () => {
    this.setState({ showResponse: false }, () => {
      this.props.navigate('/user-profile');
    });
  };

  UNSAFE_componentWillReceiveProps(np) {
    if (
      np.user.changePasswordData &&
      np.user.changePasswordData.message === 'success'
    ) {
      localStorage.addToStorage('loggedUser', np.user.changePasswordData.data);
      this.setState({ showResponse: true });
    } else if (np.user.changePasswordErrorData) {
      this.setState({ user: np.user });
    }
  }

  changePassword(state) {
    const currentUser = localStorage.getFromStorage('loggedUser');

    this.props.changePassword({
      userId: currentUser.user._id,
      password: state.password,
      confirmPassword: state.confirmPassword,
    });
  }

  render() {
    const { showResponse } = this.state;

    return (
      <div className="account user-flow password-reset">
        <MessageModal
          show={showResponse}
          type="alert"
          alertType="success"
          onClose={this.closePopup}
          footer="true"
          message="Password reset successful"
        />
        <div className="account__wrapper">
          <div className="account__card">
            <div className="account__card--inner">
              <div className="account__head">
                <h2 className="account__title clearfix">
                  <span className="account__logo ">
                    <img src={icon} alt="" />
                  </span>
                </h2>
              </div>
              <h3 className="account__form-heading subheading">New Password</h3>
              <h4 className="account__form-subheading">
                Please enter a new password
              </h4>
              <ChangePasswordForm
                handleSubmit={this.changePassword}
                user={this.state.user}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = {
  changePassword,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ChangePassword),
);
