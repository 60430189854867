import React, { useMemo } from 'react';
import { Col, FormGroup, Row } from 'reactstrap';
import Error from '../../../../shared/components/form/Error';
import { formatPhone } from '../../../../libs/commonHelper';
import { FACILITY_INVITATION_STATUS } from '../../../../constants/constants';
import { Tooltip } from '../../../common/Tooltip';

export default function ParentInformation({
  parentImage,
  parentName,
  parentNameUserSet,
  profile,
  profileUpdateEnable,
  updateParent,
  inputs,
  errors,
  onChangeFields,
  reInvite,
}) {
  const clientStatus = useMemo(() => {
    switch (profile.invitationStatus) {
      case FACILITY_INVITATION_STATUS.active:
        return 'Joined';
      case FACILITY_INVITATION_STATUS.inactive:
        return 'Pending';
      case FACILITY_INVITATION_STATUS.removed:
        return 'Declined';
      default:
        return 'N/A';
    }
  }, [profile.invitationStatus]);

  return (
    <Row>
      <Col sm="12">
        <div className="row find-parent-wrapper">
          <div className="col-md-6 parent-collapse ">
            <div className="parent-card">
              <div className="row">
                <div className="col-md-3">
                  <img
                    className="parent-image"
                    src={parentImage}
                    alt="avatar"
                  />
                </div>
                <div className="col-md-9">
                  <p className="parent-name">{parentName}</p>
                  {parentName !== parentNameUserSet && (
                    <Tooltip
                      value={'First and last name the user set in mobile app'}
                    >
                      <p className="parent-name-2">{parentNameUserSet} *</p>
                    </Tooltip>
                  )}
                  <p className="parent-email">{profile.email}</p>
                  <p className="parent-tel">
                    {profile.phone &&
                      `Mobile Number: ${formatPhone(
                        profile.phone,
                        '(###) ###-####',
                      )}`}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="offset-md-3 col-md-9">
                  <div
                    className={`status-label parent-actions ${profile.invitationStatus}`}
                  >
                    {clientStatus}
                  </div>
                  {!profile.loggedFirstTime ||
                    (profile.invitationStatus ===
                      FACILITY_INVITATION_STATUS.removed && (
                      <div className="action-wrapper parent-actions">
                        <button
                          className="action"
                          onClick={() =>
                            reInvite({ id: profile._id, phone: profile.phone })
                          }
                        >
                          Reinvite
                        </button>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6"></div>
        </div>
        <div className="row content-holder">
          <div className="col-md-3">
            <div className="form__form-group loyal-custom-form-group">
              <span className="form__form-group-label">First Name</span>
              <div className="form__form-group-field">
                <FormGroup>
                  <input
                    className="form-control"
                    name="firstName"
                    type="text"
                    placeholder="First Name"
                    value={inputs.firstName}
                    maxLength={50}
                    onChange={onChangeFields}
                  />
                  {errors.firstName.error && (
                    <Error text={errors.firstName.error} />
                  )}
                </FormGroup>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form__form-group loyal-custom-form-group">
              <span className="form__form-group-label">Last Name</span>
              <div className="form__form-group-field">
                <FormGroup>
                  <input
                    className="form-control"
                    name="lastName"
                    type="text"
                    value={inputs.lastName}
                    placeholder="Last Name"
                    maxLength={50}
                    onChange={onChangeFields}
                  />
                  {errors.lastName.error && (
                    <Error text={errors.lastName.error} />
                  )}
                </FormGroup>
              </div>
            </div>
          </div>
          <div className="col-md-6"></div>
        </div>
      </Col>
      <Col sm="12">
        <div className="row">
          <div className="col-md-6 offset-md-6 text-right">
            <button
              className="btn loyal-btn-save-update"
              onClick={profileUpdateEnable ? updateParent : undefined}
            >
              Update
            </button>
          </div>
        </div>
      </Col>
    </Row>
  );
}
