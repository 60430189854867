import React from 'react';
import { Card, CardBody } from 'reactstrap';

export const DefaultWrapper = ({ className, children }) => {
  return (
    <Card>
      <CardBody className={className}>{children}</CardBody>
    </Card>
  );
};

export const ClientPageWrapper = ({ className, children }) => (
  <div className={className}>{children}</div>
);
