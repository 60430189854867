import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import CreateAppointmentForm from './CreateAppointmentForm';
import { getPetSummaryById } from '../../../../redux/actions/petActions';
import ModalPetInfo from '../../Common/ModalPetInfo';

const NewAppointmentModal = ({
  isOpen,
  onClose,
  appointmentDate,
  appointmentTime,
  createData,
  handledateChange,
  handleTimeChange,
  onChangeSelect,
  handleShareUpdates,
  saveSuccess,
  handleChange,
  reschedule,
  submit,
  petId,
}) => {
  const [pet, setPet] = useState({});

  useEffect(() => {
    if (!petId) return;

    async function getPet() {
      const result = await getPetSummaryById(petId);

      setPet(result);
    }

    getPet();
  }, [petId]);

  return (
    <Modal
      isOpen={isOpen}
      size="xl"
      scrollable
      toggle={onClose}
      backdrop="static"
      className="model-form modal-lg patient-tracking-popup"
    >
      <ModalHeader className="pet-summary-wrapper">
        <ModalPetInfo
          petSummary={pet}
          avatar={pet.image}
          title={`New\nAppointment`}
        />
      </ModalHeader>
      <ModalBody>
        <div style={{ padding: '20px' }}>
          <CreateAppointmentForm
            appointmentDate={appointmentDate}
            appointmentTime={appointmentTime}
            reschedule={reschedule}
            createData={createData}
            handledateChange={handledateChange}
            handleTimeChange={handleTimeChange}
            onChangeSelect={onChangeSelect}
            handleShareUpdates={handleShareUpdates}
            viewMode={saveSuccess}
            handleChange={handleChange}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <button className="btn loyal-btn-clear" onClick={onClose}>
            Cancel
          </button>
          <button className="btn loyal-btn-save-update" onClick={submit}>
            Create Appointment
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default NewAppointmentModal;
