import React, { Component, createRef } from 'react';
import {
  Label,
  FormGroup,
  Table,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import HeadingText from '../../shared/components/form/HeadingText';
import CustomSelectComponent from '../../shared/components/form/CustomSelectComponent';
import MessageModal from '../../shared/components/Modals/MessageModal';
import Pagination from '../../shared/components/pagination/Pagination';
import config from '../../config/app.config';
import { SortingField } from '../common/SortingCell';
import InputWithSearchIcon from '../common/InputWithSearchIcon';

const heads = [
  {
    key: 'name',
    name: 'Breed',
    sortable: true,
    filterable: false,
    capitalize: true,
  },
  {
    key: 'species',
    name: 'Species',
    sortable: true,
    filterable: false,
    capitalize: true,
  },
];

export default class BreedTable extends Component {
  searchRef = createRef();

  constructor(props) {
    super(props);

    this.state = {
      sortField: 'name',
      sortOrder: 1,
      breeds: [],
      search: '',
      page: 1,
      limit: config.DEFAULT_PAGINATION_LENGTH,
      total: 0,
      offset: 0,
      sort: null,
      filter: {
        species: null,
      },
      cnfShow: false,
      cnfType: '',
      cnfMsg: '',
      cnfItem: '',
      cnfAlertType: '',
      selectedId: null,
    };
  }

  componentDidMount() {
    this.setState({ cnfMsg: false });
  }

  UNSAFE_componentWillReceiveProps(np) {
    const { breeds } = np;

    if (breeds) {
      this.setState({ breeds, total: breeds.total });
    }
  }

  handleFilter = (value) => {
    const { filter } = this.state;

    if (value.length > 0) {
      filter.species = value;
    } else {
      filter.species = null;
    }

    this.setState({ filter, page: 1, offset: 0 }, () => {
      this.loadCustomData();
    });
  };

  toggleForm = (status) => {
    this.props.toggleForm(status);
  };

  handleStatusFilter = (value) => {
    const { filter } = this.state;
    if (value.length > 0) {
      filter.status = value;
    } else {
      filter.status = null;
    }

    this.setState({ filter, page: 1, offset: 0 }, () => {
      this.loadCustomData();
    });
  };

  viewDetails = (params) => {
    this.setState({ cnfMsg: false });
    this.props.toggleForm(true, params);
  };

  handleSearch = (e) => {
    this.setState({ search: e.target.value, offset: 0 }, () => {
      this.loadCustomData();
    });
  };

  handleGridSort = (params) => {
    const { sortOrder } = this.state;
    const order = sortOrder === 1 ? -1 : 1;

    this.setState(
      { sortOrder: order, sortField: params, offset: 0, page: 1 },
      () => this.loadCustomData(),
    );
  };

  loadCustomData = () => {
    const { search, filter, page, limit, sortOrder, sortField } = this.state;

    this.props.loadBreeds({
      search,
      filter,
      page,
      limit,
      sort: {
        field: sortField,
        order: sortOrder,
      },
    });
  };

  remove = (id) => {
    this.setState({
      cnfItem: 'delete',
      cnfShow: true,
      cnfType: 'confirm',
      cnfMsg: 'Are you sure you want to remove this breed?',
      selectedId: id,
    });
  };

  onClose = () => {
    this.setState({ cnfShow: false, cnfAlertType: null });
  };

  onSuccess = async () => {
    const { cnfItem, selectedId: id } = this.state;
    this.setState({ cnfShow: false });

    if (cnfItem === 'Activate' || cnfItem === 'Deactivate') {
      this.props.save({ id }, true);
      let cnfMsg = cnfItem === 'Activate' ? 'Activated' : 'deactivated';
      cnfMsg = `This breed is ${cnfMsg} now`;
      const cnfType = 'alert';
      this.setState({ cnfMsg, cnfType, cnfShow: true });
    } else if (cnfItem === 'delete') {
      try {
        await this.props.delete(id);
      } catch (err) {
        this.setState({
          cnfMsg: err?.errorMessage,
          cnfType: 'alert',
          cnfAlertType: 'danger',
          cnfShow: true,
        });
      }
    }

    setTimeout(() => {
      this.loadCustomData();
    }, config.POPUP_TIMEOUT_TIME);
  };

  prevPage = () => {
    let { page, offset, limit } = this.state;
    --page;
    offset = (page - 1) * limit;
    this.setState({ page, offset }, () => this.loadCustomData());
  };

  nextPage = () => {
    let { page, offset, limit } = this.state;
    ++page;
    offset = (page - 1) * limit;
    this.setState({ page, offset }, () => this.loadCustomData());
  };

  gotoPage = (params) => {
    let { page, offset, limit } = this.state;
    page = params + 1;
    offset = (page - 1) * limit;

    this.setState({ page, offset }, () => this.loadCustomData());
  };

  handlePageLimit = (limit) => {
    this.setState({ limit, page: 1 }, () => this.loadCustomData());
  };

  headers = () => {
    const { sortOrder, sortField } = this.state;

    return heads.map((head, key) => {
      const _sortField = head.key;
      const isSortable = head.sortable && !head.filterable;
      const isSortFieldMatch = sortField === head.key;

      const handleGridSort = () => {
        this.handleGridSort(_sortField);
      };

      return (
        <th key={key}>
          {isSortable ? (
            <SortingField
              name={head.name}
              onSort={handleGridSort}
              icon={
                isSortFieldMatch
                  ? sortOrder === 1
                    ? 'triangle-up'
                    : 'triangle-down'
                  : 'diamond-narrow'
              }
            />
          ) : (
            <p key={key}>{head.name}</p>
          )}
        </th>
      );
    });
  };

  handleClearSearch = () => {
    this.setState({ search: '' }, () => {
      this.loadCustomData();
    });
  };

  tableRows = () => {
    const { breeds } = this.state;

    const headText = heads.map((head) =>
      head.key === 'name' ? 'breed' : head.key,
    );

    const capitalizedText = heads.map((head) =>
      head.capitalize && head.capitalize ? head.key : null,
    );

    const values = breeds.map((element) => ({
      breed: element.name,
      species: element.species.name,
      speciesId: element.species._id,
      id: element._id,
    }));

    return values.map((row, key) => {
      const _row = Object.keys(row).map((value, key) => {
        if (headText.includes(value)) {
          const capitalizeClass = capitalizedText.includes(value)
            ? 'text-capitalize'
            : '';

          return (
            <td key={key} className={capitalizeClass}>
              {row[value]}
            </td>
          );
        }
        return true;
      });

      return (
        <tr className="body-tr" key={key}>
          {_row}
          <td>
            <UncontrolledDropdown>
              <DropdownToggle>
                <span className="six-layers"></span>
              </DropdownToggle>
              <DropdownMenu className="dropdown__menu">
                <DropdownItem tag="div">
                  <a onClick={() => this.viewDetails(row)}>View Details</a>
                </DropdownItem>
                <DropdownItem tag="div">
                  <a onClick={() => this.remove(row.id)}>Remove</a>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </td>
        </tr>
      );
    });
  };

  render() {
    const { breeds, cnfMsg, cnfType, cnfShow, cnfAlertType, offset, search } =
      this.state;

    return (
      <div>
        <MessageModal
          show={cnfShow}
          type={cnfType}
          message={cnfMsg}
          footer
          onClose={this.onClose}
          onSuccess={this.onSuccess}
          alertType={cnfAlertType}
        />

        <div className="sticky-table">
          <HeadingText text="Manage Breeds" />
          <div className="row search form">
            <div className="col-sm-6">
              <div className="form__form-group">
                <Label for="exampleSelect" className="empty-label">
                  &nbsp;
                </Label>

                <InputWithSearchIcon
                  name="search"
                  id="search"
                  type="text"
                  onChange={this.handleSearch}
                  placeholder="Search by breed"
                  onClear={this.handleClearSearch}
                  value={search}
                  autofocus
                  ref={this.searchRef}
                />
              </div>
            </div>
            <div className="col-sm-3">
              <FormGroup>
                <Label for="exampleSelect">Filter by species</Label>
                <CustomSelectComponent
                  allFilters={this.props.species}
                  filterTypeText="Species"
                  filterTypeTextPlural="Species"
                  action={this.handleFilter}
                />
              </FormGroup>
            </div>
            <div className="col-sm-3">
              <FormGroup>
                <button
                  className="btn btn-outline-primary btn-new-breed"
                  onClick={() => this.toggleForm(true)}
                >
                  Add New Breed
                </button>
              </FormGroup>
            </div>
          </div>
        </div>

        <Table striped className="custom-table loyal-table">
          <thead>
            <tr className="header-tr" style={{ top: '190px' }}>
              {this.headers()}
              <th className="text-center action">
                <p className=" action">Action</p>
              </th>
            </tr>
          </thead>
          <tbody>{this.tableRows()}</tbody>
        </Table>
        {breeds.breed && breeds.breed.length === 0 && (
          <div className="no-data-message">No results found</div>
        )}

        <Pagination
          handlePageLimit={(limit) => this.handlePageLimit(limit)}
          nextPage={this.nextPage}
          prevPage={this.prevPage}
          gotoPage={(i) => this.gotoPage(i)}
          limit={this.props.limit}
          offset={offset}
          total={this.props.totalResults}
        />
      </div>
    );
  }
}
