import { combineReducers } from 'redux';

import themeReducer from './themeReducer';
import sidebarReducer from './sidebarReducer';
import staffReducer from './staffReducer';
import facilityReducer from './facilityReducer';
import clientReducer from './clientReducer';
import adminReducer from './adminReducer';
import commonReducer from './commonReducer';
import ReferralDoctorReducer from './referralDoctorReducer';
import breedReducer from './breedReducer';
import petReducer from './petReducer';
import pTBReducer from './pTBReducer';
import appointmentRequestReducer from './appointmentRequestReducer';
import appointmentReducer from './appointmentReducer';
import dashboardReducer from './dashboardReducer';
import broadcastReducer from './broadcastReducer';
import userReducer from './userReducer';
import refillRequestReducer from './refillRequestReducer';

const rootReducer = combineReducers({
  theme: themeReducer,
  sidebar: sidebarReducer,
  user: userReducer,
  staff: staffReducer,
  facility: facilityReducer,
  client: clientReducer,
  admin: adminReducer,
  common: commonReducer,
  refDoctor: ReferralDoctorReducer,
  breeds: breedReducer,
  pets: petReducer,
  ptb: pTBReducer,
  appointmentRequests: appointmentRequestReducer,
  appointments: appointmentReducer,
  dashboard: dashboardReducer,
  broadcast: broadcastReducer,
  refillRequests: refillRequestReducer,
});

export { rootReducer };
