import React from 'react';
import { appointmentStatus } from '../../../config/app.config';

function PetCard({
  color,
  firstName,
  lastName,
  date,
  time,
  onCardClick,
  id,
  status,
  metadata,
  laneId,
}) {
  return (
    <div
      className="card pet-card-container"
      onClick={() => onCardClick(id, metadata, laneId)}
    >
      <div className="name" style={{ borderColor: color, background: 'white' }}>
        <span className="first" title={firstName}>
          {firstName}&nbsp;
        </span>
        <span className="second" title={lastName}>
          {lastName}
        </span>
      </div>
      <div className="date-time">
        {appointmentStatus.ongoing === status && (
          <span className="first" title={date}>
            {date}
          </span>
        )}
        <span className="first" title={time}>
          {time}
        </span>
      </div>
    </div>
  );
}

export default PetCard;
