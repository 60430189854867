import React, { Component } from 'react';
import { connect } from 'react-redux';
import ResetPasswordForm from './components/Form';
import CommonHelper from '../../../libs/commonHelper';
import MessageModal from './../../../shared/components/Modals/MessageModal';
import {
  resetPassword,
  verifyResetPassword,
} from '../../../redux/actions/userActions';
import constants from '../../../constants/constants';
import localStorage from '../../../libs/storageHelper';
import { toTheSystemStoreApp } from '../../../utils/Utils';
import withRouter from '../../../shared/components/withRouter';
const icon = `${process.env.PUBLIC_URL}/img/loyal-logo.png`;

export class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: null,
      showResponse: false,
      responseMessage: '',
      responseType: '',
      responseAlertType: '',
      user: {},
      redirect: false,
      isClient: false,
    };
    this.resetPassword = this.resetPassword.bind(this);
  }

  componentDidMount() {
    const token = CommonHelper.getParameterByName('token');
    this.setState({ token });
    this.props.verifyResetPassword({ token });
  }

  UNSAFE_componentWillReceiveProps(np) {
    if (np.user.verifyResetPasswordDataError) {
      this.setState({
        showResponse: true,
        responseMessage: 'Your reset link expired or has already been used',
        responseType: 'alert',
        responseAlertType: 'danger',
        redirect: '/forgot-password',
      });
    } else if (np.user.resetPasswordDataError) {
      const userData = np.user;
      this.setState({ user: userData });
    } else if (np.user.resetPasswordData) {
      const { user } = np.user.resetPasswordData.data;
      if (user.type === constants.userRole.client) {
        return this.setState({
          showResponse: true,
          responseMessage: 'Password reset successful',
          responseType: 'alert',
          responseAlertType: 'success',
          isClient: true,
        });
      }

      localStorage.addToStorage('loggedUser', np.user.resetPasswordData.data);

      this.setState({
        showResponse: true,
        responseMessage: 'Password reset successful',
        responseType: 'alert',
        responseAlertType: 'success',
        redirect: '/login',
      });
    }
  }

  closePopup = () => {
    this.setState({ showResponse: false }, () => {
      if (this.state.isClient) {
        toTheSystemStoreApp();
      } else {
        const { redirect } = this.state;
        if (redirect) {
          this.props.history.push(redirect);
        }
      }
    });
  };

  resetPassword(state) {
    this.props.resetPassword({
      token: this.state.token,
      password: state.password,
      confirmPassword: state.confirmPassword,
    });
  }

  render() {
    const {
      showResponse,
      responseMessage,
      responseType,
      responseAlertType,
      isClient,
    } = this.state;

    return (
      <div className="account user-flow password-reset">
        <MessageModal
          show={showResponse}
          type={responseType}
          alertType={responseAlertType}
          onClose={this.closePopup}
          footer="true"
          message={responseMessage}
        />
        <div className="account__wrapper">
          <div className="account__card">
            <div className="account__card--inner">
              <div className="account__head">
                <h2 className="account__title clearfix">
                  <span className="account__logo ">
                    <img src={icon} alt="" />
                  </span>
                </h2>
              </div>
              <h3 className="account__form-heading subheading">New Password</h3>
              <h4 className="account__form-subheading">
                Please enter a new password
              </h4>
              <ResetPasswordForm
                handleSubmit={this.resetPassword}
                user={this.state.user}
                isClient={isClient}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = {
  resetPassword,
  verifyResetPassword,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ResetPassword),
);
