import { FACILITY_STATUS } from '../facility/constants';

const adminColumns = [
  {
    key: 'facility',
    name: 'Facility',
    sortKey: 'facilityDisplayName',
    sortable: true,
    capitalize: true,
  },
  {
    key: 'fullName',
    name: 'Name',
    sortKey: 'fullName',
    sortable: true,
    filterable: false,
    capitalize: true,
  },
  {
    key: 'email',
    name: 'Email',
    sortable: false,
  },
  {
    key: 'status',
    name: 'Status',
    filterable: true,
    filterValue: [
      { active: FACILITY_STATUS.ACTIVE },
      { inactive: FACILITY_STATUS.INACTIVE },
    ],
    sortable: false,
    capitalize: true,
  },
];

export default adminColumns;
