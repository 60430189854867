import {
  FETCH_USER_PROFILE,
  UPDATE_USER_PROFILE,
  UPDATE_USER_AVATAR,
  UPDATE_USER_AVATAR_ERROR,
  LOGIN,
  LOGOUT,
  LOGIN_ERROR,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_ERROR,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_ERROR,
  VERIFY_RESET_PASSWORD,
  VERIFY_RESET_PASSWORD_ERROR,
  GET_ONBOARDING_INFO,
  SIGNUP,
  GET_ONBOARDING_INFO_ERROR,
  GET_SUPPORTER_INFO,
  GET_SUPPORTER_INFO_ERROR,
  ACCEPT_INVITATION,
  ACCEPT_INVITATION_ERROR,
} from '../actions/userActions';

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_USER_PROFILE:
      return {
        ...state,
        profile: action.payload,
        profileUpdated: false,
        avatar: null,
      };
    case UPDATE_USER_PROFILE:
      return {
        ...state,
        profileUpdated: action.payload,
        resetPassword: action.resetPassword,
        avatar: null,
      };
    case UPDATE_USER_AVATAR:
      return {
        ...state,
        avatar: action.payload,
      };
    case UPDATE_USER_AVATAR_ERROR:
      return {
        ...state,
        avatar: null,
      };
    case LOGIN:
      return {
        ...state,
        loginData: action.payload,
        profile: action.payload?.user,
        loginErrorData: null,
      };
    case LOGOUT:
      return {
        ...state,
        loginData: action.payload,
        loginErrorData: null,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        loginErrorData: action.payload,
      };
    case CHANGE_PASSWORD:
      return {
        ...state,
        changePasswordData: action.payload,
        changePasswordErrorData: null,
      };
    case CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        changePasswordErrorData: action.payload,
        changePasswordData: null,
      };
    case FORGOT_PASSWORD:
      return {
        ...state,
        forgotPasswordData: action.payload,
        forgotPasswordDataError: null,
      };
    case FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        forgotPasswordDataError: action.payload,
        forgotPasswordData: null,
      };
    case VERIFY_RESET_PASSWORD:
      return {
        ...state,
        verifyResetPasswordData: action.payload,
        verifyResetPasswordDataError: null,
      };
    case VERIFY_RESET_PASSWORD_ERROR:
      return {
        ...state,
        verifyResetPasswordDataError: action.payload,
        verifyResetPasswordData: null,
      };
    case RESET_PASSWORD:
      return {
        ...state,
        resetPasswordData: action.payload,
        resetPasswordDataError: null,
      };
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        resetPasswordDataError: action.payload,
        resetPasswordData: null,
      };
    case GET_ONBOARDING_INFO:
      return {
        ...state,
        onBoardingUser: action.payload,
      };
    case GET_ONBOARDING_INFO_ERROR:
      return {
        ...state,
        onBoardingError: true,
      };

    case SIGNUP:
      return {
        ...state,
        signed: true,
      };
    case GET_SUPPORTER_INFO:
      return {
        ...state,
        supporterUser: action.payload,
      };
    case GET_SUPPORTER_INFO_ERROR:
      return {
        ...state,
        supporterError: true,
      };
    case ACCEPT_INVITATION:
      return {
        ...state,
        acceptInvitation: true,
        acceptInvitationError: undefined,
      };
    case ACCEPT_INVITATION_ERROR:
      return {
        ...state,
        acceptInvitationError: action.payload,
      };
    default:
      return state;
  }
}
