import React, { Component } from 'react';
import { connect } from 'react-redux';
import withRouter from '../../shared/components/withRouter';

const icon = `${process.env.PUBLIC_URL}/img/loyal-logo.png`;

const contactEmail = 'support@loyal.pet';

export class Contacts extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="account user-flow">
        <div className="account__wrapper">
          <div className="account__card">
            <div className="account__card--inner">
              <div className="account__head">
                <h2 className="account__title">
                  Get in touch with
                  <span className="account__logo">
                    <img src={icon} alt="" />
                  </span>
                </h2>
              </div>

              <h4 className="account__subhead subhead">
                Loyal facilitates digital communication between animal service
                providers and their clients and enables greater operating
                efficiency and higher customer satisfaction.
              </h4>

              <div>
                <h3 className="account__subhead subhead">Contacts:</h3>
                <div>
                  <a
                    href={`mailto:${contactEmail}`}
                  >{`Email: ${contactEmail}`}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Contacts),
);
