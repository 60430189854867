import React, { Component } from 'react';
import StateItem from '../inputs/StateItem';
import { ClearIcon } from '../../../containers/common/InputWithSearchIcon';

export default class TableFilterComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allFilters: [],
      filteredList: [],
      isFilterDropdownVisible: false,
      tempSelectedFilters: [],
    };
  }

  componentDidMount() {
    this.setState({
      allFilters: this.props.allFilters,
      filteredList: this.props.allFilters,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.allFilters) {
      this.setState({
        allFilters: nextProps.allFilters,
        filteredList: nextProps.allFilters,
      });
    }

    if (nextProps.filters) {
      const areFiltersEqual = this.areFiltersEqual(
        nextProps.filters,
        this.state.tempSelectedFilters,
      );

      if (!areFiltersEqual) {
        this.setState({
          tempSelectedFilters: nextProps.filters,
        });
      }
    }
  }

  UNSAFE_componentWillMount() {
    document.addEventListener('mousedown', this.handleOutsideClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick, false);
  }

  handleOutsideClick = (e) => {
    if (this.node && !this.node.contains(e.target)) {
      this.setState({
        isFilterDropdownVisible: false,
      });
    }
  };

  handleApplyStatesFilter = () => {
    const { allFilters, tempSelectedFilters } = this.state;
    this.props.action(tempSelectedFilters);

    this.setState({
      filteredList: allFilters,
    });
  };

  handleSelectState = (state) => {
    const { tempSelectedFilters } = this.state;
    const selectedIndex = tempSelectedFilters.indexOf(state);

    if (selectedIndex === -1) {
      tempSelectedFilters.push(state);
    } else {
      tempSelectedFilters.splice(selectedIndex, 1);
    }
    this.setState({
      tempSelectedFilters,
    });
    this.handleApplyStatesFilter();
  };

  handleStateChangeFocus = () => {
    this.setState({
      isFilterDropdownVisible: !this.state.isFilterDropdownVisible,
    });
  };

  isStateSelected = (state) => {
    const { tempSelectedFilters } = this.state;
    return tempSelectedFilters.indexOf(state) !== -1;
  };

  clearSelection = () => {
    this.setState(
      {
        tempSelectedFilters: [],
      },
      () => this.handleApplyStatesFilter(),
    );
  };

  areFiltersEqual = (newFilters, currentFilters) => {
    return JSON.stringify(newFilters) === JSON.stringify(currentFilters);
  };

  render() {
    const { isFilterDropdownVisible, filteredList, tempSelectedFilters } =
      this.state;
    const { label } = this.props;

    let minHeight;
    minHeight = (filteredList.length ? filteredList.length : 2) * 40;
    if (minHeight < 80) {
      minHeight = 80;
    }
    if (minHeight > 200) {
      minHeight = 200;
    }

    return (
      <div
        className="filter-select-box-wrapper clearfix"
        ref={(node) => (this.node = node)}
      >
        <p>{label} </p>
        <span
          className="filter-icon"
          onClick={this.handleStateChangeFocus}
        ></span>
        {isFilterDropdownVisible && filteredList.length > 0 && (
          <div className="states-result">
            <div style={{ width: '100%', maxHeight: 250, overflowY: 'auto' }}>
              <div className="blocks-wrapper">
                {filteredList.map((value) => {
                  const filterKey = Object.keys(value)[0];
                  return (
                    <StateItem
                      key={filterKey}
                      value={value}
                      filterKey={filterKey}
                      isStateSelected={this.isStateSelected(filterKey)}
                      handleSelectState={() =>
                        this.handleSelectState(filterKey)
                      }
                    />
                  );
                })}
              </div>
              {tempSelectedFilters.length > 1 && (
                <div
                  className="sticky btn btn-sm"
                  style={{ bottom: 0, margin: 0 }}
                  onClick={this.clearSelection}
                >
                  clear selection
                  <ClearIcon visible style={{ right: 'unset', left: '8px' }} />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}
