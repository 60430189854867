import React, { Component } from 'react';
import EyeIcon from 'mdi-react/EyeOffOutlineIcon';
import Error from '../../../../shared/components/form/Error';
import ResponseMessages from '../../../../constants/responseMessages';
import constants from '../../../../constants/constants';

export default class ResetPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      showConfirmPassword: false,
      password: '',
      confirmPassword: '',
      errorPassword: null,
      errorConfirmPassword: null,
      commonError: null,
    };

    this.showPassword = this.showPassword.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(np) {
    if (np.user && np.user.resetPasswordDataError) {
      const error = np.user.resetPasswordDataError.data;
      let errorMsg = null;

      switch (error) {
        case 'Same password':
          errorMsg = ResponseMessages.ERRORS.SAME_PASSWORD;
          break;
        case 'Passwords do not match':
          errorMsg = ResponseMessages.ERRORS.PASSWORD_NOT_MATCH;
          break;
        case 'Password reset Unsuccessful':
          errorMsg = ResponseMessages.ERRORS.PASSWORD_RESET_UNSUCCESSFUL;
          break;
        default:
          break;
      }

      this.setState({
        commonError: errorMsg,
      });
    }
  }

  validate() {
    const { password, confirmPassword } = this.state;
    let errorPassword = null;
    let errorConfirmPassword = null;
    let error = false;

    const passwordRegEx = new RegExp(constants.PASSWORD_PATTERN);

    if (!password) {
      errorPassword = ResponseMessages.ERRORS.NEW_PASSWORD_EMPTY;
      error = true;
    } else if (!passwordRegEx.test(password)) {
      errorPassword = ResponseMessages.ERRORS.PASSWORD_PATTERN;
      error = true;
    }

    if (!confirmPassword) {
      errorConfirmPassword = ResponseMessages.ERRORS.CONFIRM_PASSWORD_EMPTY;
      error = true;
    } else if (confirmPassword !== password) {
      errorConfirmPassword = ResponseMessages.ERRORS.PASSWORD_NOT_MATCH;
      error = true;
    }

    this.setState({ errorPassword, errorConfirmPassword });
    return error;
  }

  changePassword(e) {
    e.preventDefault();
    if (!this.validate()) {
      const { password, confirmPassword } = this.state;
      this.props.handleSubmit({ password, confirmPassword });
    }
  }

  onChange(e) {
    if (e.target.name === 'password' || e.target.name === 'confirmPassword') {
      const password = e.target.value.replace(/\s/g, '');
      this.setState({
        [e.target.name]: password,
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  }

  showPassword(e, field) {
    e.preventDefault();
    switch (field) {
      case 'password':
        this.setState({
          showPassword: !this.state.showPassword,
        });
        break;
      case 'confirmPassword':
        this.setState({
          showConfirmPassword: !this.state.showConfirmPassword,
        });
        break;
      default:
        break;
    }
  }

  render() {
    const { errorPassword, errorConfirmPassword, commonError } = this.state;

    return (
      <form className="form" onSubmit={this.changePassword}>
        <div className="form__form-group">
          <div className="form__form-group-field">
            <input
              name="password"
              type={this.state.showPassword ? 'text' : 'password'}
              placeholder="New Password"
              onFocus={() => {
                this.setState({ errorPassword: null });
              }}
              onChange={this.onChange}
              className="form__custom-field form__custom-field--icon-right"
            />
            <button
              className={`form__form-group-button form__form-group-icon--right${
                this.state.showPassword ? ' active' : ''
              }`}
              tabIndex="-1"
              onClick={(e) => this.showPassword(e, 'password')}
            >
              <EyeIcon />
            </button>
          </div>
          {errorPassword && <Error text={errorPassword} />}
        </div>
        <div className="form__form-group">
          <div className="form__form-group-field">
            <input
              name="confirmPassword"
              type={this.state.showConfirmPassword ? 'text' : 'password'}
              placeholder="Confirm New Password"
              onChange={this.onChange}
              onFocus={() => {
                this.setState({ errorConfirmPassword: null });
              }}
              className="form__custom-field form__custom-field--icon-right"
            />
            <button
              className={`form__form-group-button form__form-group-icon--right${
                this.state.showConfirmPassword ? ' active' : ''
              }`}
              tabIndex="-1"
              onClick={(e) => this.showPassword(e, 'confirmPassword')}
            >
              <EyeIcon />
            </button>
          </div>
          {errorConfirmPassword && <Error text={errorConfirmPassword} />}
        </div>
        {commonError && <Error text={commonError} />}
        <div className="account__btns">
          <button
            disabled={this.props.isClient}
            className="btn btn-primary account__btn"
          >
            Confirm
          </button>
        </div>
      </form>
    );
  }
}
