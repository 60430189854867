import React from 'react';
import {
  useNavigate,
  useLocation,
  useParams,
  useSearchParams,
} from 'react-router-dom';

const withRouter = (Component) => {
  const WithRouter = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    return (
      <Component
        {...props}
        location={location}
        navigate={(to, options) =>
          navigate(to, { state: { from: location }, ...options })
        }
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        history={{
          push: (route) => {
            let nextRoute = '';
            if (typeof route === 'string') {
              nextRoute = route;
              navigate(route, { state: { from: location } });
            } else if (route.pathname) {
              const { pathname, state, ...otherState } = route;
              nextRoute = pathname;
              navigate(pathname, {
                state: { from: location, ...state, ...otherState },
              });
            }
            console.warn(
              `"History" is deprecated, use "navigate" instead;\n From ${location.pathname} to ${nextRoute}`,
            );
          },
        }}
        params={params}
      />
    );
  };

  return WithRouter;
};

export default withRouter;
