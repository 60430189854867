import React from 'react';

export function Tooltip({ value, children }) {
  return value ? (
    <div className="tooltipApp">
      <span className="tooltiptext">{value}</span>
      {children}
    </div>
  ) : (
    children
  );
}
