import React from 'react';
import CreateAppointment from './CreateAppointment';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

const CreateAppointmentModal = (props) => {
  return (
    <Modal
      isOpen={props.isOpen}
      size="xl"
      scrollable
      toggle={props.onClose}
      backdrop="static"
    >
      <ModalHeader toggle={props.onClose}>New Appointment</ModalHeader>
      <ModalBody>
        <CreateAppointment
          history={props.history}
          noCard={true}
          onCancel={props.onClose}
        />
      </ModalBody>
    </Modal>
  );
};

export default CreateAppointmentModal;
