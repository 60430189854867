import React, { Component } from 'react';
import './index.scss';
import { Link } from 'react-router-dom';
import config from '../../../config/app.config';
import storageHelper from '../../../libs/storageHelper';
const icon = `${process.env.PUBLIC_URL}/img/loyal-logo.png`;

export default class Logo extends Component {
  render() {
    const loggedUser = storageHelper.getFromStorage('loggedUser');

    const redirectTo =
      loggedUser && loggedUser.user.type === config.USER_TYPES.STAFF
        ? '/tracking-board'
        : '/client-list';
    return (
      <div id="loyal-logo">
        <Link to={redirectTo}>
          <img src={icon} alt="" className={'logo-image'} />
        </Link>
      </div>
    );
  }
}
