import React, { createContext, useContext, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { appointmentActions } from '../../../../config/app.config';
import MessageModal from '../../../../shared/components/Modals/MessageModal';
import {
  cancelAppointment,
  removeAppointment,
} from '../../../../redux/actions/appointmentAction';

const { reschedule, remove, cancel } = appointmentActions;

const initialResponseBoxState = {
  showResponse: false,
  responseType: null,
  responseAlertType: null,
  responseFooter: false,
  message: null,
};

const AppointmentActionContext = createContext({
  handleActions: () => {},
});

export const AppointmentActionProvider = ({ children, getAppointments }) => {
  const [responseBox, setResponseBox] = useState(initialResponseBoxState);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [confirmType, setConfirmType] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleActions = (type, data) => {
    switch (type) {
      case reschedule:
        if (data.parentStatus === 'inactive') {
          setResponseBox({
            ...initialResponseBoxState,
            showResponse: true,
            responseType: 'alert',
            responseAlertType: 'danger',
            responseFooter: true,
            message: `${data.parentName} is inactive. Please activate the parent to reschedule the appointment.`,
          });
        } else {
          navigate(`/appointments/${data.id}`);
        }
        break;

      case remove:
        setResponseBox({
          ...initialResponseBoxState,
          showResponse: true,
          responseType: 'confirm',
          responseFooter: true,
          message: 'Are you sure you want to remove this appointment?',
        });
        setSelectedRowId(data);
        setConfirmType(remove);
        break;

      case cancel:
        setResponseBox({
          ...initialResponseBoxState,
          showResponse: true,
          responseType: 'confirm',
          responseFooter: true,
          message: 'Are you sure you want to cancel this appointment?',
        });
        setSelectedRowId(data);
        setConfirmType(cancel);
        break;

      default:
        break;
    }
  };

  const onSuccess = useCallback(async () => {
    if (confirmType === cancel) {
      await dispatch(cancelAppointment({ appointmentId: selectedRowId }));
    } else if (confirmType === remove) {
      await dispatch(removeAppointment({ appointmentId: selectedRowId }));
    }
    await getAppointments();
    onClose();
  }, [confirmType, selectedRowId, dispatch, getAppointments]);

  const onClose = () => {
    setResponseBox(initialResponseBoxState);
  };

  return (
    <AppointmentActionContext.Provider value={{ handleActions }}>
      {children}
      <MessageModal
        show={responseBox.showResponse}
        type={responseBox.responseType}
        alertType={responseBox.responseAlertType}
        footer={responseBox.responseFooter}
        onSuccess={onSuccess}
        onClose={onClose}
        message={responseBox.message}
      />
    </AppointmentActionContext.Provider>
  );
};

export const useAppointmentActionContext = () =>
  useContext(AppointmentActionContext);
