import React from 'react';
import { connect } from 'react-redux';
import { getPtbEvents } from '../../../redux/actions/pTBActions';
import { toggleLoading } from '../../../redux/actions/commonActions';

export function RefreshPtbButton({
  getPtbEvents,
  facility,
  lastPtbUpdate,
  onRefreshClick,
  style = {},
}) {
  return (
    <div className="refresh-btn-container" style={style}>
      <span className="text">
        Last update <br />
        {lastPtbUpdate?.toLocaleTimeString()}
      </span>
      <button
        onClick={onRefreshClick}
        className="refresh-board-btn btn-primary logout_btn"
      >
        Refresh
      </button>
    </div>
  );
}

const mapStateToProps = ({ user: { profile }, ptb }) => {
  return {
    facility: profile?.facility ?? {},
    lastPtbUpdate: ptb.lastPtbUpdate,
  };
};

const mapDispatchToProps = {
  getPtbEvents,
  toggleLoading,
};

const RefreshBoardButton = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RefreshPtbButton);

export default RefreshBoardButton;
