import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import Topbar from './topbar/Topbar';
import Sidebar from './sidebar/Sidebar';

import {
  changeThemeToDark,
  changeThemeToLight,
} from '../../redux/actions/themeActions';
import {
  changeMobileSidebarVisibility,
  changeSidebarVisibility,
} from '../../redux/actions/sidebarActions';

function Layout() {
  const dispatch = useDispatch();
  const sidebar = useSelector((state) => state.sidebar);

  const layoutClass = useMemo(
    () =>
      classNames({
        layout: true,
        'layout--collapse': sidebar.collapse,
      }),
    [sidebar.collapse],
  );

  return (
    <div className={layoutClass}>
      <Topbar />
      <Sidebar
        sidebar={sidebar}
        changeToDark={() => dispatch(changeThemeToDark())}
        changeToLight={() => dispatch(changeThemeToLight())}
        changeSidebarVisibility={() => dispatch(changeSidebarVisibility())}
        changeMobileSidebarVisibility={() =>
          dispatch(changeMobileSidebarVisibility())
        }
      />
    </div>
  );
}

export default Layout;
