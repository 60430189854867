import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Col } from 'reactstrap';
import debounce from 'lodash.debounce';

import {
  appointmentPageTypes,
  DEFAULT_PAGINATION_LENGTH,
} from '../../../config/app.config';
import InputWithSearchIcon from '../../common/InputWithSearchIcon';
import TimeFilter from '../Common/TimeFilter';
import HeadingText from '../../../shared/components/form/HeadingText';
import PaginationComponent from '../../../shared/components/pagination/Pagination';
import { formatDate } from '../../../helpers/formatDate';

import { toggleLoading } from '../../../redux/actions/commonActions';
import { getAllAppointments } from '../../../redux/actions/appointmentAction';
import { AppointmentActionProvider, getAppointmentsHeaders } from './utils';
import {
  appointmentComponentsWrapperMap,
  AppointmentsTable,
} from './Components';

const initialParams = {
  search: '',
  timeFilter: null,
  sortOrder: -1,
  sortField: 'dateTime',
  offset: 0,
  limit: DEFAULT_PAGINATION_LENGTH,
  filter: [],
  range: {},
};

function reducer(params, action) {
  switch (action.type) {
    case 'SET_SEARCH':
      return { ...params, search: action.payload, offset: 0 };
    case 'SET_TIME_FILTER':
      return {
        ...params,
        timeFilter: action.payload,
        offset: action.payload === 'range' ? params.offset : 0,
      };
    case 'SET_SORT': {
      const { field, order } = action.payload;
      return {
        ...params,
        sortField: field ?? params.sortField,
        sortOrder: order ?? params.sortOrder,
        offset: 0,
      };
    }
    case 'SET_FILTER':
      return {
        ...params,
        filter: {
          ...params.filter,
          [action.payload.type]: action.payload.filters,
        },
        offset: 0,
      };
    case 'SET_RANGE':
      return { ...params, range: action.payload, offset: 0 };
    case 'SET_LIMIT':
      return { ...params, limit: action.payload, offset: 0 };
    case 'SET_OFFSET':
      return { ...params, offset: action.payload };
    default:
      return params;
  }
}

export function Appointments({
  type = appointmentPageTypes.default,
  parentId,
}) {
  const searchRef = useRef();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const petId = searchParams.get('pet');

  const [params, localDispatch] = useReducer(reducer, initialParams);
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);

  const getAppointments = useCallback(async () => {
    const formattedStartDate = formatDate(params.range.startDate);
    const formattedEndDate = formatDate(params.range.endDate);

    const { status, type, doctor } = params.filter;

    const appointments = await dispatch(
      getAllAppointments({
        sort: {
          field: params.sortField,
          order: +params.sortOrder,
        },
        parentId,
        petId,
        search: params.search,
        doctorId: doctor?.length > 0 ? doctor : null,
        status: status?.length > 0 ? status : null,
        type: type?.length > 0 ? type : null,
        timeFilter: {
          type: params.timeFilter,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        },
        limit: params.limit,
        offset: params.offset,
      }),
    );

    setRows(appointments.data || []);
    setTotal(appointments.total);
  }, [parentId, petId, params, dispatch]);

  useEffect(() => {
    const fetchAppointments = async () => {
      dispatch(toggleLoading(true));
      await getAppointments();
      dispatch(toggleLoading(false));
    };

    fetchAppointments();
  }, [getAppointments, dispatch]);

  const handleTimeFilterChange = (timeFilter) => {
    localDispatch({ type: 'SET_TIME_FILTER', payload: timeFilter });
  };

  const handleSort = ({ field, order }) => {
    localDispatch({ type: 'SET_SORT', payload: { field, order } });
  };

  const handleFilter = (type, filters) => {
    localDispatch({ type: 'SET_FILTER', payload: { type, filters } });
  };

  const setDateRange = (range) => {
    localDispatch({ type: 'SET_RANGE', payload: range });
  };

  const handlePageLimit = (newLimit) => {
    localDispatch({ type: 'SET_LIMIT', payload: newLimit });
  };

  const debouncedSearch = debounce((e) => {
    localDispatch({ type: 'SET_SEARCH', payload: e.target.value });
  }, 600);

  const handleClearSearch = () => {
    localDispatch({ type: 'SET_SEARCH', payload: '' });
  };

  const Wrapper = appointmentComponentsWrapperMap[type];

  return (
    <AppointmentActionProvider getAppointments={getAppointments}>
      <Col md={12} lg={12} className="manage-patient-container admin-container">
        <Wrapper>
          {type === appointmentPageTypes.default && (
            <>
              <HeadingText text="Appointments" />
              <div className="row form col-md-6">
                <div
                  className="form__form-group"
                  style={{ marginBottom: 'unset' }}
                >
                  <InputWithSearchIcon
                    name="search"
                    id="search"
                    type="text"
                    ref={searchRef}
                    viewProfile={() => {}}
                    onChange={(e) => debouncedSearch(e)}
                    placeholder="Search by Parent Name, Pet Name"
                    onClear={handleClearSearch}
                    autofocus
                  />
                </div>
              </div>
            </>
          )}
          <div className="row">
            <TimeFilter
              changeFilter={handleTimeFilterChange}
              selected={params.timeFilter}
              setDateRange={setDateRange}
              placeholder="Select Day Or Date Range"
            />
          </div>
          <AppointmentsTable
            stickyTop="60px"
            heads={getAppointmentsHeaders(type)}
            rows={rows}
            sortField={params.sortField}
            sortOrder={params.sortOrder}
            handleSort={handleSort}
            handleFilter={handleFilter}
          />
          <PaginationComponent
            handlePageLimit={handlePageLimit}
            nextPage={() =>
              localDispatch({
                type: 'SET_OFFSET',
                payload: params.offset + params.limit,
              })
            }
            prevPage={() =>
              localDispatch({
                type: 'SET_OFFSET',
                payload: params.offset - params.limit,
              })
            }
            gotoPage={(page) =>
              localDispatch({
                type: 'SET_OFFSET',
                payload: params.limit * page,
              })
            }
            limit={params.limit}
            offset={params.offset}
            total={total}
          />
        </Wrapper>
      </Col>
    </AppointmentActionProvider>
  );
}
