import React from 'react';
import { Col, FormGroup, Row } from 'reactstrap';
import Error from '../../../shared/components/form/Error';
import PlacesSearchAutoComplete from '../../common/PlacesAutocomplete';
import config from '../../../config/app.config';
import { InputLabel } from '../../../shared/components/InputLabel';
import MaskedInput from '../../../shared/components/inputs/Masked';

export function FacilityBasicDetails({ errors, inputs, onChangeFields }) {
  return (
    <Row>
      <Col sm="12">
        <div className="row">
          <div className="col-md-6">
            <div className="form__form-group">
              <InputLabel required>Facility Name</InputLabel>
              <FormGroup>
                <input
                  name="name"
                  value={inputs.name}
                  type="text"
                  maxLength={100}
                  onChange={onChangeFields}
                />
                <InputError errorObj={errors.name} />
              </FormGroup>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form__form-group">
              <InputLabel>Abbreviation</InputLabel>
              <div className="form__form-group-field">
                <FormGroup>
                  <input
                    className="form-control"
                    name="abbreviation"
                    value={inputs.abbreviation}
                    type="text"
                    maxLength={10}
                    onChange={onChangeFields}
                  />
                  <InputError errorObj={errors.abbreviation} />
                </FormGroup>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="form__form-group">
              <InputLabel required>Display Name</InputLabel>
              <FormGroup>
                <input
                  name="displayName"
                  value={inputs.displayName}
                  type="text"
                  onChange={onChangeFields}
                />

                <InputError errorObj={errors.displayName} />
              </FormGroup>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form__form-group">
              <InputLabel>Group Affiliation</InputLabel>
              <div className="form__form-group-field">
                <FormGroup>
                  <input
                    className="form-control"
                    name="groupAffiliation"
                    value={inputs.groupAffiliation}
                    type="text"
                    onChange={onChangeFields}
                  />
                  <InputError errorObj={errors.groupAffiliation} />
                </FormGroup>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export function FacilityPhysicalAddress({
  errors,
  inputs,
  getAutoCompleteAddress,
  onChangeFields,
}) {
  return (
    <Row>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className="col-md-4">
          <InputLabel required>Address</InputLabel>
          <FormGroup>
            <PlacesSearchAutoComplete
              value={inputs.address}
              getAddress={getAutoCompleteAddress}
            />
            <InputError errorObj={errors.address} />
          </FormGroup>
        </div>
        <div className="col-md-2">
          <InputLabel required>City</InputLabel>
          <FormGroup>
            <input
              name="city"
              type="text"
              value={inputs.city}
              onChange={onChangeFields}
            />
            <InputError errorObj={errors.city} />
          </FormGroup>
        </div>
        <div className="col-md-2">
          <InputLabel required>State</InputLabel>
          <FormGroup>
            <input
              name="state"
              type="text"
              value={inputs.state}
              onChange={onChangeFields}
            />
            <InputError errorObj={errors.state} />
          </FormGroup>
        </div>
        <div className="col-md-2">
          <InputLabel required>Zip code</InputLabel>
          <FormGroup>
            <input
              name="zipCode"
              type="text"
              value={inputs.zipCode}
              onChange={onChangeFields}
            />
            <InputError errorObj={errors.zipCode} />
          </FormGroup>
        </div>
      </div>
    </Row>
  );
}

export function FacilityBillingInfo({
  inputs,
  errors,
  onChangeFields,
  getAutoCompleteBillingAddress,
}) {
  return (
    <Row>
      <Col sm="12">
        <div className="row">
          <div className="col-md-6 text-left">
            <div className="checkbox-wrapper">
              <label className="checkbox-container terms-and-conditions">
                <input
                  id="billingSameAddress"
                  className="custom-checkbox"
                  type="checkbox"
                  name="billingSameAddress"
                  onChange={onChangeFields}
                />
                <span className="checkmark" />
                <label
                  className="form-check-label"
                  htmlFor="billingSameAddress"
                >
                  Same as physical address
                </label>
              </label>
            </div>
          </div>
          <div className="col-md-6 text-right">
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a
              href={config.STRIPE_URL}
              target="_blank"
              className="btn loyal-btn-outline-primary"
            >
              Charge Bill
            </a>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="form__form-group">
              <InputLabel required>Address</InputLabel>
              <div className="form__form-group-field">
                <FormGroup>
                  <PlacesSearchAutoComplete
                    value={inputs.billingAddress}
                    disabled={inputs.billingSameAddress}
                    getAddress={getAutoCompleteBillingAddress}
                  />
                  <InputError errorObj={errors.billingAddress} />
                </FormGroup>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form__form-group">
              <InputLabel>Billing Tier</InputLabel>
              <div className="form__form-group-field">
                <FormGroup>
                  <select
                    name="billingTier"
                    value={inputs.billingTier}
                    id="billingTier"
                    className="form-control form-select"
                    onChange={onChangeFields}
                  >
                    <option value="">Select</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </select>
                  <InputError errorObj={errors.billingTier} />
                </FormGroup>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form__form-group">
              <InputLabel>Subscription</InputLabel>
              <div className="form__form-group-field">
                <FormGroup>
                  <input
                    className="form-control"
                    name="subscription"
                    value={inputs.subscription}
                    type="text"
                    maxLength={9}
                    onChange={onChangeFields}
                  />
                  <InputError errorObj={errors.subscription} />
                </FormGroup>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-2">
            <div className="form__form-group">
              <InputLabel required>City</InputLabel>
              <div className="form__form-group-field">
                <FormGroup>
                  <input
                    className="form-control"
                    name="billingCity"
                    type="text"
                    readOnly={inputs.billingSameAddress}
                    value={inputs.billingCity}
                    onChange={onChangeFields}
                  />
                  <InputError errorObj={errors.billingCity} />
                </FormGroup>
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="form__form-group">
              <InputLabel required>State</InputLabel>
              <div className="form__form-group-field">
                <FormGroup>
                  <input
                    className="form-control"
                    name="billingState"
                    type="text"
                    readOnly={inputs.billingSameAddress}
                    value={inputs.billingState}
                    onChange={onChangeFields}
                  />
                  <InputError errorObj={errors.billingState} />
                </FormGroup>
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="form__form-group">
              <InputLabel required>Zip code</InputLabel>
              <div className="form__form-group-field">
                <FormGroup>
                  <input
                    className="form-control"
                    name="billingZipCode"
                    type="text"
                    readOnly={inputs.billingSameAddress}
                    value={inputs.billingZipCode}
                    onChange={onChangeFields}
                  />
                  <InputError errorObj={errors.billingZipCode} />
                </FormGroup>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form__form-group">
              <InputLabel re>Tablets Purchased</InputLabel>
              <div className="form__form-group-field">
                <FormGroup>
                  <input
                    className="form-control"
                    name="tabletsPurchased"
                    value={inputs.tabletsPurchased}
                    type="text"
                    maxLength={4}
                    onChange={onChangeFields}
                  />
                  <InputError errorObj={errors.tabletsPurchased} />
                </FormGroup>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="form__form-group">
              <InputLabel required>Billing Email</InputLabel>
              <FormGroup>
                <input
                  name="billingEmail"
                  value={inputs.billingEmail}
                  type="text"
                  maxLength={100}
                  onChange={onChangeFields}
                />
                <InputError errorObj={errors.billingEmail} />
              </FormGroup>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form__form-group">
              <InputLabel>Notes</InputLabel>
              <div className="form__form-group-field">
                <FormGroup>
                  <textarea
                    name="notes"
                    value={inputs.notes}
                    className="form-control"
                    onChange={onChangeFields}
                  ></textarea>
                  <InputError errorObj={errors.firstName} />
                </FormGroup>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export function FacilityContactDetails({ errors, inputs, onChangeFields }) {
  return (
    <>
      <Row>
        <Col>Email Addresses</Col>
        <Col>Telephone Numbers</Col>
        <Col>Website Addresses</Col>
      </Row>
      <Row>
        <div className="col-md-4">
          <div className="form__form-group">
            <InputLabel required>General</InputLabel>
            <FormGroup>
              <input
                name="email"
                type="text"
                value={inputs.email}
                onChange={onChangeFields}
              />
              <InputError errorObj={errors.email} />
            </FormGroup>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form__form-group">
            <InputLabel>General</InputLabel>
            <div className="form__form-group-field">
              <FormGroup>
                <MaskedInput
                  name="phone"
                  value={inputs.phone}
                  onChange={onChangeFields}
                  autoComplete="off"
                />
                <InputError errorObj={errors.phone} />
              </FormGroup>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form__form-group">
            <InputLabel>Main</InputLabel>
            <div className="form__form-group-field">
              <FormGroup>
                <input
                  className="form-control"
                  name="website"
                  value={inputs.website}
                  type="text"
                  maxLength={100}
                  onChange={onChangeFields}
                />
                <InputError errorObj={errors.website} />
              </FormGroup>
            </div>
          </div>
        </div>
      </Row>
      <Row>
        <div className="col-md-4">
          <div className="form__form-group">
            <InputLabel required>To Receive Appointment Requests</InputLabel>
            <FormGroup>
              <input
                className="form-control"
                name="appointmentRequestEmail"
                type="text"
                value={inputs.appointmentRequestEmail}
                onChange={onChangeFields}
              />
              <InputError errorObj={errors.appointmentRequestEmail} />
            </FormGroup>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form__form-group">
            <InputLabel>Fax</InputLabel>
            <div className="form__form-group-field">
              <FormGroup>
                <MaskedInput
                  name="fax"
                  value={inputs.fax}
                  onChange={onChangeFields}
                  autoComplete="off"
                />
                <InputError errorObj={errors.fax} />
              </FormGroup>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form__form-group">
            <InputLabel>Pharmacy</InputLabel>
            <div className="form__form-group-field">
              <FormGroup>
                <input
                  className="form-control"
                  name="pharmacyWeb"
                  value={inputs.pharmacyWeb}
                  type="text"
                  maxLength={100}
                  onChange={onChangeFields}
                />
                <InputError errorObj={errors.pharmacyWeb} />
              </FormGroup>
            </div>
          </div>
        </div>
      </Row>
      <Row>
        <div className="col-md-4">
          <div className="form__form-group">
            <InputLabel required>
              To Receive Refill Prescription Requests
            </InputLabel>
            <FormGroup>
              <input
                name="prescriptionRequestEmail"
                value={inputs.prescriptionRequestEmail}
                type="text"
                maxLength={100}
                onChange={onChangeFields}
              />
              <InputError errorObj={errors.prescriptionRequestEmail} />
            </FormGroup>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form__form-group">
            <InputLabel>Pharmacy</InputLabel>
            <div className="form__form-group-field">
              <FormGroup>
                <MaskedInput
                  name="pharmacyPhone"
                  value={inputs.pharmacyPhone}
                  onChange={onChangeFields}
                  autoComplete="off"
                />
                <InputError errorObj={errors.pharmacyPhone} />
              </FormGroup>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form__form-group">
            <InputLabel>Staff</InputLabel>
            <div className="form__form-group-field">
              <FormGroup>
                <input
                  className="form-control"
                  name="staffWeb"
                  value={inputs.staffWeb}
                  type="text"
                  maxLength={100}
                  onChange={onChangeFields}
                />
                <InputError errorObj={errors.staffWeb} />
              </FormGroup>
            </div>
          </div>
        </div>
      </Row>

      <Row>
        <div className="col-md-4">
          <div className="form__form-group">
            <InputLabel required>Billing</InputLabel>
            <FormGroup>
              <input
                name="billingEmail"
                value={inputs.billingEmail}
                type="text"
                maxLength={100}
                onChange={onChangeFields}
              />
              <InputError errorObj={errors.billingEmail} />
            </FormGroup>
          </div>
        </div>
      </Row>
    </>
  );
}

function InputError({ errorObj: { error } = {} }) {
  return error ? <Error text={error} /> : null;
}
