import React from 'react';
import { Col, Row } from 'reactstrap';
import Moment from 'react-moment';
import humanize from 'underscore.string/humanize';

const defaultAvatar = `${process.env.PUBLIC_URL}/img/default-animal-black.png`;

export default function ModalPetInfo({
  avatar = defaultAvatar,
  petSummary,
  title,
}) {
  const petName = petSummary?.name ?? '';
  const parentName = petSummary.petParentName;

  return (
    <Row className="modal-pet-info">
      <Col
        xs="2"
        className="d-flex align-items-center justify-content-start modal-pet-avatar"
      >
        <img
          className="topbar__avatar-img"
          src={avatar}
          style={{ width: '120px', height: '120px' }}
          alt="avatar"
        />
      </Col>
      <Col className="main-pet-info-column">
        <div className="summary-content">
          <div className="summary-content-inner">
            <span className="pet-name" title={petName}>
              {petName}
            </span>
            <span className="pet-parent" title={parentName}>
              {parentName}
            </span>
          </div>
          <div>
            {petSummary && (
              <span className="text">
                {petSummary.petSpeciesName}
                {petSummary.petBreedName ? `, ${petSummary.petBreedName}` : ''}
                {petSummary.gender ? `, ${humanize(petSummary.gender)}` : ''}
              </span>
            )}

            {petSummary?.birthday && (
              <span>
                <span className="gender-icon-male"></span>
                <span className="text">
                  <Moment format="MM/DD/YYYY">{petSummary.birthday}</Moment>
                </span>
              </span>
            )}
          </div>
          <div className="supporter-summary">
            <span className="text">
              {petSummary?.coParents?.length ?? 0}
              &nbsp;Co-Parents,&nbsp;
              {petSummary &&
                petSummary.supporters &&
                petSummary.supporters.length}
              &nbsp; Supporters
            </span>
          </div>
        </div>
      </Col>
      {title && (
        <Col className="modal-name-column">
          <p className="modal-name">{title}</p>
        </Col>
      )}
    </Row>
  );
}
