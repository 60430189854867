import React, { Component } from 'react';
import { Collapse } from 'reactstrap';
import { connect } from 'react-redux';
import humanize from 'underscore.string/humanize';
import { getParent } from '../../../redux/actions/petActions';

const defaultAvatar = `${process.env.PUBLIC_URL}/img/default-animal-black.png`;

class SelectPet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parentId: null,
      petList: [],
    };
  }

  getPetsData = ({ selectedParent, pets }) => {
    if (!selectedParent) {
      this.setState({ petList: [] });
    }
    if (selectedParent && selectedParent._id !== this.state.parentId) {
      this.setState({ parentId: selectedParent._id }, () => {
        this.props.getParent(selectedParent._id);
      });
    }
    if (selectedParent && selectedParent.pets) {
      this.autoSelectPet(selectedParent.pets);
      this.setState({ petList: selectedParent.pets });
    }
    if (pets && pets.petList) {
      this.autoSelectPet(pets.petList);
      this.setState({ petList: pets.petList || [] });
    }
  };

  componentDidMount() {
    const { selectedParent, pets } = this.props;
    this.getPetsData({ selectedParent, pets });
  }

  UNSAFE_componentWillReceiveProps({ selectedParent, pets }) {
    this.getPetsData({ selectedParent, pets });
    if (!this.props.collapse) {
      this.setState({ parentId: null });
    }
  }

  autoSelectPet(petList) {
    if (petList.length === 1) {
      const pet = petList.shift();

      this.props?.selectPet(pet);
    }
    return true;
  }

  render() {
    const {
      title = 'Select Pet',
      showNewPetButton = false,
      collapse,
      selectedPet,
      selectPet,
      viewMode,
      selectedParent,
    } = this.props;

    const { petList } = this.state;

    const petCards = petList.map((pet) => {
      return viewMode ? (
        selectedPet === pet._id ? (
          <div className="pet-card" key={pet._id}>
            <div className="row">
              <div className="col-md-3">
                <img
                  className="pet-image"
                  src={pet.image ? pet.image : defaultAvatar}
                  alt="avatar"
                />
              </div>
              <div className="col-md-9">
                <p className="pet-name">{pet.name}</p>
                <p className="pet-details species">
                  {pet.species}, {pet.breed || 'N/A'}
                </p>
                <p className="pet-details">{humanize(pet.gender) || 'N/A'}</p>
              </div>
            </div>
            <div className="row">
              <div className="offset-md-3 col-md-9"></div>
            </div>
          </div>
        ) : null
      ) : (
        <div
          className={
            'pet-card ' +
            (selectPet && !viewMode ? 'not-view-mode ' : '') +
            (this.props.selectedPet === pet._id ? 'selected-pet ' : '')
          }
          key={pet._id}
          onClick={() =>
            selectPet && !viewMode ? this.props.selectPet(pet) : false
          }
        >
          {selectPet && !viewMode && (
            <div className="select-wrapper">
              <input
                id={pet._id}
                type="radio"
                checked={this.props.selectedPet === pet._id}
                onChange={() => this.props.selectPet(pet)}
              />
              <label htmlFor={pet._id} className="checkmark" />
            </div>
          )}
          <div className="row">
            <div className="col-md-3">
              <img
                className="pet-image"
                src={pet.image ? pet.image : defaultAvatar}
                alt="avatar"
              />
            </div>
            <div className="col-md-9">
              <p className="pet-name">
                <span className="pet">{pet.name}</span>
              </p>
              <p className="pet-details species">
                {pet.species}, {pet.breed || 'N/A'}
              </p>
              <p className="pet-details">{humanize(pet.gender) || 'N/A'}</p>
              <p className="pet-details">{pet?.status}</p>
            </div>
          </div>
        </div>
      );
    });

    return (
      <div className="content-holder find-parent-wrapper">
        <div className="row">
          <div className="col-md-6">
            <h3
              className={
                !selectedParent ||
                (collapse && petList.length === 0 && !showNewPetButton) ||
                (!collapse && showNewPetButton)
                  ? 'loyal-disable-tab-text'
                  : ''
              }
            >
              {title}
            </h3>
          </div>
          <div className="col-md-6">
            {showNewPetButton && collapse && (
              <button
                className="btn pull-right add-new-pet-register-btn"
                disabled={!collapse}
                onClick={this.props.showNewPetAction}
              >
                <span className="lnr lnr-plus-circle" />
                Add New Pet
              </button>
            )}
          </div>
        </div>
        <Collapse
          isOpen={
            (collapse && petList.length > 0) || (collapse && showNewPetButton)
          }
        >
          <div className="row">
            <div className="col-md-12">{petCards}</div>
          </div>
        </Collapse>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = {
  getParent,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectPet);
