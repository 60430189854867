import React, { Component } from 'react';
import EyeIcon from 'mdi-react/EyeOffOutlineIcon';
import AccountIcon from 'mdi-react/AccountIcon';
import EmailOutlineIcon from 'mdi-react/EmailOutlineIcon';
import CellphoneIcon from 'mdi-react/CellphoneIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import { connect } from 'react-redux';
import * as userActions from '../../../../redux/actions/userActions';

class VerifyForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      user: {},
    };
  }

  submit = (e) => {
    e.preventDefault();
    let data = {};

    if (!this.props.user.loggedFirstTime) {
      const { firstName, lastName, email, password, confirmPassword } =
        this.state.user;
      data = {
        firstName,
        lastName,
        email,
        password,
        confirmPassword,
      };
    }

    this.props.acceptInvitation({
      data,
      token: this.props.token,
    });
  };

  onChange = ({ target }) => {
    this.setState((prev) => ({
      ...prev,
      user: { ...prev.user, [target.name]: target.value },
    }));
  };

  showPassword = () => {
    this.setState((prev) => ({ ...prev, showPassword: !prev.showPassword }));
  };

  componentDidUpdate(pp, ps) {
    if (this.props.user.phone && !this.state.user.phone) {
      this.setState((prev) => ({ ...prev, user: this.props.user }));
    }
  }

  render() {
    return (
      <form className="form">
        {!this.props.user.loggedFirstTime && (
          <>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <div className="form__form-group-icon form__form-group-icon--left">
                  <CellphoneIcon />
                </div>
                <input
                  name="phone"
                  type="number"
                  onChange={this.onChange}
                  readOnly
                  disabled
                  value={this.state.user.phone}
                  placeholder="Phone number"
                  className="form__custom-field form__custom-field--icon-left"
                />
              </div>
            </div>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <div className="form__form-group-icon form__form-group-icon--left">
                  <EmailOutlineIcon />
                </div>
                <input
                  name="email"
                  type="email"
                  onChange={this.onChange}
                  placeholder="Email"
                  className="form__custom-field form__custom-field--icon-left"
                />
              </div>
            </div>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <div className="form__form-group-icon form__form-group-icon--left">
                  <AccountIcon />
                </div>
                <input
                  name="firstName"
                  type="text"
                  value={this.state.user.firstName}
                  onChange={this.onChange}
                  placeholder="First name"
                  className="form__custom-field form__custom-field--icon-left"
                />
              </div>
            </div>{' '}
            <div className="form__form-group">
              <div className="form__form-group-field">
                <div className="form__form-group-icon form__form-group-icon--left">
                  <AccountIcon />
                </div>
                <input
                  name="lastName"
                  type="text"
                  onChange={this.onChange}
                  value={this.state.user.lastName}
                  placeholder="Last name"
                  className="form__custom-field form__custom-field--icon-left"
                />
              </div>
            </div>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <div className="form__form-group-icon form__form-group-icon--left">
                  <KeyVariantIcon />
                </div>
                <input
                  name="password"
                  type={this.state.showPassword ? 'text' : 'password'}
                  placeholder="Password"
                  onChange={this.onChange}
                  className="form__custom-field form__custom-field--icon-lr"
                />
                <button
                  className={`form__form-group-button form__form-group-icon--right${
                    this.state.showPassword ? ' active' : ''
                  }`}
                  onClick={(e) => this.showPassword(e)}
                  type="button"
                >
                  <EyeIcon />
                </button>
              </div>
            </div>{' '}
            <div className="form__form-group">
              <div className="form__form-group-field">
                <div className="form__form-group-icon form__form-group-icon--left">
                  <KeyVariantIcon />
                </div>
                <input
                  name="confirmPassword"
                  type={this.state.showPassword ? 'text' : 'password'}
                  placeholder="Confirm Password"
                  onChange={this.onChange}
                  className="form__custom-field form__custom-field--icon-lr"
                />
                <button
                  className={`form__form-group-button form__form-group-icon--right${
                    this.state.showPassword ? ' active' : ''
                  }`}
                  onClick={(e) => this.showPassword(e)}
                  type="button"
                >
                  <EyeIcon />
                </button>
              </div>
            </div>
          </>
        )}
        <div className="account__btns">
          <button
            className="btn btn-primary account__btn"
            onClick={this.submit}
          >
            {this.props.user.loggedFirstTime ? 'Accept' : 'Register'}
          </button>
        </div>
      </form>
    );
  }
}

const mapDispatchToProps = {
  acceptInvitation: userActions.acceptInvitation,
};

export default connect(null, mapDispatchToProps)(VerifyForm);
