import { USER_TYPES } from '../../config/app.config';

const staffColumns = [
  {
    key: 'fullName',
    name: 'Name',
    sortable: true,
    filterable: false,
    capitalize: true,
  },
  {
    key: 'designation',
    name: 'Designation',
    sortable: true,
    filterable: false,
    capitalize: true,
  },
  {
    key: 'type',
    name: 'Permissions',
    sortable: false,
    transform: (type) => (type === USER_TYPES.ADMIN ? 'Admin' : 'User'),
    filterable: false,
    capitalize: true,
  },
  {
    key: 'emailPhone',
    name: 'Email Address',
    sortable: false,
    filterable: false,
  },
  {
    key: 'username',
    name: 'Username',
    sortable: false,
    filterable: false,
  },
  {
    key: 'privateCredentials',
    name: 'Credentials',
    transform: (privateCredentials) =>
      privateCredentials ? 'Private' : 'Assigned',
    sortable: false,
    filterable: false,
  },
  {
    key: 'status',
    name: 'Status',
    sortable: false,
    filterable: true,
    filterValue: [{ active: 'active' }, { inactive: 'inactive' }],
    capitalize: true,
  },
];

export const facilityColumn = {
  key: 'facilityDisplayName',
  name: 'Facility',
  sortable: true,
  filterable: false,
  capitalize: true,
};

export default staffColumns;
