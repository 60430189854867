import React, { useState, useCallback, useMemo } from 'react';
import { Carousel, CarouselItem } from 'reactstrap';
import {
  AttachmentControls,
  AttachmentIndicators,
  AttachmentItem,
  AttachmentsList,
} from './AttachmentsTabComponents';

export function AttachmentsTab({ photos, documents, children }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const attachments = useMemo(() => {
    return [
      ...photos.map((url) => ({
        type: 'image',
        url,
        name: url.split('/').pop(),
        altText: 'Photo',
      })),
      ...documents.map((url) => ({
        type: 'pdf',
        url,
        name: url.split('/').pop(),
      })),
    ];
  }, [photos, documents]);

  const next = useCallback(() => {
    if (animating) return;
    const nextIndex =
      activeIndex === attachments.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }, [animating, activeIndex, attachments.length]);

  const previous = useCallback(() => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? attachments.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }, [animating, activeIndex, attachments.length]);

  const goToIndex = useCallback(
    (newIndex) => {
      if (animating) return;
      setActiveIndex(newIndex);
    },
    [animating],
  );

  return (
    <>
      <section className="attachments-tab">
        {attachments.length > 0 ? (
          <div className="scrollable-modal-area">
            <AttachmentsList attachments={attachments} goToIndex={goToIndex} />

            <Carousel
              activeIndex={activeIndex}
              interval={false}
              next={attachments.length > 1 ? next : () => {}}
              previous={attachments.length > 1 ? previous : () => {}}
            >
              {attachments.length > 1 && (
                <AttachmentIndicators
                  items={attachments}
                  activeIndex={activeIndex}
                  goToIndex={goToIndex}
                />
              )}
              {attachments.map((attachment, index) => (
                <CarouselItem
                  onExiting={() => setAnimating(true)}
                  onExited={() => setAnimating(false)}
                  key={index}
                >
                  <AttachmentItem attachment={attachment} />
                </CarouselItem>
              ))}
              {attachments.length > 1 && (
                <AttachmentControls next={next} previous={previous} />
              )}
            </Carousel>
          </div>
        ) : (
          <p>No attachments provided.</p>
        )}
      </section>
      <>{children}</>
    </>
  );
}
