import React, { Component, createRef } from 'react';
import { Label, FormGroup } from 'reactstrap';

import HeadingText from '../../shared/components/form/HeadingText';
import Pagination from '../../shared/components/pagination/Pagination';
import MessageModal from '../../shared/components/Modals/MessageModal';
import CustomSelectComponent from '../../shared/components/form/CustomSelectComponent';
import config from '../../config/app.config';
import AdminTableComponent from './AdminTableComponent';
import adminColumns from './constants';
import InputWithSearchIcon from '../common/InputWithSearchIcon';
import { STATUS } from '../../constants/constants';
import UpdateFacility from '../facility/UpdateFacility';

export default class AdminTable extends Component {
  searchRef = createRef();

  constructor() {
    super();

    this.state = {
      rows: [],
      sortField: 'fullName',
      sortOrder: 1,
      search: '',
      updateFacilityPopup: false,
      selectedId: null,
      offset: 0,
      limit: config.DEFAULT_PAGINATION_LENGTH,
      total: 0,
      selectedFilters: [],
      faciltyList: [],
      statusFilters: [],
      responseBox: {
        showResponse: false,
        responseType: null,
        responseAlertType: null,
        responseFooter: false,
        message: null,
      },
    };
  }

  UNSAFE_componentWillReceiveProps(np) {
    if (np.data && np.data.tableData) {
      this.createRows(np.data.tableData);
    }

    if (np.data.facilityAll) {
      this.setState({
        faciltyList: np.data.facilityAll,
      });
    }

    if (np.data.adminStatusUpdateResponse) {
      this.setState({
        responseBox: {
          showResponse: true,
          responseType: 'alert',
          responseAlertType: 'success',
          responseFooter: true,
          message: np.data.adminStatusUpdateResponse,
        },
      });
    }
  }

  createRows = (data) => {
    if (data.admins) {
      const rows = data.admins.map((member) => ({
        facility: member.facilityDisplayName,
        fullName: member.fullName,
        email: member.email,
        status: member.status,
        action: '',
        id: member._id,
        joined: member.joined,
        facilityId: member.facilityId,
        isPrimary: member.isPrimary,
        firstName: member.firstName,
        lastName: member.lastName,
      }));

      const { total, limit, offset } = data;

      this.setState({ rows, total, offset, limit });
    }
  };

  nextPage = () => {
    const {
      search,
      offset,
      limit,
      selectedFilters: facility,
      sortOrder,
      sortField,
    } = this.state;
    const skip = offset + limit;

    this.props.getAdminList({
      search,
      skip,
      limit,
      facility,
      sort: [
        {
          field: sortField,
          order: sortOrder,
        },
      ],
    });
  };

  prevPage = () => {
    const {
      search,
      offset,
      limit,
      selectedFilters: facility,
      sortOrder,
      sortField,
    } = this.state;
    const skip = offset - limit;

    this.props.getAdminList({
      search,
      skip,
      limit,
      facility,
      sort: [
        {
          field: sortField,
          order: sortOrder,
        },
      ],
    });
  };

  gotoPage = (page) => {
    const {
      search,
      limit,
      selectedFilters: facility,
      sortOrder,
      sortField,
    } = this.state;
    const skip = limit * page;

    this.props.getAdminList({
      search,
      skip,
      limit,
      facility,
      sort: [
        {
          field: sortField,
          order: sortOrder,
        },
      ],
    });
  };

  viewProfile = (data) => {
    this.props.toggleModal(true, data);
  };

  handleGridSort = (field) => {
    const {
      search,
      limit,
      selectedFilters: facility,
      sortOrder,
      sortField,
    } = this.state;
    const order = sortField === field && sortOrder === 1 ? -1 : 1;

    this.setState(
      {
        sortField: field,
        sortOrder: order,
        offset: 0,
        skip: 0,
      },
      () => {
        this.props.getAdminList({
          search,
          sort: [
            {
              field,
              order,
            },
          ],
          skip: 0,
          limit,
          facility,
        });
      },
    );
  };

  handleSearch = (e) => {
    const { selectedFilters: facility } = this.state;
    const search = e.target.value;
    this.setState({ search, offset: 0 }, () => {
      this.props.getAdminList({
        offset: 0,
        search,
        facility,
        limit: config.DEFAULT_PAGINATION_LENGTH,
      });
    });
  };

  handleClearSearch = () => {
    const { selectedFilters: facility } = this.state;
    this.setState({ search: '' }, () => {
      this.props.getAdminList({
        offset: 0,
        search: '',
        facility,
        limit: config.DEFAULT_PAGINATION_LENGTH,
      });
    });
  };

  viewFacility = (facilityId) => {
    this.setState({
      selectedId: facilityId,
      updateFacilityPopup: true,
    });
  };

  handleGridFilter = (header, value) => {
    this.setState({ statusFilters: value, offset: 0 }, () => {
      const {
        limit,
        sortField,
        sortOrder,
        search,
        selectedFilters: facility,
        statusFilters: status,
      } = this.state;
      this.props.getAdminList({
        offset: 0,
        limit,
        sortField,
        facility,
        sortOrder,
        search,
        status,
      });
    });
  };

  handleFilter = (list) => {
    this.setState({ selectedFilters: list }, () => {
      const {
        search,
        limit,
        selectedFilters: facility,
        skip,
        statusFilters: status,
      } = this.state;
      this.props.getAdminList({ search, skip, limit, facility, status });
    });
  };

  handleActiveStatus = (status, id, joined, firstName) => {
    const msg = status === STATUS.ACTIVE ? 'activate' : 'deactivate';
    let message = `Are you sure you want to ${msg} this admin profile?`;
    let confirmType = 'change_status';

    if (!joined) {
      message = `Are you sure you want to reinvite ${firstName}?`;
      confirmType = 'reinvite';
    }

    const responseBox = {
      showResponse: true,
      responseType: 'confirm',
      responseAlertType: null,
      responseFooter: true,
      message,
    };
    this.setState({
      status,
      selectedRowId: id,
      confirmType,
      responseBox,
    });
  };

  handleReinvite = (id, status, name) => {
    const message = `Are you sure you want to reinvite ${name}?`;
    const confirmType = 'reinvite';
    const responseBox = {
      showResponse: true,
      responseType: 'confirm',
      responseAlertType: null,
      responseFooter: true,
      message,
    };
    this.setState({
      selectedRowId: id,
      confirmType,
      responseBox,
    });
  };

  handleActivateDeactivate = (id, status) => {
    const type = status === STATUS.ACTIVE ? 'deactivate' : 'activate';
    const newStatus =
      status === STATUS.ACTIVE ? STATUS.INACTIVE : STATUS.ACTIVE;
    const message = `Are you sure you want to ${type} this admin profile?`;
    const confirmType = 'change_status';

    this.setState({
      status: newStatus,
      selectedRowId: id,
      confirmType,
      responseBox: {
        showResponse: true,
        responseType: 'confirm',
        responseAlertType: null,
        responseFooter: true,
        message,
      },
    });
  };

  handleRemove = (id) => {
    const message = 'Are you sure you want to remove this admin profile?';
    const confirmType = 'remove';

    this.setState({
      status: 'removed',
      selectedRowId: id,
      confirmType,
      responseBox: {
        showResponse: true,
        responseType: 'confirm',
        responseAlertType: null,
        responseFooter: true,
        message,
      },
    });
  };

  handleActions = (params) => {
    switch (params.type) {
      case 'view':
        this.viewProfile(params.data);
        break;
      case 'changeStatus':
        this.handleActivateDeactivate(
          params.data.id,
          params.data.status,
          params.data.name,
        );
        break;
      case 'reinvite':
        this.handleReinvite(
          params.data.id,
          params.data.status,
          params.data.name,
        );
        break;
      case 'remove':
        this.handleRemove(params.data.id, params.data.name);
        break;
      default:
        break;
    }
  };

  onSuccess = () => {
    const { confirmType, status, selectedRowId, search, skip, limit } =
      this.state;
    const data = { search, skip, limit };

    if (confirmType === 'change_status') {
      this.props.changeStatus(status, selectedRowId, data);
    }

    if (confirmType === 'remove') {
      this.props.changeStatus(status, selectedRowId, data);
    }

    if (confirmType === 'reinvite') {
      this.props.reinvite(selectedRowId, data);
    }

    this.onClose();
  };

  onClose = () => {
    this.setState({
      responseBox: {
        showResponse: false,
        responseType: null,
        responseAlertType: null,
        responseFooter: false,
        message: null,
      },
    });
  };

  closeViewFacilityPopup = () => {
    this.setState({ updateFacilityPopup: false, selectedId: null });
  };

  handlePageLimit = (newLimit) => {
    const { search, facility, sortField, sortOrder } = this.state;

    this.setState({ limit: newLimit }, () => {
      this.props.getAdminList({
        search,
        limit: newLimit,
        facility,
        sort: [
          {
            field: sortField,
            order: sortOrder,
          },
        ],
      });
    });
  };

  render() {
    const {
      rows,
      total,
      offset,
      limit,
      faciltyList,
      sortField,
      sortOrder,
      responseBox,
      selectedId,
      updateFacilityPopup,
    } = this.state;

    return (
      <div>
        <MessageModal
          show={responseBox.showResponse}
          type={responseBox.responseType}
          alertType={responseBox.responseAlertType}
          footer={responseBox.responseFooter}
          onSuccess={this.onSuccess}
          onClose={this.onClose}
          message={responseBox.message}
        />
        <div className="sticky-table">
          <HeadingText text="Manage Admins" />
          <div className="row search form">
            <div className="col-sm-6">
              <div className="form__form-group">
                <Label for="exampleSelect" className="empty-label">
                  &nbsp;
                </Label>

                <InputWithSearchIcon
                  name="search"
                  id="search"
                  type="text"
                  onChange={this.handleSearch}
                  ref={this.searchRef}
                  placeholder="Search by Facility, Name, or Email Address"
                  onClear={this.handleClearSearch}
                  value={this.state.search}
                  autofocus
                />
              </div>
            </div>
            <div className="col-sm-3">
              <FormGroup>
                <Label for="exampleSelect">Filter by facility</Label>
                <CustomSelectComponent
                  allFilters={faciltyList}
                  filterTypeText="Facility"
                  filterTypeTextPlural="Facilities"
                  action={this.handleFilter}
                />
              </FormGroup>
            </div>
            <div className="col-sm-3">
              <FormGroup>
                <Label for="exampleSelect" className="empty-label">
                  &nbsp;
                </Label>
                <button
                  className="btn btn-outline-primary pull-right top-panel-button"
                  onClick={this.props.toggleModal.bind(this, true, null)}
                >
                  Invite New Admin
                </button>
              </FormGroup>
            </div>
          </div>
        </div>
        <AdminTableComponent
          sortField={sortField}
          sortOrder={sortOrder}
          heads={adminColumns}
          rows={rows}
          handleGridSort={this.handleGridSort}
          handleGridFilter={this.handleGridFilter}
          handleActiveStatus={this.handleActions}
          handleViewFacility={this.viewFacility}
        />
        <UpdateFacility
          view={updateFacilityPopup}
          closeFacilityPopup={this.closeViewFacilityPopup}
          selectedId={selectedId}
        />
        <Pagination
          handlePageLimit={this.handlePageLimit}
          nextPage={this.nextPage}
          prevPage={this.prevPage}
          gotoPage={(i) => this.gotoPage(i)}
          limit={limit}
          offset={offset}
          total={total}
        />
      </div>
    );
  }
}
