import { PHASES } from '../config/app.config';

const phases = [
  {
    _id: 1,
    displayName: PHASES['1'],
  },
  {
    _id: 2,
    displayName: PHASES['2'],
  },
  {
    _id: 3,
    displayName: PHASES['3'],
  },
  {
    _id: 4,
    displayName: PHASES['4'],
  },
  {
    _id: 5,
    displayName: PHASES['5'],
  },
  {
    _id: 6,
    displayName: PHASES['6'],
  },
  {
    _id: 7,
    displayName: PHASES['7'],
  },
  {
    _id: 8,
    displayName: PHASES['8'],
  },
  {
    _id: 9,
    displayName: PHASES['9'],
  },
  {
    _id: 10,
    displayName: PHASES['10'],
  },
  {
    _id: 11,
    displayName: PHASES['11'],
  },
  {
    _id: 12,
    displayName: PHASES['12'],
  },
];

export default phases;
