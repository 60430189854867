import React, { useState, useEffect, useRef } from 'react';
import { Input } from 'reactstrap';
import Error from '../../../../shared/components/form/Error';

const limits = {
  MAX_PARAGRAPHS: 8,
  MAX_CHARACTERS: 300,
};

const errors = {
  CHARACTERS_LIMIT: `Text should be ${limits.MAX_CHARACTERS} characters max`,
  PARAGRAPHS_LIMIT: `The number of new paragraphs should not exceed ${limits.MAX_PARAGRAPHS}`,
};

export const ModalTextArea = ({
  inputValue,
  setInputValue,
  placeholder = 'Enter any additional comments to client…',
  title = 'Comments to client (optional)',
  rows = 4,
}) => {
  const [error, setError] = useState('');
  const textAreaRef = useRef(null);

  const validateInput = (value) => {
    const paragraphs = value.split('\n');
    let newError = '';

    if (value.length >= limits.MAX_CHARACTERS) {
      newError = errors.CHARACTERS_LIMIT;
      value = value.slice(0, limits.MAX_CHARACTERS);
    } else if (paragraphs.length > limits.MAX_PARAGRAPHS) {
      newError = errors.PARAGRAPHS_LIMIT;
      value = paragraphs.slice(0, limits.MAX_PARAGRAPHS).join('\n');
    }

    setError(newError);
    return value;
  };

  const handleInputChange = (event) => {
    let value = event.target.value;
    value = validateInput(value);
    setInputValue(value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      const value = event.target.value;
      const paragraphQty = value.split('\n').length;

      if (paragraphQty >= limits.MAX_PARAGRAPHS) {
        event.preventDefault();
        setError(errors.PARAGRAPHS_LIMIT);
      }
    }
  };

  useEffect(() => {
    const el = textAreaRef.current;
    if (el) {
      const adjustHeight = () => {
        el.style.height = 'auto';
        el.style.height = `${el.scrollHeight}px`;
      };

      adjustHeight();
      el.addEventListener('input', adjustHeight);

      return () => {
        el.removeEventListener('input', adjustHeight);
      };
    }
  }, [textAreaRef, inputValue]);

  useEffect(() => {
    if (inputValue === '' && error !== '') {
      setError('');
    }
  }, [inputValue, error]);

  return (
    <div className="textarea-input">
      <p className="content-label">{title}</p>
      <Input
        innerRef={textAreaRef}
        maxLength={limits.MAX_CHARACTERS}
        name="content"
        type="textarea"
        className="content"
        placeholder={placeholder}
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        rows={rows}
      />
      {error && <Error text={error} />}
    </div>
  );
};
