import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import StaffForm from './StaffForm';
import { ResponseMessage } from '../../shared/components/ResponseMessage';

export default function AddNewStaffModal({
  isOpen,
  onClose,
  onSubmit,
  successResponse,
  errorResponse,
}) {
  const [isPrivateCredentials, setIsPrivateCredentials] = useState(true);

  useEffect(() => {
    if (!isOpen) setIsPrivateCredentials(true);
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} size="lg" backdrop="static" className="model-form">
      <ModalHeader className="header-form">
        {isPrivateCredentials
          ? 'Add New Staff Member'
          : 'Create Assigned Credential'}
      </ModalHeader>
      <ModalBody>
        <ResponseMessage
          type={successResponse ? 'success' : 'danger'}
          message={successResponse || errorResponse}
        />
        <StaffForm
          onSubmit={onSubmit}
          closeModal={onClose}
          onPrivateCredentialsChange={setIsPrivateCredentials}
        />
      </ModalBody>
    </Modal>
  );
}
