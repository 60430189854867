import React, { Component } from 'react';
import SidebarLink from './SidebarLink';
import config from '../../../config/app.config';
import localStorage from '../../../libs/storageHelper';
import SidebarLinkCustomIcon from './SidebarLinkCustomIcon';
import { routes } from '../../../constants/routes';

const { STAFF, ADMIN, SUPER_ADMIN } = config.USER_TYPES;
const { SUPPORT_TICKETS_URL } = config;

const routesData = {
  Clients: {
    title: 'Clients',
    icon: 'schedule',
    route: '/client-list',
  },
  MetricsDashboard: {
    title: 'Metrics Dashboard',
    icon: 'web-design',
    route: '/metrics-dashboard',
  },
  TrackingBoard: {
    title: 'Tracking Board',
    icon: 'ptb',
    route: '/tracking-board',
  },
  NewAppointment: {
    title: 'New Appointment',
    icon: 'calender',
    route: '/appointment',
  },
  Appointments: {
    title: 'Appointments',
    icon: 'calender',
    route: routes.appointments.absolute,
  },
  AppointmentRequests: {
    title: 'Appointment Requests',
    icon: 'calendar-and-loading',
    route: routes.appointmentRequests,
  },
  ManageClients: {
    title: 'Manage Clients',
    icon: 'user',
    route: routes.manageClients.absolute,
    Component: SidebarLink,
  },
  BroadcastNews: {
    title: 'Broadcast News',
    icon: 'broadcast-news',
    route: '/broadcast-news',
  },
  ManageFacilities: {
    title: 'Facilities',
    icon: 'hospital-3',
    route: '/manage-facilities',
  },
  ManageAdmins: {
    title: 'Admins',
    icon: 'admin-settings-male',
    route: '/manage-admins',
  },
  ManageStaff: {
    title: 'Staff',
    icon: 'doctors-bag',
    route: '/manage-staff',
  },
  // ManageReferring: {
  //   title: 'Referring Doctors',
  //   icon: 'doctor',
  //   route: '/manage-referring',
  // },
  ManageBreeds: {
    title: 'Breeds',
    icon: 'paw',
    route: '/manage-breeds',
    Component: SidebarLink,
  },
  ManageUsers: {
    title: 'Users',
    icon: 'user',
    route: '/manage-users',
    Component: SidebarLink,
  },
  ManageSupport: {
    title: 'Support',
    icon: 'online-support',
    link: SUPPORT_TICKETS_URL,
  },
  RefillRequests: {
    title: 'Refill Requests',
    icon: 'pill-in-circle-arrows',
    route: routes.refillRequests,
  },
};

const roleRoutes = {
  [STAFF]: [
    'TrackingBoard',
    'Clients',
    'Appointments',
    'AppointmentRequests',
    'RefillRequests',
  ],
  [ADMIN]: [
    'MetricsDashboard',
    'BroadcastNews',
    'TrackingBoard',
    'Appointments',
    // 'ManageReferring',
    'AppointmentRequests',
    'RefillRequests',
    'Clients',
    'ManageStaff',
  ],
  [SUPER_ADMIN]: [
    'MetricsDashboard',
    'BroadcastNews',
    'ManageFacilities',
    'ManageAdmins',
    'ManageStaff',
    // 'ManageReferring',
    'ManageBreeds',
    'ManageUsers',
    'ManageSupport',
  ],
};

class SidebarContent extends Component {
  constructor(props) {
    super(props);

    this.state = { userType: SUPER_ADMIN };
    this.sesssion = localStorage.getFromStorage('loggedUser');
  }

  render() {
    const { type: userType } = this.sesssion.user;

    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
          <div className="sidebar__block--menu">
            {roleRoutes[userType].map((routeKey) => {
              const { Component: CustomComponent, ...route } =
                routesData[routeKey];
              const SidebarComponent = CustomComponent || SidebarLinkCustomIcon;

              return <SidebarComponent key={routeKey} {...route} />;
            })}
          </div>
        </ul>
      </div>
    );
  }
}

export default SidebarContent;
