import React from 'react';
import {
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
} from 'reactstrap';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import ModalPetInfo from '../../Common/ModalPetInfo';
import { AttachmentsTab } from '../../../../shared/components/Modals/AttachmentsTab';
import { FooterButtons } from '../../../../shared/components/Modals/FooterButtons';
import { toggleAppointmentRequestsModalClass } from '../../ParentProfile/utils/toggleAppointmentRequestsModalClass';

import { MainInfoTab } from './MainInfoTab';
import { tab, useAppointmentRequestModalContext } from '../utils';

export function AppointmentRequestModal() {
  const {
    isAppointmentRequestModalOpen,
    appointmentRequestData,
    closeAppointmentRequestModal,
    activeTab,
    setActiveTab,
  } = useAppointmentRequestModalContext();
  const { isPetAppointmentRequestsModalOpen, petsAppointmentRequestsNumber } =
    useSelector((state) => state.pets);

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const isDataAvailable = !!Object.values(appointmentRequestData)?.length;
  const { photos, documents } = appointmentRequestData;

  const hasAttachments = ({ photos, documents }) => {
    return photos?.length > 0 || documents?.length > 0;
  };

  const shouldIncludeTab = (tabItem) => {
    if (tabItem === tab.ATTACHMENTS) {
      return hasAttachments({ photos, documents });
    }
    return true;
  };

  const filteredTabs = Object.values(tab).filter(shouldIncludeTab);

  const TabDataMap = {
    [tab.MAIN_INFO]: <MainInfoTab />,
    [tab.ATTACHMENTS]: <AttachmentsTab photos={photos} documents={documents} />,
  };

  return (
    <Modal
      isOpen={isAppointmentRequestModalOpen}
      size="lg"
      backdrop="static"
      className="model-form appointment-request-details-modal"
    >
      <ModalHeader className="pet-summary-wrapper">
        {appointmentRequestData?.petSummary && (
          <ModalPetInfo
            petSummary={appointmentRequestData.petSummary}
            avatar={appointmentRequestData.petSummary.avatar}
            title={`Appointment\nRequest\nDetails`}
          />
        )}
      </ModalHeader>
      <ModalBody>
        <Nav tabs>
          {filteredTabs.map((tab) => (
            <NavItem key={tab}>
              <NavLink
                className={classNames({ active: activeTab === tab })}
                onClick={() => toggleTab(tab)}
              >
                {tab}
              </NavLink>
            </NavItem>
          ))}
        </Nav>

        {isDataAvailable && (
          <TabContent activeTab={activeTab}>
            {TabDataMap[activeTab]}
            <FooterButtons
              onClose={() => {
                closeAppointmentRequestModal();

                // if it's the Clients page and the Pet tab then show Pet Appointment Requests modal
                if (
                  petsAppointmentRequestsNumber &&
                  isPetAppointmentRequestsModalOpen
                ) {
                  toggleAppointmentRequestsModalClass({
                    action: 'remove',
                  });
                }
              }}
              showSaveButton={false}
            />
          </TabContent>
        )}
      </ModalBody>
    </Modal>
  );
}
