import React, { Component } from 'react';
import { Collapse, Row, FormGroup } from 'reactstrap';
import { connect } from 'react-redux';
import MinusIcon from 'mdi-react/MinusCircleOutlineIcon';

import Error from '../../../../shared/components/form/Error';
import DatePickerComponent from '../../Common/DatePickerComponent';
import SpeciesSelect from '../../../../shared/components/inputs/SpeciesSelect/SpeciesSelect';
import GenderSelect from '../../../../shared/components/inputs/GenderSelect/GenderSelect';
import BreedsSelect from '../../../../shared/components/inputs/BreedsSelect/BreedsSelect';

class RegisterNewPet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputs: {
        name: '',
        species: '',
        breed: '',
        gender: '',
        birthday: '',
      },
      refreshState: false,
    };
  }

  removePet = (id) => {
    this.props.removePetAction(id);
  };

  onChangeFields = (e) => {
    const property = e.target.name;

    const value = e.target.value;
    let { inputs, refreshState } = this.state;
    refreshState = !refreshState;
    inputs[property] = value;

    this.setState({
      inputs,
      refreshState,
    });

    this.props.petInputsSet(inputs, this.props.index);
  };

  handleGenderSelect = (value) => {
    const { inputs } = this.state;
    this.setState({
      inputs: {
        ...inputs,
        gender: value,
      },
    });

    this.props.petInputsSet(inputs, this.props.index);
  };

  handleSpeciesChange = (value) => {
    const { inputs } = this.state;

    this.setState({
      inputs: {
        ...inputs,
        species: value,
      },
    });

    this.props.petInputsSet(inputs, this.props.index);
  };

  onChangeSelect = (e) => {
    if (e) {
      const { inputs } = this.state;

      this.setState({
        inputs: {
          ...inputs,
          breed: e.value,
        },
      });
      this.props.petInputsSet(inputs, this.props.index);
    }
  };

  onChangeCalender = (e) => {
    const { inputs, refreshState } = this.state;
    inputs.birthday = e;
    this.setState({
      inputs,
      refreshState: !refreshState,
    });
    this.props.petInputsSet(inputs, this.props.index);
  };

  render() {
    const { speciesList, petErrors } = this.props;

    const { inputs, refreshState } = this.state;

    return (
      <div className="content-holder find-parent-wrapper">
        <div className="row">
          <div className="col-md-6">
            <h3>Add New Pet</h3>
          </div>
          <div className="col-md-6">
            <button
              className="btn pull-right cancel-top-panel-button"
              onClick={() => this.removePet(this.props.pet.id)}
            >
              <MinusIcon /> Cancel
            </button>
          </div>
        </div>
        <Collapse isOpen>
          <Row>
            <div className="col-md-2">
              <img
                className="loyal-parent-image"
                src="/img/default-animal-black.png"
                alt="avatar"
              />
            </div>
            <div className="col-md-3">
              <div className="form__form-group loyal-custom-form-group">
                <span className="form__form-group-label">
                  Pet Name <span className="required">*</span>
                </span>
                <div className="form__form-group-field">
                  <FormGroup>
                    <input
                      className="form-control"
                      name="name"
                      type="text"
                      placeholder="Type pet name"
                      value={inputs.name}
                      maxLength={50}
                      onChange={this.onChangeFields}
                    />
                    {petErrors.name.error && (
                      <Error text={petErrors.name.error} />
                    )}
                  </FormGroup>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form__form-group loyal-custom-form-group">
                <span className="form__form-group-label">
                  Species <span className="required">*</span>
                </span>
                <div className="form__form-group-field">
                  <SpeciesSelect
                    value={inputs.species}
                    options={speciesList}
                    error={petErrors.species.error}
                    onChange={this.handleSpeciesChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form__form-group loyal-custom-form-group">
                <span className="form__form-group-label">Breed</span>
                <div className="form__form-group-field">
                  <BreedsSelect
                    value={inputs.breed}
                    onChange={this.onChangeSelect}
                    species={inputs.species}
                  />
                </div>
              </div>
            </div>
          </Row>
          <Row>
            <div className="col-md-2"> </div>
            <div className="col-md-3">
              <div className="form__form-group loyal-custom-form-group">
                <span className="form__form-group-label">Gender</span>
                <div className="form__form-group-field">
                  <GenderSelect
                    value={inputs.gender}
                    onChange={this.handleGenderSelect}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form__form-group loyal-custom-form-group form-group">
                <span className="form__form-group-label">Birthday</span>
                <div className="form__form-group-field">
                  <FormGroup>
                    <DatePickerComponent
                      viewMode={false}
                      maxDate
                      minDate={false}
                      is
                      refreshState={refreshState}
                      date={inputs.birthday}
                      handleChange={this.onChangeCalender}
                    />
                    {petErrors.birthday.error && (
                      <Error text={petErrors.birthday.error} />
                    )}
                  </FormGroup>
                </div>
              </div>
            </div>
            <div className="col-md-4"></div>
          </Row>
        </Collapse>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = {};

RegisterNewPet.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterNewPet);
