import React, { forwardRef } from 'react';
import { Badge } from 'reactstrap';
import { useHover } from './utils';

export const RemoveIcon = forwardRef(({ isHovered, onClick }, ref) => {
  return (
    <svg
      width="18"
      height="18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: 'pointer' }}
      ref={ref}
      onClick={onClick}
    >
      <path
        d="M9 18A9 9 0 1 0 9 0a9 9 0 0 0 0 18Z"
        fill={isHovered ? '#00b0ec' : '#fff'}
      />
      <path
        d="m12 6-6 6M6 6l6 6"
        stroke={isHovered ? '#fff' : '#00b0ec'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});

RemoveIcon.displayName = 'RemoveIcon';

export const DefaultFilterPill = ({ value, onRemove }) => {
  const [hoverRef, isHovered] = useHover();

  return (
    <Badge pill className={isHovered ? 'white-bg' : 'blue-bg'}>
      <span className="text-capitalize">{value}</span>
      <RemoveIcon ref={hoverRef} isHovered={isHovered} onClick={onRemove} />
    </Badge>
  );
};

export const DateFilterPill = ({ timeFilter, range, onRemove }) => {
  let filterText = timeFilter;

  if (timeFilter === 'day' && range.startDate) {
    filterText = range.startDate;
  } else if (timeFilter === 'range' && range.startDate && range.endDate) {
    filterText = `${range.startDate} - ${range.endDate}`;
  }

  const [hoverRef, isHovered] = useHover();

  return (
    <Badge pill className={isHovered ? 'white-bg' : 'blue-bg'}>
      <span className="text-capitalize">{filterText}</span>
      <RemoveIcon ref={hoverRef} isHovered={isHovered} onClick={onRemove} />
    </Badge>
  );
};

export const ClearAllPill = ({ onClearAll }) => {
  return (
    <Badge
      pill
      className="clear-all-filters-badge"
      onClick={() => onClearAll()}
    >
      Clear All
    </Badge>
  );
};
