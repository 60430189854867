import React, { Component } from 'react';
import moment from 'moment';
import { Card, CardBody, Col } from 'reactstrap';
import { connect } from 'react-redux';

import FindParent from '../../Common/FindParent';
import SelectPet from '../../Common/SelectPet';
import { getParent, clearPetList } from '../../../../redux/actions/petActions';
import {
  createAppointment,
  clearAppointmentStore,
} from '../../../../redux/actions/appointmentAction';
import {
  reinvite,
  clearInviteStore,
} from '../../../../redux/actions/clientActions';
import MessageModal from '../../../../shared/components/Modals/MessageModal';
import NewAppointmentModal from './NewAppointmentModal';
import withRouter from '../../../../shared/components/withRouter';
import { DEFAULT_TIMESTAMP_FORMAT } from '../../../../constants/constants';
import { routes } from '../../../../constants/routes';

class CreateAppointment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRedirectedFromList: false,
      isFindParentOpen: true,
      petCollapse: false,
      selectedParent: null,
      defaultParent: null,
      selectedPet: '',
      shareUpdates: false,
      appointmentDate: null,
      appointmentTime: null,
      time: '',
      appointmentType: '',
      appointmentTypeOther: '',
      doctor: '',
      referringDoctor: '',
      pet: null,
      errors: {
        appointmentDate: null,
        time: null,
        appointmentType: null,
        appointmentTypeOther: null,
        doctor: null,
        referringDoctor: null,
      },
      responseBox: {
        showResponse: false,
        responseType: '',
        responseAlertType: '',
        responseFooter: '',
        message: '',
        onSuccess: null,
        appointment: null,
      },
      isCreateOpen: false,
      saveSuccess: false,
    };

    this.setSelectedParent = this.setSelectedParent.bind(this);
    this.registerNew = this.registerNew.bind(this);
    this.selectPet = this.selectPet.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleShareUpdates = this.handleShareUpdates.bind(this);
    this.handledateChange = this.handledateChange.bind(this);
    this.validate = this.validate.bind(this);
    this.onClose = this.onClose.bind(this);
    this.createAppointment = this.createAppointment.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  componentDidMount() {
    this.props.clearPetList();
  }

  UNSAFE_componentWillReceiveProps(np) {
    if (np.location && np.location.state) {
      this.setState({
        selectedParent: np.location.state.user,
        isRedirectedFromList: true,
        petCollapse: true,
        defaultParent: np.location.state.user,
      });
    }
    const { responseBox, selectedParent, saveSuccess } = this.state;
    if (
      np.appointments &&
      np.appointments.createAppointment &&
      !saveSuccess &&
      selectedParent
    ) {
      responseBox.showResponse = true;
      responseBox.responseFooter = true;
      responseBox.responseType = 'alert';
      responseBox.message = `Appointment successfully created. We've notified ${selectedParent.name}`;
      responseBox.onSuccess = () => {
        this.onClose();
        this.props.history.push(
          `/client-list/profile/${this.state.selectedParent._id}`,
        );
      };

      this.setState({
        saveSuccess: true,
        responseBox,
      });

      setTimeout(() => {
        this.props.history.push(
          `/client-list/profile/${this.state.selectedParent._id}`,
        );
      }, 2000);
    }
    if (np.client.statusUpdated && selectedParent) {
      responseBox.showResponse = true;
      responseBox.responseFooter = true;
      responseBox.responseType = 'alert';
      responseBox.message = `Invitation resent to ${selectedParent.name}`;
      responseBox.onSuccess = this.onClose;
      this.setState({
        saveSuccess: true,
        responseBox,
      });

      setTimeout(() => {
        this.props.history.push('/empty');
        this.props.history.push('/appointments');
      }, 2000);
    }
    if (np.client.statusUpdated && selectedParent) {
      responseBox.showResponse = true;
      responseBox.responseFooter = true;
      responseBox.responseType = 'alert';
      responseBox.message = `Invitation resent to ${selectedParent.name}`;
      responseBox.onSuccess = this.onClose;

      this.setState({ responseBox });
    }
  }

  handleChange(e) {
    const fieldName = e.target.name;
    this.setState({
      [fieldName]: e.target.value,
    });
  }

  selectPet(selectedPet) {
    const { selectedParent } = this.state;
    if (selectedParent) {
      this.setState({
        pet: selectedPet,
        selectedPet: selectedPet ? selectedPet._id : '',
        isCreateOpen: selectedPet !== null,
      });
    }
  }

  setSelectedParent(selectedParent) {
    this.setState({
      selectedParent,
      petCollapse: true,
      selectedPet: '',
      isCreateOpen: false,
    });
  }

  registerNew() {
    this.props.history.push({
      pathname: routes.manageClients.absolute,
      openNewParent: true,
    });
  }

  handleShareUpdates() {
    this.setState({ shareUpdates: !this.state.shareUpdates });
  }

  handledateChange(appointmentDate) {
    this.setState({ appointmentDate });
  }

  handleTimeChange = (time) => {
    if (time) {
      const appointmentTime = moment(time);
      this.setState({ appointmentTime });
    }
  };

  onChangeSelect = (e, type, resetShareUpdates = false) => {
    const state = this.state;
    if (!e) {
      state[type] = null;
      state.shareUpdates = false;
    } else {
      if (type === 'referringDoctor') {
        state.shareUpdates = !resetShareUpdates;
      }
      state[type] = e.value;
    }

    this.setState(state);
  };

  validate() {
    const {
      appointmentDate,
      appointmentTime,
      appointmentType,
      errors,
    } = this.state;

    let dateTime = moment(appointmentDate);

    if (appointmentTime) {
      const dt = moment(appointmentDate).format(DEFAULT_TIMESTAMP_FORMAT);
      const apDate = `${dt} ${appointmentTime.format('hh:mm A')}`;

      dateTime = moment(apDate);
    }

    const difference = dateTime.diff(moment.now());
    const differenceInMinutes = moment.duration(difference).asMinutes();

    if (!appointmentDate) {
      errors.appointmentDate = 'Appointment date cannot be empty';
      this.setState({ errors });
      return true;
    } else if (!dateTime.isValid()) {
      errors.appointmentDate = 'Appointment date is invalid';
      this.setState({ errors });
      return true;
    } else if (
      (differenceInMinutes < -11 && dateTime.minute() > 11) ||
      (difference < 0 && dateTime.minute() > 11) ||
      (differenceInMinutes < -11 && difference < 0)
    ) {
      errors.appointmentDate = 'Appointment cannot be a past time';
      this.setState({ errors });
      return true;
    } else {
      errors.appointmentDate = null;
      this.setState({ errors });
    }

    if (!appointmentTime) {
      errors.time = 'Appointment time cannot be empty';
      this.setState({ errors });
      return true;
    } else {
      errors.time = null;
      this.setState({ errors });
    }

    if (!appointmentType) {
      errors.appointmentType = 'Appointment type cannot be empty';
      this.setState({ errors });
      return true;
    } else {
      errors.appointmentType = null;
      this.setState({ errors });
    }
  }

  createAppointment() {
    const {
      selectedParent: parentId,
      selectedPet: pet,
      shareUpdates,
      appointmentDate,
      appointmentTime,
      appointmentType: type,
      doctor,
      referringDoctor,
      appointmentTypeOther: typeOther,
    } = this.state;

    this.onClose();
    this.props.createAppointment({
      parentId: parentId._id,
      pet,
      appointmentDate: moment(appointmentDate).format(DEFAULT_TIMESTAMP_FORMAT),
      time: appointmentTime.format('hh:mm A'),
      type,
      typeOther: typeOther.trim(),
      doctor: doctor === '' ? null : doctor,
      referringDoctor: referringDoctor === '' ? null : referringDoctor,
      shareUpdates,
    });
  }

  cancel() {
    if (this.state.isRedirectedFromList) {
      this.props.history.push({
        pathname: `/client-list/profile/${this.state.selectedParent._id}`,
        activeTab: '3',
      });
    } else {
      this.setState(
        {
          petCollapse: false,
          selectedParent: null,
          selectedPet: '',
          shareUpdates: false,
          appointmentDate: null,
          appointmentTime: null,
          time: '',
          appointmentType: '',
          appointmentTypeOther: '',
          doctor: '',
          referringDoctor: '',
          errors: {
            appointmentDate: null,
            time: null,
            appointmentType: null,
            doctor: null,
            referringDoctor: null,
          },
          responseBox: {
            showResponse: false,
            responseType: '',
            responseAlertType: '',
            responseFooter: '',
            message: '',
            onSuccess: null,
          },
          isCreateOpen: false,
          saveSuccess: false,
          resetParent: true,
        },
        () => {
          this.setState({ resetParent: false });
        },
      );
    }
    this.props.onCancel && this.props.onCancel();
  }

  getUserData() {
    const { selectedParent, appointmentDate, appointmentTime, pet } =
      this.state;

    return {
      appointmentDate,
      pet: pet.name,
      appointmentTime: moment(appointmentTime, 'HH:mm').format('hh:mm A'),
      parent: `${selectedParent.firstName} ${selectedParent.lastName}`,
    };
  }

  submit = () => {
    if (this.validate()) {
      return;
    }

    this.createAppointment();
  };

  onClose() {
    const { responseBox } = this.state;

    responseBox.showResponse = false;
    responseBox.responseType = '';
    responseBox.responseAlertType = '';
    responseBox.responseFooter = '';
    responseBox.message = '';
    responseBox.appointment = null;

    this.setState({ responseBox });

    this.props.clearInviteStore();
    this.props.clearAppointmentStore();
  }

  onCloseAppointmentModal = () => {
    this.setState({ selectedPet: null }, () => {
      this.selectPet(null);
    });
  };

  onChangeAppointmentType = (event) => {
    this.setState({ appointmentType: event.currentTarget.value });
  };

  render() {
    const {
      isFindParentOpen,
      selectedParent,
      petCollapse,
      selectedPet,
      appointmentDate,
      appointmentTime,
      time,
      appointmentType,
      doctor,
      referringDoctor,
      shareUpdates,
      errors,
      responseBox,
      isCreateOpen,
      saveSuccess,
      defaultParent,
    } = this.state;

    const content = (
      <>
        {!this.props.noCard && (
          <label className={`heading-text ${saveSuccess && 'highlight-green'}`}>
            {saveSuccess
              ? 'Appointment Successfully Created'
              : 'New Appointment'}
          </label>
        )}
        <div className="content-wrapper">
          <FindParent
            collapse={isFindParentOpen}
            defaultParent={defaultParent}
            setSelectedParent={this.setSelectedParent}
            reset={this.state.resetParent}
            registerNew={this.registerNew}
            reInvite={this.props.reinvite}
            viewMode={saveSuccess}
            hideSearch={false}
            title={defaultParent && 'Parent'}
            history={this.props.history}
          />
          <SelectPet
            selectedParent={selectedParent}
            collapse={petCollapse}
            selectPet={this.selectPet}
            selectedPet={selectedPet}
            viewMode={saveSuccess}
          />

          <div className="row">
            <NewAppointmentModal
              isOpen={isCreateOpen}
              petId={selectedParent?._id}
              reschedule={false}
              createData={{
                shareUpdates,
                appointmentDate,
                appointmentTime,
                time,
                appointmentType,
                doctor,
                referringDoctor,
                errors,
                collapse: isCreateOpen,
              }}
              handledateChange={this.handledateChange}
              handleTimeChange={this.handleTimeChange}
              onChangeSelect={this.onChangeSelect}
              handleShareUpdates={this.handleShareUpdates}
              appointmentDate={this.state.appointmentDate}
              appointmentTime={this.state.appointmentTime}
              viewMode={saveSuccess}
              onClose={this.onCloseAppointmentModal}
              handleChange={this.onChangeAppointmentType}
              submit={this.submit}
            />
          </div>
          <div className="row">
            <div className="col-md-6"></div>
            <div className="col-md-6 text-right">
              {petCollapse &&
                (!saveSuccess ? (
                  <button
                    disabled={!petCollapse}
                    className="btn loyal-btn-clear"
                    onClick={this.cancel}
                  >
                    Cancel
                  </button>
                ) : (
                  <>
                    <button
                      disabled={!petCollapse}
                      className="btn loyal-btn-clear"
                      onClick={this.cancel}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn loyal-btn-save-update"
                      onClick={() => {
                        this.props.history.push({
                          pathname: `client-list/profile/${selectedParent._id}`,
                          activeTab: '3',
                        });
                      }}
                    >
                      View Appointments
                    </button>
                  </>
                ))}
            </div>
          </div>
        </div>
      </>
    );

    return (
      <>
        <MessageModal
          show={responseBox.showResponse}
          type={responseBox.responseType}
          alertType={responseBox.responseAlertType}
          footer={responseBox.responseFooter}
          onSuccess={responseBox.onSuccess}
          onClose={this.onClose}
          appointment={responseBox.appointment}
          message={responseBox.message}
        />

        <div className="create-appointment">
          <Col md={12} lg={12}>
            {this.props.noCard && content}
            {!this.props.noCard && (
              <Card>
                <CardBody>{content}</CardBody>
              </Card>
            )}
          </Col>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
  appointmentCreated: state.appointments.createAppointment,
  appointmentCreateError: state.appointments.createAppointmentError,
});

const mapDispatchToProps = {
  getParent,
  createAppointment,
  reinvite,
  clearInviteStore,
  clearAppointmentStore,
  clearPetList,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateAppointment),
);
