import { handleRequest } from '../services/APIService';

export const FETCH_REFILL_REQUESTS = 'FETCH_REFILL_REQUESTS';
export const FETCH_REFILL_REQUESTS_ERROR = 'FETCH_REFILL_REQUESTS_ERROR';

export const CHANGE_REFILL_REQUEST_STATUS = 'CHANGE_REFILL_REQUESTS_STATUS';
export const CHANGE_REFILL_REQUEST_STATUS_ERROR =
  'CHANGE_REFILL_REQUESTS_STATUS_ERROR';

export const FETCH_REFILL_REQUEST_STATUSES = 'FETCH_REFILL_REQUEST_STATUSES';
export const FETCH_REFILL_REQUEST_STATUSES_ERROR =
  'FETCH_REFILL_REQUEST_STATUSES_ERROR';

export const FETCH_REFILL_REQUEST_STAFF_COMMENTS =
  'FETCH_REFILL_REQUEST_STAFF_COMMENTS';
export const FETCH_REFILL_REQUEST_STAFF_COMMENTS_ERROR =
  'FETCH_REFILL_REQUEST_STAFF_COMMENTS';

export const ADD_REFILL_REQUEST_STAFF_COMMENTS =
  'ADD_REFILL_REQUEST_STAFF_COMMENTS';
export const ADD_REFILL_REQUEST_STAFF_COMMENTS_ERROR =
  'ADD_REFILL_REQUEST_STAFF_COMMENTS_ERROR';

export const getAllRefillRequests = ({ facilityId, params }) => {
  return async (dispatch) => {
    try {
      const results = await handleRequest(
        'get',
        `facility/${facilityId}/prescriptions`,
        true,
        {},
        params,
      );

      dispatch({
        type: FETCH_REFILL_REQUESTS,
        payload: results.data,
      });
    } catch (error) {
      dispatch({
        type: FETCH_REFILL_REQUESTS_ERROR,
        payload: error.response || error,
      });
    }
  };
};

export const changeRefillRequestStatus = (
  newStatusId,
  prescriptionId,
  comments,
) => {
  return async (dispatch) => {
    try {
      const results = await handleRequest(
        'put',
        `prescription/${prescriptionId}/status`,
        true,
        {
          newStatusId,
          comments,
        },
      );

      return dispatch({
        type: CHANGE_REFILL_REQUEST_STATUS,
        payload: results.data,
      });
    } catch (error) {
      return dispatch({
        type: CHANGE_REFILL_REQUEST_STATUS_ERROR,
        payload: error.response || error,
      });
    }
  };
};

export const getAllRefillRequestStatuses = ({ facilityId }) => {
  return async (dispatch) => {
    try {
      const results = await handleRequest(
        'get',
        `facility/${facilityId}/prescription-statuses`,
        true,
        {},
      );

      dispatch({
        type: FETCH_REFILL_REQUEST_STATUSES,
        payload: results.data,
      });
    } catch (error) {
      dispatch({
        type: FETCH_REFILL_REQUEST_STATUSES_ERROR,
        payload: error.response || error,
      });
    }
  };
};

export const getRefillRequestStaffComments = (prescriptionId) => {
  return async (dispatch) => {
    try {
      const results = await handleRequest(
        'get',
        `prescription/${prescriptionId}/staff-comments`,
        true,
        {},
      );

      dispatch({
        type: FETCH_REFILL_REQUEST_STAFF_COMMENTS,
        payload: results.data,
      });
    } catch (error) {
      dispatch({
        type: FETCH_REFILL_REQUEST_STAFF_COMMENTS_ERROR,
        payload: error.response || error,
      });
    }
  };
};

export const addRefillRequestStaffComments = (
  authorId,
  prescriptionId,
  comment,
) => {
  return async (dispatch) => {
    try {
      const results = await handleRequest(
        'put',
        `prescription/${prescriptionId}/staff-comments`,
        true,
        {
          authorId,
          comment,
        },
      );

      return dispatch({
        type: ADD_REFILL_REQUEST_STAFF_COMMENTS,
        payload: results.data,
      });
    } catch (error) {
      return dispatch({
        type: ADD_REFILL_REQUEST_STAFF_COMMENTS_ERROR,
        payload: error.response || error,
      });
    }
  };
};
