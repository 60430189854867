import {
  IMAGE_UPLOADED,
  IMAGE_UPLOAD_ERROR,
  CREATE_NEWS,
  CREATE_NEWS_ERROR,
  CLEAR_ALL,
} from '../actions/broadcastActions';

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case IMAGE_UPLOADED:
      return {
        ...state,
        uploadedImage: action.payload,
      };
    case IMAGE_UPLOAD_ERROR:
      return {
        ...state,
        uploadedImage: null,
      };
    case CREATE_NEWS:
      return {
        ...state,
        createNewsData: action.payload,
        uploadedImage: null,
        createNewsError: null,
      };
    case CREATE_NEWS_ERROR:
      return {
        ...state,
        createNewsData: null,
        uploadedImage: null,
        createNewsError: action.payload,
      };
    case CLEAR_ALL:
      return {
        ...state,
        createNewsData: null,
        uploadedImage: null,
        createNewsError: null,
      };
    default:
      return state;
  }
}
