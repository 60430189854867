import React from 'react';
import { refillRequestPageTypes } from '../../../../config/app.config';
import {
  ClientPageWrapper,
  DefaultWrapper,
} from '../../Common/PageWithTableWrapper';

const refillClassName = 'refill-requests-container';

export const refillComponentsWrapperMap = {
  [refillRequestPageTypes.default]: (props) => (
    <DefaultWrapper className={refillClassName} {...props} />
  ),
  [refillRequestPageTypes.clientPage]: (props) => (
    <ClientPageWrapper className={refillClassName} {...props} />
  ),
};
