import constants from '../../../../constants/constants';
import {
  refillRequestDeliveryTypes,
  refillRequestPageTypes,
} from '../../../../config/app.config';

const attachmentIcon = `${process.env.PUBLIC_URL}/img/icons/file-attachment-icon.png`;

export function getRefillRequestsHeaders(
  type = refillRequestPageTypes.default,
) {
  const commonHeaders = [
    {
      key: 'date',
      name: 'Date',
      sortable: true,
      filterable: false,
      capitalize: true,
    },
    {
      key: 'time',
      name: 'Time',
      sortable: false,
      filterable: false,
      capitalize: true,
    },
    {
      key: 'status',
      name: 'Status',
      sortable: false,
      filterable: true,
      capitalize: false,
    },
    {
      key: 'parentFullName',
      name: 'Parent Name',
      sortable: true,
      filterable: false,
      capitalize: true,
      link: (row) =>
        `/client-list/profile/${row.parentId}?tab=${constants.QUERY_TAB_PARAMS.parent}`,
    },
    {
      key: 'petName',
      name: 'Pet Name',
      sortable: true,
      filterable: false,
      capitalize: true,
      link: (row) =>
        `/client-list/profile/${row.parentId}?tab=${constants.QUERY_TAB_PARAMS.petInfo}&${constants.QUERY_PARAMS.pet}=${row.petId}`,
    },
    {
      key: 'doctorFullName',
      name: 'Doctor',
      sortable: false,
      filterable: true,
      filterValue: [],
      capitalize: true,
    },
    {
      key: 'medication',
      name: 'Medication',
      sortable: true,
      filterable: false,
      capitalize: true,
    },
    {
      key: 'quantity',
      name: 'Quantity',
      sortable: false,
      filterable: false,
      capitalize: true,
    },
    {
      key: 'dosage',
      name: 'Dosage',
      sortable: false,
      filterable: false,
      capitalize: true,
    },
    {
      key: 'deliveryType',
      name: 'Delivery',
      sortable: false,
      filterable: true,
      filterValue: Object.entries(refillRequestDeliveryTypes).map(
        ([key, value]) => ({ [key]: value }),
      ),
      capitalize: true,
    },
    {
      key: 'comments',
      name: 'Client Comments',
      sortable: false,
      filterable: false,
      capitalize: false,
    },
    {
      key: 'internalComments',
      name: 'Internal Comments',
      className: 'no-wrap',
      sortable: false,
      filterable: false,
      capitalize: false,
    },
    {
      key: 'attachments',
      name: 'Attachments',
      imgSrc: attachmentIcon,
      imgAlt: 'File attachment icon',
      className: 'with-icon',
      sortable: false,
      filterable: false,
      capitalize: false,
    },
  ];

  const headersTypes = {
    [refillRequestPageTypes.default]: commonHeaders,
    [refillRequestPageTypes.clientPage]: commonHeaders.filter(
      ({ key }) => key !== 'parentFullName',
    ),
  };

  return headersTypes[type];
}
