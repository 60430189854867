/* eslint-disable react/jsx-key */
/* eslint-disable no-case-declarations */
import React, { Component } from 'react';
import { Modal, ModalBody, Button, Collapse, ModalHeader } from 'reactstrap';
import Moment from 'react-moment';
import createDOMPurify from 'dompurify';
// import mixpanel from 'mixpanel-browser';
import TimePicker from 'rc-time-picker';
import Select from 'react-dropdown-select';

import ModalPetInfo from '../Common/ModalPetInfo';
// import config from '../../../config/app.config';
// import localStorage from '../../../libs/storageHelper';
import constants, { MASKS } from '../../../constants/constants';
import { conformToMask } from 'react-text-mask';

const DOMPurify = createDOMPurify(window);

export default class PhaseNotificationModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseIDs: [],
      showCustomText: true,
      selectedCheckboxIds: [],
      selectedMessageIds: [],
      selectedMessages: [],
      customTextId: 1,
      customText: '',
      inputError: '',
      updateError: '',
      isEmptyTextError: false,
      isEmptyTimeError: false,
      isEmptyTextError2: false,
      isEmptyDropdownError: false,
      emptyTextError: '',
      errorMessages: [],
      textField1: '',
      textField2: '',
      multiSelect: [],
      selectedValue: [],
      lastDragMessage: null,
    };
  }

  toggle = (id) => {
    const { collapseIDs } = this.state;
    if (collapseIDs.includes(id)) {
      this.setState({
        collapseIDs: collapseIDs.filter((Id) => id !== Id),
      });
    } else {
      this.setState({
        collapseIDs: collapseIDs.concat(id),
      });
    }
  };

  componentDidMount() {
    this.setState({
      errorMessages: [],
      isEmptyTextError: false,
      isEmptyTextError2: false,
      isEmptyTimeError: false,
      isEmptyDropdownError: false,
      collapseIDs: [],
      emptyTextError: '',
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.show) {
      this.setState({
        selectedCheckboxIds: [],
        selectedMessageIds: [],
        selectedMessages: [],
        customText: '',
        collapseIDs: [],
        btnDisable: false,
        lastDragMessage: null,
      });
    } else {
      const { ptbMessages = [] } = nextProps;
      const draggedMessages = ptbMessages.filter((m) => !!m.isDragMessage);

      draggedMessages.sort(
        (a, b) =>
          new Date(b.updatedAt).valueOf() - new Date(a.updatedAt).valueOf(),
      );

      const [lastDragMessage] = draggedMessages;
      this.setState({
        collapseIDs: ptbMessages.map(({ _id }) => _id),
        lastDragMessage,
      });
    }
  }

  isMessageSelected = (id) => {
    const { selectedCheckboxIds } = this.state;
    return selectedCheckboxIds.indexOf(id) !== -1;
  };

  handleSelectMessage = (
    id,
    message,
    isCustom,
    notificationType,
    control = null,
    value = null,
    placeHolder = null,
  ) => {
    const {
      selectedMessages,
      selectedMessageIds,
      selectedCheckboxIds,
      errorMessages,
    } = this.state;
    let {
      isEmptyTextError,
      isEmptyTimeError,
      isEmptyDropdownError,
      multiSelect,
    } = this.state;
    const selectedIndex = selectedMessageIds.indexOf(id);
    const multiController =
      control === 'textField' ? placeHolder.split(',').length === 2 : false;

    const timeIndex = errorMessages.indexOf('isEmptyTimeError');
    const dropdownIndex = errorMessages.indexOf('isEmptyDropdownError');
    const textIndex = errorMessages.indexOf('isEmptyTextError');

    if (value !== null) {
      multiSelect = [];
      value.split(',').map((option) => {
        multiSelect.push({ value: option, label: option, id: option });
        return true;
      });
    }

    if (!isCustom) {
      if (selectedIndex === -1) {
        selectedMessageIds.push(id);
        selectedMessages.push({
          id,
          message,
          isCustom: false,
          notificationType,
          control,
        });
      } else {
        selectedMessageIds.splice(selectedIndex, 1);
        selectedMessages.splice(selectedIndex, 1);
      }

      switch (control) {
        case 'timePicker':
          const isTimePicker = selectedMessages.find(
            (message) => message.control === 'timePicker',
          );
          isEmptyTimeError = selectedIndex === -1;
          if (
            selectedIndex === -1 &&
            !errorMessages.includes('isEmptyTimeError')
          ) {
            errorMessages.push('isEmptyTimeError');
          } else if (
            selectedIndex !== -1 &&
            typeof isTimePicker === 'undefined'
          ) {
            errorMessages.splice(timeIndex, 1);
          }
          break;
        case 'dropDown':
          isEmptyDropdownError = selectedIndex === -1;
          selectedIndex === -1
            ? errorMessages.push('isEmptyDropdownError')
            : errorMessages.splice(dropdownIndex, 1);
          break;
        case 'textField':
          isEmptyTextError = selectedIndex === -1;
          if (selectedIndex === -1) {
            multiController
              ? errorMessages.push('isEmptyTextError', 'isEmptyTextError2')
              : errorMessages.push('isEmptyTextError');
          } else {
            if (multiController) {
              errorMessages.splice(textIndex, 1);
              const textIndex2 = errorMessages.indexOf('isEmptyTextError2');
              errorMessages.splice(textIndex2, 1);
            } else {
              errorMessages.splice(textIndex, 1);
            }
          }
          break;
        default:
          break;
      }

      this.setState({ selectedMessageIds, selectedMessages });
    } else {
      if (selectedIndex !== -1) {
        selectedMessageIds.splice(selectedIndex, 1);
        selectedMessages.splice(selectedIndex, 1);
      }
      this.setState({
        selectedMessageIds,
        selectedMessages,
        isEmptyTextError: message.length === 0,
      });
    }

    const selectedCheckboxIndex = selectedCheckboxIds.indexOf(id);
    if (selectedCheckboxIndex === -1) {
      selectedCheckboxIds.push(id);
    } else {
      selectedCheckboxIds.splice(selectedCheckboxIndex, 1);
    }

    this.setState({
      selectedCheckboxIds,
      updateError: '',
      selectedValue: [],
      multiSelect,
      isEmptyTimeError,
      isEmptyDropdownError,
      isEmptyTextError,
      errorMessages,
    });
  };

  getText(el) {
    return el.innerText || this.getTextForFirefox(el);
  }

  getTextForFirefox(el) {
    // Taken from http://stackoverflow.com/a/3908094
    let text = '';
    if (typeof window.getSelection !== 'undefined') {
      const sel = window.getSelection();
      const tempRange = sel.getRangeAt(0);
      sel.removeAllRanges();
      const range = document.createRange();
      range.selectNodeContents(el);
      sel.addRange(range);
      text = sel.toString();
      sel.removeAllRanges();
      sel.addRange(tempRange);
    }

    return text;
  }

  handleUpdateMessage = (e, id) => {
    const { selectedMessages, selectedMessageIds } = this.state;

    const text = this.getText(e.target).trim();
    const selectedIndex = selectedMessageIds.indexOf(id);
    if (!(text && text.length > 0)) {
      this.setState({ isEmptyTextError: true });
    } else {
      this.setState({ isEmptyTextError: false, updateError: '' });
    }

    selectedMessages[selectedIndex].message = text;
    this.setState({ selectedMessages });
  };

  handleCustomTextEdit = (e, id) => {
    const { selectedMessages, selectedMessageIds } = this.state;
    const text = e.target.value;
    const selectedIndex = selectedMessageIds.indexOf(id);

    if (text.length <= 250) {
      selectedMessages[selectedIndex].message = text;
      this.setState({ selectedMessages });
    }
    if (text && text.length > 0) {
      this.setState({ isEmptyTextError: false, updateError: '' });
    } else {
      this.setState({ isEmptyTextError: true });
    }
  };

  handleCustomMessage = () => {
    this.setState({
      showCustomText: !this.state.showCustomText,
      customText: '',
      inputError: '',
    });
  };

  handleCustomText = (e) => {
    const customText = e.target.value;
    if (customText.length <= 250) {
      this.setState({ customText });
    }
  };

  update = () => {
    const {
      selectedMessages,
      isEmptyTextError,
      isEmptyTimeError,
      isEmptyDropdownError,
      selectedCheckboxIds,
      selectedMessageIds,
      errorMessages,
    } = this.state;
    const { drag } = this.props;

    let { customTextId, customText } = this.state;

    customText = customText.trim();
    if (customText !== '') {
      selectedMessageIds.push(customTextId);
      selectedCheckboxIds.push(customTextId);
      selectedMessages.push({
        id: customTextId,
        message: customText,
        isCustom: true,
        notificationType: null,
      });
      customTextId = customTextId + 1;
      this.setState({
        selectedMessages,
        selectedCheckboxIds,
        customTextId,
        customText: '',
        showCustomText: true,
        inputError: '',
        updateError: '',
      });
    } else {
      this.setState({
        inputError: 'Please add a custom message.',
        updateError: '',
      });
    }

    if (selectedCheckboxIds.length !== 0) {
      if (errorMessages.length > 0) {
        if (
          errorMessages.includes('isEmptyTextError') ||
          errorMessages.includes('isEmptyTextError2')
        ) {
          this.setState({ updateError: 'Message cannot be empty\n' });
        } else if (errorMessages.includes('isEmptyTimeError')) {
          this.setState({ updateError: 'Select a time\n' });
        } else if (errorMessages.includes('isEmptyDropdownError')) {
          this.setState({ updateError: 'Select at least one option\n' });
        }
      } else {
        const isMessagesEmpty = selectedMessages.some(
          (message) => message.message.trim() === '',
        );
        if (isEmptyTextError || isMessagesEmpty) {
          this.setState({ updateError: 'Message cannot be empty\n' });
        } else if (isEmptyTimeError) {
          this.setState({ updateError: 'Select a time\n' });
        } else if (isEmptyDropdownError) {
          this.setState({ updateError: 'Select at least one option\n' });
        } else {
          this.setState({ updateError: '', inputError: '' }, function () {
            const selectedMessageList = selectedMessages.filter((message) =>
              selectedCheckboxIds.includes(message.id),
            );

            // if (drag) {
            // if (drag.laneId !== constants.appointmentPhases.expected.id) {
            //   selectedMessageList.push({
            //     id: customTextId,
            //     isCustom: true,
            //     message: this.loadDescription(drag),
            //     notificationType: null,
            //     isDragMessage: true,
            //   });
            // }
            // }
            this.setState({ btnDisable: true });
            this.props.handleMessageSubmit(selectedMessageList, drag);
          });
        }
      }
    } else {
      this.setState({
        updateError:
          'Please select at least one message to send an update notification.\n',
      });
      // if (!drag) {
      // } else {
      // const message = [];
      // if (drag.laneId !== constants.appointmentPhases.expected.id) {
      //   message.push({
      //     id: customTextId,
      //     isCustom: true,
      //     message: this.loadDescription(drag),
      //     notificationType: null,
      //     isDragMessage: true,
      //   });
      // }
      // const phases = config.PHASES;
      // mixpanel.init(localStorage.getConfig('mpt'));
      // mixpanel.track('Card Move', {
      //   appointmentId: drag.cardId,
      //   phaseId: drag.laneId,
      //   phaseName: phases[drag.laneId],
      // });
      // this.setState({ btnDisable: true });
      // this.props.handleMessageSubmit(message, drag);
      // }
    }
  };

  handClosePTBModal = () => {
    this.setState(
      {
        selectedMessages: [],
        selectedCheckboxIds: [],
        selectedMessageIds: [],
        customText: '',
        updateError: '',
        inputError: '',
        isEmptyTextError: false,
        showCustomText: true,
        errorMessages: [],
        isEmptyTimeError: false,
        isEmptyDropdownError: false,
        emptyTextError: '',
      },
      () => {
        this.props.closePTBModal();
      },
    );
  };

  formatPhoneNumber(phoneNumberString) {
    return conformToMask(phoneNumberString, MASKS.phone, {
      guide: false,
    }).conformedValue;
  }

  loadDescription(data) {
    const { lastDragMessage } = this.state;

    if (lastDragMessage) {
      const { message, updatedAt } = lastDragMessage;
      return (
        <>
          <span>{message}</span>
          <span className="time" style={{ float: 'right' }}>
            <Moment format="hh:mm A">{updatedAt}</Moment>
          </span>
          <span
            className="date"
            style={{ float: 'right', marginRight: '20px' }}
          >
            <Moment format="MM/DD/YYYY">{updatedAt}</Moment>&nbsp;
          </span>
        </>
      );
    }

    const { facility, petSummary } = this.props;

    if (!facility || !data) return '';

    const facilityName = facility.name;
    const { name } = petSummary;

    switch (+data.laneId) {
      case 2:
        return `${name} has been checked-in at ${facilityName}. Stay tuned for further updates.`;
      case 3:
        return `${name} is now undergoing diagnostic evaluation.`;
      case 4:
        return `${name} is now in the preparation phase before their procedure begins.`;
      case 5:
        return `${name} is now undergoing their procedure.`;
      case 6:
        return `${name}’s dental procedure will begin shortly.`;
      case 7:
        return `${name}’s surgical procedure will begin shortly.`;
      case 8:
        return `${name} is now in the recovery phase after their procedure.`;
      case 9:
        return `${name} has been admitted to stay overnight at the facility`;
      case 10:
        return `${name} is now boarding with ${facilityName}.`;
      case 11:
        return `${name} has started the discharge process from ${facilityName}.`;
      case 12:
        return `${name} has been discharged by ${facilityName}.`;
      default:
        return 'Unknown message (Out of phase)';
    }
  }

  handeReplace(value, occurrence, replaceValue) {
    let nth = 0;

    return value.replace(/textField/g, (match, i, original) => {
      nth++;
      return nth === occurrence ? replaceValue : match;
    });
  }

  handleChange = (text, id, message, type, order = null) => {
    const {
      selectedMessages,
      selectedMessageIds,
      textField1,
      textField2,
      errorMessages,
    } = this.state;
    let { isEmptyTextError, isEmptyTimeError, isEmptyDropdownError } =
      this.state;
    let messageText = '';

    switch (type) {
      case 'timePicker':
        messageText =
          text !== null
            ? message.replace(/timePicker/g, text.format('hh:mm A'))
            : message;
        isEmptyTimeError = false;

        const isTimePicker = selectedMessages.find(
          (message) =>
            message.control === 'timePicker' &&
            message.message.includes('ENTER TIME'),
        );
        const timeIndex = errorMessages.indexOf('isEmptyTimeError');
        if (
          errorMessages.includes('isEmptyTimeError') &&
          text !== null &&
          typeof isTimePicker === 'undefined'
        ) {
          errorMessages.splice(timeIndex, 1);
        } else if (
          !errorMessages.includes('isEmptyTimeError') &&
          text === null
        ) {
          errorMessages.push('isEmptyTimeError');
        }
        break;
      case 'dropDown':
        isEmptyDropdownError = false;
        messageText = message.replace(/dropDown/g, text);
        const dropdownIndex = errorMessages.indexOf('isEmptyDropdownError');
        if (errorMessages.includes('isEmptyDropdownError')) {
          errorMessages.splice(dropdownIndex, 1);
        }

        break;
      case 'textField':
        isEmptyTextError = false;
        const textIndex = errorMessages.indexOf('isEmptyTextError');
        if (order === null) {
          messageText = message.replace(/textField/, text);
          if (errorMessages.includes('isEmptyTextError')) {
            errorMessages.splice(textIndex, 1);
          }
        } else if (order === 1) {
          messageText = message.replace(/textField/, text);
          messageText = this.handeReplace(messageText, 2, textField2);
          if (errorMessages.includes('isEmptyTextError') && text !== '') {
            errorMessages.splice(textIndex, 1);
          } else if (
            !errorMessages.includes('isEmptyTextError') &&
            text === ''
          ) {
            errorMessages.push('isEmptyTextError');
          }
          this.setState({
            textField1: text,
          });
        } else if (order === 2) {
          const textIndex2 = errorMessages.indexOf('isEmptyTextError2');
          if (errorMessages.includes('isEmptyTextError2') && text !== '') {
            errorMessages.splice(textIndex2, 1);
          } else if (
            !errorMessages.includes('isEmptyTextError2') &&
            text === ''
          ) {
            errorMessages.push('isEmptyTextError2');
          }

          messageText = this.handeReplace(message, 2, text);
          messageText = messageText.replace(/textField/, textField1);
          this.setState({
            textField2: text,
          });
        }
        break;
      default:
        break;
    }

    const selectedIndex = selectedMessageIds.indexOf(id);
    selectedMessages[selectedIndex].message = messageText;
    this.setState({
      selectedMessages,
      isEmptyTextError,
      isEmptyDropdownError,
      isEmptyTimeError,
      errorMessages,
    });
  };

  setValues(optionsList, id, message) {
    const { selectedValue } = this.state;
    if (optionsList.length > 0) {
      let result = optionsList.map((item) => {
        return item.value;
      });
      result = result.toString();

      this.handleChange(result, id, message, 'dropDown');

      selectedValue.push(optionsList);
      this.setState({ selectedValue });
    }
  }

  render() {
    const { petSummary, phaseMessages, facility, ptbMessages, show, drag } =
      this.props;

    const {
      collapseIDs,
      customText,
      selectedMessages,
      inputError,
      updateError,
      multiSelect,
      selectedValue,
      btnDisable,
      lastDragMessage,
    } = this.state;

    let ptbMessageList = '';
    let messages = '';
    let customMessages = '';
    let dragMessage = '';

    if (ptbMessages) {
      ptbMessageList = ptbMessages
        .filter((m) => m._id !== lastDragMessage?._id)
        .sort(
          (a, b) =>
            new Date(b.updatedAt).valueOf() - new Date(a.updatedAt).valueOf(),
        )
        .map((ptbMessage) => {
          const html = DOMPurify.sanitize(ptbMessage.message);

          if (ptbMessage.phaseMessageId !== null) {
            return (
              <div
                className="disable-phase-wrapper clearfix"
                key={ptbMessage._id}
              >
                <span
                  className="heading"
                  dangerouslySetInnerHTML={{ __html: html }}
                ></span>
                <span className="dateTime-wrapper">
                  <span className="time">
                    <Moment format="hh:mm A">{ptbMessage.updatedAt}</Moment>
                  </span>
                  <span className="date">
                    <Moment format="MM/DD/YYYY">{ptbMessage.updatedAt}</Moment>
                  </span>
                </span>
              </div>
            );
          } else {
            return (
              <div
                className={
                  ptbMessage.isDragMessage
                    ? 'disable-custom-wrapper disable-custom-wrapper-drag'
                    : 'disable-custom-wrapper'
                }
                key={ptbMessage._id}
              >
                <span className="heading">
                  {ptbMessage.isDragMessage ? 'Phase Change Update' : 'Custom'}
                  &nbsp;Message
                </span>
                <span
                  onClick={() => this.toggle(ptbMessage._id)}
                  className={
                    collapseIDs.includes(ptbMessage._id)
                      ? 'collapse-btn show'
                      : 'collapse-btn'
                  }
                ></span>
                <span className="time">
                  <Moment format="hh:mm A">{ptbMessage.updatedAt}</Moment>
                </span>
                <span className="date">
                  <Moment format="MM/DD/YYYY">{ptbMessage.updatedAt}</Moment>
                </span>
                <Collapse
                  key={ptbMessage._id}
                  isOpen={collapseIDs.includes(ptbMessage._id)}
                >
                  <span dangerouslySetInnerHTML={{ __html: html }}></span>
                </Collapse>
              </div>
            );
          }
        });
    }

    if (phaseMessages) {
      if (
        lastDragMessage ||
        (drag && drag.laneId !== constants.appointmentPhases.expected.id)
      ) {
        dragMessage = (
          <div key="pushMessage" className="active-wrapper drag-message">
            <div className="states-block">
              <label className="checkbox-label">
                {this.loadDescription(drag)}
              </label>
            </div>
          </div>
        );
      }

      messages = phaseMessages.map((phaseMessage, key) => {
        const petName =
          petSummary && petSummary.name
            ? petSummary.name.charAt(0).toUpperCase() + petSummary.name.slice(1)
            : '';
        let message = petSummary
          ? phaseMessage.message.replace(/PetName/g, petName)
          : phaseMessage.message;
        let controlMessage =
          petSummary && phaseMessage.controlMessage
            ? phaseMessage.controlMessage.replace(/PetName/g, petName)
            : phaseMessage.message;

        message = message.replace(/FacilityName/g, facility.displayName);

        if (phaseMessage.fetchable) {
          switch (phaseMessage.fetchableValue) {
            case 'FacilityName':
              controlMessage = controlMessage.replace(
                /FacilityName/g,
                facility.displayName,
              );
              break;
            case 'PhoneNumber':
              const phone = this.formatPhoneNumber(facility.phone);
              message = message.replace(/PhoneNumber/g, phone);
              controlMessage = controlMessage.replace(/PhoneNumber/g, phone);
              break;
            default:
              break;
          }
        }

        const html = DOMPurify.sanitize(message);
        controlMessage = DOMPurify.sanitize(controlMessage);
        if (phaseMessage.status === 'tab') {
          return (
            <div className="disable-phase-wrapper clearfix" key={message._id}>
              <span
                className="heading"
                dangerouslySetInnerHTML={{ __html: html }}
              ></span>
              <span className="dateTime-wrapper"></span>
            </div>
          );
        } else {
          let editableContent = '';
          if (
            this.isMessageSelected(phaseMessage._id) &&
            phaseMessage.editable &&
            phaseMessage.control
          ) {
            switch (phaseMessage.control) {
              case 'timePicker':
                editableContent = (
                  <div key="timepicker" className="editable-div">
                    {controlMessage.split('timePicker')[0]}
                    <TimePicker
                      showSecond={false}
                      placeholder="HH:MM AM"
                      onChange={(e) =>
                        this.handleChange(
                          e,
                          phaseMessage._id,
                          controlMessage,
                          'timePicker',
                        )
                      }
                      format="h:mm A"
                      use12Hours
                      inputReadOnly
                      minuteStep={15}
                    />
                    {controlMessage.split('timePicker')[1]}
                  </div>
                );
                break;
              case 'textField':
                if (controlMessage.split('textField').length > 2) {
                  editableContent = (
                    <div className="editable-div">
                      {controlMessage.split('textField')[0]}
                      <input
                        type="text"
                        placeholder={
                          phaseMessage.placeholder.split(',')[0] || 'textField'
                        }
                        onChange={(e) =>
                          this.handleChange(
                            e.target.value.trim(),
                            phaseMessage._id,
                            controlMessage,
                            'textField',
                            1,
                          )
                        }
                      />
                      {controlMessage.split('textField')[1]}
                      <input
                        type="text"
                        placeholder={
                          phaseMessage.placeholder.split(',')[1] || 'textField'
                        }
                        onChange={(e) =>
                          this.handleChange(
                            e.target.value.trim(),
                            phaseMessage._id,
                            controlMessage,
                            'textField',
                            2,
                          )
                        }
                      />
                      {controlMessage.split('textField')[2]}
                    </div>
                  );
                } else {
                  editableContent = (
                    <div className="editable-div">
                      {controlMessage.split('textField')[0]}
                      <input
                        type="text"
                        placeholder={phaseMessage.placeholder || 'textField'}
                        onChange={(e) =>
                          this.handleChange(
                            e.target.value.trim(),
                            phaseMessage._id,
                            controlMessage,
                            'textField',
                          )
                        }
                      />
                      {controlMessage.split('textField')[1]}
                    </div>
                  );
                }
                break;
              case 'dropDown':
                editableContent = (
                  <div className="editable-div">
                    {controlMessage.split('dropDown')[0]}
                    <Select
                      options={multiSelect}
                      values={selectedValue}
                      searchable={false}
                      multi={true}
                      placeholder="Select diagnostic evaluation"
                      onChange={(values) =>
                        this.setValues(values, phaseMessage._id, controlMessage)
                      }
                    />
                    {controlMessage.split('dropDown')[1]}
                  </div>
                );
                break;
              default:
                break;
            }
          } else if (
            this.isMessageSelected(phaseMessage._id) &&
            phaseMessage.editable
          ) {
            editableContent = (
              <div
                className="editable-div"
                contentEditable="true"
                ref={'content' + phaseMessage._id}
                onInput={(e) => {
                  this.handleUpdateMessage(e, phaseMessage._id);
                }}
                dangerouslySetInnerHTML={{ __html: html }}
              ></div>
            );
          } else {
            editableContent = null;
          }
          return (
            <div className="active-wrapper">
              <div className="states-block" key={phaseMessage._id}>
                <input
                  type="checkbox"
                  className="checkbox-input"
                  id={phaseMessage._id}
                  checked={this.isMessageSelected(phaseMessage._id)}
                  onChange={() => {
                    this.handleSelectMessage(
                      phaseMessage._id,
                      message,
                      false,
                      phaseMessage.notificationType,
                      phaseMessage.control,
                      phaseMessage.value,
                      phaseMessage.placeholder,
                    );
                  }}
                />
                <label
                  htmlFor={phaseMessage._id}
                  className={
                    this.isMessageSelected(phaseMessage._id) &&
                    phaseMessage.editable
                      ? 'checkbox-label custom-text'
                      : 'checkbox-label'
                  }
                >
                  {this.isMessageSelected(phaseMessage._id) &&
                  phaseMessage.editable ? (
                    '.'
                  ) : (
                    <p dangerouslySetInnerHTML={{ __html: html }}></p>
                  )}
                </label>
                {editableContent}
              </div>
            </div>
          );
        }
      });
    }

    if (selectedMessages) {
      customMessages = selectedMessages.map((selectedMessage) => {
        const html = DOMPurify.sanitize(selectedMessage.message);

        if (selectedMessage.isCustom) {
          return (
            <div className="active-wrapper">
              <div className="states-block" key={selectedMessage.id}>
                <input
                  type="checkbox"
                  className="checkbox-input"
                  id={selectedMessage.id}
                  checked={this.isMessageSelected(selectedMessage.id)}
                  onChange={() => {
                    this.handleSelectMessage(
                      selectedMessage.id,
                      selectedMessage.message,
                      true,
                      null,
                      selectedMessage.control,
                      null,
                      null,
                    );
                  }}
                />
                <label
                  htmlFor={selectedMessage.id}
                  className={
                    this.isMessageSelected(selectedMessage.id)
                      ? 'checkbox-label custom-text'
                      : 'checkbox-label'
                  }
                >
                  {this.isMessageSelected(selectedMessage.id) ? (
                    <p />
                  ) : (
                    <p dangerouslySetInnerHTML={{ __html: html }}></p>
                  )}
                </label>
                {this.isMessageSelected(selectedMessage.id) ? (
                  <textarea
                    name="text"
                    className="text-area edit"
                    value={selectedMessage.message}
                    onChange={(e) => {
                      this.handleCustomTextEdit(e, selectedMessage.id);
                    }}
                    rows="5"
                  />
                ) : null}
              </div>
            </div>
          );
        }
        return <></>;
      });
    }

    return (
      <div className="row ">
        <div className="col-md-12">
          <Modal
            isOpen={show}
            className="model-form modal-lg patient-tracking-popup"
          >
            <ModalHeader className="pet-summary-wrapper">
              <ModalPetInfo
                avatar={petSummary?.image}
                petSummary={petSummary}
                title={`Tracking Board\nStatus Update`}
              />
            </ModalHeader>
            <ModalBody
              style={{
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
              }}
            >
              <div
                className="message-container"
                style={{
                  flex: 1,
                  maxHeight: 'unset',
                  height: 'unset',
                  overflow: 'auto',
                }}
              >
                <div
                  className="blocks-wrapper"
                  style={{ width: '100%', minWidth: '200px', overflow: 'auto' }}
                >
                  {dragMessage}
                  {messages}
                  {customMessages}
                  {ptbMessageList}

                  <div className="custom-text-wrapper">
                    <div className="states-block text" key="custom-txt-5">
                      <textarea
                        name="text"
                        className="text-area"
                        id="exampleText"
                        value={customText}
                        onChange={(e) => this.handleCustomText(e)}
                        placeholder="Add custom message (optional)"
                        rows="5"
                      />
                      <div className="error-text error">{inputError}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="action-button-wrapper">
                <div className="error-text error">{updateError}</div>
                <Button
                  className="clear btn-popup-clear"
                  color="secondary"
                  onClick={() => this.handClosePTBModal()}
                >
                  Cancel
                </Button>
                <Button
                  disabled={btnDisable}
                  className="save-update"
                  color="primary"
                  onClick={() => this.update()}
                >
                  Send Status Update
                </Button>
              </div>
            </ModalBody>
          </Modal>
        </div>
      </div>
    );
  }
}
