import React, { Component } from 'react';

export default class CustomSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDropdownOpen: false,
      isAllSelected: false,
    };
  }

  componentDidMount() {
    const { selectedItems, allItems, disableValue, listName } = this.props;

    if (selectedItems.includes(disableValue)) {
      this.props.action(
        listName,
        allItems.map((filter) => filter.id),
      );
    }

    this.setState(
      { isAllSelected: selectedItems.length === allItems.length },
      () => this.handlePlaceholderText(),
    );
  }

  UNSAFE_componentWillMount() {
    document.addEventListener('mousedown', this.handleOutsideClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick, false);
  }

  UNSAFE_componentWillReceiveProps(np) {
    const { selectedItems, allItems } = np;

    this.setState(
      { isAllSelected: selectedItems.length === allItems.length },
      () => this.handlePlaceholderText(),
    );
  }

  handlePlaceholderText = () => {
    const { allItems, selectedItems, placeHolderPlural, placeHolderSingular } =
      this.props;
    const searchElement = this.refs.searchInput;

    let placeholderText = `Select ${placeHolderSingular}`;

    if (selectedItems.length === 1) {
      placeholderText = `${selectedItems.length} ${placeHolderSingular} Selected`;
    }

    if (selectedItems.length > 1) {
      placeholderText = `${selectedItems.length} ${placeHolderPlural} Selected`;
    }

    if (selectedItems.length === allItems.length) {
      placeholderText = `All ${placeHolderPlural} Selected`;
    }

    searchElement.placeholder = placeholderText;
  };

  handleOutsideClick = (e) => {
    if (this.node && !this.node.contains(e.target)) {
      this.setState({ isDropdownOpen: false });
    }
  };

  handleStateChangeFocus = () => {
    this.setState({ isDropdownOpen: true });
  };

  handleSelectAll = () => {
    const { isAllSelected } = this.state;

    const { allItems, listName } = this.props;

    const items = isAllSelected ? [] : allItems.map((filter) => filter.id);

    this.props.action(listName, items);
  };

  isItemSelected = (state) => {
    const { selectedItems } = this.props;
    return selectedItems.indexOf(state) !== -1;
  };

  handleSelectItem = (item) => {
    const { selectedItems, listName, disableValue, allItems } = this.props;

    const selectedIndex = selectedItems.indexOf(item);

    if (disableValue && item === disableValue && selectedIndex === -1) {
      return this.props.action(
        listName,
        allItems.map((filter) => filter.id),
      );
    } else if (disableValue && item === disableValue) {
      return this.props.action(listName, []);
    }

    if (selectedIndex === -1) {
      selectedItems.push(item);
    } else {
      selectedItems.splice(selectedIndex, 1);
    }

    if (
      disableValue &&
      selectedItems.length !== allItems.length &&
      selectedItems.includes(disableValue)
    ) {
      return this.props.action(
        listName,
        selectedItems.filter((item) => item !== disableValue),
      );
    }

    if (disableValue && selectedItems.length === allItems.length - 1) {
      return this.props.action(
        listName,
        allItems.map((filter) => filter.id),
      );
    }

    this.props.action(listName, selectedItems);
  };

  render() {
    const { allItems, placeHolderSingular, isSelectAllRequired } = this.props;
    const { isDropdownOpen, isAllSelected } = this.state;

    return (
      <div
        className="custom-select-box-wrapper clearfix"
        ref={(node) => (this.node = node)}
      >
        <input
          type="text"
          className="form-control search-state"
          placeholder={`Select ${placeHolderSingular}`}
          ref="searchInput"
          readOnly
          onFocus={this.handleStateChangeFocus}
        />
        {isDropdownOpen && allItems.length > 0 && (
          <div className="states-result broadcast-audience">
            <div className="blocks-wrapper">
              {allItems.length === 1
                ? null
                : isSelectAllRequired && (
                    <div className="states-block">
                      <input
                        type="checkbox"
                        className="checkbox-input"
                        checked={isAllSelected}
                        id="select_all"
                        onChange={this.handleSelectAll}
                      />
                      <label
                        htmlFor="select_all"
                        className="checkbox-label select-all"
                      >
                        All
                      </label>
                    </div>
                  )}
              {allItems.map((item) => (
                <div className="states-block" key={item.id}>
                  <input
                    type="checkbox"
                    className="checkbox-input"
                    id={item.id}
                    checked={this.isItemSelected(item.id)}
                    onChange={() => this.handleSelectItem(item.id)}
                  />
                  <label htmlFor={item.id} className="checkbox-label">
                    {item.name}
                  </label>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }
}
