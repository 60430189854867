import React from 'react';

function StateItem({ value, filterKey, isStateSelected, handleSelectState }) {
  return (
    <div className="states-block">
      <input
        type="checkbox"
        className="checkbox-input"
        id={filterKey}
        checked={isStateSelected}
        onChange={handleSelectState}
      />
      <label htmlFor={filterKey} className="checkbox-label">
        {Object.values(value)}
      </label>
    </div>
  );
}

export default StateItem;
