const responseMessages = {
  ERRORS: {
    NEW_PASSWORD_EMPTY: 'New password cannot be empty',
    PASSWORD_EMPTY: 'Password cannot be empty',
    PASSWORD_INVALID: 'Invalid password',
    OLD_PASSWORD_EMPTY: 'Old password cannot be empty',
    CONFIRM_PASSWORD_EMPTY: 'Confirm password cannot be empty',
    INVALID_CREDENTIALS: 'You have entered an invalid username or password',
    PASSWORD_PATTERN:
      'Your password must be at least 8 characters long and include at least one lowercase letter, one uppercase letter, and one number',
    PASSWORD_NOT_MATCH: 'Passwords do not match',
    SAME_PASSWORD: 'New password should not be same as old password',
    PASSWORD_RESET_UNSUCCESSFUL: 'Password reset Unsuccessful',
    CANNOT_FIND_EMAIL: 'We could not find your email address',
    ACCOUNT_NOT_ASSOCIATED:
      'The account associated with this email address / username has been deactivated.',
    CANNOT_FIND_USER: 'We could not find your email/username',
    USERNAME_NOT_ASSOCIATED:
      'The account associated with this email/username has been deactivated.',
    EMPTY_EMAIL: 'Email cannot be empty',
    EMPTY_EMAIL_OR_USERNAME: 'Email/username cannot be empty',
    INVALID_EMAIL: 'Invalid email address',
    INVALID_EMAIL_OR_USERNAME:
      'You have entered an invalid username or password',
    LOGIN_SYSTEM_ERROR: 'You have entered an invalid username or password',
    MSG_MAX_IMAGE: 'Image should not exceed the maximum file size of 9mb',
    MSG_MIN_IMAGE: 'Image resolution must be 161px*161px or higher',
    MSG_IMAGE_INVALID: 'Image type must be Jpg or Png',
    MIN_IMAGE_RESOLUTION: 161,
    BROADCAST_MIN_IMAGE_RESOLUTION: 70,
    BROADCAST_MSG_MIN_IMAGE: 'Image resolution must be 70px*70px or higher',
    FACILITY_EMPTY: 'Facility should not be empty',
    NAME_EMPTY: 'Name should not be empty',
    PHONE_INVALID: 'Invalid phone number',
    EXTENTION_INVALID: 'Invalid phone extention',
    PREFERRED_STATUS_EMPTY: 'Preferred status update should not be empty',
    EMAIL_EXISTS: 'Email already exists',
    MIN_LOGO_IMAGE_RESOLUTION: 128,
    MIN_ICON_IMAGE_RESOLUTION: 64,
    MSG_MIN_LOGO_SIZE: 'Image resolution must be #px*#px or higher',
    EMPTY_BREED: 'Breed should not be empty',
    EMPTY_SPECIES: 'Species should not be empty',
  },
  SUCCESS: {
    PROFILE_UPDATE: 'Profile updated successfully',
    INVITATION_SUCCESS: 'Invitation sent successfully ',
    BREED_UPDATE: 'Breed updated successfully ',
    BREED_ADD: 'Breed added successfully ',
    REF_DOCTOR_UPDATE: 'Profile updated successfully ',
    MEMBER_INVITATION_SENT:
      'New staff member added and invitation sent successfully',
    MEMBER_ADDED: 'Assigned credential created successfully',
    PASSWORD_CHANGE_SUCCESS:
      'Your password has been changed. Please login using the new password.',
  },
  BUTTON_TEXT: {
    ACTIVE: 'Activate',
    DEACTIVATE: 'Deactivate',
  },
  WARN: {
    RESET_PHASE_MESSAGES:
      'This action will reset all sent messages. Are you sure you want to continue?',
    CAN_NOT_MOVE_TO_EXPECTED:
      'Sorry, you cannot move an appointment with a past date back to the expected phase.',
  },
};

module.exports = responseMessages;
