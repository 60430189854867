import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, Col, Row } from 'reactstrap';
import moment from 'moment';
import Board from './Board';
import { getPetSummary } from '../../../redux/actions/petActions';
import {
  getByPhase,
  savePhaseMessage,
  getPtbEvents,
  clearStore,
} from '../../../redux/actions/pTBActions';
import PhaseNotificationModel from './PhaseNotificationModel';
import { toggleLoading } from '../../../redux/actions/commonActions';
import MessageModal from './../../../shared/components/Modals/MessageModal';
import PetSuccessModal from '../../../shared/components/Modals/PetSuccessModal';
import config from '../../../config/app.config';
import RefreshBoardButton from './RefreshBoardButton';
import InputWithSearchIcon from '../../common/InputWithSearchIcon';
import { TRACKING_BOARD_DATE_FORMAT } from '../../../constants/constants';

class PetTrackingBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleClass: 'minimize',
      showPetModel: false,
      petSummary: [],
      facility: null,
      events: [],
      phaseMessages: [],
      confirmPopup: {
        showResponse: false,
        responseMessage: {
          text: '',
          date: '',
          time: '',
        },
      },
      alertShow: false,
      alertMessage: '',
      search: '',
    };
  }

  componentDidMount() {
    const { user } = this.props;

    if (user.profile && user.profile.facility) {
      this.setState({ facility: user.profile.facility });
      this.props.getPtbEvents(
        user.profile.facility._id,
        user.profile.facility.utcOffset,
      );
    }
    setInterval(() => {
      const time = moment().format('hma');
      if (time.toString() === '120am') {
        const { facility } = this.state;
        this.props.getPtbEvents(facility._id, facility.utcOffset);
      }
    }, 30000);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { user, ptb, pets } = nextProps;
    const { petSummary } = this.state;

    if (nextProps) {
      if (ptb.phaseMessage && !ptb.ptbMessageSave) {
        const confirmPopup = {
          showResponse: false,
          responseMessage: {},
        };
        this.setState({
          phaseMessages: ptb.phaseMessage.phaseMessages,
          ptbMessages: ptb.phaseMessage.ptbMessages,
          confirmPopup,
        });
      }
      if (ptb.ptbMessageSave) {
        this.setState({ showPetModel: false });
      }
      if (user.profile && user.profile.facility) {
        this.setState({ facility: user.profile.facility });
        if (!ptb.loaded || ptb.cardDrag) {
          if (ptb.cardDrag.activeStatus) {
            this.setState({
              showPetModel: false,
              alertShow: true,
              alertMessage: this.alertMessage(ptb.cardDrag),
            });
          }

          this.props.getPtbEvents(
            user.profile.facility._id,
            user.profile.facility.utcOffset,
          );
        }
      }

      if (ptb.appointment) {
        const name = petSummary && petSummary.name ? petSummary.name : '';
        const confirmPopup = {
          showResponse: true,
          responseMessage: {
            text: `Update sent to ${name}'s support network`,
            date: ptb.appointment.updatedAt,
            time: ptb.appointment.updatedAt,
          },
        };
        this.setState({ confirmPopup, showPetModel: false }, () => {
          setTimeout(() => {
            confirmPopup.showResponse = false;
            this.setState({ confirmPopup });
          }, config.POPUP_TIMEOUT_TIME);

          this.props.getPtbEvents(
            user.profile.facility._id,
            user.profile.facility.utcOffset,
          );
          this.props.clearStore();
        });
      }

      this.setState({ events: ptb.ptbEvents });

      if (pets && pets.petSummary) {
        this.setState({ petSummary: pets.petSummary });
      }
    }
  }

  handleSearchClear = () => {
    this.setState({ search: '' }, () => {
      this.search();
    });
  };

  alertMessage(params) {
    const { parentStatus, petName, parentName, ongoingAppointmentsStatus } =
      params;
    if (!ongoingAppointmentsStatus) {
      return `${petName} has an ongoing procedure. Please complete the ongoing procedure before starting a new procedure.`;
    } else if (!parentStatus) {
      return `${parentName} is inactive. Please activate parent to update the procedure.`;
    } else {
      return `${petName} is inactive. Please activate pet to update the procedure.`;
    }
  }

  maximize = () => {
    this.setState({ toggleClass: 'maximize' });
  };

  minimize = () => {
    this.setState({ toggleClass: 'minimize' });
  };

  openPetModel = (cardId, metadata, laneId, drag) => {
    if (drag) {
      drag = {
        cardId,
        laneId,
        type: metadata.type,
      };
    }

    this.setState(
      { phaseId: laneId, appointmentId: cardId, showPetModel: true, drag },
      () => {
        this.props.getPetSummary(metadata.petId);
        this.props.getByPhase({ phaseId: laneId, appointmentId: cardId });
      },
    );
  };

  closePTBModal = () => {
    this.setState({ showPetModel: false });
  };

  handleMessageSubmit = (messages, drag) => {
    const { appointmentId, phaseId } = this.state;
    this.setState({ showPetModel: true });
    this.props.savePhaseMessage({ messages, phaseId, appointmentId, drag });
  };

  onClosePopup = () => {
    const { confirmPopup } = this.state;
    confirmPopup.showResponse = false;
    confirmPopup.responseMessage = null;

    this.setState({ confirmPopup });
  };

  handleSearch = (e) => {
    this.setState({ search: e.target.value }, () => {
      this.search();
    });
  };

  search = () => {
    const { search, facility } = this.state;
    this.props.toggleLoading(true);
    this.props.getPtbEvents(facility._id, facility.utcOffset, search);
  };

  closePopup = () => {
    this.setState({ alertShow: false });
  };

  render() {
    const {
      toggleClass,
      showPetModel,
      drag,
      petSummary,
      phaseMessages,
      ptbMessages,
      facility,
      events,
      confirmPopup,
      alertShow,
      alertMessage,
    } = this.state;

    return (
      <div className="patient-tracking">
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <Row>
                <Col className="search-wrap">
                  <div className="search form">
                    <div className="form__form-group">
                      <div style={{ margin: '10px 0' }}>
                        {moment().format(TRACKING_BOARD_DATE_FORMAT)}
                      </div>

                      <InputWithSearchIcon
                        name="search"
                        id="search"
                        type="text"
                        onChange={this.handleSearch}
                        placeholder="Search by Pet or Pet Parent Name"
                        value={this.state.search}
                        onClear={this.handleSearchClear}
                      />
                    </div>
                  </div>
                </Col>

                <Col className="refresh-btn-board-container">
                  <RefreshBoardButton
                    style={{ marginTop: '18px', marginLeft: '10px' }}
                    onRefreshClick={() => {
                      this.props.getPtbEvents(
                        this.props.user.profile.facility._id,
                        this.props.user.profile.facility.utcOffset,
                      );
                    }}
                  />

                  <div className="icon-maximize">
                    <span
                      onClick={this.maximize}
                      className="lnr lnr-frame-expand"
                    />
                  </div>
                </Col>
              </Row>

              <Board
                toggleClass={toggleClass}
                minimize={this.minimize}
                openPetModel={this.openPetModel}
                events={events}
              />
            </CardBody>
          </Card>

          <PhaseNotificationModel
            show={showPetModel}
            key={56}
            petSummary={petSummary}
            phaseMessages={phaseMessages}
            ptbMessages={ptbMessages}
            facility={facility}
            drag={drag}
            handleMessageSubmit={(message, drag) =>
              this.handleMessageSubmit(message, drag)
            }
            closePTBModal={() => this.closePTBModal()}
          />
        </Col>
        <PetSuccessModal
          show={confirmPopup.showResponse}
          key={59}
          onClose={this.onClosePopup}
          message={confirmPopup.responseMessage}
        />

        <MessageModal
          show={alertShow}
          type="alert"
          alertType="danger"
          onClose={this.closePopup}
          footer="true"
          message={alertMessage}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = {
  getPetSummary,
  getByPhase,
  getPtbEvents,
  savePhaseMessage,
  toggleLoading,
  clearStore,
};

export default connect(mapStateToProps, mapDispatchToProps)(PetTrackingBoard);
