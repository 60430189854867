import React from 'react';
import MessageModal from '../../../../shared/components/Modals/MessageModal';
import { ModalTextArea } from './ModalTextArea';
import {
  useRefillRequestModalContext,
  useStatusChangeModalContext,
} from '../utils';

export function StatusChangeModal() {
  const { inputValue, setInputValue, responseBox, onClose, onSuccess } =
    useStatusChangeModalContext();

  const { isRefillRequestModalOpen, closeRefillRequestModal } =
    useRefillRequestModalContext();

  const handleStatusChangeConfirmation = () => {
    onSuccess();
    if (isRefillRequestModalOpen) closeRefillRequestModal();
  };

  return (
    <MessageModal
      show={responseBox.showResponse}
      type={responseBox.responseType}
      alertType={responseBox.responseAlertType}
      footer={responseBox.responseFooter}
      onSuccess={handleStatusChangeConfirmation}
      onClose={onClose}
      message={
        <span dangerouslySetInnerHTML={{ __html: responseBox.message }} />
      }
      bodyExtraComponent={
        <ModalTextArea inputValue={inputValue} setInputValue={setInputValue} />
      }
    />
  );
}
