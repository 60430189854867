import React from 'react';
import { CarouselControl, CarouselIndicators, Button } from 'reactstrap';
import { saveAs } from 'file-saver';

const downloadIcon = `${process.env.PUBLIC_URL}/img/icons/download.png`;

const truncateFileName = (name) => {
  if (name.length <= 20) return name;
  return `${name.substring(0, 8)}...${name.substring(name.length - 9)}`;
};

export function AttachmentsList({ attachments, goToIndex }) {
  return (
    <div className="files-list">
      {attachments.map(({ name, url }, index) => (
        <div key={index} className="file-container">
          <button
            className="file-name"
            title={name}
            onClick={() => goToIndex(index)}
          >
            {truncateFileName(name)}
          </button>
          <button className="download-button" onClick={() => saveAs(url, name)}>
            <img src={downloadIcon} alt="Download icon" />
          </button>
        </div>
      ))}
    </div>
  );
}

export function AttachmentItem({ attachment }) {
  if (attachment.type === 'image') {
    return (
      <div className="image-file">
        <img src={attachment.url} alt={attachment.altText} />
        <p className="file-name">{attachment.name}</p>
      </div>
    );
  } else if (attachment.type === 'pdf') {
    return (
      <div className="pdf-file">
        <p>{attachment.name}</p>
        <a href={attachment.url} target="_blank" rel="noreferrer">
          <Button color="primary">View PDF</Button>
        </a>
      </div>
    );
  }
  return null;
}

export function AttachmentControls({ next, previous }) {
  return (
    <>
      <CarouselControl
        direction="prev"
        directionText="Previous"
        onClickHandler={previous}
      />
      <CarouselControl
        direction="next"
        directionText="Next"
        onClickHandler={next}
      />
    </>
  );
}

export function AttachmentIndicators({ items, activeIndex, goToIndex }) {
  return (
    <CarouselIndicators
      items={items.map((item, index) => ({ ...item, key: index }))}
      activeIndex={activeIndex}
      onClickHandler={goToIndex}
    />
  );
}
