import React, { Component } from 'react';
import OnboardingForm from './components/OnboardingForm';
import * as userActions from '../../../redux/actions/userActions';
import { connect } from 'react-redux';
import { Modal, Button, ModalFooter } from 'reactstrap';
import { toTheSystemStoreApp } from '../../../utils/Utils';
import withRouter from '../../../shared/components/withRouter';
const icon = `${process.env.PUBLIC_URL}/img/loyal-logo.png`;

class Onboarding extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const search = new URLSearchParams(this.props.location.search);

    const token = search.get('token');
    const facility = search.get('facility');

    if (!token) {
      this.props.history.push('/login');
      return;
    }

    this.setState({ token, facility });
    this.props.getOnboardingInfo(token);
  }

  componentDidUpdate() {
    if (this.props.signed) {
      toTheSystemStoreApp();
    }
  }

  render() {
    return (
      <div className="account user-flow">
        <div className="account__wrapper">
          <div className="account__card__onboarding p-5">
            <div className="account__card--inner">
              <div className="account__head">
                <h2 className="mb-4">
                  Onboarding
                  <span className="account__logo">
                    <img src={icon} alt="" />
                  </span>
                </h2>
              </div>
              <OnboardingForm
                user={this.props.info}
                token={this.state.token}
                facility={this.state.facility}
              />
            </div>
          </div>
        </div>
        <Modal
          isOpen={!!this.props.onBoardingError}
          contentClassName="p-0"
          toggle={() => window.close()}
          className="p-3"
        >
          <div className="p-3">The token has expired</div>
          <ModalFooter>
            <Button
              size="sm"
              className="m-0"
              color="primary"
              onClick={() => window.close()}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  info: { ...state.user.onBoardingUser },
  signed: state.user.signed,
  onBoardingError: state.user.onBoardingError,
});

const mapDispatchToProps = {
  getOnboardingInfo: userActions.getOnboardingInfo,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Onboarding),
);
