import { handleRequest } from '../services/APIService';

export const CREATE_APPOINTMENT = 'CREATE_APPOINTMENT';
export const CREATE_APPOINTMENT_ERROR = 'CREATE_APPOINTMENT_ERROR';
export const CLEAR_APPOINTMENT = 'CLEAR_APPOINTMENT';
export const CANCEL_APPOINTMENT = 'CANCEL_APPOINTMENT';
export const CANCEL_APPOINTMENT_ERROR = 'CANCEL_APPOINTMENT_ERROR';
export const REMOVE_APPOINTMENT = 'REMOVE_APPOINTMENT';
export const REMOVE_APPOINTMENT_ERROR = 'REMOVE_APPOINTMENT_ERROR';
export const RESCHEDULE_APPOINTMENT = 'RESCHEDULE_APPOINTMENT';
export const RESCHEDULE_APPOINTMENT_ERROR = 'RESCHEDULE_APPOINTMENT_ERROR';
export const GET_APPOINTMENT = 'GET_APPOINTMENT';
export const GET_APPOINTMENT_ERROR = 'GET_APPOINTMENT_ERROR';
export const UNSUBSCRIBE_SHARE_UPDATE = 'UNSUBSCRIBE_SHARE_UPDATE';
export const UNSUBSCRIBE_SHARE_UPDATE_ERROR = 'UNSUBSCRIBE_SHARE_UPDATE_ERROR';
export const SUBSCRIBE_SHARE_UPDATE = 'SUBSCRIBE_SHARE_UPDATE';
export const SUBSCRIBE_SHARE_UPDATE_ERROR = 'SUBSCRIBE_SHARE_UPDATE_ERROR';

export const createAppointment = (data) => async (dispatch) => {
  try {
    const results = await handleRequest('post', 'appointment', true, data);
    dispatch({
      type: CREATE_APPOINTMENT,
      payload: results,
    });

    return results;
  } catch (error) {
    const errorResponse = error?.response || error;
    dispatch({
      type: CREATE_APPOINTMENT_ERROR,
      payload: errorResponse,
    });

    throw errorResponse;
  }
};

export const clearAppointmentStore = () => (dispatch) => {
  return dispatch({
    type: CLEAR_APPOINTMENT,
    payload: null,
  });
};

export const getAllAppointments = (params) => {
  return async (dispatch) => {
    try {
      const results = await handleRequest(
        'get',
        'appointments',
        true,
        {},
        params,
      );
      dispatch({ type: 'GET_ALL_APPOINTMENTS_SUCCESS', payload: results.data });

      return results.data;
    } catch (error) {
      throw error.response || error;
    }
  };
};

export const cancelAppointment = (data) => async (dispatch) => {
  try {
    const results = await handleRequest(
      'put',
      'appointment/cancel',
      true,
      data,
    );

    dispatch({
      type: CANCEL_APPOINTMENT,
      payload: results,
    });
  } catch (error) {
    dispatch({
      type: CANCEL_APPOINTMENT_ERROR,
      payload: error.response || error,
    });
  }
};

export const removeAppointment = (data) => async (dispatch) => {
  try {
    const results = await handleRequest(
      'delete',
      'appointment/remove',
      true,
      data,
    );

    dispatch({
      type: REMOVE_APPOINTMENT,
      payload: results,
    });
  } catch (error) {
    dispatch({
      type: REMOVE_APPOINTMENT_ERROR,
      payload: error.response || error,
    });
  }
};

export const rescheduleAppointment = (data) => (dispatch) => {
  handleRequest('put', 'appointment/reschedule', true, data)
    .then((results) => {
      return dispatch({
        type: RESCHEDULE_APPOINTMENT,
        payload: results,
      });
    })
    .catch((error) => {
      return dispatch({
        type: RESCHEDULE_APPOINTMENT_ERROR,
        payload: error.response || error,
      });
    });
};

export const getAppointmentById = (data) => (dispatch) => {
  handleRequest('get', `appointment/${data}`, true)
    .then((results) => {
      return dispatch({
        type: GET_APPOINTMENT,
        payload: results,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_APPOINTMENT_ERROR,
        payload: error.response || error,
      });
    });
};

export const unsubscribeDoctor = (data) => (dispatch) => {
  handleRequest('get', `appointment/unsubscribe/${data}`, false)
    .then((results) => {
      return dispatch({
        type: UNSUBSCRIBE_SHARE_UPDATE,
        payload: results,
      });
    })
    .catch((error) => {
      return dispatch({
        type: UNSUBSCRIBE_SHARE_UPDATE_ERROR,
        payload: error.response || error,
      });
    });
};

export const subscribe = (data) => (dispatch) => {
  handleRequest('get', `appointment/subscribe/${data}`, false)
    .then((results) => {
      return dispatch({
        type: SUBSCRIBE_SHARE_UPDATE,
        payload: results,
      });
    })
    .catch((error) => {
      return dispatch({
        type: SUBSCRIBE_SHARE_UPDATE_ERROR,
        payload: error.response || error,
      });
    });
};
