import React from 'react';
import { useSelector } from 'react-redux';
import LoadingScreen from 'react-loading-screen';

function Loading() {
  const loading = useSelector((state) => state.common.loading);

  return (
    <div className="loyal-loading">
      <LoadingScreen
        loading={loading}
        bgColor="#1a24407a"
        spinnerColor="#9ee5f8"
        textColor="#9ee5f8"
      >
        <div />
      </LoadingScreen>
    </div>
  );
}

export default Loading;
