import React from 'react';
import { Tooltip } from '../common/Tooltip';

const Row = ({ count = 0, type = 'client', label = 'Clients', tooltip }) => {
  return (
    <div className="totals-dashboard-item">
      <Tooltip value={tooltip}>
        <span key={type} className={type}>
          <span className={`${type}Circle"`} />
          {label}
        </span>
      </Tooltip>
      <span className={type}>{count}</span>
    </div>
  );
};

export default function UsersTotals({
  staff = 0,
  client = 0,
  pet = 0,
  supporter = 0,
}) {
  return (
    <div className="total-wrapper">
      <h5 className="heading">Loyal Pet Users & Pets</h5>

      <div className="bullets">
        <Row type="client" label="Clients" count={client} />
        <Row type="pet" label="Pets" count={pet} />
        <Row
          type="supporter"
          label="Supporters *"
          tooltip="Supporters of Client Pets"
          count={supporter}
        />
        <Row type="staff" label="Staff" count={staff} />
      </div>
    </div>
  );
}
