import React, { PureComponent, createRef } from 'react';
import { Card, CardBody, Col, Label } from 'reactstrap';
import FacilityTableComponent from './FacilityTableComponent';
import Pagination from '../../shared/components/pagination/Pagination';
import { connect } from 'react-redux';

import {
  getFacilityList,
  updateFacilityStatus,
  synchronizeFacilitiesDayLightTime,
} from '../../redux/actions/facilityActions';
import config from '../../config/app.config';
import PlusIcon from 'mdi-react/PlusCircleOutlineIcon';
import AddFacility from './AddFacility';
import MessageModal from '../../shared/components/Modals/MessageModal';
import HeadingText from '../../shared/components/form/HeadingText';
import UpdateFacility from './UpdateFacility';
import { toggleLoading } from '../../redux/actions/commonActions';
import InputWithSearchIcon from '../common/InputWithSearchIcon';
import { FACILITY_STATUS, heads } from './constants';

class Facility extends PureComponent {
  searchRef = createRef();

  constructor() {
    super();

    this.state = {
      rows: [],
      filters: {},
      sortField: 'name',
      sortOrder: 1,
      search: '',
      offset: 0,
      limit: config.DEFAULT_PAGINATION_LENGTH,
      total: 0,
      confirmPopup: {
        showResponse: false,
        responseMessage: '',
        responseType: '',
        responseTitle: '',
        clientId: null,
        status: null,
        confirmType: null,
      },
      newFacilityPopup: false,
      updateFacilityPopup: false,
      selectedId: null,
    };
  }

  UNSAFE_componentWillMount() {
    this.props.toggleLoading(true);
  }

  componentDidUpdate(np) {
    if (np.common.loading) {
      this.props.toggleLoading(false);
    }
  }

  componentDidMount() {
    const { offset, limit, sortField, sortOrder } = this.state;
    this.props.getFacilityList({ offset, limit, sortField, sortOrder });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.facility.facility) {
      const propsData = nextProps.facility.facility;
      this.setState({ total: propsData.total });
      this.createRows(propsData.facilities);
      heads[4].filterValue = propsData.adminList;
    }
    if (nextProps.facility.statusUpdated) {
      const confirmPopup = {
        showResponse: true,
        responseMessage: '',
        responseType: 'alert',
        responseAlertType: null,
        footer: true,
      };
      if (nextProps.facility.statusUpdated.errorMessage) {
        confirmPopup.responseAlertType = 'danger';
        confirmPopup.responseMessage =
          nextProps.facility.statusUpdated.errorMessage;
      } else {
        let alertMessage = '';
        switch (nextProps.facility.statusUpdated.status) {
          case FACILITY_STATUS.ACTIVE:
            alertMessage = 'This profile is activated now';
            break;
          case FACILITY_STATUS.INACTIVE:
            alertMessage = 'This profile is deactivated now';
            break;
          case FACILITY_STATUS.REMOVED:
            alertMessage = 'This profile is removed now';
            break;
          default:
            alertMessage = '';
            break;
        }
        confirmPopup.responseMessage = alertMessage;
      }
      setTimeout(() => {
        this.setState({ confirmPopup }, this.reFreshTableData);
      }, 300);
    }
  }

  handleActiveStatus = (status, _id) => {
    let statusMessage = '';
    switch (status) {
      case FACILITY_STATUS.ACTIVE:
        statusMessage =
          'Are you sure you want to activate this facility profile? ';
        break;
      case FACILITY_STATUS.INACTIVE:
        statusMessage =
          'Are you sure you want to deactivate this facility profile? ';
        break;
      case FACILITY_STATUS.REMOVED:
        statusMessage =
          'Are you sure you want to remove this facility profile? ';
        break;
      default:
        statusMessage = '';
        break;
    }

    this.setState({
      confirmPopup: {
        showResponse: true,
        responseMessage: statusMessage,
        responseType: 'confirm',
        responseAlertType: null,
        clientId: _id,
        footer: true,
        confirmType: 'change_status',
        status,
      },
    });
  };

  onSuccess = () => {
    const { confirmPopup } = this.state;
    if (confirmPopup.confirmType === 'change_status') {
      this.props.updateFacilityStatus({
        status: confirmPopup.status,
        id: confirmPopup.clientId,
      });
    }

    this.closePopup();
  };

  closePopup = () => {
    const { confirmPopup } = this.state;

    this.setState({
      confirmPopup: {
        ...confirmPopup,
        confirmType: null,
        showResponse: false,
      },
    });
  };

  createRows = (data) => {
    const rows = data.map((row) => ({
      name: row.name,
      displayName: row.displayName,
      city: row.city,
      state: row.state,
      zipCode: row.zipCode,
      admin: row.adminName !== null ? row.adminName : 'N/A',
      status: row.status,
      action: '',
      id: row._id,
      adminName: row.adminName,
      removePossibility: row.removePossibility,
    }));

    this.setState({ rows });
  };

  nextPage = () => {
    let { offset, limit, sortField, sortOrder, search } = this.state;
    offset = parseInt(offset + limit);
    this.setState({ offset }, () => {
      this.props.getFacilityList({
        offset,
        limit,
        sortField,
        sortOrder,
        search,
      });
    });
  };

  prevPage = () => {
    let { offset, limit, sortField, sortOrder, search } = this.state;
    offset = parseInt(offset - limit);
    this.setState({ offset }, () => {
      this.props.getFacilityList({
        offset,
        limit,
        sortField,
        sortOrder,
        search,
      });
    });
  };

  gotoPage = (i) => {
    let { offset, limit, sortField, sortOrder, search } = this.state;
    offset = parseInt(limit * i);
    this.setState({ offset }, () => {
      this.props.getFacilityList({
        offset,
        limit,
        sortField,
        sortOrder,
        search,
      });
    });
  };

  handleGridSort = (field) => {
    let { limit, sortField, sortOrder, search, filters } = this.state;
    const order = sortOrder === 1 ? -1 : 1;
    sortField = field;
    sortOrder = order;

    this.setState({ sortField, sortOrder, offset: 0 }, () => {
      this.props.getFacilityList({
        offset: 0,
        limit,
        sortField: field,
        sortOrder: order,
        search,
        filters,
      });
    });
  };

  handleGridFilter = (header, value) => {
    const { limit, sortField, sortOrder, search, filters } = this.state;
    filters[header] = value;
    this.setState({ filters, offset: 0 }, () => {
      this.props.getFacilityList({
        offset: 0,
        limit,
        sortField,
        sortOrder,
        search,
        filters,
      });
    });
  };

  handleSearch = (e) => {
    const search = e.target.value;
    const { limit, sortField, sortOrder } = this.state;

    this.setState({ search, offset: 0 }, () => {
      this.props.getFacilityList({
        offset: 0,
        limit,
        sortField,
        sortOrder,
        search,
      });
    });
  };

  handlePageLimit = (perPage) => {
    const { sortField, sortOrder, search } = this.state;
    this.setState({ limit: perPage, offset: 0 }, () => {
      this.props.getFacilityList({
        offset: 0,
        limit: perPage,
        sortField,
        sortOrder,
        search,
      });
    });
  };

  newFacilityPopup = () => {
    this.setState({ newFacilityPopup: true });
  };

  syncDaylightTime = async () => {
    const res = await this.props.synchronizeFacilitiesDayLightTime();

    const message = res?.updated
      ? `Synchronized ${res?.updated} facilities time`
      : 'All facilities timezone offsets are up to date';

    this.setState({
      confirmPopup: {
        showResponse: true,
        responseMessage: message,
        responseType: 'alert',
        responseAlertType: null,
        footer: true,
      },
    });
  };

  closeFacilityPopup = () => {
    const offset = 0;
    this.setState({ newFacilityPopup: false, selectedId: null, offset }, () =>
      this.reFreshTableData(),
    );
  };

  closeViewFacilityPopup = () => {
    const offset = 0;
    if (!this.updateFacilityPopup) {
      this.setState(
        { updateFacilityPopup: false, selectedId: null, offset },
        () => this.reFreshTableData(),
      );
    }
  };

  reFreshTableData = () => {
    const { offset, limit, sortField, sortOrder, search, filters } = this.state;
    this.props.getFacilityList({
      offset,
      limit,
      sortField,
      sortOrder,
      search,
      filters,
    });
  };

  handleClearSearch = () => {
    const { limit, sortField, sortOrder } = this.state;

    const search = '';
    this.setState({ search, offset: 0 }, () => {
      this.props.getFacilityList({
        offset: 0,
        limit,
        sortField,
        sortOrder,
        search,
      });
    });
  };

  viewFacility = (selectedFacility) => {
    this.setState({
      selectedId: selectedFacility.id,
      updateFacilityPopup: true,
    });
  };

  render() {
    const {
      rows,
      total,
      offset,
      limit,
      search,
      sortField,
      sortOrder,
      confirmPopup,
      newFacilityPopup,
      updateFacilityPopup,
      selectedId,
    } = this.state;

    return (
      <Col md={12} lg={12} className="manage-staff-container admin-container">
        <Card>
          <CardBody>
            <div className="sticky-table">
              <HeadingText text="Manage Facilities" />
              <div className="row search form">
                <div className="col-sm-6">
                  <div className="form__form-group">
                    <Label for="exampleSelect" className="empty-label">
                      &nbsp;
                    </Label>

                    <InputWithSearchIcon
                      name="search"
                      id="search"
                      type="text"
                      onChange={this.handleSearch}
                      ref={this.searchRef}
                      value={search}
                      onClear={this.handleClearSearch}
                      placeholder="Search by Facility Name"
                      autofocus
                    />
                  </div>
                </div>
                <div
                  className="col-sm-6"
                  style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    columnGap: '8px',
                    alignItems: 'flex-end',
                  }}
                >
                  <button
                    className="btn btn-outline-primary pull-right top-panel-button"
                    onClick={this.syncDaylightTime}
                  >
                    Synchronize DayLight time
                  </button>
                  <button
                    className="btn btn-outline-primary pull-right top-panel-button"
                    onClick={this.newFacilityPopup}
                  >
                    &nbsp;
                    <PlusIcon />
                    &nbsp;Add New Facility
                  </button>
                </div>
              </div>
            </div>
            <FacilityTableComponent
              sortField={sortField}
              sortOrder={sortOrder}
              heads={heads}
              rows={rows}
              viewProfile={(field) => this.viewFacility(field)}
              handleGridSort={(field) => this.handleGridSort(field)}
              handleGridFilter={(header, field) =>
                this.handleGridFilter(header, field)
              }
              handleActiveStatus={(status, id, joined, firstName) =>
                this.handleActiveStatus(status, id, joined, firstName)
              }
            />
            <Pagination
              handlePageLimit={(limit) => this.handlePageLimit(limit)}
              nextPage={this.nextPage}
              prevPage={this.prevPage}
              gotoPage={(i) => this.gotoPage(i)}
              limit={limit}
              offset={offset}
              total={total}
            />
            <AddFacility
              view={newFacilityPopup}
              closeFacilityPopup={this.closeFacilityPopup}
            />
            <UpdateFacility
              view={updateFacilityPopup}
              closeFacilityPopup={this.closeViewFacilityPopup}
              selectedId={selectedId}
            />
          </CardBody>
        </Card>

        <MessageModal
          show={confirmPopup.showResponse}
          type={confirmPopup.responseType}
          alertType={confirmPopup.responseAlertType}
          footer={confirmPopup.footer}
          onSuccess={this.onSuccess}
          onClose={this.closePopup}
          message={confirmPopup.responseMessage}
        />
      </Col>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = {
  getFacilityList,
  updateFacilityStatus,
  toggleLoading,
  synchronizeFacilitiesDayLightTime,
};

export default connect(mapStateToProps, mapDispatchToProps)(Facility);
