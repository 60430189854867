import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Label } from 'reactstrap';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
} from 'recharts';
import DashboardFacilityFilterComponent from '../../shared/components/form/DashboardFacilityFilterComponent';
import { getProcedureUpdateMsgsData } from '../../redux/actions/dashboardActions';
import TimeRangeSelect from '../../shared/components/inputs/TimeRangeSelect/TimeRangeSelect';
import phases from '../../constants/phases';

class ProcedureUpdateMessageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      type: 'daily',
      selectedPhase: [],
      selectedFacilities: null,
      timeFilter: 'daily',
      total: 0,
      avg: 0,
    };
  }

  componentDidMount() {
    this.getChartData();
  }

  getChartData = () => {
    const {
      selectedFacilities: facility,
      selectedPhase: phase,
      timeFilter,
    } = this.state;

    this.props.getProcedureUpdateMsgsData({
      filters: {
        facility,
        phase,
        timeFilter,
      },
    });
  };

  isFacilityArraysNotEquals = (newList, oldList) => {
    const newListSize = newList.length;

    if (
      (newListSize === 0 && (!oldList || oldList.length > 0)) ||
      newListSize > oldList.length ||
      newListSize !== oldList.length
    ) {
      return true;
    }

    return newList.some((value) => !oldList.includes(value));
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const isArraysEquals = this.isFacilityArraysNotEquals(
      nextProps.facilities,
      this.state.selectedFacilities,
    );
    if (isArraysEquals) {
      this.setState(
        {
          selectedFacilities: [...nextProps.facilities],
        },
        () => {
          this.getChartData();
        },
      );
    }

    if (nextProps.dashboard.procedureUpdateMsgs) {
      this.setState({
        data: nextProps.dashboard.procedureUpdateMsgs.result,
        total: nextProps.dashboard.procedureUpdateMsgs.total,
        avg: nextProps.dashboard.procedureUpdateMsgs.avg,
      });
    }
  }

  handleType = (e) => {
    this.setState({ timeFilter: e.target.value }, () => {
      this.getChartData();
    });
  };

  handleFilter = (selected) => {
    this.setState({ selectedPhase: selected }, () => {
      this.getChartData();
    });
  };

  render() {
    const { data, total, avg } = this.state;
    const { processedDate } = this.props;

    return (
      <div className="total-average new-user">
        <div className="header-container">
          <h3>Procedure Update Message Sent</h3>
          <TimeRangeSelect handleTypeSelect={this.handleType} />
          <p className="loyal-last-process-date">
            Last updated {processedDate}
          </p>
        </div>
        <div className="sub-header-container clearfix">
          <div className="wrapper">
            <Label>Phase</Label>
            <DashboardFacilityFilterComponent
              facilities={phases}
              handleFilter={this.handleFilter}
              filterTypeTextPlural="Phases"
              filterTypeText="Phase"
            />
          </div>
        </div>
        <div className="chart">
          <ResponsiveContainer width="100%" height={240}>
            <BarChart
              width={100}
              height={240}
              data={data}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="0 0" vertical={false} />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="value" fill="#0631b4" />
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div className="total-average-wrapper msg-sent clearfix">
          <div className="total-wrapper">
            <div className="label">
              <span> Total</span>
            </div>
            <div className="no-padding provider procedure-messages">
              <span>{total}</span>
            </div>
          </div>
          <div className="avg-wrapper">
            <div className="label procedure-messages">
              <span> Average</span>
            </div>
            <div className="no-padding provider procedure-messages">
              <span>{avg}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = {
  getProcedureUpdateMsgsData,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProcedureUpdateMessageComponent);
