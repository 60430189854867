import React, { Component } from 'react';
import DateRangePickerComponent from './DateRangePickerComponent';
import PropTypes from 'prop-types';
import moment from 'moment';

export default class TimeFilter extends Component {
  static propTypes = {
    changeFilter: PropTypes.func.isRequired,
    selected: PropTypes.string,
    setDateRange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    filterList: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string,
    showClearButton: PropTypes.bool,
  };

  static defaultProps = {
    filterList: ['today', 'this week', 'next week'],
    showClearButton: true,
    title: 'Filter By',
  };

  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
      endDate: new Date(),
    };
    this.handleSelect = this.handleSelect.bind(this);
    this.changeFilter = this.changeFilter.bind(this);
    this.clearAll = this.clearAll.bind(this);
  }

  handleSelect(ranges) {
    this.setState(
      {
        startDate: ranges.selection.startDate,
        endDate: ranges.selection.endDate,
      },
      () => {
        const start = moment(ranges.selection.startDate);
        const end = moment(ranges.selection.endDate);

        if (!start.isSame(end)) {
          this.props.changeFilter('range');
          this.props.setDateRange({
            startDate: ranges.selection.startDate,
            endDate: ranges.selection.endDate,
          });
        } else {
          this.props.changeFilter('day');
          this.props.setDateRange({
            startDate: ranges.selection.startDate,
            endDate: ranges.selection.endDate,
          });
        }
      },
    );
  }

  changeFilter(value) {
    if (value !== 'range') {
      this.setState({
        startDate: new Date(),
        endDate: new Date(),
      });
    }
    this.props.changeFilter(value);
  }

  clearAll() {
    this.changeFilter('');
  }

  render() {
    const { startDate, endDate } = this.state;
    const { selected, placeholder, filterList, showClearButton, title } =
      this.props;
    const selectionRange = {
      startDate,
      endDate,
      key: 'selection',
      selected,
    };

    const list = filterList.map((value) => (
      <li
        key={value}
        className="time-filter-item"
        onClick={() => {
          this.changeFilter(value);
        }}
      >
        <span className={value === selected ? 'active' : null}>{value}</span>
      </li>
    ));
    return (
      <div className="time-filters-wrapper">
        <ul className="time-filter-list clearfix">
          <li className="time-filter-item">{title}</li>
          {list}
        </ul>
        <DateRangePickerComponent
          placeholder={placeholder}
          handleSelect={this.handleSelect}
          selectionRange={selectionRange}
        />
        {showClearButton && (
          <a
            className={`clear-filter ${selected ? 'active' : ''}`}
            onClick={this.clearAll}
          >
            Clear
          </a>
        )}
      </div>
    );
  }
}
