import React from 'react';
import { appointmentPageTypes } from '../../../../config/app.config';
import {
  ClientPageWrapper,
  DefaultWrapper,
} from '../../Common/PageWithTableWrapper';

const appointmentClassName = 'appointments-container';

export const appointmentComponentsWrapperMap = {
  [appointmentPageTypes.default]: (props) => (
    <DefaultWrapper className={appointmentClassName} {...props} />
  ),
  [appointmentPageTypes.clientPage]: (props) => (
    <ClientPageWrapper className={appointmentClassName} {...props} />
  ),
};
