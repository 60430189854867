import React from 'react';

export function AppointmentInfo({ appointmentInfo }) {
  const labelName = {
    dateTime: 'Date and Time',
    type: 'Appointment Type',
    doctor: 'Doctor',
  };

  return (
    <div className="modal-main-info-data of-appointment">
      {Object.entries(appointmentInfo).map(([key, value]) => {
        const label = labelName[key];
        let dataToShow = value;

        if (key === 'id') return null;

        if (key === 'dateTime') {
          dataToShow = `${value.date} ${value.time}`;
        }

        return (
          <div
            key={key}
            className={`${key} modal-main-info-field`}
            style={{ gridArea: key }}
          >
            <p className="field-label">{label}</p>
            <p
              className="field-data text-capitalize ellipsis"
              title={dataToShow}
            >
              {dataToShow}
            </p>
          </div>
        );
      })}
    </div>
  );
}
