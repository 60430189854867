import React, { Component } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import TimeRangeSelect from '../../shared/components/inputs/TimeRangeSelect/TimeRangeSelect';

export default class ProcedureDetailsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      procedureDetail: null,
      type: 'daily',
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.procedureDetail !== null) {
      this.setState({ procedureDetail: this.props.procedureDetail });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.procedureDetail !== null) {
      this.setState({ procedureDetail: nextProps.procedureDetail });
    }
  }

  handleType = (e) => {
    const type = e.target.value;
    this.setState({ type }, () => {
      this.props.handleProcedureDetailType(type);
    });
  };

  render() {
    const { procedureDetail, type } = this.state;
    const { processedDate } = this.props;
    const totalCountByType = {
      daily: 30,
      weekly: 12,
      monthly: 6,
    };

    const totalCount = totalCountByType[type] || 30;

    return (
      <div>
        <div className="total-average new-user procedure-details">
          <div className="header-container">
            <h3>Procedure Details</h3>
            <TimeRangeSelect handleTypeSelect={this.handleType} />
            <p className="loyal-last-process-date">
              Last updated {processedDate}
            </p>
          </div>
          <div className="chart">
            <ResponsiveContainer width="100%" height={240}>
              <LineChart
                data={procedureDetail && procedureDetail.data}
                syncId="procedureDetails"
                margin={{ top: 10, right: 20, left: 0, bottom: 0 }}
              >
                <CartesianGrid strokeDasharray="0 0" vertical={false} />
                <XAxis dataKey="name" />
                <YAxis tickSize={10} />
                <Tooltip position="top" />
                <Line
                  type="linear"
                  dataKey="initiated"
                  stroke="#56fba9"
                  fill="#56fba9"
                />
                <Line
                  type="linear"
                  dataKey="completed"
                  stroke="#c724d1"
                  fill="#c724d1"
                />
                <Line
                  type="linear"
                  dataKey="closed"
                  stroke="#ed3434"
                  fill="#ed3434"
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
          <div className="total-average-wrapper">
            <div className="label">
              <span> Total</span>
              <span> Average</span>
            </div>
            <div className="no-padding initiated">
              <span>{procedureDetail && procedureDetail.totalInitiated}</span>
              <span>
                {procedureDetail &&
                  (procedureDetail.totalInitiated / totalCount).toFixed(2)}
              </span>
            </div>
            <div className="no-padding completed">
              <span>{procedureDetail && procedureDetail.totalCompleted}</span>
              <span>
                {procedureDetail &&
                  (procedureDetail.totalCompleted / totalCount).toFixed(2)}
              </span>
            </div>
            <div className="no-padding closed">
              <span>{procedureDetail && procedureDetail.totalClosed}</span>
              <span>
                {procedureDetail &&
                  (procedureDetail.totalClosed / totalCount).toFixed(2)}
              </span>
            </div>
            <div className="bullet-wrapper">
              <div className="bullets">
                <span key="initiated">
                  <span className="initiated" />
                  &nbsp;&nbsp; Initiated
                </span>
                <span key="completed">
                  <span className="completed" />
                  &nbsp;&nbsp; Completed
                </span>
                <span key="closed">
                  <span className="closed" />
                  &nbsp;&nbsp; Home
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="average-closed-wrapper">
          Average Home&nbsp;
          <span>
            {procedureDetail && procedureDetail.averageClosed
              ? procedureDetail.averageClosed === 100
                ? 100
                : procedureDetail.averageClosed.toFixed(2)
              : 0}
            %
          </span>
        </div>
      </div>
    );
  }
}
