import React, { Component } from 'react';
import EmailOutlineIcon from 'mdi-react/EmailOutlineIcon';
import Error from '../../../../shared/components/form/Error';
import AlertBarComponent from '../../../../shared/components/AlertBarComponent';
import ResponseMessages from '../../../../constants/responseMessages';

export default class ForgotPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      errorEmail: null,
      alertBarType: '',
      showAlertBar: false,
      alertBarMessage: '',
      sendSuccess: false,
    };
    this.sendLink = this.sendLink.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(np) {
    if (
      np.sendResetLinkData.forgotPasswordDataError &&
      np.sendResetLinkData.forgotPasswordDataError.data ===
        'private_credentials'
    ) {
      this.setState({
        alertBarMessage: 'Please contact hospital admin to reset password',
        alertBarType: 'error',
        showAlertBar: true,
        sendSuccess: true,
      });
    } else if (
      (np.sendResetLinkData.forgotPasswordData &&
        np.sendResetLinkData.forgotPasswordData.data === 'success') ||
      np.sendResetLinkData.forgotPasswordDataError
    ) {
      this.setState({
        alertBarMessage:
          'If the email provided is in our database, an email containing a link to change your password has been sent to you. Please click on the link to reset your password.',
        alertBarType: 'info',
        showAlertBar: true,
        sendSuccess: true,
      });
    }
  }

  validate() {
    const { email } = this.state;
    let errorEmail = null;
    let error = false;
    /* eslint-disable no-useless-escape */
    const emailRegEx =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!email) {
      errorEmail = ResponseMessages.ERRORS.EMPTY_EMAIL;
      error = true;
    } else if (!emailRegEx.test(email)) {
      errorEmail = ResponseMessages.ERRORS.INVALID_EMAIL;
      error = true;
    }

    this.setState({ errorEmail, showAlertBar: false });
    return error;
  }

  sendLink(e) {
    e.preventDefault();
    if (!this.validate()) {
      this.props.handleSubmit(this.state);
    }
  }

  onChange(e) {
    if (e.target.name === 'password') {
      const password = e.target.value.replace(/\s/g, '');
      this.setState({
        password,
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  }

  render() {
    const { errorEmail, alertBarType, showAlertBar, alertBarMessage } =
      this.state;

    return (
      <form className="form">
        <div className="form__form-group">
          <div className="form__form-group-field">
            <div className="form__form-group-icon form__form-group-icon--left">
              <EmailOutlineIcon />
            </div>
            <input
              name="email"
              type="text"
              placeholder="Email address"
              onChange={this.onChange}
              className="form__custom-field form__custom-field--icon-left"
            />
          </div>
          {errorEmail && <Error text={errorEmail} />}
        </div>
        {showAlertBar && (
          <AlertBarComponent type={alertBarType} message={alertBarMessage} />
        )}
        <div className="account__btns">
          <button
            className="btn btn-primary account__btn"
            onClick={this.sendLink}
          >
            {this.state.sendSuccess ? 'Resend Link' : 'Send Link'}
          </button>
        </div>
      </form>
    );
  }
}
