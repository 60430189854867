import React, { createContext, useCallback, useContext, useState } from 'react';

export const tab = Object.freeze({
  MAIN_INFO: 'Main Information',
  ATTACHMENTS: 'Attachments',
});

const AppointmentRequestModalContext = createContext({
  isAppointmentRequestModalOpen: false,
  openAppointmentRequestModal: () => {},
  closeAppointmentRequestModal: () => {},
  appointmentRequestData: {},
  handleSetAppointmentRequestData: () => {},
  activeTab: tab.MAIN_INFO,
  setActiveTab: () => {},
  doctors: [],
  handelSetDoctors: () => {},
});

export const AppointmentRequestModalProvider = ({ children }) => {
  const [isAppointmentRequestModalOpen, setIsAppointmentRequestModalOpen] =
    useState(false);
  const [appointmentRequestData, setAppointmentRequestData] = useState({});
  const [activeTab, setActiveTab] = useState(tab.MAIN_INFO);
  const [doctors, setDoctors] = useState([]);

  const openAppointmentRequestModal = useCallback((tabToOpen) => {
    setIsAppointmentRequestModalOpen(true);
    tabToOpen && setActiveTab(tabToOpen);
  }, []);

  const closeAppointmentRequestModal = useCallback(() => {
    setIsAppointmentRequestModalOpen(false);
    setAppointmentRequestData({});
    setActiveTab(tab.MAIN_INFO);
  }, []);

  const handleSetAppointmentRequestData = useCallback((data) => {
    const { date, time, ...rest } = data;
    setAppointmentRequestData({
      ...rest,
      dateTime: `${date} ${time}`,
    });
  }, []);

  const handleSetDoctors = useCallback((newDoctors) => {
    const transformedDoctorsArray = newDoctors.map((doctor) => ({
      value: doctor._id,
      label: `Dr. ${doctor.name}`,
    }));

    setDoctors([{ value: '', label: 'N/A' }, ...transformedDoctorsArray]);
  }, []);

  return (
    <AppointmentRequestModalContext.Provider
      value={{
        isAppointmentRequestModalOpen,
        openAppointmentRequestModal,
        closeAppointmentRequestModal,
        appointmentRequestData,
        handleSetAppointmentRequestData,
        activeTab,
        setActiveTab,
        doctors,
        handleSetDoctors,
      }}
    >
      {children}
    </AppointmentRequestModalContext.Provider>
  );
};

export const useAppointmentRequestModalContext = () => {
  return useContext(AppointmentRequestModalContext);
};
