import React from 'react';
import { Table } from 'reactstrap';
import { daysOfWeek } from '../utils';

export function AvailabilityTable({ availability }) {
  const tableHeaders = [
    { key: 'weekDay', name: 'Day of the Week' },
    { key: 'availabilityDetails', name: 'Details' },
  ];

  const renderRowValues = daysOfWeek.map((day, index) => {
    if (availability[day]) {
      return (
        <tr className="body-tr" key={index}>
          <td title={day}>{day}</td>
          <td title={availability[day]} className="custom-no-over-flow-td">
            {availability[day]}
          </td>
        </tr>
      );
    }
    return null;
  });

  return (
    <Table striped className="custom-table loyal-table">
      <thead>
        <tr className="header-tr">
          {tableHeaders.map((header) => (
            <th key={header.key}>
              <p>{header.name}</p>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>{renderRowValues}</tbody>
    </Table>
  );
}
