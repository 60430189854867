import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';

import { appointmentRequestPageTypes } from '../../../../config/app.config';
import ModalPetInfo from '../../Common/ModalPetInfo';
import { FooterButtons } from '../../../../shared/components/Modals/FooterButtons';
import { getPetSummaryById } from '../../../../redux/actions/petActions';
import { AppointmentRequests } from '../../AppointmentRequests';

export function PetAppointmentRequestsModal({
  isOpen,
  openCreateNewAppointmentModal,
  handleClose,
  petId,
  parentId,
}) {
  const [petSummary, setPetSummary] = useState({});

  useEffect(() => {
    if (!petId) return;

    async function getPet() {
      const result = await getPetSummaryById(petId);

      setPetSummary(result);
    }

    getPet();
  }, [petId]);

  return (
    <Modal
      isOpen={isOpen}
      size="lg"
      backdrop="static"
      className="model-form pet-appointment-requests-modal"
    >
      <ModalHeader className="pet-summary-wrapper">
        <ModalPetInfo
          petSummary={petSummary}
          avatar={petSummary.image}
          title={`Pet\nAppointment\nRequests`}
        />
      </ModalHeader>
      <ModalBody>
        <div className="content">
          <Button
            className="create-new-appointment save-update"
            color="primary"
            onClick={openCreateNewAppointmentModal}
          >
            Create New Appointment
          </Button>

          <div className="modal-request-table-wrapper">
            <AppointmentRequests
              tableHeaderPosition="0"
              petId={petId}
              parentId={parentId}
              type={appointmentRequestPageTypes.clientPageModal}
            />
          </div>

          <FooterButtons onClose={handleClose} showSaveButton={false} />
        </div>
      </ModalBody>
    </Modal>
  );
}
