import {
  GET_FACILITY_DATA,
  ACTIVATE_DEACTIVATE_FACILITY,
  INITIATE_POPUP,
  UPDATE_FACILITY_LOGO,
  UPDATE_FACILITY_LOGO_ERROR,
  UPDATE_FACILITY_ICON,
  UPDATE_FACILITY_ICON_ERROR,
  ADD_NEW_FACILITY,
  ADD_NEW_FACILITY_ERROR,
  GET_FACILITY_BY_ID,
  GET_FACILITY_BY_ID_ERROR,
  UPDATE_FACILITY,
  UPDATE_FACILITY_ERROR,
  ACTIVATE_DEACTIVATE_FACILITY_ERROR,
} from '../actions/facilityActions';

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_FACILITY_DATA:
      return {
        ...state,
        facility: action.payload,
        statusUpdated: false,
      };
    case ACTIVATE_DEACTIVATE_FACILITY:
      return {
        ...state,
        statusUpdated: action.payload,
      };
    case ACTIVATE_DEACTIVATE_FACILITY_ERROR:
      return {
        ...state,
        statusUpdated: action.payload,
      };
    case INITIATE_POPUP:
      return {
        ...state,
        logo: null,
        icon: null,
        newFacilityResponse: null,
        newFacilityErrorResponse: null,
        updateFacilityResponse: null,
        updateFacilityErrorResponse: null,
        singleFacilityResponse: null,
      };
    case UPDATE_FACILITY_LOGO:
      return {
        ...state,
        logo: action.payload,
      };
    case UPDATE_FACILITY_LOGO_ERROR:
      return {
        ...state,
        logo: null,
      };
    case UPDATE_FACILITY_ICON:
      return {
        ...state,
        icon: action.payload,
      };
    case UPDATE_FACILITY_ICON_ERROR:
      return {
        ...state,
        icon: null,
      };
    case ADD_NEW_FACILITY:
      return {
        ...state,
        newFacilityResponse: action.payload,
        newFacilityErrorResponse: null,
      };
    case ADD_NEW_FACILITY_ERROR:
      return {
        ...state,
        newFacilityErrorResponse: action.payload,
        newFacilityResponse: null,
      };
    case GET_FACILITY_BY_ID:
      return {
        ...state,
        singleFacilityResponse: action.payload,
        singleFacilityErrorResponse: null,
      };
    case GET_FACILITY_BY_ID_ERROR:
      return {
        ...state,
        singleFacilityErrorResponse: action.payload,
        singleFacilityResponse: null,
      };
    case UPDATE_FACILITY:
      return {
        ...state,
        updateFacilityResponse: action.payload,
        updateFacilityErrorResponse: null,
      };
    case UPDATE_FACILITY_ERROR:
      return {
        ...state,
        updateFacilityErrorResponse: action.payload,
        updateFacilityResponse: null,
      };
    default:
      return state;
  }
}
