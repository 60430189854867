import Config from '../config/app.config';

export const initBeacon = (user) => {
  if (!window?.Beacon?.('info')?.beaconId) {
    window?.Beacon?.('init', Config.LOYALPET_HELP_SCOUT_BEACON_ID);
  }

  window.Beacon('identify', {
    name: `${user.firstName} ${user.lastName}`,
    email: user.email,
    signature: user.helpScoutSignature,
  });
};

export const destroyBeacon = () => {
  window?.Beacon?.('destroy');
};
