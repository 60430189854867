import React from 'react';

/**
 * @type 'success' | 'danger'
 * @message string
 * @returns
 */
export function ResponseMessage({ message = '', type = 'success' }) {
  if (!message) return null;

  return (
    <div className={`alert alert-${type} fade show`} role="alert">
      <div className="alert__content">
        <p>{message}</p>
      </div>
    </div>
  );
}
