import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'reactstrap';

import TableFilterComponent from '../../../../shared/components/form/TableFilterComponent';
import { getAllRefillRequestStatuses } from '../../../../redux/actions/refillRequestActions';
import { getStaffDoctorsByFacility } from '../../../../redux/actions/staffActions';
import { refillRequestDeliveryTypes } from '../../../../config/app.config';
import { SortingField } from '../../../common/SortingCell';
import {
  getDefaultSortOrder,
  getSortFieldName,
} from '../../Common/SortBy/utils';

import {
  tab,
  useRefillRequestModalContext,
  useStatusChangeModalContext,
} from '../utils';
import { StatusDropdown, getFilteredStatuses } from './StatusDropdown';

export function RefillRequestsTable({
  heads,
  rows,
  handleFilter,
  handleSort,
  sortField,
  sortOrder,
  stickyTop,
}) {
  const dispatch = useDispatch();
  const allStatuses = useSelector(
    (state) => state.refillRequests.refillRequestStatuses || [],
  );
  const doctors = useSelector(
    (state) => state.staff.staffDoctorsByFacility || [],
  );
  const facilityId = useSelector((state) => state.user?.profile?.facility?._id);
  const { openRefillRequestModal, handleSetRefillRequestData } =
    useRefillRequestModalContext();
  const { prepareConfirmationMessage } = useStatusChangeModalContext();

  useEffect(() => {
    if (facilityId) {
      dispatch(getAllRefillRequestStatuses({ facilityId }));
      dispatch(getStaffDoctorsByFacility());
    }
  }, [dispatch, facilityId]);

  const handleStatusFilter = (key, list) => {
    const filterField = key === 'doctorFullName' ? 'doctorId' : key;

    handleFilter(filterField, list);
  };

  const renderRowValues = (row) => {
    const dropdownStatuses = getFilteredStatuses(row.deliveryType, allStatuses);

    const statusColumn = (head, currentStatus) => (
      <td key={head.key} onClick={(e) => e.stopPropagation()}>
        <StatusDropdown
          currentStatus={currentStatus}
          statuses={dropdownStatuses}
          onStatusChange={(newStatus) =>
            prepareConfirmationMessage({
              currentStatus,
              newStatus: newStatus.label,
              newStatusId: newStatus._id,
              prescriptionId: row.id,
            })
          }
          className="refill-requests-dropdown"
        />
      </td>
    );

    const attachmentsAndNotesColumns = (head) => {
      const tabToOpen = {
        attachments: tab.ATTACHMENTS,
        internalComments: tab.INTERNAL_COMMENTS,
      };

      const isButtonVisible = {
        attachments: !!row.documents?.length || !!row.photos?.length,
        internalComments: !!row.staffInternalComments?.length,
      };

      return (
        <td
          key={head.key}
          className="custom-no-over-flow-td"
          onClick={(e) => {
            isButtonVisible[head.key] && e.stopPropagation();
          }}
          style={{ textAlign: 'center' }}
        >
          {isButtonVisible[head.key] && (
            <a
              className="view-tab-button full-width"
              onClick={() => {
                openRefillRequestModal(tabToOpen[head.key]);
                handleSetRefillRequestData(row);
              }}
            >
              View
            </a>
          )}
        </td>
      );
    };

    const defaultColumn = (head, value, capitalizeClass) => {
      const columnText =
        value === 'delivery' ? refillRequestDeliveryTypes.delivery : value;

      return (
        <td
          key={head.key}
          title={columnText}
          className={`custom-no-over-flow-td ${capitalizeClass}`}
        >
          {columnText}
        </td>
      );
    };

    return heads.map((head) => {
      const value = row[head.key];
      const capitalizeClass = head.capitalize ? 'text-capitalize' : '';

      if (head.key === 'status' && allStatuses.length !== 0) {
        return statusColumn(head, value);
      }

      if (head.key === 'attachments' || head.key === 'internalComments') {
        return attachmentsAndNotesColumns(head);
      }

      return defaultColumn(head, value, capitalizeClass);
    });
  };

  const renderTableHeaders = () => {
    const renderSortableHeader = (head) => {
      const sortKey = getSortFieldName(head.key);
      const defaultOrder = getDefaultSortOrder(sortKey);

      return (
        <th key={head.key} className={head.className ?? ''}>
          {sortField === sortKey ? (
            <SortingField
              icon={sortOrder === 1 ? 'triangle-up' : 'triangle-down'}
              name={head.name}
              onSort={() => handleSort({ order: sortOrder === 1 ? -1 : 1 })}
            />
          ) : (
            <SortingField
              name={head.name}
              icon="diamond-narrow"
              onSort={() => handleSort({ field: sortKey, order: defaultOrder })}
            />
          )}
        </th>
      );
    };

    const renderFilterableHeader = (head) => (
      <th key={head.key} className={head.className ?? ''}>
        <TableFilterComponent
          allFilters={head.filterValue}
          action={(list) => {
            handleStatusFilter(head.key, list);
          }}
          label={head.name}
        />
      </th>
    );

    const renderIconHeader = (head) => (
      <th key={head.key} className={head.className ?? ''}>
        <img src={head.imgSrc} alt={head.imgAlt} title={head.name} />
      </th>
    );

    const renderDefaultHeader = (head) => (
      <th key={head.key} className={head.className ?? ''}>
        <p>{head.name}</p>
      </th>
    );

    const renderHeader = (head) => {
      if (!head) return null;

      if (head.sortable) {
        return renderSortableHeader(head);
      } else if (head.filterable) {
        if (head.key === 'status') {
          head.filterValue = allStatuses.map(({ label }) => ({
            [label]: label,
          }));
        }
        if (head.key === 'doctorFullName') {
          if (doctors.length === 0) return renderDefaultHeader(head);

          const filterValues = doctors.map(({ _id, name }) => ({
            [_id]: `Dr. ${name}`,
          }));

          filterValues.push({ NA: 'N/A' });

          head.filterValue = filterValues;
        }

        return renderFilterableHeader(head);
      } else if (head.imgSrc) {
        return renderIconHeader(head);
      } else {
        return renderDefaultHeader(head);
      }
    };

    return (
      <tr className="header-tr" style={stickyTop ? { top: stickyTop } : {}}>
        {heads.map((head) => renderHeader(head))}
      </tr>
    );
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <Table striped className="custom-table loyal-table">
          <thead>{renderTableHeaders()}</thead>
          <tbody>
            {rows.map((row, index) => (
              <tr
                className="body-tr refill-request-row"
                key={index}
                onClick={() => {
                  openRefillRequestModal();
                  handleSetRefillRequestData(row);
                }}
              >
                {renderRowValues(row)}
              </tr>
            ))}
          </tbody>
        </Table>

        {rows.length === 0 ? (
          <div className="no-data-message">No Refill Requests</div>
        ) : null}
      </div>
    </div>
  );
}
