import { handleRequest } from '../services/APIService';

export const GET_PHASE_BY_ID = 'GET_PHASE_BY_ID';
export const GET_PHASE_BY_ID_ERROR = 'GET_PHASE_BY_ID_ERROR';
export const GET_PTB_EVENTS = 'GET_PTB_EVENTS';
export const GET_PTB_EVENTS_ERROR = 'GET_PTB_EVENTS_ERROR';
export const CARD_DRAG = 'CARD_DRAG';
export const CARD_DRAG_FAILED = 'CARD_DRAG_FAILED';
export const SAVE_PHASE_MESSAGE = 'SAVE_PHASE_MESSAGE';
export const SAVE_PHASE_MESSAGE_ERROR = 'SAVE_PHASE_MESSAGE_ERROR';
export const DELETE_PHASE_MESSAGE = 'DELETE_PHASE_MESSAGE';
export const DELETE_PHASE_MESSAGE_ERROR = 'DELETE_PHASE_MESSAGE_ERROR';
export const CLEAR_STORE = 'CLEAR_STORE';

export const getByPhase = (data) => (dispatch) => {
  handleRequest('post', 'phase-message/by-phase', true, data)
    .then((results) => {
      return dispatch({
        type: GET_PHASE_BY_ID,
        payload: results.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_PHASE_BY_ID_ERROR,
        payload: error.response,
      });
    });
};

export const getPtbEvents =
  (facilityId, offset, search = '') =>
  (dispatch) => {
    handleRequest('get', `ptb/appointment`, true, null, {
      facilityId,
      offset,
      search,
    })
      .then((results) => {
        return dispatch({
          type: GET_PTB_EVENTS,
          payload: results.data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: GET_PTB_EVENTS_ERROR,
          payload: error,
        });
      });
  };

export const dragCard = (data) => (dispatch) => {
  return handleRequest('post', 'ptb/appointment/drag-card', true, data)
    .then((results) => {
      dispatch({
        type: CARD_DRAG,
        payload: results.data,
      });
      return null;
    })
    .catch((error) => {
      dispatch({
        type: CARD_DRAG_FAILED,
        payload: error.response,
      });
      return error.response.data;
    });
};

export const savePhaseMessage = (data) => (dispatch) => {
  handleRequest('post', 'phase-message', true, data)
    .then((results) => {
      return dispatch({
        type: SAVE_PHASE_MESSAGE,
        payload: results.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: SAVE_PHASE_MESSAGE_ERROR,
        payload: error.response,
      });
    });
};

export const deletePhaseMessage = (data) => (dispatch) => {
  handleRequest('delete', 'phase-message', true, data)
    .then((results) => {
      return dispatch({
        type: DELETE_PHASE_MESSAGE,
        payload: results.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: DELETE_PHASE_MESSAGE_ERROR,
        payload: error.response,
      });
    });
};

export const clearStore = () => (dispatch) => {
  return dispatch({
    type: CLEAR_STORE,
    payload: null,
  });
};
