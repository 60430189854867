import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getFilteredSpecies } from '../../../../redux/actions/breedAction';
import PetItem from './PetItem';

const UpdatePets = ({ profile, inputChangeStatus, parentId }) => {
  const [searchParams] = useSearchParams();
  const petId = searchParams.get('pet');

  const [speciesList, setSpeciesList] = useState([]);

  useEffect(() => {
    async function getSpecies() {
      const species = await getFilteredSpecies();

      setSpeciesList(
        species.map((data) => ({
          _id: data._id,
          name: data.name,
        })),
      );
    }

    getSpecies();
  }, []);

  const pets =
    petId != null
      ? profile.pets?.filter((pet) => pet._id === petId)
      : profile.pets;

  return (
    <div className="pet-update-card-wrapper">
      {pets?.length > 0 ? (
        pets.map((pet) => (
          <PetItem
            pet={pet}
            key={pet._id}
            parentId={parentId}
            inputChangeStatus={inputChangeStatus}
            speciesList={speciesList}
          />
        ))
      ) : (
        <div className="no-pets">No Pets</div>
      )}
    </div>
  );
};

export default UpdatePets;
