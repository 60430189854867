import React, { Component } from 'react';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import { Col } from 'reactstrap';
import ModalImage from 'react-modal-image';
import config from '../../../config/app.config';
import { getPetProfile } from '../../../redux/actions/petActions';
import humanize from 'underscore.string/humanize';
import withRouter from '../../../shared/components/withRouter';

const defautAvatar = `${window.location.origin}/img/defaultAvatar.png`;

class PetProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      isloaded: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.props.getPetProfile(this.props.params.petId);
    this.setState({
      isloaded: false,
    });
  }

  UNSAFE_componentWillReceiveProps(np) {
    if (np.pets && np.pets.petProfileData) {
      this.setState({
        data: np.pets.petProfileData,
        isloaded: true,
      });
    }
  }

  render() {
    const { data, isloaded } = this.state;
    const additionalDetailsData =
      data && data.additionalDetails ? data.additionalDetails : [];
    const additionalDetails = additionalDetailsData.map((details) => (
      <div className="additional-details clearfix" key={details.name}>
        <div className="description-wrapper">
          <h5 className="title">{details.name}</h5>
          <p className="description">{details.description}</p>
        </div>
        <div className="images-wrapper">
          {details.images.map((image) => (
            <ModalImage
              key={image}
              className="image"
              small={`${config.PET_PROFILE_ADDITIONAL_DATA_IMAGE_PATH}${image}`}
              large={`${config.PET_PROFILE_ADDITIONAL_DATA_IMAGE_PATH}${image}`}
            />
          ))}
        </div>
      </div>
    ));

    const avatarImg =
      data.image !== null
        ? `${config.PET_PROFILE_PICTURE_PATH}${data.image}`
        : defautAvatar;

    return (
      isloaded && (
        <div className="pet-profile-wrapper">
          <div className="container">
            <div className="row">
              <Col md={12} lg={12}>
                <h1 className="title text-center">Pet Profile : {data.name}</h1>
              </Col>
            </div>
            <div className="row">
              <Col md={12} lg={12}>
                <div className="pet-details d-xs-none">
                  <div className="row">
                    <Col md={3}>
                      <div className="profile-picture-wrapper">
                        <img src={avatarImg} alt={'Avatar img'} />
                      </div>
                    </Col>
                    <Col xs={12} className="d-block d-sm-none">
                      <div className="content top clearfix">
                        <h5 className="content-label">Pet Name</h5>
                        <p className="content-value">
                          {data.name ? data.name : 'N/A'}
                        </p>
                      </div>
                      <div className="content top clearfix">
                        <h5 className="content-label">Birthday</h5>
                        <p className="content-value">
                          {data.birthday ? (
                            <Moment format="MM/DD/YYYY">{data.birthday}</Moment>
                          ) : (
                            'N/A'
                          )}
                        </p>
                      </div>
                      <div className="content bottom clearfix">
                        <h5 className="content-label">Species</h5>
                        <p className="content-value">
                          {data.petSpeciesName ? data.petSpeciesName : 'N/A'}
                        </p>
                      </div>
                      <div className="content bottom clearfix">
                        <h5 className="content-label">Breed</h5>
                        <p className="content-value">
                          {data.petBreedName ? data.petBreedName : 'N/A'}
                        </p>
                      </div>
                      <div className="content bottom clearfix">
                        <h5 className="content-label">Gender</h5>
                        <p className="content-value">
                          {data.gender ? humanize(data.gender) : 'N/A'}
                        </p>
                      </div>
                    </Col>
                    <Col md={3} className="d-none d-sm-block">
                      <div className="content top clearfix">
                        <h5 className="content-label">Pet Name</h5>
                        <p className="content-value">
                          {data.name ? data.name : 'N/A'}
                        </p>
                      </div>
                      <div className="content bottom clearfix">
                        <h5 className="content-label">Species</h5>
                        <p className="content-value">
                          {data.petSpeciesName ? data.petSpeciesName : 'N/A'}
                        </p>
                      </div>
                    </Col>
                    <Col md={3} className="d-none d-sm-block">
                      <div className="content bottom clearfix">
                        <h5 className="content-label">Breed</h5>
                        <p className="content-value">
                          {data.petBreedName ? data.petBreedName : 'N/A'}
                        </p>
                      </div>
                    </Col>
                    <Col md={3} className="d-none d-sm-block">
                      <div className="content top clearfix">
                        <h5 className="content-label">Birthday</h5>
                        <p className="content-value">
                          {data.birthday ? (
                            <Moment format="MM/DD/YYYY">{data.birthday}</Moment>
                          ) : (
                            'N/A'
                          )}
                        </p>
                      </div>
                      <div className="content bottom clearfix">
                        <h5 className="content-label">Gender</h5>
                        <p className="content-value">
                          {data.gender ? humanize(data.gender) : 'N/A'}
                        </p>
                      </div>
                    </Col>
                  </div>
                </div>
              </Col>
            </div>
            <div className="row">
              <Col md={12} lg={12}>
                <div className="additional-details-wrapper">
                  <h4 className="title">Additional Details</h4>
                  {additionalDetails}
                </div>
              </Col>
            </div>
          </div>
        </div>
      )
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = {
  getPetProfile,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PetProfile),
);
