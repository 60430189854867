import React, { Component } from 'react';
import { connect } from 'react-redux';
import { subscribe } from '../../../redux/actions/appointmentAction';
import CommonHelper from '../../../libs/commonHelper';

const icon = `${process.env.PUBLIC_URL}/img/loyal-logo.png`;

export class Subscribe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: null,
      pet: {
        name: '',
      },
      showResponse: false,
    };
  }

  componentDidMount() {
    const token = CommonHelper.getParameterByName('token');
    this.props.subscribe(token);
    this.setState({ token });
  }

  UNSAFE_componentWillReceiveProps(np) {
    if (
      np.appointments &&
      np.appointments.subscribe &&
      np.appointments.subscribeError === null
    ) {
      this.setState({ pet: np.appointments.subscribe.pet, showResponse: true });
    }
  }

  render() {
    const { pet, showResponse } = this.state;

    return (
      <div className="account user-flow unsubscribe">
        <div className="account__wrapper">
          <div className="account__card">
            <div className="account__card--inner">
              <div className="account__head">
                <h2 className="account__title">
                  <span className="account__logo">
                    <img src={icon} alt="" />
                  </span>
                </h2>
              </div>
              {showResponse ? (
                <div className="body-wrapper">
                  <h3 className="subheading"> Subscribed Successful </h3>

                  <div className="p-first">
                    You have successfully re-subscribed to {pet.name}`s
                    procedure updates.
                  </div>

                  <div className="your">- Your friends at Loyal</div>
                </div>
              ) : (
                <div className="body-wrapper">
                  <h3 className="subheading"> Subscribed Failed </h3>
                  <div className="p-first">
                    You have already subscribed or the token is invalid.
                  </div>
                  <div className="your">- Your friends at Loyal</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = {
  subscribe,
};

export default connect(mapStateToProps, mapDispatchToProps)(Subscribe);
