import React, { Component } from 'react';
import {
  Button,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { connect } from 'react-redux';
import { STATUS } from '../../constants/constants';

class ActivateModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tempSelectedFilters: [],
      isAllSelected: false,
      allFacilities: [],
      successResponse: null,
      errorResponse: null,
    };
  }

  componentDidMount() {
    this.setState({
      allFacilities: this.props.allFacilities,
      tempSelectedFilters: [],
      isAllSelected: false,
      successResponse: null,
      errorResponse: null,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.facilities) {
      this.setState({
        allFacilities: nextProps.facilities,
        tempSelectedFilters: [],
        isAllSelected: false,
        successResponse: null,
        errorResponse: null,
      });
    }
    if (nextProps.successResponse) {
      this.setState({
        successResponse: nextProps.successResponse,
      });
    }
    if (nextProps.errorResponse) {
      this.setState({
        errorResponse: nextProps.errorResponse,
      });
    }
  }

  UNSAFE_componentWillMount() {
    document.addEventListener('mousedown', this.handleOutsideClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick, false);
  }

  handleSelectAll = () => {
    const { allFacilities, isAllSelected } = this.state;

    const selectedStates = isAllSelected
      ? []
      : allFacilities.map((filter) => filter._id);
    this.setState({
      isAllSelected: !isAllSelected,
      tempSelectedFilters: selectedStates,
      errorResponse: null,
    });
  };

  handleSelectState = (state) => {
    const { tempSelectedFilters, isAllSelected, allFacilities } = this.state;
    const selectedIndex = tempSelectedFilters.indexOf(state);

    if (this.props.isReInvite) {
      this.setState({
        tempSelectedFilters: [state],
        errorResponse: null,
      });
      return;
    }

    if (selectedIndex === -1) {
      tempSelectedFilters.push(state);
    } else {
      tempSelectedFilters.splice(selectedIndex, 1);
      if (isAllSelected) {
        this.handleSelectAll();
      }
    }

    if (allFacilities.length === tempSelectedFilters.length) {
      this.handleSelectAll();
    }

    this.setState({
      tempSelectedFilters,
      errorResponse: null,
    });
  };

  isStateSelected = (state) => {
    const { tempSelectedFilters } = this.state;
    return tempSelectedFilters.indexOf(state) !== -1;
  };

  closeModal = () => {
    this.setState({ tempSelectedFilters: [], isAllSelected: false }, () =>
      this.props.closeActivatePopup(),
    );
  };

  saveActive = () => {
    const { tempSelectedFilters } = this.state;
    if (tempSelectedFilters.length === 0) {
      this.setState({ errorResponse: 'Please select a facility' });
    } else {
      this.props.saveFacility(tempSelectedFilters, this.props.isReInvite);
    }
  };

  generateText = (elementType) => {
    const { isReInvite, status } = this.props;

    switch (elementType) {
      case 'header':
        if (isReInvite) {
          return 'Reinvite Client to Facility';
        } else if (status === STATUS.ACTIVE) {
          return 'Deactivate Client from Facility';
        } else {
          return 'Activate Client for Facility';
        }
      case 'button':
        if (isReInvite) {
          return 'Reinvite';
        } else if (status === STATUS.ACTIVE) {
          return 'Deactivate';
        } else {
          return 'Reactivate';
        }
      default:
        return '';
    }
  };

  render() {
    const { show, isReInvite } = this.props;
    const { allFacilities, errorResponse, successResponse, isAllSelected } =
      this.state;

    const facilityList =
      allFacilities &&
      allFacilities.map((facility, key) => {
        return (
          <div className="states-block" key={key}>
            <input
              type="checkbox"
              className="checkbox-input"
              id={facility._id}
              checked={this.isStateSelected(facility._id)}
              onChange={() => {
                this.handleSelectState(facility._id);
              }}
            />
            <label
              htmlFor={facility._id}
              className="checkbox-label"
              title={facility.displayName}
            >
              {facility.displayName}
            </label>
          </div>
        );
      });

    const noFacilitiesText = (
      <div className="no-facility-text">No facilities to display</div>
    );

    const selectAllCheckbox = (
      <div className="states-block">
        <input
          type="checkbox"
          className="checkbox-input"
          checked={isAllSelected}
          id="select_all"
          onChange={this.handleSelectAll}
        />
        <label htmlFor="select_all" className="checkbox-label select-all">
          All
        </label>
      </div>
    );

    return (
      <Modal
        isOpen={show}
        size="lg"
        backdrop="static"
        className="model-form modal-dialog-client"
      >
        <ModalHeader className="header-form">
          {this.generateText('header')}
        </ModalHeader>
        <ModalBody>
          <Card>
            <CardBody>
              {successResponse !== null && (
                <div className="alert alert-success fade show" role="alert">
                  <div className="alert__content">
                    <p>{successResponse}</p>
                  </div>
                </div>
              )}
              {errorResponse !== null && (
                <div className="alert alert-danger fade show" role="alert">
                  <div className="alert__content">
                    <p>{errorResponse}</p>
                  </div>
                </div>
              )}
              <form className="form " autoComplete="off">
                <div
                  className="custom-select-box-wrapper clearfix"
                  ref={(node) => (this.node = node)}
                >
                  <div className="states-result">
                    <div
                      style={{ width: '100%', height: 200, overflowY: 'auto' }}
                    >
                      <div className="blocks-wrapper">
                        {facilityList && facilityList.length > 0 ? (
                          <>
                            {!isReInvite && selectAllCheckbox}
                            {facilityList}
                          </>
                        ) : (
                          noFacilitiesText
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4"></div>
                  <div className="col-md-8 text-right">
                    <Button
                      className="clear"
                      color="secondary"
                      onClick={this.closeModal}
                    >
                      Cancel
                    </Button>
                    {facilityList && facilityList.length > 0 && (
                      <Button
                        className="save-update"
                        color="primary"
                        onClick={this.saveActive}
                      >
                        {this.generateText('button')}
                      </Button>
                    )}
                  </div>
                </div>
              </form>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ActivateModal);
