import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash.debounce';

import InputWithSearchIcon from '../../common/InputWithSearchIcon';
import SortBy from '../Common/SortBy';
import FilterPills from '../Common/FilterPills';
import TimeFilter from '../Common/TimeFilter';
import ToggleSwitch from '../Common/ToggleSwitch';
import {
  appointmentRequestPageTypes,
  appointmentRequestStatus,
  DEFAULT_PAGINATION_LENGTH,
} from '../../../config/app.config';
import { formatDate } from '../../../helpers/formatDate';

import { toggleAppointmentRequestsModalClass } from '../ParentProfile/utils/toggleAppointmentRequestsModalClass';
import PaginationComponent from '../../../shared/components/pagination/Pagination';
import MessageModal from '../../../shared/components/Modals/MessageModal';
import HeadingText from '../../../shared/components/form/HeadingText';
import withRouter from '../../../shared/components/withRouter';

import { toggleLoading } from '../../../redux/actions/commonActions';
import {
  getAllAppointmentRequests,
  resetAppointmentRequests,
  setStatusToggleState,
} from '../../../redux/actions/appointmentRequestActions';
import { setPetAppointmentRequestsNumber } from '../../../redux/actions/petActions';

import {
  AppointmentRequestsTable,
  appointmentComponentsWrapperMap,
} from './Components';
import { AppointmentRequestModal } from './RequestDetails';
import {
  AppointmentRequestModalProvider,
  getAppointmentRequestsHeaders,
  SuccessModalProvider,
} from './utils';

const { pending, completed } = appointmentRequestStatus;

const initialParams = {
  search: '',
  timeFilter: null,
  sortOrder: -1,
  sortField: 'createdAt',
  offset: 0,
  limit: DEFAULT_PAGINATION_LENGTH,
  status: [pending],
  filters: {},
  range: {},
};

function reducer(params, action) {
  switch (action.type) {
    case 'SET_SEARCH':
      return { ...params, search: action.payload, offset: 0 };
    case 'SET_TIME_FILTER':
      return {
        ...params,
        timeFilter: action.payload,
        offset: action.payload === 'range' ? params.offset : 0,
      };
    case 'SET_SORT': {
      const { field, order } = action.payload;
      return {
        ...params,
        sortField: field ?? params.sortField,
        sortOrder: order ?? params.sortOrder,
        offset: 0,
      };
    }
    case 'SET_FILTER':
      return {
        ...params,
        filters: {
          ...params.filters,
          [action.payload.type]: action.payload.filters,
        },
        offset: 0,
      };
    case 'TOGGLE_STATUS': {
      const newStatus = action.payload;
      return { ...params, status: newStatus, offset: 0 };
    }
    case 'SET_RANGE':
      return { ...params, range: action.payload, offset: 0 };
    case 'SET_LIMIT':
      return { ...params, limit: action.payload, offset: 0 };
    case 'SET_OFFSET':
      return { ...params, offset: action.payload };
    case 'CLEAR_TIME_FILTER_AND_RANGE':
      return {
        ...params,
        timeFilter: initialParams.timeFilter,
        range: initialParams.range,
      };
    case 'CLEAR_ALL_FILTERS':
      return {
        ...params,
        timeFilter: initialParams.timeFilter,
        filters: initialParams.filters,
        range: initialParams.range,
        offset: 0,
      };
    case 'REMOVE_FILTER': {
      const { filterKey, filterToBeRemoved } = action.payload;
      const newFilters = {
        ...params.filters,
        [filterKey]: params.filters[filterKey].filter(
          (value) => value !== filterToBeRemoved,
        ),
      };
      return { ...params, filters: newFilters, offset: 0 };
    }
    default:
      return params;
  }
}

function AppointmentRequests({
  type = appointmentRequestPageTypes.default,
  petId,
  parentId,
  tableHeaderPosition = '60px',
}) {
  const searchRef = useRef();
  const dispatch = useDispatch();
  const isDefaultPage = type === appointmentRequestPageTypes.default;

  const { appointmentRequestsList, statusToggleState } = useSelector(
    (state) => state.appointmentRequests,
  );
  const doctors = useSelector(
    (state) => state.staff.staffDoctorsByFacility || [],
  );
  const { isPetAppointmentRequestsModalOpen, petsAppointmentRequestsNumber } =
    useSelector((state) => state.pets);
  const sortOptions = getAppointmentRequestsHeaders().filter(
    (header) => !!header.sortable,
  );

  const [params, localDispatch] = useReducer(reducer, {
    ...initialParams,
    status: statusToggleState[type] || initialParams.status,
  });
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const getAppointmentRequests = useCallback(async () => {
    const formattedStartDate = formatDate(params.range.startDate);
    const formattedEndDate = formatDate(params.range.endDate);

    const { type, doctorId } = params.filters;

    await dispatch(
      getAllAppointmentRequests({
        sort: {
          field: params.sortField,
          order: +params.sortOrder,
        },
        search: params.search,
        doctorId: doctorId?.length > 0 ? doctorId : null,
        type: type?.length > 0 ? type : null,
        status: params.status?.length > 0 ? params.status : null,
        parentId,
        petId,
        timeFilter: {
          type: params.timeFilter,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        },
        limit: params.limit,
        offset: params.offset,
      }),
    );
  }, [params, parentId, petId, dispatch]);

  // fetch appointment requests for the first render
  useEffect(() => {
    const fetchAppointmentRequests = async () => {
      dispatch(toggleLoading(true));
      await getAppointmentRequests();
      dispatch(toggleLoading(false));
    };

    fetchAppointmentRequests();
  }, [getAppointmentRequests, dispatch]);

  // update the whole table after fetch of appointment requests
  useEffect(() => {
    setRows(appointmentRequestsList?.data || []);
    setTotal(appointmentRequestsList?.total || 0);

    if (type === appointmentRequestPageTypes.clientPageModal) {
      dispatch(
        setPetAppointmentRequestsNumber({
          [petId]: appointmentRequestsList?.total ?? 0,
        }),
      );
    }
  }, [appointmentRequestsList, type, petId, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetAppointmentRequests());
    };
  }, [dispatch]);

  const handleTimeFilterChange = (timeFilter) => {
    localDispatch({ type: 'SET_TIME_FILTER', payload: timeFilter });
  };

  const handleSort = ({ field, order }) => {
    localDispatch({ type: 'SET_SORT', payload: { field, order } });
  };

  const handleFilter = (type, filters) => {
    localDispatch({ type: 'SET_FILTER', payload: { type, filters } });
  };

  const handleStatusToggle = (addedStatus) => {
    const newStatus = params.status.includes(addedStatus)
      ? params.status.filter((s) => s !== addedStatus)
      : [...params.status, addedStatus];

    localDispatch({ type: 'TOGGLE_STATUS', payload: newStatus });

    // save the status filter to Redux only for Appointment Requests Page
    if (isDefaultPage) dispatch(setStatusToggleState(type, newStatus));
  };

  const handleDateRangeChange = (range) => {
    localDispatch({ type: 'SET_RANGE', payload: range });
  };

  const handlePageLimit = (newLimit) => {
    localDispatch({ type: 'SET_LIMIT', payload: newLimit });
  };

  const debouncedSearch = debounce((e) => {
    localDispatch({ type: 'SET_SEARCH', payload: e.target.value });
  }, 600);

  const handleClearSearch = () => {
    localDispatch({ type: 'SET_SEARCH', payload: '' });
  };

  const handleClearAllFilters = () => {
    localDispatch({ type: 'CLEAR_ALL_FILTERS' });
  };

  const handleRemoveFilter = (filterKey, filterToBeRemoved) => {
    if (filterKey === 'timeFilter') {
      localDispatch({ type: 'CLEAR_TIME_FILTER_AND_RANGE' });
    } else {
      localDispatch({
        type: 'REMOVE_FILTER',
        payload: { filterKey, filterToBeRemoved },
      });
    }
  };

  const handleToggleSuccessModal = async (type) => {
    switch (type) {
      case 'open': {
        setShowSuccessModal(true);

        // update Appointment Requests when Appointment was created
        await getAppointmentRequests();
        break;
      }
      case 'close': {
        setShowSuccessModal(false);

        // if it's the Clients page and the Pet tab then show Pet Appointment Requests modal
        if (
          petsAppointmentRequestsNumber &&
          isPetAppointmentRequestsModalOpen
        ) {
          toggleAppointmentRequestsModalClass({
            action: 'remove',
          });
        }

        break;
      }
      default: {
        break;
      }
    }
  };

  const Wrapper = appointmentComponentsWrapperMap[type];

  const ToggleSwitchesColumn = () => (
    <Col className="toggle-switches-column">
      <div className="toggle-switches-block">
        <ToggleSwitch
          label="Pending Requests"
          checked={params.status.includes(pending)}
          handleToggle={() => handleStatusToggle(pending)}
        />
        <ToggleSwitch
          label="Completed Requests"
          checked={params.status.includes(completed)}
          handleToggle={() => handleStatusToggle(completed)}
        />
      </div>
    </Col>
  );

  return (
    <AppointmentRequestModalProvider>
      <SuccessModalProvider handleToggleSuccessModal={handleToggleSuccessModal}>
        <Col
          md={12}
          lg={12}
          className="manage-patient-container admin-container"
        >
          <Wrapper>
            {isDefaultPage && <HeadingText text="Appointment Requests" />}
            <Row className="search-field-and-date-filter">
              {isDefaultPage && (
                <Col className="form search-column">
                  <div
                    className="form__form-group search"
                    style={{ marginBottom: 'unset' }}
                  >
                    <InputWithSearchIcon
                      name="search"
                      id="search"
                      type="text"
                      ref={searchRef}
                      viewProfile={() => {}}
                      onChange={(e) => debouncedSearch(e)}
                      placeholder="Search by Doctor Name, Parent Name, Pet Name"
                      onClear={handleClearSearch}
                      autofocus
                    />
                  </div>
                </Col>
              )}
              <Col className="date-filter-column">
                <TimeFilter
                  title="Filter By Request Date:"
                  changeFilter={handleTimeFilterChange}
                  selected={params.timeFilter}
                  setDateRange={handleDateRangeChange}
                  placeholder="Select Day Or Date Range"
                  filterList={['today', 'last 7 days']}
                  showClearButton={!isDefaultPage}
                />
              </Col>
              {!isDefaultPage && <ToggleSwitchesColumn />}
            </Row>
            {isDefaultPage && (
              <Row className="sort-filter-row">
                <Col className="sort-column">
                  <SortBy
                    options={sortOptions}
                    sortField={params.sortField}
                    sortOrder={params.sortOrder}
                    handleSort={handleSort}
                  />
                </Col>
                <Col>
                  <FilterPills
                    filters={{
                      ...params.filters,
                      timeFilter: params.timeFilter
                        ? [
                            {
                              timeFilter: params.timeFilter,
                              range: {
                                startDate: formatDate(params.range.startDate),
                                endDate: formatDate(params.range.endDate),
                              },
                            },
                          ]
                        : [],
                    }}
                    entityLookup={{ doctors }}
                    onRemove={handleRemoveFilter}
                    onClearAll={handleClearAllFilters}
                  />
                </Col>
                <ToggleSwitchesColumn />
              </Row>
            )}
            <AppointmentRequestsTable
              stickyTop={tableHeaderPosition}
              heads={getAppointmentRequestsHeaders(type)}
              rows={rows}
              sortField={params.sortField}
              sortOrder={params.sortOrder}
              filters={params.filters}
              handleSort={handleSort}
              handleFilter={handleFilter}
            />
            {isDefaultPage && (
              <PaginationComponent
                handlePageLimit={handlePageLimit}
                nextPage={() =>
                  localDispatch({
                    type: 'SET_OFFSET',
                    payload: params.offset + params.limit,
                  })
                }
                prevPage={() =>
                  localDispatch({
                    type: 'SET_OFFSET',
                    payload: params.offset - params.limit,
                  })
                }
                gotoPage={(page) =>
                  localDispatch({
                    type: 'SET_OFFSET',
                    payload: params.limit * page,
                  })
                }
                limit={params.limit}
                offset={params.offset}
                total={total}
              />
            )}
          </Wrapper>
        </Col>
        <MessageModal
          show={showSuccessModal}
          type="ok"
          responseAlertType="danger"
          message="Appointment is successfully created."
          onClose={() => handleToggleSuccessModal('close')}
        />
        <AppointmentRequestModal />
      </SuccessModalProvider>
    </AppointmentRequestModalProvider>
  );
}

export default withRouter(AppointmentRequests);
