import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'reactstrap';
import TimePicker from 'rc-time-picker';
import Select from 'react-select';

import Error from '../../../../shared/components/form/Error';
import DatePickerComponent from '../../Common/DatePickerComponent';
import { createAppointment } from '../../../../redux/actions/appointmentAction';
import Config from '../../../../config/app.config';

import {
  useAppointmentRequestModalContext,
  useSuccessModalContext,
} from '../utils';

// Create Appointment Block
const initialAppointmentState = {
  appointmentDate: '',
  time: '',
  doctor: '',
  parentId: '',
  pet: '',
  type: '',
};

function appointmentDetailsReducer(state, action) {
  switch (action.type) {
    case 'SET_APPOINTMENT_DATE':
      return { ...state, appointmentDate: action.payload };
    case 'SET_TIME':
      return { ...state, time: action.payload };
    case 'SET_TYPE':
      return { ...state, type: action.payload };
    case 'SET_DOCTOR':
      return { ...state, doctor: action.payload };
    case 'SET_APPOINTMENT_REQUEST_DATA':
      return {
        ...state,
        doctor: action.payload.doctorId || null,
        parentId: action.payload.parentId || '',
        pet: action.payload.petId || '',
        type: action.payload.type || '',
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

const appointmentTypes = Object.values(Config.appointmentTypes).map((type) => ({
  value: type,
  label: type,
}));

const FieldWrapper = ({
  label,
  gridArea,
  isRequired = false,
  error,
  children,
}) => (
  <div className="modal-create-appointment-field" style={{ gridArea }}>
    <p className="field-label">
      {label}
      {isRequired && <span className="required">*</span>}
    </p>
    {children}
    {error && <Error text={error} />}
  </div>
);

export function CreateAppointmentBlock({
  appointmentRequestData,
  doctors,
  defaultDoctor,
  defaultAppointmentType,
}) {
  const dispatch = useDispatch();
  const [appointmentDate, setAppointmentDate] = useState(null);
  const [appointmentTime, setAppointmentTime] = useState(null);
  const [appointmentType, setAppointmentType] = useState(
    defaultAppointmentType,
  );
  const [selectedDoctor, setSelectedDoctor] = useState(defaultDoctor);
  const [errors, setErrors] = useState({});
  const { closeAppointmentRequestModal } = useAppointmentRequestModalContext();
  const { temporaryShowSuccessModal } = useSuccessModalContext();

  const [appointmentDetails, dispatchAppointmentDetails] = useReducer(
    appointmentDetailsReducer,
    initialAppointmentState,
  );

  useEffect(() => {
    if (appointmentRequestData) {
      dispatchAppointmentDetails({
        type: 'SET_APPOINTMENT_REQUEST_DATA',
        payload: appointmentRequestData,
      });
    }
  }, [appointmentRequestData]);

  const handleSetAppointmentDetails = useCallback((type, value) => {
    dispatchAppointmentDetails({ type, payload: value });
  }, []);

  const handleDateChange = (date) => {
    setAppointmentDate(date);
    setErrors((prevErrors) => ({ ...prevErrors, appointmentDate: '' }));
    handleSetAppointmentDetails('SET_APPOINTMENT_DATE', date);
  };

  const handleTimeChange = (time) => {
    setAppointmentTime(time);
    setErrors((prevErrors) => ({ ...prevErrors, appointmentTime: '' }));
    handleSetAppointmentDetails('SET_TIME', time.format('hh:mm A'));
  };

  const handleTypeChange = (typeData) => {
    setAppointmentType([typeData]);
    handleSetAppointmentDetails('SET_TYPE', typeData.value);
  };

  const handleDoctorChange = (doctorData) => {
    setSelectedDoctor([doctorData]);
    handleSetAppointmentDetails(
      'SET_DOCTOR',
      doctorData.value ? doctorData.value : null,
    );
  };

  const handleSubmit = async () => {
    const newErrors = {};

    if (!appointmentDate) {
      newErrors.appointmentDate = 'Date is a required field';
    }
    if (!appointmentTime) {
      newErrors.appointmentTime = 'Time is a required field';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    closeAppointmentRequestModal();

    await dispatch(
      createAppointment({
        ...appointmentDetails,
        appointmentRequestId: appointmentRequestData?.id,
      }),
    );

    temporaryShowSuccessModal();
  };

  return (
    <div className="modal-create-appointment-wrapper">
      <h3>Create Appointment</h3>
      <div className="modal-create-appointment-block">
        <FieldWrapper
          label="Appointment Date"
          gridArea="date"
          isRequired={true}
          error={errors.appointmentDate}
        >
          <DatePickerComponent
            minDate
            maxDate={false}
            viewMode={false}
            date={appointmentDate}
            handleChange={handleDateChange}
          />
        </FieldWrapper>
        <FieldWrapper
          label="Appointment Time"
          gridArea="time"
          isRequired={true}
          error={errors.appointmentTime}
        >
          <TimePicker
            showSecond={false}
            onChange={handleTimeChange}
            format="h:mm A"
            value={appointmentTime}
            placeholder="HH:MM AM"
            name="appointmentTime"
            inputReadOnly
            use12Hours
            minuteStep={15}
          />
        </FieldWrapper>
        <FieldWrapper label="Doctor" gridArea="doctor">
          <Select
            value={doctors.filter(
              (option) => option.value === selectedDoctor[0]?.value,
            )}
            onChange={handleDoctorChange}
            name="selectedDoctor"
            matchProp="_id"
            disabled={false}
            classNamePrefix="doctors-select"
            className="custom-select-control doctors-select"
            options={doctors}
            placeholder="Select Doctor"
          />
        </FieldWrapper>
        <FieldWrapper label="Appointment Type" gridArea="type">
          <Select
            value={appointmentType}
            onChange={handleTypeChange}
            name="selectedAppointmentType"
            matchProp="_id"
            disabled={false}
            classNamePrefix="appointment-type-select"
            className="custom-select-control appointment-type-select"
            options={appointmentTypes}
            placeholder="Select Type"
          />
        </FieldWrapper>
        <Button
          className="save-update"
          style={{ gridArea: 'button' }}
          color="primary"
          onClick={handleSubmit}
        >
          Create Appointment
        </Button>
      </div>
    </div>
  );
}
