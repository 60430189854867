import React, { Component } from 'react';
import {
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import { connect } from 'react-redux';
import {
  updateLogo,
  initiatePopups,
  getFacilityById,
  updateFacility,
} from '../../redux/actions/facilityActions';
import { toggleLoading } from '../../redux/actions/commonActions';
import config from '../../config/app.config';
import { validate, reset } from '../../libs/validationHelper';
import {
  defaultFacilityErrors,
  defaultFacilityInputs,
  tabs,
  validationRules,
} from './constants';
import { UploadLogoIcon } from './components/UploadLogoIcon';
import { AddFacilityErrorsView } from './components/AddFacilityErrors';
import {
  FacilityBasicDetails,
  FacilityBillingInfo,
  FacilityContactDetails,
  FacilityPhysicalAddress,
} from './components/FacilityFormParts';

const updateFacilityErrors = {
  ...defaultFacilityErrors,
  email: { tab: 5, error: null, display: ' email' },
};

class UpdateFacility extends Component {
  constructor() {
    super();

    this.state = {
      modal: false,
      activeTab: '1',
      fromProfile: false,
      inputs: { ...defaultFacilityInputs },
      error: false,
      errors: { ...updateFacilityErrors },
      successResponse: null,
      errorResponse: null,
      refreshState: true,
      selectedId: null,
      updateInputsSet: false,
    };
  }

  onChangeFields = (e) => {
    const property = e.target.name;
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    let { inputs, refreshState } = this.state;
    refreshState = !refreshState;
    inputs[property] = value;
    if (inputs.billingSameAddress) {
      inputs.billingAddress = inputs.address;
      inputs.billingCity = inputs.city;
      inputs.billingState = inputs.state;
      inputs.billingZipCode = inputs.zipCode;
    }

    this.setState({ inputs, refreshState });
  };

  onSubmit = async () => {
    const { inputs, selectedId } = this.state;
    if (this.validate(inputs)) {
      return;
    }
    this.props.toggleLoading(true);

    const [logo, logoIcon] = await Promise.all([
      inputs.logo && !inputs.logo.includes('https://')
        ? this.props.updateLogo(inputs.logo, 'logo')
        : inputs.logo,
      inputs.logoIcon && !inputs.logoIcon.includes('https://')
        ? this.props.updateLogo(inputs.logoIcon, 'logoIcon')
        : inputs.logoIcon,
    ]);
    const facility = { ...inputs, logo, logoIcon };
    this.setState({
      inputs: facility,
    });

    this.props.updateFacility(facility, selectedId);
  };

  closeModal = () => {
    this.setState(
      {
        inputs: { ...defaultFacilityInputs },
        selectedId: null,
        refreshState: !this.state.refreshState,
      },
      () => {
        this.props.initiatePopups();
        this.onClose();
      },
    );
  };

  validate = (inputs) => {
    const { refreshState } = this.state;
    let { errors, activeTab } = this.state;

    errors = { ...updateFacilityErrors };
    const validationResponse = validate(validationRules, inputs, errors);

    const error = validationResponse.error;
    errors = validationResponse.errors;

    const keys = Object.keys(errors);
    let errorTab = null;
    keys.forEach((inputKey) => {
      if (errors[inputKey].error !== null && errorTab === null) {
        errorTab = errors[inputKey].tab;
      }
    });
    activeTab = errorTab ? errorTab.toString() : activeTab;
    this.setState({
      errors,
      error,
      activeTab,
      errorResponse: null,
      successResponse: null,
      refreshState: !refreshState,
    });
    return error;
  };

  onClose() {
    let { errors } = this.state;
    const error = false;
    errors = reset(errors);
    this.setState({
      modal: false,
      error,
      errors,
      successResponse: null,
      errorResponse: null,
      activeTab: '1',
    });

    this.props.closeFacilityPopup();
  }

  changeTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  getAutoCompleteAddress = ({ address, city, state, zipCode, placeId }) => {
    const { inputs, refreshState } = this.state;

    inputs.address = address || inputs.address;
    inputs.city = city || inputs.city;
    inputs.state = state || inputs.state;
    inputs.zipCode = zipCode || inputs.zipCode;
    inputs.placeId = placeId || inputs.placeId;

    if (inputs.billingSameAddress) {
      inputs.billingAddress = inputs.address;
      inputs.billingCity = inputs.city;
      inputs.billingState = inputs.state;
      inputs.billingZipCode = inputs.zipCode;
    }
    this.setState({ inputs, refreshState: !refreshState });
  };

  getAutoCompleteBillingAddress = ({ address, city, state, zipCode }) => {
    const { inputs, refreshState } = this.state;

    inputs.billingAddress = address || inputs.billingAddress;
    inputs.billingCity = city || inputs.billingCity;
    inputs.billingState = state || inputs.billingState;
    inputs.billingZipCode = zipCode || inputs.billingZipCode;

    this.setState({ inputs, refreshState: !refreshState });
  };

  setToInputs = (response) => {
    const { inputs, refreshState } = this.state;
    inputs.logo = response.logo || '';
    inputs.logoIcon = response.logoIcon || '';
    inputs.name = response.name || '';
    inputs.displayName = response.displayName || '';
    inputs.abbreviation = response.abbreviation || '';
    inputs.groupAffiliation = response.groupAffiliation || '';
    inputs.address = response.address || '';
    inputs.city = response.city || '';
    inputs.state = response.state || '';
    inputs.zipCode = response.zipCode || '';
    inputs.placeId = response.placeId || '';
    inputs.billingAddress = response.billingAddress || '';
    inputs.billingCity = response.billingCity || '';
    inputs.billingState = response.billingState || '';
    inputs.billingZipCode = response.billingZipCode || '';
    inputs.billingEmail = response.billingEmail || '';
    inputs.billingTier = response.billingTier || '';
    inputs.pharmacyPhone = response.pharmacyPhone || '';
    inputs.pharmacyWeb = response.pharmacyWeb || '';
    inputs.subscription = response.subscription || '';
    inputs.notes = response.notes || '';
    inputs.tabletsPurchased = response.tabletsPurchased || '';
    inputs.billingSameAddress = response.billingSameAddress || false;
    inputs.phone = response.phone || '';
    inputs.website = response.website || '';
    inputs.appointmentRequestEmail = response.appointmentRequestEmail || '';
    inputs.prescriptionRequestEmail = response.prescriptionRequestEmail || '';
    inputs.staffWeb = response.staffWeb || '';
    inputs.fax = response.fax || '';
    inputs.email = response.email
      ? response.email
      : response.admin && response.admin.email
      ? response.admin.email
      : '';
    this.setState({
      inputs,
      updateInputsSet: true,
      refreshState: !refreshState,
    });
  };

  onUploadError = (message) => {
    this.setState({ errorResponse: message });
  };

  onFileUpload = (file, imageType) => {
    this.setState({
      inputs: {
        ...this.state.inputs,
        [imageType]: file,
      },
    });
  };

  componentDidMount() {
    this.props.initiatePopups();

    if (this.props.fromProfile) {
      this.setState({ fromProfile: true });
    }
  }

  UNSAFE_componentWillReceiveProps(np) {
    const { view, facility } = np;
    const { updateInputsSet, selectedId } = this.state;

    if (
      selectedId === null &&
      np.selectedId &&
      !facility.singleFacilityResponse
    ) {
      this.props.getFacilityById(np.selectedId);
      this.setState({ selectedId: np.selectedId, updateInputsSet: false });
    }
    if (facility.singleFacilityResponse && updateInputsSet === false) {
      this.setToInputs(facility.singleFacilityResponse);
    }

    if (facility.updateFacilityResponse) {
      const message = 'Profile updated successfully';
      this.setState({ successResponse: message, errorResponse: null });

      this.props.initiatePopups();
      setTimeout(() => {
        this.closeModal();
      }, config.POPUP_TIMEOUT_TIME);
    }
    if (facility.updateFacilityErrorResponse) {
      if (facility.updateFacilityErrorResponse.errorMessage) {
        this.setState({
          errorResponse: facility.updateFacilityErrorResponse.errorMessage,
          successResponse: null,
        });
      }
    }
    if (view && this.state.modal === false) {
      this.setState({
        modal: true,
        successResponse: null,
        errorResponse: null,
      });
    }
  }

  render() {
    const { inputs, errors, successResponse, errorResponse, fromProfile } =
      this.state;
    const { handleSubmit } = this.props;

    return (
      <Modal
        isOpen={this.state.modal}
        size="lg"
        backdrop="static"
        className="model-form modal-dialog-facility"
      >
        <ModalHeader className="header-form">Facility Profile</ModalHeader>
        <ModalBody>
          <Card>
            <CardBody>
              <AddFacilityErrorsView
                successResponse={successResponse}
                errorResponse={errorResponse}
              />
              <form className="form" onSubmit={handleSubmit} autoComplete="off">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-3">
                      <UploadLogoIcon
                        onUploadError={this.onUploadError}
                        upload={this.onFileUpload}
                        logo={inputs.logo}
                        icon={inputs.logoIcon}
                      />
                    </div>

                    <div className="col-md-9 loyal-facility-tab-container">
                      <div>
                        <Nav tabs>
                          {tabs.map(({ value, label }) => (
                            <NavItem key={value}>
                              <NavLink
                                onClick={() => this.changeTab(value)}
                                active={this.state.activeTab === value}
                              >
                                {label}
                              </NavLink>
                            </NavItem>
                          ))}
                        </Nav>
                        <TabContent activeTab={this.state.activeTab}>
                          <TabPane tabId="1">
                            <FacilityBasicDetails
                              onChangeFields={this.onChangeFields}
                              inputs={inputs}
                              errors={errors}
                            />
                          </TabPane>

                          <TabPane tabId="2">
                            <FacilityPhysicalAddress
                              onChangeFields={this.onChangeFields}
                              getAutoCompleteAddress={
                                this.getAutoCompleteAddress
                              }
                              inputs={inputs}
                              errors={errors}
                            />
                          </TabPane>

                          <TabPane tabId="3">
                            <FacilityBillingInfo
                              inputs={inputs}
                              errors={errors}
                              onChangeFields={this.onChangeFields}
                              getAutoCompleteBillingAddress={
                                this.getAutoCompleteBillingAddress
                              }
                            />
                          </TabPane>

                          <TabPane tabId="4"></TabPane>

                          <TabPane tabId="5">
                            <FacilityContactDetails
                              inputs={inputs}
                              errors={errors}
                              onChangeFields={this.onChangeFields}
                            />
                          </TabPane>
                        </TabContent>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <Button
                          className="clear"
                          color="secondary"
                          onClick={this.closeModal}
                        >
                          Cancel
                        </Button>
                        <Button
                          className={
                            fromProfile ? 'save-update d-none' : 'save-update'
                          }
                          color="primary"
                          onClick={this.onSubmit}
                          disabled={fromProfile}
                        >
                          Update
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = {
  toggleLoading,
  updateLogo,
  initiatePopups,
  getFacilityById,
  updateFacility,
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateFacility);
