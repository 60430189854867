import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { Col } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash.debounce';

import InputWithSearchIcon from '../../common/InputWithSearchIcon';
import TimeFilter from '../Common/TimeFilter';
import {
  DEFAULT_PAGINATION_LENGTH,
  refillRequestPageTypes,
} from '../../../config/app.config';
import { formatDate } from '../../../helpers/formatDate';

import PaginationComponent from '../../../shared/components/pagination/Pagination';
import HeadingText from '../../../shared/components/form/HeadingText';
import withRouter from '../../../shared/components/withRouter';

import {
  getAllRefillRequests,
  changeRefillRequestStatus,
} from '../../../redux/actions/refillRequestActions';
import { toggleLoading } from '../../../redux/actions/commonActions';

import { RefillRequestModal } from './RequestDetails';
import {
  StatusChangeModal,
  RefillRequestsTable,
  refillComponentsWrapperMap,
} from './Components';
import {
  RefillRequestModalProvider,
  StatusChangeModalProvider,
  getRefillRequestsHeaders,
} from './utils';

const initialParams = {
  search: '',
  timeFilter: null,
  sortOrder: -1,
  sortField: 'createdAt',
  offset: 0,
  limit: DEFAULT_PAGINATION_LENGTH,
  filters: {},
  range: {},
};

function reducer(params, action) {
  switch (action.type) {
    case 'SET_SEARCH':
      return { ...params, search: action.payload, offset: 0 };
    case 'SET_TIME_FILTER':
      return {
        ...params,
        timeFilter: action.payload,
        offset: action.payload === 'range' ? params.offset : 0,
      };
    case 'SET_SORT': {
      const { field, order } = action.payload;
      return {
        ...params,
        sortField: field ?? params.sortField,
        sortOrder: order ?? params.sortOrder,
        offset: 0,
      };
    }
    case 'SET_FILTER':
      return {
        ...params,
        filters: {
          ...params.filters,
          [action.payload.type]: action.payload.filters,
        },
        offset: 0,
      };
    case 'SET_RANGE':
      return { ...params, range: action.payload, offset: 0 };
    case 'SET_LIMIT':
      return { ...params, limit: action.payload, offset: 0 };
    case 'SET_OFFSET':
      return { ...params, offset: action.payload };
    default:
      return params;
  }
}

function RefillRequests({ type = refillRequestPageTypes.default, parentId }) {
  const searchRef = useRef();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const petId = searchParams.get('pet');

  const facilityId = useSelector((state) => state.user?.profile?.facility?._id);
  const { updatedRefillRequest, refillRequestsList } = useSelector(
    (state) => state.refillRequests,
  );

  const [params, localDispatch] = useReducer(reducer, initialParams);
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);

  const isStatusFilterExist =
    params.filters.status && params.filters.status.length > 0;

  const getRefillRequests = useCallback(async () => {
    const formattedStartDate = formatDate(params.range.startDate);
    const formattedEndDate = formatDate(params.range.endDate);

    const { status, deliveryType, doctorId } = params.filters;

    await dispatch(
      getAllRefillRequests({
        facilityId,
        params: {
          sort: {
            field: params.sortField,
            order: +params.sortOrder,
          },
          search: params.search,
          status: status?.length > 0 ? status : null,
          doctorId: doctorId?.length > 0 ? doctorId : null,
          parentId,
          petId,
          deliveryType: deliveryType?.length > 0 ? deliveryType : null,
          timeFilter: {
            type: params.timeFilter,
            startDate: formattedStartDate,
            endDate: formattedEndDate,
          },
          limit: params.limit,
          offset: params.offset,
        },
      }),
    );
  }, [facilityId, parentId, petId, params, dispatch]);

  const updateTable = useCallback(async () => {
    setRows((prevRows) => {
      return prevRows.map((row) => {
        if (row.id === updatedRefillRequest?._id) {
          const { status, statusHistory } = updatedRefillRequest;
          return {
            ...row,
            status: status.label,
            statusId: status._id,
            statusHistory,
          };
        }
        return row;
      });
    });
  }, [updatedRefillRequest]);

  // fetch refill requests for the first render
  useEffect(() => {
    const fetchRefillRequests = async () => {
      if (facilityId) {
        dispatch(toggleLoading(true));
        await getRefillRequests();
        dispatch(toggleLoading(false));
      }
    };

    fetchRefillRequests();
  }, [facilityId, getRefillRequests, dispatch]);

  // update the whole table after fetch of refill requests
  useEffect(() => {
    setRows(refillRequestsList?.data || []);
    setTotal(refillRequestsList?.total || 0);
  }, [refillRequestsList]);

  // update the particular refill request status in the table
  useEffect(() => {
    if (!isStatusFilterExist) updateTable();
  }, [updateTable, isStatusFilterExist]);

  const handleTimeFilterChange = (timeFilter) => {
    localDispatch({ type: 'SET_TIME_FILTER', payload: timeFilter });
  };

  const handleSort = ({ field, order }) => {
    localDispatch({ type: 'SET_SORT', payload: { field, order } });
  };

  const handleFilter = (type, filters) => {
    localDispatch({ type: 'SET_FILTER', payload: { type, filters } });
  };

  const handleDateRangeChange = (range) => {
    localDispatch({ type: 'SET_RANGE', payload: range });
  };

  const handlePageLimit = (newLimit) => {
    localDispatch({ type: 'SET_LIMIT', payload: newLimit });
  };

  const debouncedSearch = debounce((e) => {
    localDispatch({ type: 'SET_SEARCH', payload: e.target.value });
  }, 600);

  const handleClearSearch = () => {
    localDispatch({ type: 'SET_SEARCH', payload: '' });
  };

  const handleRefillRequestStatusChange = async (
    newStatusId,
    prescriptionId,
    comments,
  ) => {
    dispatch(toggleLoading(true));
    await dispatch(
      changeRefillRequestStatus(newStatusId, prescriptionId, comments),
    );
    /* if status filter is set up then fetch all prescriptions
       to avoid showing prescriptions that is not match filter */
    if (isStatusFilterExist) await getRefillRequests();
    dispatch(toggleLoading(false));
  };

  const Wrapper = refillComponentsWrapperMap[type];

  return (
    <RefillRequestModalProvider>
      <StatusChangeModalProvider
        handleStatusChange={handleRefillRequestStatusChange}
      >
        <Col
          md={12}
          lg={12}
          className="manage-patient-container admin-container"
        >
          <Wrapper>
            {type === refillRequestPageTypes.default && (
              <>
                <HeadingText text="Refill Requests" />
                <div className="row form col-md-6">
                  <div
                    className="form__form-group search"
                    style={{ marginBottom: 'unset' }}
                  >
                    <InputWithSearchIcon
                      name="search"
                      id="search"
                      type="text"
                      ref={searchRef}
                      viewProfile={() => {}}
                      onChange={(e) => debouncedSearch(e)}
                      placeholder="Search by Medication, Doctor Name, Parent Name, Pet Name"
                      onClear={handleClearSearch}
                      autofocus
                    />
                  </div>
                </div>
              </>
            )}
            <div className="row">
              <TimeFilter
                changeFilter={handleTimeFilterChange}
                selected={params.timeFilter}
                setDateRange={handleDateRangeChange}
                placeholder="Select Day Or Date Range"
                filterList={['today', 'last 7 days']}
              />
            </div>
            <RefillRequestsTable
              stickyTop="60px"
              heads={getRefillRequestsHeaders(type)}
              rows={rows}
              sortField={params.sortField}
              sortOrder={params.sortOrder}
              handleSort={handleSort}
              handleFilter={handleFilter}
            />
            <PaginationComponent
              handlePageLimit={handlePageLimit}
              nextPage={() =>
                localDispatch({
                  type: 'SET_OFFSET',
                  payload: params.offset + params.limit,
                })
              }
              prevPage={() =>
                localDispatch({
                  type: 'SET_OFFSET',
                  payload: params.offset - params.limit,
                })
              }
              gotoPage={(page) =>
                localDispatch({
                  type: 'SET_OFFSET',
                  payload: params.limit * page,
                })
              }
              limit={params.limit}
              offset={params.offset}
              total={total}
            />
          </Wrapper>
        </Col>

        <StatusChangeModal />
        <RefillRequestModal />
      </StatusChangeModalProvider>
    </RefillRequestModalProvider>
  );
}

export default withRouter(RefillRequests);
