const storageHelper = {
  getFromStorage: (key) => {
    const localData = window.localStorage.getItem(key);

    return JSON.parse(localData);
  },

  getConfig: (key) => {
    const localData = storageHelper.getFromStorage('loggedUser');
    if (localData && localData.configs && localData.configs[key]) {
      return localData.configs[key];
    }
    return null;
  },

  addToStorage: (key, value) => {
    window.localStorage.setItem(key, JSON.stringify(value));
  },
  getSession: (key) => {
    const localData = window.localStorage.getItem(key);

    return localData;
  },
  addToSession: (key, value) => {
    window.localStorage.setItem(key, value);
  },
  removeFromStorage: (key) => {
    window.localStorage.removeItem(key);
  },
};

module.exports = storageHelper;
