import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import { getDefaultSortOrder, getSortFieldName, getSortOptions } from './utils';

const SortBy = ({ options, sortField, sortOrder, handleSort }) => {
  const sortOptions = getSortOptions(sortField);

  const handleFieldChange = (event) => {
    let newField = event.target.value;
    newField = getSortFieldName(newField);
    const defaultOrder = getDefaultSortOrder(newField);

    handleSort({ field: newField, order: defaultOrder });
  };

  const handleOrderChange = (event) => {
    const newOrder = parseInt(event.target.value, 10);

    handleSort({ order: newOrder });
  };

  return (
    <div className="sort-by-wrapper">
      <p>Sort By:</p>
      <Input
        type="select"
        name="sortField"
        id="sortField"
        value={sortField}
        onChange={handleFieldChange}
      >
        {options.map(({ key, name }) => (
          <option key={key} value={key}>
            {name}
          </option>
        ))}
      </Input>
      <Input
        type="select"
        name="sortOrder"
        id="sortOrder"
        value={sortOrder}
        onChange={handleOrderChange}
      >
        {sortOptions.map(({ label, value }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </Input>
    </div>
  );
};

SortBy.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  sortField: PropTypes.string.isRequired,
  sortOrder: PropTypes.number.isRequired,
  handleSort: PropTypes.func.isRequired,
};

export default SortBy;
