import {
  appointmentPageTypes,
  appointmentStatus,
  appointmentTypes,
} from '../../../../config/app.config';
import constants from '../../../../constants/constants';

export function getAppointmentsHeaders(type = appointmentPageTypes.default) {
  const commonHeaders = [
    {
      key: 'dateTime',
      name: 'Date',
      sortable: true,
      filterable: false,
      capitalize: true,
    },
    {
      key: 'time',
      name: 'Time',
      sortable: false,
      filterable: false,
      capitalize: true,
    },
    {
      key: 'type',
      name: 'Appointment Type',
      sortable: false,
      filterable: true,
      filterValue: [
        { [appointmentTypes.initialConsult]: 'Initial Consult' },
        { [appointmentTypes.reCheckExam]: 'Recheck Exam' },
        { [appointmentTypes.technicianAppointment]: 'Technician Appointment' },
        { [appointmentTypes.procedure]: 'Procedure' },
      ],
      capitalize: true,
    },
    {
      key: 'parentName',
      name: 'Parent Name',
      sortable: true,
      filterable: false,
      capitalize: true,
      link: (row) =>
        `/client-list/profile/${row.parentId}?tab=${constants.QUERY_TAB_PARAMS.parent}`,
    },
    {
      key: 'petName',
      name: 'Pet Name',
      sortable: true,
      filterable: false,
      capitalize: true,
      link: (row) =>
        `/client-list/profile/${row.parentId}?tab=${constants.QUERY_TAB_PARAMS.petInfo}&${constants.QUERY_PARAMS.pet}=${row.petId}`,
    },

    {
      key: 'doctor',
      name: 'Doctor',
      sortable: false,
      filterable: true,
      filterValue: [],
      capitalize: true,
    },
    {
      key: 'status',
      name: 'Status',
      sortable: false,
      filterable: true,
      filterValue: [
        { [appointmentStatus.upcoming]: 'Upcoming' },
        { [appointmentStatus.ongoing]: 'Ongoing' },
        { [appointmentStatus.past]: 'Past' },
        { [appointmentStatus.no_updates]: 'No Updates' },
        { [appointmentStatus.cancelled]: 'Cancelled' },
        { [appointmentStatus.expired]: 'Expired' },
      ],
      capitalize: false,
    },
    {
      key: 'action',
      name: 'Action',
      className: 'action',
      sortable: false,
      filterable: false,
      capitalize: false,
    },
  ];

  const headersTypes = {
    [appointmentPageTypes.default]: commonHeaders,
    [appointmentPageTypes.clientPage]: commonHeaders
      .filter(({ key }) => key !== 'parentName')
      .map((header) => {
        if (header.key === 'petName') {
          return { ...header, link: undefined };
        }
        return header;
      }),
  };

  return headersTypes[type];
}
