import { handleRequest } from '../services/APIService';
import { TOGGLE_LOADING } from './commonActions';

export const GET_CLIENT_DATA = 'GET_CLIENT_DATA';
export const getClientList = (data) => (dispatch) => {
  handleRequest('get', 'client', true, null, data)
    .then((results) => {
      return dispatch({
        type: GET_CLIENT_DATA,
        payload: results.data,
      });
    })
    .finally(() => {
      dispatch({
        type: TOGGLE_LOADING,
        payload: false,
      });
    });
};

export const REINVITE_CLIENT = 'REINVITE_CLIENT';
export const reinvite = (data) => (dispatch) => {
  handleRequest('post', 'client/reinvite', true, data).then((results) => {
    return dispatch({
      type: REINVITE_CLIENT,
      payload: results.data,
    });
  });
};

export const FETCH_PARENTS = 'FETCH_PARENTS';
export const FETCH_PARENTS_ERROR = 'FETCH_PARENTS_ERROR';
export const getParents = (search) => (dispatch) => {
  handleRequest('get', `parent/search?search=${search}`, true)
    .then((results) => {
      return dispatch({
        type: FETCH_PARENTS,
        payload: results.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: FETCH_PARENTS_ERROR,
        payload: error,
      });
    });
};

export const getClientFacilities = (clientId) => {
  return handleRequest('get', `/clients/${clientId}/facilities`, true)
    .then((results) => results.data)
    .catch((error) => error);
};

export const SAVE_FACILITIES_FOR_CLIENT = 'SAVE_FACILITIES_FOR_CLIENT';
export const SAVE_FACILITIES_FOR_CLIENT_ERROR =
  'SAVE_FACILITIES_FOR_CLIENT_ERROR';
export const saveClientFacility = (data) => (dispatch) => {
  handleRequest('post', '/client/save/facilities', true, data)
    .then((results) => {
      return dispatch({
        type: SAVE_FACILITIES_FOR_CLIENT,
        payload: results.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: SAVE_FACILITIES_FOR_CLIENT_ERROR,
        payload: error,
      });
    });
};

export const CLEAR_CLIENT_PROPS = 'CLEAR_CLIENT_PROPS';
export const clearUpdateProps = () => (dispatch) => {
  return dispatch({
    type: CLEAR_CLIENT_PROPS,
    payload: null,
  });
};

export const CLEAR_INVITE = 'CLEAR_INVITE';
export const clearInviteStore = () => (dispatch) => {
  return dispatch({
    type: CLEAR_INVITE,
    payload: null,
  });
};
