import React from 'react';

export function InputLabel({ required = false, children }) {
  return (
    <span className="form__form-group-label">
      {children}
      {required ? <span className="required">&nbsp;*</span> : null}
    </span>
  );
}
