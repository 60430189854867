import axios from 'axios';
import config from '../../config/app.config';
import localStorage from '../../libs/storageHelper';

const baseURL = config.BASE_URL;
const CLOUDINARY_URL = config.CLOUDINARY_URL;
const API_CLIENT_URL = config.BASE_API_CLIENT_URL;

export async function handleRequest(
  method,
  url,
  requireAuth,
  additionalOptions,
  queryParams = {},
  withApiClient = false,
) {
  const user = localStorage.getFromStorage('loggedUser');
  const requestOptions = {
    url: withApiClient ? API_CLIENT_URL + url : baseURL + url,
    method,
    headers: {
      'Content-Type': 'application/json',
    },
    data: JSON.stringify(additionalOptions),
    params: queryParams,
  };

  if (requireAuth && user) {
    requestOptions.headers.Authorization = `Bearer ${user.token}`;
  }

  const request = await axios(requestOptions).catch((error) => {
    if (error.response.status === config.RESPONSE.UNAUTHORIZED) {
      localStorage.removeFromStorage('loggedUser');
      window.location = 'login';
    }
    throw error;
  });

  return request.data;
}

export function handleUploadRequest(file, type) {
  const fd = new FormData();
  const loggedUser = localStorage.getFromStorage('loggedUser');
  const uploadPreset = loggedUser.configs.cup;
  fd.append('upload_preset', uploadPreset);
  fd.append('folder', `${config.CLOUDINARY_BASE_FOLDER}/${type}`);
  fd.append('file', file);

  const request = axios({
    method: 'post',
    url: `${CLOUDINARY_URL}`,
    data: fd,
  });

  request.catch((error) => {
    if (error.status === config.RESPONSE.UNAUTHORIZED) {
      localStorage.removeFromStorage('loggedUser');
      window.location = 'login';
    }
  });

  return request;
}
