import React from 'react';
import { FormGroup } from 'reactstrap';
import { InputLabel } from '../../../shared/components/InputLabel';
import Error from '../../../shared/components/form/Error';

export function FormInput({
  label = '',
  name = '',
  type = 'text',
  value,
  error = '',
  required = false,
  onChange,
  className,
  wrapperClassName = "col-md-4",
  ...restProps
}) {
  return (
    <div className={wrapperClassName}>
      <div className="form__form-group">
        <InputLabel required={required}>{label}</InputLabel>
        <div className="form__form-group-field">
          <FormGroup className={className}>
            <input
              className="form-control"
              name={name}
              type={type}
              value={value}
              maxLength={50}
              onChange={onChange}
              {...restProps}
            />
            {error && <Error text={error} />}
          </FormGroup>
        </div>
      </div>
    </div>
  );
}
