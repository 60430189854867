import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import localStorage from '../../libs/storageHelper';
import {
  subscribeToTopic,
  unsubscribeFromTopic,
} from '../../redux/actions/dashboardActions';
import {
  LOYALPET_API_KEY,
  LOYALPET_APP_ID,
  LOYALPET_AUTH_DOMAIN,
  LOYALPET_MESSAGING_SENDER_ID,
  LOYALPET_PROJECT_ID,
  LOYALPET_STORAGE_BUCKET,
  LOYALPET_VAPID_KEY,
} from '../../config/app.config';

const firebaseConfig = {
  apiKey: LOYALPET_API_KEY,
  authDomain: LOYALPET_AUTH_DOMAIN,
  projectId: LOYALPET_PROJECT_ID,
  storageBucket: LOYALPET_STORAGE_BUCKET,
  messagingSenderId: LOYALPET_MESSAGING_SENDER_ID,
  appId: LOYALPET_APP_ID,
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const fetchToken = () => {
  return getToken(messaging, {
    vapidKey: LOYALPET_VAPID_KEY,
  })
    .then(async (currentToken) => {
      if (currentToken) {
        await subscribeToTopic({ token: currentToken });
        localStorage.addToStorage('firebase_token', currentToken);
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
};

export const onMessageListener = (callback) => onMessage(messaging, callback);

export const unsubscribeFromFirebaseTopic = async (data) => {
  return unsubscribeFromTopic(data);
};
