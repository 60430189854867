import React, { useCallback, useEffect, useState, useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import TrelloBoard from 'react-trello';
import moment from 'moment';
import PetCard from './PetCard';
import localStorage from '../../../libs/storageHelper';
import constant from '../../../constants/constants';
import {
  dragCard,
  deletePhaseMessage,
  getPtbEvents,
} from '../../../redux/actions/pTBActions';
import MessageModal from '../../../shared/components/Modals/MessageModal';

import ResponseMessages from '../../../constants/responseMessages';
import { defaultLanes } from './constants';
import RefreshBoardButton from './RefreshBoardButton';
import {
  fetchToken,
  onMessageListener,
  unsubscribeFromFirebaseTopic,
} from '../../App/firebase';
import { PHASES } from '../../../config/app.config';

const getAppointmentMoveDetails = ({
  cardDetails,
  initialPhaseId,
  targetPhaseId,
}) => ({
  petName: cardDetails.metadata.petName,
  fromPhase: PHASES[+initialPhaseId],
  toPhase: PHASES[+targetPhaseId],
});

function getCardColor(laneId) {
  const { appointmentPhases } = constant;
  return Object.values(appointmentPhases).find(({ id }) => `${id}` === laneId)
    .color;
}

function getAlertMessage(metadata) {
  const { petParentStatus, petParentName, petName } = metadata;
  if (petParentStatus === constant.STATUS.INACTIVE) {
    return `${petParentName} is inactive. Please activate parent to update the procedure.`;
  } else {
    return `${petName} is inactive. Please activate pet to update the procedure.`;
  }
}

const prepareLanes = (events) =>
  defaultLanes.map((lane) => ({
    ...lane,
    cards: events
      .filter((event) => lane.id === `${event.phase}`)
      .map((event) => ({
        id: event._id,
        firstName: event.petName,
        lastName: event.parentLastName,
        date: event.date,
        time: event.time,
        status: event.status,
        color: getCardColor(lane.id),
        laneId: `${lane.id}`,
        metadata: {
          petId: event.petId,
          type: event.type,
          petName: event.petName,
          petParentStatus: event.parentFacilityStatus || 'N/A',
          petParentName: event.parentName,
          facilityId: event.facility,
        },
      })),
  }));

const Board = ({
  toggleClass,
  minimize,
  events,
  openPetModel,
  dragCard,
  refreshBoard,
  deletePhaseMessage,
}) => {
  const boardScroollRef = useRef();
  const scrollRef = useRef();
  const boardRef = useRef();
  const facility = useSelector((state) => state?.user?.profile?.facility);

  const [eventBus, setEventBus] = useState(null);
  const [dragMove, setDragMove] = useState({
    cardId: '',
    sourceLaneId: '',
    targetLaneId: '',
    card: null,
  });
  const [ptbClass, setPtbClass] = useState('');

  const [eventData, setEventsData] = useState({
    lanes: defaultLanes.map((lane) => ({ ...lane })),
  });
  const [confirmPopup, setConfirmPopup] = useState({
    showResponse: false,
    responseMessage: '',
    responseType: '',
    responseAlertType: '',
    footer: false,
  });

  const fillBoardWithAppointments = useCallback((events) => {
    setEventsData({
      lanes: prepareLanes(events),
    });
  }, []);

  const onRefreshClick = useCallback(
    (updatedAppointment) => {
      const { _id, utcOffset } = facility;

      if (!_id) return;

      refreshBoard(_id, utcOffset);
    },
    [facility, refreshBoard],
  );

  useEffect(() => {
    if (events) {
      fillBoardWithAppointments(events);
    }
  }, [toggleClass, events, fillBoardWithAppointments]);

  useEffect(() => {
    fetchToken();
    const unsubscribe = onMessageListener((payload) => {
      const { data } = payload.data;
      onRefreshClick(JSON.parse(data));
    });

    return async () => {
      unsubscribe?.();
      const token = localStorage.getFromStorage('firebase_token');
      if (token) {
        await unsubscribeFromFirebaseTopic({ token });
      }
    };
  }, [facility?._id, onRefreshClick]);

  const showAlert = (
    message,
    { type = 'ok', alertType = 'danger', onSuccess, onCancel, subMessage } = {},
  ) => {
    setConfirmPopup({
      showResponse: true,
      subMessage,
      onSuccess,
      onCancel,
      responseMessage: message,
      responseType: type,
      responseAlertType: alertType,
    });
  };

  const closeAlert = useCallback(() => {
    setConfirmPopup({
      showResponse: false,
      responseMessage: '',
      onSuccess: undefined,
      onCancel: undefined,
      responseType: '',
      responseAlertType: '',
      footer: false,
    });
  }, []);

  const handleDragEnd = (
    dragCardId,
    sourceId,
    targetId,
    position,
    dragCardDetails,
  ) => {
    if (targetId === sourceId) return;
    setDragMove({
      card: dragCardDetails,
      cardId: dragCardId,
      sourceLaneId: sourceId,
      targetLaneId: targetId,
    });

    if (+targetId === 1) {
      return showAlert(
        `It's not possible to move appointment back to Expected phase`,
        {
          type: 'ok',
          alertType: 'danger',
          onCancel: () => {
            moveCardBack({
              cardId: dragCardId,
              from: targetId,
              to: sourceId,
              card: dragCardDetails,
            });
            closeAlert();
          },
        },
      );
    }
    const { petName, fromPhase, toPhase } = getAppointmentMoveDetails({
      cardDetails: dragCardDetails,
      initialPhaseId: sourceId,
      targetPhaseId: targetId,
    });

    showAlert(`Are you sure you want to move ${petName}`, {
      type: 'confirm',
      subMessage: `from ${fromPhase} to ${toPhase}?`,
      alertType: 'info',
      onSuccess: async () => {
        closeAlert();
        await moveCardToPhase({
          dragCardId,
          sourceId,
          targetId,
          position,
          dragCardDetails,
        });
      },
      onCancel: () => {
        closeAlert();
        moveCardBack({
          cardId: dragCardId,
          from: targetId,
          to: sourceId,
          card: dragCardDetails,
        });
      },
    });
  };

  const moveCardToPhase = async ({
    dragCardId,
    sourceId,
    targetId,
    dragCardDetails,
  }) => {
    setPtbClass('');

    // check if pet and parene
    if (dragCardDetails.metadata.petParentStatus === constant.STATUS.INACTIVE) {
      showAlert(getAlertMessage(dragCardDetails.metadata), {
        type: 'ok',
        alertType: 'danger',
      });
      return false;
    }

    const startOfTheDay = moment().startOf('date').valueOf();
    const appointment = events
      .filter((event) => event._id === dragCardId)
      .shift();
    const appointmentTime = moment(appointment?.dateTime).valueOf();

    if (targetId === 1 && startOfTheDay > appointmentTime) {
      showAlert(ResponseMessages.WARN.CAN_NOT_MOVE_TO_EXPECTED, {
        type: 'alert',
        alertType: 'info',
      });
      return false;
    }

    if (targetId < sourceId) {
      await onCardMoveBack({
        cardId: dragCardId,
        cardDetails: dragCardDetails,
        targetLaneId: targetId,
      });
    } else if (targetId !== sourceId) {
      const error = await dragCard({
        appointmentId: dragCardId,
        phase: targetId,
      });
      if (error) {
        showAlert(error.errorMessage);
      } else {
        openPetModel(dragCardId, dragCardDetails.metadata, targetId, true);
      }
    }
  };

  const onCardClick = (cardId, metadata, laneId) => {
    if (metadata.petParentStatus === constant.STATUS.INACTIVE) {
      showAlert(getAlertMessage(metadata));
    }
    requestAnimationFrame(() => {
      openPetModel(cardId, metadata, laneId);
    });
  };

  const onCardMoveBack = async ({ cardId, cardDetails, targetLaneId }) => {
    closeAlert();

    await deletePhaseMessage({
      phaseId: targetLaneId,
      appointmentId: cardId,
    });
    const error = await dragCard({
      appointmentId: cardId,
      phase: targetLaneId,
    });
    if (error) {
      return showAlert(error.errorMessage);
    }
    openPetModel(cardId, cardDetails?.metadata, targetLaneId, true);
  };

  const moveCardBack = useCallback(
    ({ from, to, card, cardId }) => {
      if (!from || !to) return;

      eventBus?.publish({
        type: 'MOVE_CARD',
        fromLaneId: from,
        toLaneId: to,
        cardId,
        index: 0,
      });
      eventBus?.publish({
        type: 'UPDATE_CARD',
        laneId: to,
        card: {
          ...card,
          laneId: to,
          color: getCardColor(to),
        },
      });
    },
    [eventBus],
  );

  const onConfirmClose = useCallback(() => {
    closeAlert();
  }, [closeAlert]);

  const handleDragStart = () => {
    setPtbClass('ptb-board');
  };

  const handleScroll = (event) => {
    const targetDiv = event.target;

    if (targetDiv === boardScroollRef.current && scrollRef.current) {
      scrollRef.current.scrollLeft = targetDiv.scrollLeft;
    } else if (targetDiv === scrollRef.current && boardScroollRef.current) {
      boardScroollRef.current.scrollLeft = targetDiv.scrollLeft;
    }
  };

  const onBoardDataChange = (newData) => {
    setEventsData(newData);
    if (dragMove?.card) {
      eventBus?.publish({
        type: 'UPDATE_CARD',
        laneId: dragMove.targetLaneId,
        card: {
          ...dragMove.card,
          color: getCardColor(dragMove.targetLaneId),
        },
      });
    }
  };

  const boardWidth = boardRef?.current?.clientWidth;

  return (
    <div className={`board ${toggleClass}`}>
      {toggleClass === 'maximize' && (
        <div className="refresh-btn-board-container">
          <div className="icon-minimize" style={{ width: '300px' }}>
            <span onClick={minimize} className="lnr lnr-frame-contract" />
          </div>
          <RefreshBoardButton onRefreshClick={onRefreshClick} />
        </div>
      )}

      <div
        ref={scrollRef}
        onScroll={handleScroll}
        className="overflow-top-scroll-wrapper sticky-top-60"
        style={{
          visibility:
            boardWidth <= scrollRef?.current?.clientWidth
              ? 'hidden'
              : 'inherit',
        }}
      >
        <div style={boardWidth ? { width: boardWidth } : {}} />
      </div>
      <div
        ref={boardScroollRef}
        onScroll={handleScroll}
        className="board-scroll-wrapper"
      >
        <div ref={boardRef} className="board-fit-content">
          <TrelloBoard
            eventBusHandle={setEventBus}
            laneDraggable={false}
            onDataChange={onBoardDataChange}
            handleDragStart={handleDragStart}
            handleDragEnd={handleDragEnd}
            dragClass="dragClass"
            className={`react-trello-board ${ptbClass}`}
            data={eventData}
            hideCardDeleteIcon
            components={{
              Card: (props) => (
                <PetCard {...props} onCardClick={onCardClick}></PetCard>
              ),
            }}
          />
        </div>
      </div>

      <MessageModal
        show={confirmPopup.showResponse}
        type={confirmPopup.responseType}
        alertType={confirmPopup.responseAlertType}
        message={confirmPopup.responseMessage}
        subMessage={confirmPopup.subMessage}
        onSuccess={confirmPopup.onSuccess || onCardMoveBack}
        onClose={confirmPopup.onCancel || onConfirmClose}
        footer
      />
    </div>
  );
};

const mapDispatchToProps = {
  dragCard,
  deletePhaseMessage,
  refreshBoard: getPtbEvents,
};

export default connect(null, mapDispatchToProps)(Board);
