export const heads = [
  {
    key: 'parentName',
    name: 'Parent Name',
    sortable: true,
    filterable: false,
    capitalize: true,
  },
  {
    key: 'coParents',
    name: 'Co-Parent Name',
    sortable: false,
    filterable: false,
    capitalize: true,
  },
  {
    key: 'petName',
    name: 'Pet Name',
    sortable: true,
    filterable: false,
    capitalize: true,
  },
  {
    key: 'speciesName',
    name: 'Species',
    sortable: false,
    filterable: true,
    filterValue: [],
    capitalize: true,
  },
  {
    key: 'breedName',
    name: 'Breed',
    sortable: false,
    filterable: true,
    filterValue: [],
    capitalize: true,
  },
  {
    key: 'gender',
    name: 'Gender',
    sortable: false,
    filterable: true,
    filterValue: [
      { male: 'male' },
      { female: 'female' },
      { male_neutered: 'male neutered' },
      { female_neutered: 'female neutered' },
      { unknown: 'unknown' },
    ],
    capitalize: true,
  },
  {
    key: 'phone',
    name: 'Mobile Number',
    sortable: false,
    filterable: false,
    capitalize: false,
  },
];
