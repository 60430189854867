import React from 'react';
import {
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
} from 'reactstrap';
import classNames from 'classnames';

import ModalPetInfo from '../../Common/ModalPetInfo';
import { AttachmentsTab } from '../../../../shared/components/Modals/AttachmentsTab';
import { FooterButtons } from '../../../../shared/components/Modals/FooterButtons';

import { MainInfoTab } from './MainInfoTab';
import { InternalCommentsTab } from './InternalCommentsTab';
import {
  tab,
  useRefillRequestModalContext,
} from '../utils/RefillRequestModalContext';

export function RefillRequestModal() {
  const {
    isRefillRequestModalOpen,
    refillRequestData,
    closeRefillRequestModal,
    activeTab,
    setActiveTab,
  } = useRefillRequestModalContext();

  const isDataAvailable = !!Object.values(refillRequestData)?.length;
  const { photos, documents } = refillRequestData;

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const hasAttachments = ({ photos, documents }) => {
    return photos?.length > 0 || documents?.length > 0;
  };

  const shouldIncludeTab = (tabItem) => {
    if (tabItem === tab.ATTACHMENTS) {
      return hasAttachments({ photos, documents });
    }
    return true;
  };

  const filteredTabs = Object.values(tab).filter(shouldIncludeTab);

  const TabDataMap = {
    [tab.MAIN_INFO]: <MainInfoTab />,
    [tab.ATTACHMENTS]: (
      <AttachmentsTab photos={photos} documents={documents}>
        <FooterButtons
          onClose={closeRefillRequestModal}
          showSaveButton={false}
        />
      </AttachmentsTab>
    ),
    [tab.INTERNAL_COMMENTS]: <InternalCommentsTab />,
  };

  return (
    <Modal
      isOpen={isRefillRequestModalOpen}
      size="lg"
      backdrop="static"
      className="model-form refill-request-details-modal"
    >
      <ModalHeader className="pet-summary-wrapper">
        {refillRequestData?.petSummary && (
          <ModalPetInfo
            petSummary={refillRequestData.petSummary}
            avatar={refillRequestData.petSummary.avatar}
            title={`Refill Request\nDetails`}
          />
        )}
      </ModalHeader>
      <ModalBody>
        <Nav tabs>
          {filteredTabs.map((tab) => (
            <NavItem key={tab}>
              <NavLink
                className={classNames({ active: activeTab === tab })}
                onClick={() => toggleTab(tab)}
              >
                {tab}
              </NavLink>
            </NavItem>
          ))}
        </Nav>

        {isDataAvailable && (
          <TabContent activeTab={activeTab}>{TabDataMap[activeTab]}</TabContent>
        )}
      </ModalBody>
    </Modal>
  );
}
