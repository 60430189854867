import React from 'react';
import { InputLabel } from '../../../shared/components/InputLabel';
import { FormGroup } from 'reactstrap';
import { USER_TYPES } from '../../../config/app.config';

export const PermissionsSelect = ({ onChange, value }) => {
  return (
    <div className="form__form-group">
      <InputLabel>Permissions</InputLabel>
      <div className="form__form-group-field">
        <FormGroup>
          <select
            name="type"
            id="type"
            className="form-control form-select"
            value={value}
            onChange={onChange}
          >
            <option value={USER_TYPES.STAFF}>User</option>
            <option value={USER_TYPES.ADMIN}>Admin</option>
          </select>
        </FormGroup>
      </div>
    </div>
  );
};
