import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import {
  AppointmentInfo,
  AvailabilityTable,
  CreateAppointmentBlock,
  PreferredDatesTable,
} from '../Components';
import {
  useAppointmentRequestModalContext,
  getAppointmentRequestsHeaders,
  parseAvailabilityInfo,
} from '../utils';

export function MainInfoTab() {
  const { appointmentRequestData, doctors } =
    useAppointmentRequestModalContext();
  const { appointmentInfo } = appointmentRequestData;
  const isCompletedRequest = !!appointmentInfo?.id;

  const heads = getAppointmentRequestsHeaders();
  const { availability, otherComments } = parseAvailabilityInfo(
    appointmentRequestData.availabilityInfo || '',
  );

  const getDoctorData = (doctorId) => {
    return doctors.filter((doctor) => doctor.value === doctorId);
  };

  const requestType = appointmentRequestData?.type;
  const defaultAppointmentType = requestType
    ? [{ label: requestType, value: requestType }]
    : [];

  const renderAppointmentRequestFields = () => {
    const excludedKeys = [
      'date',
      'time',
      'preferredDate',
      'availabilityInfo',
      'attachments',
      'open',
    ];

    const additionalFields = [
      { key: 'dateTime', name: 'Date and Time' },
      { key: 'otherComments', name: 'Other Client Comments' },
    ];

    const headsExtraFields = heads
      .filter(({ key }) => !excludedKeys.includes(key))
      .concat(additionalFields);

    return headsExtraFields.map(
      ({ key, name, capitalize, display, link: linkToProfile }) => {
        let dataToShow = appointmentRequestData[key];
        const link = linkToProfile?.(appointmentRequestData);
        let addEllipsisClass = !link; // for link ellipsis class is added to to anchor tag

        if (
          !isCompletedRequest &&
          (key === 'type' || key === 'doctorFullName')
        ) {
          return null;
        }

        if (key === 'condition' && !dataToShow.trim()) {
          if (isCompletedRequest) {
            dataToShow = 'No comments provided';
          } else {
            addEllipsisClass = false;
            dataToShow = "No comments provided about the pet's condition";
          }
        }

        if (key === 'otherComments') {
          addEllipsisClass = false;
          dataToShow = otherComments || 'No other comments provided';
        }

        return (
          <div
            key={key}
            className={`${key} modal-main-info-field `}
            style={{ gridArea: key, display: display ?? 'block' }}
          >
            <p className="field-label">{name}</p>
            <p
              className={classNames('field-data', {
                'text-capitalize': capitalize,
                ellipsis: addEllipsisClass,
              })}
              title={dataToShow}
            >
              {link ? (
                <Link to={link} className="ellipsis">
                  {dataToShow}
                </Link>
              ) : (
                dataToShow
              )}
            </p>
          </div>
        );
      },
    );
  };

  return (
    <section className="main-info-tab">
      {!isCompletedRequest && (
        <CreateAppointmentBlock
          appointmentRequestData={appointmentRequestData}
          doctors={doctors}
          defaultDoctor={getDoctorData(appointmentRequestData.doctorId)}
          defaultAppointmentType={defaultAppointmentType}
        />
      )}

      <div
        className={classNames('scrollable-modal-area', {
          'with-order': isCompletedRequest,
        })}
      >
        {isCompletedRequest && (
          <div className="appointment-info">
            <h3>Appointment Details</h3>
            <AppointmentInfo
              appointmentInfo={{
                ...appointmentInfo,
                doctor: getDoctorData(appointmentInfo.doctor)[0]?.label || '',
              }}
            />
          </div>
        )}

        {appointmentRequestData?.preferredDate?.length > 0 && (
          <div className="modal-table specific-dates">
            <h3 className={classNames({ small: isCompletedRequest })}>
              Specific Date(s)
            </h3>
            <PreferredDatesTable
              preferredDates={appointmentRequestData.preferredDate}
            />
          </div>
        )}
        {Object.keys(availability).length > 0 && (
          <div className="modal-table availability">
            <h3 className={classNames({ small: isCompletedRequest })}>
              Client Availability
            </h3>
            <AvailabilityTable availability={availability} />
          </div>
        )}

        <div className="request-additional-info">
          <h3>Client Request Details</h3>
          <div
            className={`modal-main-info-data of-request ${
              isCompletedRequest ? 'full-variant' : 'short-variant'
            }`}
          >
            {renderAppointmentRequestFields()}
          </div>
        </div>
      </div>
    </section>
  );
}
