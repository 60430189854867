export const routes = {
  login: {
    relative: 'login',
    absolute: '/login',
  },
  appointments: {
    relative: 'appointments',
    absolute: '/appointments',
  },
  manageClients: {
    absolute: '/manage-clients',
    relative: 'manage-clients',
  },
  refillRequests: '/refill-requests',
  appointmentRequests: '/appointment-requests',
};
