import React from 'react';
import PropTypes from 'prop-types';
import {
  ClearAllPill,
  DateFilterPill,
  DefaultFilterPill,
} from './FilterPillsComponents.js';
import { formatFilterText } from './utils.js';

const FilterPills = ({ filters, entityLookup, onRemove, onClearAll }) => {
  const hasActiveFilters = Object.values(filters)?.some(
    (values) => values.length > 0,
  );

  const renderActiveFilters = () => {
    if (!hasActiveFilters) {
      return <span className="empty-filters-message">No active filters</span>;
    }

    return Object.entries(filters).flatMap(([key, values]) =>
      values.map((value) => {
        if (key === 'timeFilter') {
          return (
            <DateFilterPill
              key={`${key}-${JSON.stringify(values)}`}
              timeFilter={value.timeFilter}
              range={value.range || {}}
              onRemove={() => onRemove(key, value)}
            />
          );
        }

        const filterText = formatFilterText(key, value, entityLookup);
        return (
          <DefaultFilterPill
            key={`${key}-${value}`}
            value={filterText}
            onRemove={() => onRemove(key, value)}
          />
        );
      }),
    );
  };

  return (
    <div className="filter-pills-wrapper">
      <p className="title">Active Filters: </p>
      <div className="filter-pills">
        {hasActiveFilters && <ClearAllPill onClearAll={onClearAll} />}
        {renderActiveFilters()}
      </div>
    </div>
  );
};

FilterPills.propTypes = {
  filters: PropTypes.shape({
    [PropTypes.string]: PropTypes.arrayOf(PropTypes.string),
    timeFilter: PropTypes.arrayOf(
      PropTypes.shape({
        timeFilter: PropTypes.string.isRequired,
        range: PropTypes.shape({
          startDate: PropTypes.string,
          endDate: PropTypes.string,
        }),
      }),
    ),
  }).isRequired,
  // entityLookup is used to transform filters, ex. doctorId, into user-friendly name
  entityLookup: PropTypes.object,
  onRemove: PropTypes.func.isRequired,
  onClearAll: PropTypes.func.isRequired,
};

export default FilterPills;
