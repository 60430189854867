import React, { PureComponent } from 'react';
import { Card, CardBody, Col, Label, Button } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import DashboardFacilityFilterComponent from '../../shared/components/form/DashboardFacilityFilterComponent';
import NewUserComponent from './NewUserComponent';
import PhaseHistoryComponent from './PhaseHistoryComponent';
import ProcedureUpdateMessageComponent from './ProcedureUpdateMessageComponent';
import ProcedureDetailsComponent from './ProcedureDetailsComponent';
import { getAllFacilities } from '../../redux/actions/staffActions';
import {
  getDashboardData,
  getDashboardNewUserData,
  getDashboardProcedureDetailsData,
  getDashboardAverageTimeData,
} from '../../redux/actions/dashboardActions';
import config from '../../config/app.config';
import { toggleLoading } from '../../redux/actions/commonActions';
import UsersTotals from './TotalUsers';

const { SUPER_ADMIN } = config.USER_TYPES;

class Dashboard extends PureComponent {
  constructor() {
    super();

    this.state = {
      facilities: [],
      facilityIds: [],
      userType: null,
      processedDate: null,
      displayName: '',
      timer: '',
      userTotal: null,
      newUser: {},
      newUserType: 'daily',
      phaseHistoryType: 'daily',
      procedureDetail: {},
      procedureDetailType: 'daily',
      historyChartData: [],
      historyPhases: [],
    };

    this.timeFr = requestAnimationFrame(this.timer);
  }

  UNSAFE_componentWillMount() {
    const {
      facilityIds,
      newUserType,
      procedureDetailType,
      historyPhases,
      phaseHistoryType,
    } = this.state;
    this.props.toggleLoading(true);
    this.props.getAllFacilities();
    this.props.getDashboardData({ facilityIds });
    this.props.getDashboardNewUserData({ facilityIds, type: newUserType });
    this.props.getDashboardProcedureDetailsData({
      facilityIds,
      type: procedureDetailType,
    });
    this.props.getDashboardAverageTimeData({
      facilityIds,
      type: phaseHistoryType,
      phases: historyPhases,
    });
  }

  componentDidUpdate(np) {
    if (np.common.loading) {
      this.props.toggleLoading(false);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.staff && nextProps.staff.facilityAll) {
      const facilities = nextProps.staff.facilityAll;
      this.setState({ facilities });
    }
    if (nextProps && nextProps.user && nextProps.user.profile) {
      this.setState({
        userType: nextProps.user.profile.type,
        userFacility: nextProps.user.profile.facility,
      });
    }
    if (nextProps && nextProps.dashboard && nextProps.dashboard.dashboard) {
      const dashboard = nextProps.dashboard.dashboard;
      if (dashboard.countSummary) {
        this.setState({
          userTotal: dashboard.countSummary,
          processedDate: dashboard.processedDate,
        });
      }
      if (dashboard.newUser) {
        this.setState({ newUser: dashboard.newUser });
      }
    }
    if (nextProps && nextProps.dashboard && nextProps.dashboard.newUser) {
      this.setState({ newUser: nextProps.dashboard.newUser });
    }
    if (
      nextProps &&
      nextProps.dashboard &&
      nextProps.dashboard.procedureDetail
    ) {
      this.setState({ procedureDetail: nextProps.dashboard.procedureDetail });
    }

    if (nextProps.dashboard.averageTimeSpent) {
      this.setState({ historyChartData: nextProps.dashboard.averageTimeSpent });
    }
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.timeFr);
  }

  handleNewUserType = (newUserType) => {
    const { facilityIds } = this.state;
    this.setState({ newUserType }, () => {
      this.props.getDashboardNewUserData({ facilityIds, type: newUserType });
    });
  };

  handlePhaseHistoryType = (phaseHistoryType) => {
    const { facilityIds, historyPhases } = this.state;
    this.setState({ phaseHistoryType }, () => {
      this.props.getDashboardAverageTimeData({
        facilityIds,
        type: phaseHistoryType,
        phases: historyPhases,
      });
    });
  };

  handleHistoryPhaseChange = (historyPhases) => {
    const { facilityIds, phaseHistoryType } = this.state;
    this.setState({ historyPhases }, () => {
      this.props.getDashboardAverageTimeData({
        facilityIds,
        type: phaseHistoryType,
        phases: historyPhases,
      });
    });
  };

  handleProcedureDetailType = (procedureDetailType) => {
    const { facilityIds } = this.state;
    this.setState({ procedureDetailType }, () => {
      this.props.getDashboardProcedureDetailsData({
        facilityIds,
        type: procedureDetailType,
      });
    });
  };

  handleFilter = (selectedArray) => {
    const {
      historyPhases,
      phaseHistoryType,
      newUserType,
      procedureDetailType,
    } = this.state;
    this.setState({ facilityIds: selectedArray }, () => {
      this.props.getDashboardData({ facilityIds: selectedArray });
      this.props.getDashboardAverageTimeData({
        facilityIds: selectedArray,
        type: phaseHistoryType,
        phases: historyPhases,
      });
      this.props.getDashboardNewUserData({
        facilityIds: selectedArray,
        type: newUserType,
      });
      this.props.getDashboardProcedureDetailsData({
        facilityIds: selectedArray,
        type: procedureDetailType,
      });
      this.props.toggleLoading(true);
    });
  };

  timer = () => {
    const timer = moment().format('MMMM Do YYYY dddd LT');
    this.setState({ timer });
    return requestAnimationFrame(this.timer);
  };

  render() {
    const {
      facilities,
      userFacility,
      userType,
      userTotal,
      timer,
      procedureDetail,
      newUser,
      facilityIds,
      historyChartData,
      processedDate,
    } = this.state;

    const selectedFacility = userFacility?.name ?? '';

    return (
      <Col md={12} lg={12} className="matrix-dashboard">
        <Card>
          <CardBody>
            <div className="facility-wrapper">
              {userType !== SUPER_ADMIN ? (
                <Label className="facility-name">{selectedFacility}</Label>
              ) : (
                <>
                  <Label>Filter Values by Facilities</Label>
                  <DashboardFacilityFilterComponent
                    facilities={facilities}
                    handleFilter={this.handleFilter}
                    filterTypeTextPlural="Facilities"
                    filterTypeText="Facility"
                  />
                </>
              )}
            </div>
            <div className="total-average">
              <Label>{timer}</Label>
              <div className="row">
                <Col key="total-user-1" md={3} lg={3} className="total-user-3">
                  <UsersTotals
                    client={userTotal?.clientCount}
                    pet={userTotal?.petCount}
                    staff={userTotal?.providerCount}
                    supporter={userTotal?.supporterCount}
                  />
                </Col>

                <Col
                  key="total-user-2"
                  md={7}
                  lg={7}
                  className="provider-by-designation-7"
                >
                  <div className="provider-by-designation-wrapper">
                    <h5 className="heading">Staff by Designation</h5>
                    <div className="row">
                      {userTotal?.designationSummery?.map((designation, key) => (
                        <Col key={key} md={4} lg={4}>
                          <div className="single-designation">
                            <span key={key} className="label">
                              {designation.name}
                            </span>
                            <span className="value">{designation.count}</span>
                          </div>
                        </Col>
                      ))}
                    </div>
                  </div>
                </Col>

                <Col key="total-user-3" md={2} lg={2} className="average-2">
                  <div className="average-wrapper">
                    <div key="supporter" className="pet">
                      <span key="text" className="text">
                        AVG Supporters
                        <br /> per Client
                      </span>
                      <span key="value" className="value">
                        {userTotal?.supportePerPet.toFixed(2)}
                      </span>
                    </div>
                    <div key="pet" className="supporter">
                      <span key="text" className="text">
                        AVG Pets <br />
                        per Client
                      </span>
                      <span key="value" className="value">
                        {userTotal?.petPerClient.toFixed(2)}
                      </span>
                    </div>
                  </div>
                </Col>
              </div>
            </div>
            <div className="row">
              <div className="dashboard-wrapper">
                <NewUserComponent
                  processedDate={processedDate}
                  newUser={newUser}
                  handleNewUserType={(type) => this.handleNewUserType(type)}
                />
                <ProcedureUpdateMessageComponent
                  processedDate={processedDate}
                  facilities={facilityIds}
                />
                <ProcedureDetailsComponent
                  processedDate={processedDate}
                  procedureDetail={procedureDetail}
                  handleProcedureDetailType={(type) =>
                    this.handleProcedureDetailType(type)
                  }
                />
                <PhaseHistoryComponent
                  processedDate={processedDate}
                  chartData={historyChartData}
                  handlePhaseHistoryType={(type) => {
                    this.handlePhaseHistoryType(type);
                  }}
                  handleHistoryPhaseChange={this.handleHistoryPhaseChange}
                />
              </div>
            </div>
          </CardBody>
          {userType === SUPER_ADMIN && (
            <div>
              <a href="https://mixpanel.com/login/" target="_new">
                <Button className="btn-mixpanel" color="primary">
                  View Mix Panel
                </Button>
              </a>
            </div>
          )}
        </Card>
      </Col>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = {
  getAllFacilities,
  getDashboardData,
  toggleLoading,
  getDashboardNewUserData,
  getDashboardProcedureDetailsData,
  getDashboardAverageTimeData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
