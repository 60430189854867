import React, { useState } from 'react';
import { defaultIcon } from '../constants';
import { PlusButton } from '../../../shared/components/buttons/PlusButton';
import { AvatarModal } from '../../../shared/components/Modals/AvatarModal';

const ImageUploadBlock = ({
  onClick = () => {},
  src = '',
  alt = '',
  btnText = 'Upload',
}) => {
  return (
    <div className="col-md-12 loyal-image-upload-box">
      <div className="row">
        <div className="col-md-12">
          <img
            className="loyal-uploaded-image-logo"
            src={src || defaultIcon}
            alt={alt}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <PlusButton onClick={onClick}>{btnText}</PlusButton>
        </div>
      </div>
    </div>
  );
};

export function UploadLogoIcon({ logo, icon, upload, onUploadError }) {
  const [isOpenCrop, setIsCropModalOpen] = useState(false);
  const [imageType, setImageType] = useState();

  const selectLogo = () => {
    setImageType('logo');
    setIsCropModalOpen(true);
  };

  const selectLogoIcon = () => {
    setImageType('logoIcon');
    setIsCropModalOpen(true);
  };

  const onDone = (file) => {
    setIsCropModalOpen(false);
    upload(file, imageType);
  };

  return (
    <div className="row">
      <AvatarModal
        isOpen={isOpenCrop}
        onDone={onDone}
        withCrop={imageType === 'logoIcon'}
        onCancel={() => setIsCropModalOpen(false)}
      />
      <ImageUploadBlock
        src={logo}
        onClick={selectLogo}
        alt="logo"
        btnText="Upload logo"
      />
      <ImageUploadBlock
        src={icon}
        onClick={selectLogoIcon}
        alt="avatar"
        btnText="Upload avatar"
      />
    </div>
  );
}
