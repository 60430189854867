import { REGEX_EMAIL } from '../../constants/constants';

export default function validateStaffForm(inputs = {}, isCreate = true) {
  const errors = {
    firstName: null,
    lastName: null,
    email: null,
    facility: null,
    designation: null,
    username: null,
    privateCredentials: null,
  };
  let error = false;

  if (inputs.privateCredentials) {
    if (!inputs.lastName) {
      errors.lastName = 'Last name cannot be empty';
      error = true;
    } else if (/\s/.test(inputs.lastName)) {
      errors.lastName = 'Invalid last name';
      error = true;
    } else if (inputs.lastName.length > 50) {
      errors.firstName = 'Invalid last name';
      error = true;
    }

    if (!inputs.email) {
      errors.email = 'Email cannot be empty';
      error = true;
    } else if (!REGEX_EMAIL.test(inputs.email)) {
      errors.email = 'Invalid email';
      error = true;
    }

    if (!inputs.designation) {
      errors.designation = 'Designation cannot be empty';
      error = true;
    }
  }

  if (!inputs.facility) {
    errors.facility = 'Facility cannot be empty';
    error = true;
  }

  if (!inputs.privateCredentials) {
    if (!inputs.username) {
      errors.username = 'Username cannot be empty';
      error = true;
    } else if (/\s/.test(inputs.username)) {
      errors.username = 'Invalid username';
      error = true;
    }
  }

  if (isCreate && !inputs.password) {
    errors.password = 'Password cannot be empty';
    error = true;
  }

  return {
    errors,
    error,
  };
}
