import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import classNames from 'classnames';
import { connect } from 'react-redux';
import StarIcon from 'mdi-react/StarIcon';
import TableStatusFilterComponent from '../../shared/components/form/TableFilterComponent';
import { toggleLoading } from '../../redux/actions/commonActions';
import { SortingField } from '../common/SortingCell';

class AdminTableComponent extends Component {
  static propTypes = {
    heads: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string,
        sortable: PropTypes.bool,
      }),
    ).isRequired,
    rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  UNSAFE_componentWillMount() {
    this.props.toggleLoading(true);
  }

  componentWillUnmount() {
    if (this.props.common.loading) {
      this.props.toggleLoading(false);
    }
  }

  handleStatusFilter = (list) => {
    this.props.handleGridFilter('status', list);
  };

  render() {
    const { rows, heads, sortField, sortOrder } = this.props;

    const headText = heads.map((head) => head.key);

    const capitalizedText = heads.map((head) =>
      head.capitalize ? head.key : null,
    );

    const tableRows = rows.map((row, key) => {
      const _row = Object.keys(row).map((value, key) => {
        if (headText.includes(value)) {
          const capitalizeClass = capitalizedText.includes(value)
            ? 'text-capitalize'
            : '';

          const onClick = () => {
            if (value === 'fullName') {
              this.props.handleActiveStatus({ type: 'view', data: row });
            }

            if (value === 'facility') {
              this.props.handleViewFacility(row.facilityId);
            }
          };

          return (
            <td
              key={key}
              className={classNames({
                lastName: value === 'fullName' || value === 'facility',
                capitalizeClass,
              })}
              onClick={onClick}
            >
              {row[value]}
              {row.isPrimary && value === 'fullName' && (
                <StarIcon className="star-icon" />
              )}
            </td>
          );
        }
        return true;
      });

      return (
        <tr className="body-tr" key={key}>
          {_row}
        </tr>
      );
    });

    const headers = heads.map((head, key) => {
      return head.sortable && !head.filterable ? (
        <th key={key}>
          {sortField === head.sortKey ? (
            <SortingField
              icon={sortOrder === 1 ? 'triangle-up' : 'triangle-down'}
              name={head.name}
              onSort={() => this.props.handleGridSort(head.sortKey)}
            />
          ) : (
            <SortingField
              name={head.name}
              icon="diamond-narrow"
              onSort={() => this.props.handleGridSort(head.sortKey)}
            />
          )}
        </th>
      ) : head.key === 'status' ? (
        <th key={key}>
          <TableStatusFilterComponent
            allFilters={head.filterValue}
            action={(list) => this.handleStatusFilter(list)}
            label={head.name}
          />
        </th>
      ) : (
        <th key={key}>
          <p>{head.name}</p>
        </th>
      );
    });

    return (
      <div className="row">
        <div className="col-md-12">
          <Table striped className="custom-table loyal-table">
            <thead>
              <tr className="header-tr" style={{ top: '200px' }}>
                {headers}
              </tr>
            </thead>
            <tbody>{tableRows}</tbody>
          </Table>

          {rows.length === 0 && !this.props.common.loading && (
            <div className="no-data-message">No results found</div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = { toggleLoading };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminTableComponent);
