const { PHASES } = require('../config/app.config');

const constants = {
  FACILITY_INVITATION_STATUS: {
    active: 'active',
    inactive: 'inactive',
    removed: 'removed',
  },
  PASSWORD_PATTERN: '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d).{8,}$',
  appointmentPhases: {
    expected: {
      id: 1,
      name: PHASES['1'],
      color: '#00205B',
    },
    checkIn: {
      id: 2,
      name: PHASES['2'],
      color: '#00AEFF',
    },
    diagnostics: {
      id: 3,
      name: PHASES['3'],
      color: '#FFD900',
    },
    preparation: {
      id: 4,
      name: PHASES['4'],
      color: '#2DDE98',
    },
    procedure: {
      id: 5,
      name: PHASES['5'],
      color: '#0bbd0b',
    },
    dental: {
      id: 6,
      name: PHASES['6'],
      color: '#129d58',
    },
    surgery: {
      id: 7,
      name: PHASES['7'],
      color: '#006935',
    },
    recovery: {
      id: 8,
      name: PHASES['8'],
      color: '#FF0092',
    },
    hospitalized: {
      id: 9,
      name: PHASES['9'],
      color: '#774AA4',
    },
    boarding: {
      id: 10,
      name: PHASES['10'],
      color: '#862633',
    },
    discharge: {
      id: 11,
      name: PHASES['11'],
      color: '#FF6A00',
    },
    completed: {
      id: 12,
      name: PHASES['12'],
      color: '#1CC7D0',
    },
  },
  chartPhases: {
    expected: {
      id: 1,
      name: 'expected',
      color: '#00205B',
    },
    checkin: {
      id: 2,
      name: 'checkin',
      color: '#00AEFF',
    },
    diagnostics: {
      id: 3,
      name: 'diagnostics',
      color: '#FFD900',
    },
    preparation: {
      id: 4,
      name: 'preparation',
      color: '#2dde98',
    },
    procedure: {
      id: 5,
      name: 'procedure',
      color: '#0bbd0b',
    },
    dental: {
      id: 6,
      name: 'dental',
      color: '#129d58',
    },
    surgery: {
      id: 7,
      name: 'surgery',
      color: '#006935',
    },
    recovery: {
      id: 8,
      name: 'recovery',
      color: '#FF0092',
    },
    hospitalized: {
      id: 9,
      name: 'hospitalized',
      color: '#774AA4',
    },
    boarding: {
      id: 10,
      name: 'boarding',
      color: '#862633',
    },
    discharge: {
      id: 11,
      name: 'discharge',
      color: '#FF6A00',
    },
    completed: {
      id: 12,
      name: 'completed',
      color: '#1CC7D0',
    },
  },
  STATUS: {
    ACTIVE: 'active',
    INACTIVE: 'inactive',
  },
  userRole: {
    admin: 'admin',
    superAdmin: 'super_admin',
    staff: 'staff',
    client: 'client',
  },
  BROADCAST_TYPES: [
    {
      id: 'all_users',
      name: 'All Users',
    },
    {
      id: 'all_registered_parents',
      name: 'All Registered Parents',
    },
    {
      id: 'all_supporters',
      name: 'All Supporters',
    },
    {
      id: 'parents_canine_type',
      name: 'All Dog Parents (Canine type)',
    },
    {
      id: 'parents_feline_type',
      name: 'All Cat Parents (Feline type)',
    },
    {
      id: 'all_surgery_patients',
      name: 'All Surgery Patients',
    },
    {
      id: 'surgery_visited_last_7days',
      name: 'All Surgery Patients. Visited in Last 7 Days',
    },
    {
      id: 'surgery_visited_last_30days',
      name: 'All Surgery Patients. Visited in Last 30 Days',
    },
    {
      id: 'all_dental_patients',
      name: 'All Dental Patients',
    },
    {
      id: 'dental_visited_last_7days',
      name: 'All Dental Patients. Visited in Last 7 Days',
    },
    {
      id: 'dental_visited_last_30days',
      name: 'All Dental Patients. Visited in Last 30 Days',
    },
    {
      id: 'last_visit_180days_ago',
      name: 'Last Visit Was More Than 180 Days Ago',
    },
    {
      id: 'new_users_within_2days',
      name: 'New Users Within 2 Days',
    },
    {
      id: 'new_users_within_7days',
      name: 'New Users Within 7 Days',
    },
  ],
  CLIENT_TABLE_FIELDS: {
    petName: 'petName',
    parentName: 'parentName',
  },
  QUERY_TAB_PARAMS: {
    petInfo: 'petInfo',
    parent: 'parent',
    appointments: 'appointments',
    refillRequests: 'refillRequests',
  },
  QUERY_PARAMS: {
    pet: 'pet',
  },
  NA: 'N/A',
  REGEX_DIGIT: /[^\d]+/g,
  REGEX_PHONE: /(\d{3})(\d{3})(\d{4})/,
  TRACKING_BOARD_DATE_FORMAT: 'dddd; MMMM D, YYYY',
  TOPBAR_DATE_FORMAT: 'dddd, MMMM D, YYYY HH:mm:ss',
  DEFAULT_TIMESTAMP_FORMAT: 'MM/DD/YYYY',
  REGEX_EMAIL:
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  MASKS: {
    phone: [
      '(',
      /[1-9]/,
      /\d/,
      /\d/,
      ')',
      ' ',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ],
    ext: [/[1-9]/, /\d/, /\d/, /\d/, /\d/],
    date: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
  },
};

module.exports = constants;
