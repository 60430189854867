import React from 'react';
import { Button } from 'reactstrap';

export function PlusButton({ children, onClick, className }) {
  return (
    <Button
      onClick={onClick}
      className={className || 'btn loyal-btn-outline-primary'}
      color="primary"
    >
      <span className="lnr lnr-plus-circle" />
      <span className="text">{children}</span>
    </Button>
  );
}
