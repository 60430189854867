import React, { Component } from 'react';
import { Label } from 'reactstrap';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import DashboardFacilityFilterComponent from '../../shared/components/form/DashboardFacilityFilterComponent';
import TimeRangeSelect from '../../shared/components/inputs/TimeRangeSelect/TimeRangeSelect';
import constant from '../../constants/constants';
import phases from '../../constants/phases';

export default class PhaseHistoryComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newUser: null,
      type: 'daily',
      phasesSelected: null,

      data: [],
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ data: nextProps.chartData });
  }

  handleFilter = (params) => {
    this.setState({ phasesSelected: params });
    this.props.handleHistoryPhaseChange(params);
  };

  handleType = (e) => {
    const type = e.target.value;
    this.setState({ type }, () => {
      this.props.handlePhaseHistoryType(type);
    });
  };

  loadLine = () => {
    const { data, phasesSelected } = this.state;
    if (data.length) {
      let strokes = data[0];
      delete strokes.name;
      strokes = Object.keys(strokes);
      return strokes.forEach((line) => {
        const color = constant.chartPhases[line]
          ? constant.chartPhases[line].color
          : null;
        if (phasesSelected) {
          const isExists = phasesSelected.includes(
            constant.chartPhases[line].id,
          );
          if (isExists) {
            return (
              <Line type="linear" dataKey={line} stroke={color} fill={color} />
            );
          }
        } else {
          return (
            <Line type="linear" dataKey={line} stroke={color} fill={color} />
          );
        }
      });
    }
  };

  render() {
    const { data } = this.state;
    const { processedDate } = this.props;
    return (
      <div className="total-average new-user phase-history">
        <div className="header-container">
          <h3>AVG Time Spent in Phases</h3>
          <TimeRangeSelect handleTypeSelect={this.handleType} />
          <p className="loyal-last-process-date">
            Last updated {processedDate}
          </p>
        </div>
        <div className="sub-header-container clearfix">
          <div className="wrapper">
            <Label>Phase</Label>
            <DashboardFacilityFilterComponent
              filterTypeTextPlural="Phases"
              filterTypeText="Phase"
              facilities={phases}
              handleFilter={this.handleFilter}
            />
          </div>
        </div>
        <div className="chart">
          <ResponsiveContainer width="100%" height={240}>
            <LineChart
              data={data}
              syncId="averageTimeSpent"
              margin={{ top: 10, right: 20, left: 0, bottom: 0 }}
            >
              <CartesianGrid strokeDasharray="0 0" vertical={false} />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              {this.loadLine()}
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div className="total-average-wrapper">
          <div className="bullet-wrapper">
            <div className="bullets">
              <span key="expected">
                <span className="expected" />
                &nbsp;&nbsp; Expected
              </span>
              <span key="procedure">
                <span className="procedure" />
                &nbsp;&nbsp; Procedure
              </span>
              <span key="hospitalized">
                <span className="hospitalized" />
                &nbsp;&nbsp; Hospitalized
              </span>
            </div>
            <div className="bullets">
              <span key="checkin">
                <span className="checkin" />
                &nbsp;&nbsp; Check-In
              </span>
              <span key="dental">
                <span className="dental" />
                &nbsp;&nbsp; Dental
              </span>
              <span key="boarding">
                <span className="boarding" />
                &nbsp;&nbsp; Boarding
              </span>
            </div>
            <div className="bullets">
              <span key="diagnostic">
                <span className="diagnostic" />
                &nbsp;&nbsp; Diagnostic
              </span>
              <span key="surgery">
                <span className="surgery" />
                &nbsp;&nbsp; Surgery
              </span>
              <span key="discharge">
                <span className="discharge" />
                &nbsp;&nbsp; Discharge
              </span>
            </div>
            <div className="bullets">
              <span key="preparation">
                <span className="preparation" />
                &nbsp;&nbsp; Preparation
              </span>
              <span key="recovery">
                <span className="recovery" />
                &nbsp;&nbsp; Recovery
              </span>
              <span key="completed">
                <span className="completed" />
                &nbsp;&nbsp; Completed
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
