import {
  FETCH_REFILL_REQUESTS,
  FETCH_REFILL_REQUESTS_ERROR,
  CHANGE_REFILL_REQUEST_STATUS,
  CHANGE_REFILL_REQUEST_STATUS_ERROR,
  FETCH_REFILL_REQUEST_STATUSES,
  FETCH_REFILL_REQUEST_STATUSES_ERROR,
  FETCH_REFILL_REQUEST_STAFF_COMMENTS,
  FETCH_REFILL_REQUEST_STAFF_COMMENTS_ERROR,
  ADD_REFILL_REQUEST_STAFF_COMMENTS,
  ADD_REFILL_REQUEST_STAFF_COMMENTS_ERROR,
} from '../actions/refillRequestActions';

export const initialState = {
  refillRequestsList: [],
  refillRequestsListError: null,
  updatedRefillRequest: null,
  updatedRefillRequestError: null,
  refillRequestStatuses: null,
  refillRequestStatusesError: null,
  refillRequestStaffComments: null,
  refillRequestStaffCommentsError: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_REFILL_REQUESTS:
      return {
        ...state,
        refillRequestsList: action.payload,
        refillRequestsListError: null,
      };
    case FETCH_REFILL_REQUESTS_ERROR:
      return {
        ...state,
        refillRequestsList: [],
        refillRequestsListError: action.payload,
      };
    case CHANGE_REFILL_REQUEST_STATUS:
      return {
        ...state,
        updatedRefillRequest: action.payload,
        updatedRefillRequestError: null,
      };
    case CHANGE_REFILL_REQUEST_STATUS_ERROR:
      return {
        ...state,
        updatedRefillRequest: null,
        updatedRefillRequestError: action.payload,
      };
    case FETCH_REFILL_REQUEST_STATUSES:
      return {
        ...state,
        refillRequestStatuses: action.payload,
        refillRequestStatusesError: null,
      };
    case FETCH_REFILL_REQUEST_STATUSES_ERROR:
      return {
        ...state,
        refillRequestStatuses: [],
        refillRequestStatusesError: action.payload,
      };
    case FETCH_REFILL_REQUEST_STAFF_COMMENTS:
    case ADD_REFILL_REQUEST_STAFF_COMMENTS:
      return {
        ...state,
        refillRequestStaffComments: action.payload,
        refillRequestStaffCommentsError: null,
      };
    case FETCH_REFILL_REQUEST_STAFF_COMMENTS_ERROR:
      return {
        ...state,
        refillRequestStaffComments: [],
        refillRequestStaffCommentsError: action.payload,
      };
    case ADD_REFILL_REQUEST_STAFF_COMMENTS_ERROR:
      return {
        ...state,
        refillRequestStaffCommentsError: action.payload,
      };
    default:
      return state;
  }
}
