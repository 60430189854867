import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { rootReducer } from '../../redux/reducers/index';

const enhancers = [];
const middleware = [thunk];

const composedEnhancers = compose(
  composeWithDevTools(applyMiddleware(...middleware), ...enhancers),
);

const store = createStore(rootReducer, composedEnhancers);

export default store;
