import React, { Component } from 'react';
import { Modal } from 'reactstrap';
import { connect } from 'react-redux';
import Moment from 'react-moment';

class PetSuccessModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onClose = () => {
    this.props.onClose();
  };

  render() {
    const { message, show } = this.props;
    const checkImage = `${process.env.PUBLIC_URL}/img/check-line-icon.png`;

    return (
      <Modal
        isOpen={show}
        className="modal-dialog modal-dialog--alert modal-dialog--header alert-modal ptb-success-popup"
      >
        <div className="modal__body">
          <img src={checkImage} alt="check" />
          <p>{message && message.text} </p>

          <div className="footer-text">
            <span className="date">
              {message && <Moment format="MM/DD/YYYY"></Moment>}
            </span>
            <span>{message && <Moment format="hh:mm A"></Moment>}</span>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PetSuccessModal);
