import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Collapse, FormGroup, Row } from 'reactstrap';
import { getParent } from '../../../../redux/actions/petActions';
import Error from '../../../../shared/components/form/Error';
import MaskedInput from '../../../../shared/components/inputs/Masked';

class RegisterParent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parentId: null,
      refreshState: false,
      inputs: {
        firstName: '',
        lastName: '',
        phoneEmail: '',
      },
    };
  }

  onlyNumberKey = (evt) => {
    // Only ASCII charactar in that range allowed
    const ASCIICode = evt.which ? evt.which : evt.keyCode;
    return !(ASCIICode < 48 || ASCIICode > 57);
  };

  onChangeFields = (e) => {
    const { name: property, value } = e.target;

    let { inputs, refreshState } = this.state;
    refreshState = !refreshState;

    inputs[property] = value;
    this.setState({
      inputs,
      refreshState,
    });
    this.props.parentInputsSet(inputs);
  };

  UNSAFE_componentWillReceiveProps(np) {
    if (np.parentInputs) {
      this.setState({ inputs: np.parentInputs });
    }
  }

  render() {
    const { collapse, parentErrors } = this.props;
    const { inputs } = this.state;

    return (
      <div className="content-holder find-parent-wrapper">
        <div className="row">
          <div className="col-md-12">
            <h3 className={!collapse ? 'loyal-disable-tab-text' : ''}>
              Invite New Parent
            </h3>
          </div>
        </div>
        <Collapse isOpen={collapse}>
          <Row>
            <div className="register-parent">
              <div style={{ marginRight: '10px' }}>
                <img
                  className="loyal-parent-image"
                  src="/img/default-black.png"
                  alt="avatar"
                />
              </div>
              <div className="loyal-custom-form-group">
                <span className="form__form-group-label">
                  Mobile Number. <span className="required">*</span>
                </span>
                <div className="form__form-group-field">
                  <FormGroup>
                    <MaskedInput
                      name="phoneEmail"
                      placeholder="Type Mobile Number"
                      value={inputs.phoneEmail}
                      onChange={this.onChangeFields}
                      autoComplete="off"
                    />
                    {parentErrors.phoneEmail.error && (
                      <Error text={parentErrors.phoneEmail.error} />
                    )}
                  </FormGroup>
                </div>
              </div>

              <div className="loyal-custom-form-group">
                <span className="form__form-group-label">First Name</span>
                <div className="form__form-group-field">
                  <FormGroup>
                    <input
                      className="form-control"
                      name="firstName"
                      type="text"
                      placeholder="Type First Name"
                      value={inputs.firstName}
                      maxLength={50}
                      onChange={this.onChangeFields}
                      autoComplete="off"
                    />
                    {parentErrors.firstName.error && (
                      <Error text={parentErrors.firstName.error} />
                    )}
                  </FormGroup>
                </div>
              </div>

              <div className="loyal-custom-form-group">
                <span className="form__form-group-label">Last Name</span>
                <div className="form__form-group-field">
                  <FormGroup>
                    <input
                      className="form-control"
                      name="lastName"
                      type="text"
                      placeholder="Type Last Name"
                      value={inputs.lastName}
                      maxLength={50}
                      onChange={this.onChangeFields}
                      autoComplete="off"
                    />
                    {parentErrors.lastName.error && (
                      <Error text={parentErrors.lastName.error} />
                    )}
                  </FormGroup>
                </div>
              </div>
            </div>
          </Row>
        </Collapse>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = {
  getParent,
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterParent);
