import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { refillRequestDeliveryTypes } from '../../../../config/app.config';
import { FooterButtons } from '../../../../shared/components/Modals/FooterButtons';

import {
  useRefillRequestModalContext,
  getRefillRequestsHeaders,
  useStatusChangeModalContext,
} from '../utils';
import {
  AddressField,
  StatusHistoryTable,
  StatusDropdown,
  getFilteredStatuses,
} from '../Components';

export function MainInfoTab() {
  const { closeRefillRequestModal, refillRequestData } =
    useRefillRequestModalContext();
  const { prepareConfirmationMessage } = useStatusChangeModalContext();

  const heads = getRefillRequestsHeaders();
  const allStatuses = useSelector(
    (state) => state.refillRequests.refillRequestStatuses || [],
  );
  const dropdownStatuses = getFilteredStatuses(
    refillRequestData.deliveryType,
    allStatuses,
  );

  const isPickupDelivery = refillRequestData.deliveryType === 'pickup';

  const handleConfirmationModalOpen = (newStatus) => {
    prepareConfirmationMessage({
      currentStatus: refillRequestData.status,
      newStatus: newStatus.label,
      newStatusId: newStatus._id,
      prescriptionId: refillRequestData.id,
    });
  };

  const renderRefillRequestFields = () => {
    const excludedKeys = ['date', 'time', 'attachments', 'internalComments'];

    const additionalFields = [
      { key: 'address', name: 'Shipping Address' },
      { key: 'dateTime', name: 'Date and Time' },
    ];

    const headsExtraFields = heads
      .filter(({ key }) => !excludedKeys.includes(key))
      .concat(additionalFields);

    const { zipCode, state, city, address } = refillRequestData;

    return headsExtraFields.map(
      ({ key, name, capitalize, display, link: linkToProfile }) => {
        let dataToShow = refillRequestData[key];
        const link = linkToProfile?.(refillRequestData);
        let addEllipsisClass = !link; // for link ellipsis class is added to anchor tag

        if (key === 'deliveryType' && dataToShow === 'delivery') {
          dataToShow = refillRequestDeliveryTypes.delivery;
        }

        if (key === 'address') {
          return (
            <AddressField
              key={key}
              addressData={[address, city, state, zipCode]}
            />
          );
        }

        if (key === 'comments') {
          addEllipsisClass = false;
          name = 'Client Comments';
        }

        if (key === 'comments' && !dataToShow.trim()) {
          dataToShow = 'No comments provided';
        }

        if (key === 'status') {
          return (
            <div
              key={key}
              className="status modal-main-info-field"
              style={{ gridArea: 'status' }}
            >
              <p className="field-label">{name}</p>
              <StatusDropdown
                currentStatus={refillRequestData.status}
                statuses={dropdownStatuses}
                onStatusChange={handleConfirmationModalOpen}
                className="field-data text-capitalize"
              />
            </div>
          );
        }

        return (
          <div
            key={key}
            className={`${key} modal-main-info-field `}
            style={{ gridArea: key, display: display ?? 'block' }}
          >
            <p className="field-label">{name}</p>
            <p
              className={classNames('field-data', {
                'text-capitalize': capitalize,
                ellipsis: addEllipsisClass,
              })}
              title={dataToShow}
            >
              {link ? (
                <Link to={link} className="ellipsis">
                  {dataToShow}
                </Link>
              ) : (
                dataToShow
              )}
            </p>
          </div>
        );
      },
    );
  };

  return (
    <>
      <section className="main-info-tab">
        <div className="scrollable-modal-area">
          <div
            className={classNames('modal-main-info-data', {
              'no-address': isPickupDelivery,
            })}
          >
            {renderRefillRequestFields()}
          </div>

          <div className="status-history-wrapper modal-table">
            <h3>Status History</h3>
            <StatusHistoryTable refillRequestData={refillRequestData} />
          </div>
        </div>
      </section>

      <FooterButtons onClose={closeRefillRequestModal} showSaveButton={false} />
    </>
  );
}
