import React, { Component, createRef } from 'react';
import { Card, CardBody, Col, FormGroup, Label } from 'reactstrap';
import PlusIcon from 'mdi-react/PlusCircleOutlineIcon';
import { connect } from 'react-redux';
import StaffTableComponent from './StaffTableComponent';
import Pagination from '../../shared/components/pagination/Pagination';
import {
  getStaffList,
  getAllFacilities,
  getAllDesignations,
  addNewStaffMember,
  updateStaffStatus,
  reinviteStaff,
} from '../../redux/actions/staffActions';
import {
  toggleMessageModel,
  toggleLoading,
} from '../../redux/actions/commonActions';
import ViewStaff from './ViewStaff';
import CustomSelectComponent from '../../shared/components/form/CustomSelectComponent';
import MessageModal from '../../shared/components/Modals/MessageModal';
import localStorage from '../../libs/storageHelper';
import config from '../../config/app.config';
import HeadingText from '../../shared/components/form/HeadingText';
import responseMessages from '../../constants/responseMessages';
import defaultStaffColumns, { facilityColumn } from './constants';
import InputWithSearchIcon from '../common/InputWithSearchIcon';
import { isPrimaryAdmin, isSuperAdmin } from '../../utils/Utils';
import constants from '../../constants/constants';
import AddNewStaffModal from './AddNewStaffModal';
const { STATUS } = constants;

const buildFacilityOptions = (facilities = []) => {
  return [
    <option key="" value="">
      Select
    </option>,
  ].concat(
    facilities.map((value) => (
      <option
        key={value.id}
        value={value.id}
        disabled={value.status === STATUS.INACTIVE}
      >
        {value.name}
      </option>
    )),
  );
};

class Staff extends Component {
  searchRef = createRef();

  constructor() {
    super();
    const loggedUser = localStorage.getFromStorage('loggedUser').user;
    const facilities =
      loggedUser.type === config.USER_TYPES.SUPER_ADMIN
        ? []
        : [loggedUser.facility];

    this.modalTimeOutRef = null;
    this.state = {
      isInvite: true,
      staff: [],
      filters: {
        search: '',
        facilities,
        offset: 0,
        limit: config.DEFAULT_PAGINATION_LENGTH,
        total: 0,
        status: [],
        sortField: 'fullName',
        sortOrder: 1,
      },
      modal: false,
      staffColumns: defaultStaffColumns,
      facilityOptions: [],
      error: false,
      successResponse: null,
      errorResponse: null,
      refreshState: true,
      selectedId: null,
      selectedMember: {},
      viewPopup: false,
      confirmPopup: {
        showResponse: false,
        responseMessage: '',
        responseType: '',
        responseAlertType: '',
        memberId: null,
        status: null,
        footer: false,
      },
      loggedUser,
    };
  }

  componentDidMount() {
    const { loggedUser } = this.state;
    if (loggedUser.type === config.USER_TYPES.SUPER_ADMIN) {
      this.props.getAllFacilities();
    }
    this.props.getStaffList(this.state.filters);
    this.props.getAllDesignations();
  }

  getCurrentUserFacilityOption = (props) => {
    if (!props.currentFacility) return [];

    return [
      {
        name: props.currentFacility.name,
        id: props.currentFacility._id,
      },
    ];
  };

  UNSAFE_componentWillReceiveProps(np) {
    if (np.staffList) {
      this.setState({
        filters: {
          ...this.state.filters,
          total: np.staffList.total,
          limit: np.staffList.perPage,
          offset: (np.staffList.page - 1) * np.staffList.perPage,
        },
        viewPopup: false,
      });

      if (isSuperAdmin(this.state.loggedUser)) {
        const staffColumns = [...defaultStaffColumns];
        staffColumns.splice(staffColumns.length - 1, 0, facilityColumn);

        this.setState({
          staffColumns,
        });
      }
    }

    this.setState({
      facilityOptions: buildFacilityOptions(
        np.facilityAll || this.getCurrentUserFacilityOption(np),
      ),
    });

    if (np.newStaffResponse) {
      const { isInvite } = this.state;
      const { SUCCESS } = responseMessages;

      const successResponse = isInvite
        ? SUCCESS.MEMBER_INVITATION_SENT
        : SUCCESS.MEMBER_ADDED;

      this.setState({ successResponse, errorResponse: null });
      this.closeModalWithTimeOut();
    }
    if (np.newStaffErrorResponse) {
      if (np.newStaffErrorResponse.errorMessage) {
        this.setState({
          errorResponse: np.newStaffErrorResponse.errorMessage,
          successResponse: null,
        });
      }
    }
    if (np.staffUpdateStatus) {
      const { confirmPopup } = this.state;
      let status = '';
      switch (confirmPopup.status) {
        case 'active':
          status = 'activated';
          break;
        case 'inactive':
          status = 'deactivated';
          break;
        case 'removed':
          status = 'removed';
          break;
        default:
          break;
      }
      confirmPopup.showResponse = true;
      confirmPopup.responseMessage = `This profile is ${status} now`;
      confirmPopup.responseType = 'alert';
      confirmPopup.responseAlertType = 'success';
      confirmPopup.footer = true;
      this.setState({ confirmPopup }, function () {
        this.props.getStaffList(this.state.filters);
      });
    }
    if (np.staffReinviteStatus) {
      const { confirmPopup } = this.state;
      confirmPopup.showResponse = true;
      confirmPopup.responseMessage = 'Invitation sent successfully ';
      confirmPopup.responseType = 'alert';
      confirmPopup.responseAlertType = 'success';
      confirmPopup.footer = true;
      this.setState({ confirmPopup }, function () {
        this.props.getStaffList(this.state.filters);
      });
    }
    if (np.updateStaffResponse) {
      this.props.getStaffList(this.state.filters);
    }
  }

  componentDidUpdate(np) {
    if (np.common.loading) {
      this.props.toggleLoading(false);
    }
  }

  closeModalWithTimeOut = () => {
    this.modalTimeOutRef = setTimeout(() => {
      this.closeModal();
    }, config.NEW_STAFF_POPUP_TIMEOUT_TIME);
  };

  viewProfile = (selectedMember) => {
    this.setState({
      selectedId: selectedMember.id,
      viewPopup: true,
      selectedMember,
    });
  };

  nextPage = () => {
    const { filters } = this.state;
    filters.offset = parseInt(filters.offset + filters.limit);
    this.setState({ filters }, () => {
      this.props.getStaffList(filters);
    });
  };

  prevPage = () => {
    const { filters } = this.state;
    filters.offset = parseInt(filters.offset - filters.limit);
    this.setState({ filters }, () => {
      this.props.getStaffList(filters);
    });
  };

  handleSearch = (e) => {
    const search = e.target.value;
    const { filters } = this.state;
    filters.search = search;
    filters.offset = 0;
    this.setState({ filters }, () => {
      this.props.getStaffList(filters);
    });
  };

  handleFilter = (list) => {
    const { filters } = this.state;
    filters.facilities = list;
    filters.offset = 0;
    this.setState({ filters }, () => {
      this.props.getStaffList(filters);
    });
  };

  handleGridFilter = (header, value) => {
    const { filters } = this.state;
    if (header === 'facilities') {
      filters.facilities = value;
    } else {
      filters.status = value;
    }
    filters.offset = 0;
    this.setState({ filters }, () => {
      this.props.getStaffList(filters);
    });
  };

  handlePageLimit = (perPage) => {
    const { filters } = this.state;
    filters.limit = perPage;
    filters.offset = 0;
    this.setState({ filters }, () => {
      this.props.getStaffList(filters);
    });
  };

  gotoPage = (i) => {
    const { filters } = this.state;
    filters.offset = parseInt(filters.limit * i);
    this.setState({ filters }, () => {
      this.props.getStaffList(filters);
    });
  };

  onSubmit = (staff) => {
    this.props.toggleLoading(true);
    this.setState({ isInvite: staff.privateCredentials });
    this.props.addNewStaffMember(staff);
  };

  closeModal = () => {
    if (this.modalTimeOutRef) {
      clearTimeout(this.modalTimeOutRef);
    }
    this.setState({
      modal: false,
      successResponse: null,
      errorResponse: null,
      selectedId: null,
      viewPopup: false,
    });
    this.props.getStaffList(this.state.filters);
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
      error: false,
      successResponse: null,
      errorResponse: null,
      selectedId: null,
      viewPopup: false,
    });
  };

  handleGridSort = (field) => {
    const { filters } = this.state;
    const order = filters.sortOrder === 1 ? -1 : 1;
    filters.sortField = field;
    filters.sortOrder = order;

    this.setState({ filters }, () => {
      this.props.getStaffList(filters);
    });
  };

  handleActiveStatus = (status, id, firstName) => {
    let statusMessage = '';
    let confirmType = 'change_status';
    switch (status) {
      case 'active':
        statusMessage =
          'Are you sure you want to activate this staff profile? ';
        break;
      case 'inactive':
        statusMessage =
          'Are you sure you want to deactivate this staff profile? ';
        break;
      case 'removed':
        statusMessage = 'Are you sure you want to remove this staff profile? ';
        break;
      case 'reinvite':
        statusMessage = `Are you sure you want to reinvite ${firstName}? `;
        confirmType = 'reinvite';
        break;
      default:
        break;
    }
    const confirmPopup = {
      showResponse: true,
      responseMessage: statusMessage,
      responseType: 'confirm',
      responseAlertType: null,
      staffId: id,
      footer: true,
      confirmType,
      status,
    };
    this.setState({ confirmPopup, showResponse: true });
  };

  onSuccess = () => {
    const { confirmPopup } = this.state;
    if (confirmPopup.confirmType === 'change_status') {
      this.props.updateStaffStatus(confirmPopup.staffId, {
        id: confirmPopup.staffId,
        status: confirmPopup.status,
      });
    } else if (confirmPopup.confirmType === 'reinvite') {
      this.props.reinviteStaff({ id: confirmPopup.staffId });
    }

    confirmPopup.showResponse = false;
    this.setState({ showResponse: false, confirmPopup });
  };

  handleClearSearch = () => {
    const newFilter = { ...this.state.filters, search: '' };

    this.setState({ filters: newFilter }, () => {
      this.props.getStaffList(newFilter);
    });
  };

  closePopup = () => {
    const { confirmPopup } = this.state;
    let { refreshState } = this.state;
    confirmPopup.showResponse = false;
    refreshState = !refreshState;
    this.setState({ confirmPopup, refreshState });
  };

  render() {
    const {
      facilityAll = [],
      staffList: { staff = [] },
    } = this.props;

    const {
      successResponse,
      errorResponse,
      loggedUser,
      viewPopup,
      selectedId,
      selectedMember,
      confirmPopup,
      staffColumns,
      filters,
      facilityOptions,
    } = this.state;

    const allowedToAddStaff =
      isPrimaryAdmin(loggedUser) || isSuperAdmin(loggedUser);

    return (
      <Col md={12} lg={12} className="manage-staff-container admin-container">
        <Card>
          <CardBody>
            <div className="sticky-table">
              <HeadingText text="Manage Staff" />
              <div className="row search form">
                <div className="col-sm-6">
                  <div className="form__form-group">
                    <Label for="exampleSelect" className="empty-label">
                      &nbsp;
                    </Label>

                    <InputWithSearchIcon
                      name="search"
                      id="search"
                      type="text"
                      onChange={this.handleSearch}
                      placeholder="Search by Name, Designation, Email address, or Username"
                      value={this.state.filters.search}
                      onClear={this.handleClearSearch}
                      ref={this.searchRef}
                      autofocus
                    />
                  </div>
                </div>
                <div className="col-sm-3">
                  {isSuperAdmin(loggedUser) && (
                    <FormGroup>
                      <Label for="exampleSelect" className="loyal-header-label">
                        Filter by facility
                      </Label>
                      <CustomSelectComponent
                        allFilters={facilityAll}
                        filterTypeText="Facility"
                        filterTypeTextPlural="Facilities"
                        action={(list) => this.handleFilter(list)}
                      />
                    </FormGroup>
                  )}
                </div>
                <div className="col-sm-3">
                  {allowedToAddStaff && (
                    <FormGroup>
                      <Label for="exampleSelect" className="empty-label">
                        &nbsp;
                      </Label>
                      <div
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                      >
                        <button
                          className="btn btn-outline-primary pull-right top-panel-button"
                          style={{ flexShrink: 0 }}
                          onClick={this.toggle}
                        >
                          <PlusIcon />
                          Add New Staff Member
                        </button>
                      </div>
                    </FormGroup>
                  )}
                </div>
              </div>
            </div>

            <StaffTableComponent
              sortField={filters.sortField}
              sortOrder={filters.sortOrder}
              heads={staffColumns}
              rows={staff}
              handleGridSort={(field) => this.handleGridSort(field)}
              handleGridFilter={(header, field) =>
                this.handleGridFilter(header, field)
              }
              viewProfile={(field) => this.viewProfile(field)}
              handleActiveStatus={
                allowedToAddStaff ? this.handleActiveStatus : undefined
              }
            />
            <Pagination
              nextPage={this.nextPage}
              prevPage={this.prevPage}
              handlePageLimit={(limit) => this.handlePageLimit(limit)}
              gotoPage={(i) => this.gotoPage(i)}
              limit={filters.limit}
              offset={filters.offset}
              total={filters.total}
            />
          </CardBody>
        </Card>
        <AddNewStaffModal
          isOpen={this.state.modal}
          onClose={this.closeModal}
          onSubmit={this.onSubmit}
          successResponse={successResponse}
          errorResponse={errorResponse}
        />
        <ViewStaff
          selectedId={selectedId}
          selectedMember={selectedMember}
          view={viewPopup}
          data={{ facilityOptions }}
        />
        <MessageModal
          show={confirmPopup.showResponse}
          type={confirmPopup.responseType}
          alertType={confirmPopup.responseAlertType}
          footer={confirmPopup.footer}
          onSuccess={this.onSuccess}
          onClose={this.closePopup}
          message={confirmPopup.responseMessage}
        />
      </Col>
    );
  }
}

const mapStateToProps = (state) => ({
  currentFacility: state.user?.profile?.facility,
  designationAll: state.staff.designationAll,
  newStaffResponse: state.staff.newStaffResponse,
  newStaffErrorResponse: state.staff.newStaffErrorResponse,
  staffList: state.staff.staffList || {},
  facilityAll: state.staff.facilityAll,
  staffReinviteStatus: state.staff.staffReinviteStatus,
  staffUpdateStatus: state.staff.staffUpdateStatus,
  updateStaffResponse: state.staff.updateStaffResponse,
  common: state.common,
});

const mapDispatchToProps = {
  getStaffList,
  getAllFacilities,
  getAllDesignations,
  addNewStaffMember,
  updateStaffStatus,
  toggleMessageModel,
  reinviteStaff,
  toggleLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(Staff);
