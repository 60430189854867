export const daysOfWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const parseAvailabilityInfo = (availabilityInfo) => {
  const availability = {};
  let otherComments = '';

  availabilityInfo.split('\n').forEach((line) => {
    line = line.trim();

    if (line.startsWith('Other Comments:')) {
      otherComments += line.replace('Other Comments:', '').trim() + '\n';
      return;
    }

    const indexOfColon = line.indexOf(':');
    if (indexOfColon !== -1) {
      const potentialDay = line.substring(0, indexOfColon).trim();
      if (daysOfWeek.includes(potentialDay)) {
        const day = potentialDay;
        const dayInfo = line.substring(indexOfColon + 1).trim();
        if (dayInfo) {
          availability[day] = dayInfo;
        }
        return;
      }
    }

    if (line) {
      otherComments += line + '\n';
    }
  });

  return { availability, otherComments };
};
