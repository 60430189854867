const CLOUDINARY_BASE_FOLDER =
  process.env.LOYALPET_CLOUDINARY_BASE_FOLDER || 'development';
const CLOUDINARY_RES_BASE = `https://res.cloudinary.com/${
  process.env.LOYALPET_CLOUDINARY_NAME || ''
}/image/upload`;
const CLOUDINARY_API_BASE = `https://api.cloudinary.com/v1_1/${
  process.env.LOYALPET_CLOUDINARY_NAME || ''
}/image/upload`;
const PROFILE_IMAGE_FOLDER = 'profile';

const Config = {
  BASE_URL: process.env.LOYALPET_BASE_URL || '',
  BASE_API_CLIENT_URL: process.env.LOYALPET_BASE_API_CLIENT_URL || '',
  SOCKET_URL: process.env.LOYALPET_SOCKET_URL || '',
  GOOGLE_API_KEY: process.env.LOYALPET_GOOGLE_API_KEY || '',
  STRIPE_URL: process.env.LOYALPET_STRIPE_URL || '',
  SUPPORT_TICKETS_URL: process.env.LOYALPET_SUPPORT_TICKETS_URL || '',
  CLOUDINARY_VIEW_URL: `${CLOUDINARY_RES_BASE}/${CLOUDINARY_BASE_FOLDER}`,
  CLOUDINARY_URL: CLOUDINARY_API_BASE,
  CLOUDINARY_BASE_FOLDER,
  PET_PROFILE_IMAGE_PATH: `${CLOUDINARY_RES_BASE}/${CLOUDINARY_BASE_FOLDER}/pet/profile/`,
  PARENT_PROFILE_IMAGE_PATH: `${CLOUDINARY_RES_BASE}/${CLOUDINARY_BASE_FOLDER}/${PROFILE_IMAGE_FOLDER}`,
  FACILITY_PROFILE_IMAGE_PATH: `${CLOUDINARY_RES_BASE}/c_pad,h_78,w_138/${CLOUDINARY_BASE_FOLDER}/${PROFILE_IMAGE_FOLDER}/`,
  FACILITY_PROFILE_ICON_IMAGE_PATH: `${CLOUDINARY_RES_BASE}/c_pad,h_140,w_140/${CLOUDINARY_BASE_FOLDER}/${PROFILE_IMAGE_FOLDER}`,
  PET_PROFILE_PICTURE_PATH: `${CLOUDINARY_RES_BASE}/w_160,c_fill,ar_1:1,g_auto,r_max/${CLOUDINARY_BASE_FOLDER}/pet/profile`,
  PET_PROFILE_ADDITIONAL_DATA_IMAGE_PATH: `${CLOUDINARY_RES_BASE}/w_160,c_fill,ar_1:1,g_auto,r_max/${CLOUDINARY_BASE_FOLDER}/pet/snaps/`,
  PROFILE_IMAGE_FOLDER,
  USER_TYPES: {
    SUPER_ADMIN: 'super_admin',
    ADMIN: 'admin',
    STAFF: 'staff',
  },
  DEFAULT_PAGINATION_LENGTH: 20,
  DEFAULT_STAFF_PAGINATION_LENGTH: 20,
  CO_PARENT_STATUS: {
    INVITED: 'invited',
    JOINED: 'accepted',
  },
  POPUP_TIMEOUT_TIME: 7000,
  NEW_STAFF_POPUP_TIMEOUT_TIME: 2000,
  RESPONSE: {
    UNAUTHORIZED: 401,
  },
  appointmentStatus: {
    upcoming: 'upcoming',
    ongoing: 'ongoing',
    past: 'past',
    no_updates: 'no updates',
    removed: 'removed',
    cancelled: 'cancelled',
    expired: 'expired',
  },
  appointmentTypes: {
    initialConsult: 'Initial Consult',
    reCheckExam: 'Recheck Exam',
    technicianAppointment: 'Technician Appointment',
    procedure: 'Procedure',
  },
  appointmentActions: {
    remove: 'remove',
    cancel: 'cancel',
    reschedule: 'reschedule',
  },
   appointmentRequestStatus: {
    pending: "pending",
    completed: 'completed',
  },
  refillRequestDeliveryTypes: {
    pickup: 'Pickup',
    delivery: 'Shipping',
  },
  refillRequestDefaultStatuses: {
    requested: 'Requested',
    inTheQueue: 'In the queue',
    readyForPickup: 'Ready for pickup',
    shipped: 'Shipped',
  },
  refillRequestPageTypes: {
    default: 'default',
    clientPage: 'client-page',
  },
  appointmentRequestPageTypes: {
    default: 'default',
    clientPageModal: 'client-page-modal',
  },
  appointmentPageTypes: {
    default: 'default',
    clientPage: 'client-page',
  },
  PHASES: {
    1: 'Expected',
    2: 'Check-In',
    3: 'Diagnostics',
    4: 'Preparation',
    5: 'Procedure',
    6: 'Dental',
    7: 'Surgery',
    8: 'Recovery',
    9: 'Hospitalized',
    10: 'Boarding',
    11: 'Discharge',
    12: 'Completed',
  },
  SUBSCRIPTION_STATUS: {
    SUBSCRIBED: 'subscribed',
    UNSUBSCRIBED: 'unsubscribed',
  },
  LOYALPET_API_KEY: process.env.LOYALPET_API_KEY,
  LOYALPET_AUTH_DOMAIN: process.env.LOYALPET_AUTH_DOMAIN,
  LOYALPET_PROJECT_ID: process.env.LOYALPET_PROJECT_ID,
  LOYALPET_STORAGE_BUCKET: process.env.LOYALPET_STORAGE_BUCKET,
  LOYALPET_MESSAGING_SENDER_ID: process.env.LOYALPET_MESSAGING_SENDER_ID,
  LOYALPET_APP_ID: process.env.LOYALPET_APP_ID,
  LOYALPET_VAPID_KEY: process.env.LOYALPET_VAPID_KEY,
  LOYALPET_HELP_SCOUT_BEACON_ID: process.env.LOYALPET_HELP_SCOUT_BEACON_ID,
};

module.exports = Config;
