import React, { useEffect, useState } from 'react';
import { Card, CardBody, Button } from 'reactstrap';
import FacilitySelect from '../../shared/components/inputs/FacilitySelect/FacilitySelect';
import config, { USER_TYPES } from '../../config/app.config';
import validateStaffForm from './staffValidate';
import generatePassword from '../../helpers/generatePassword';
import { isSuperAdmin } from '../../utils/Utils';
import { InputLabel } from '../../shared/components/InputLabel';
import DesignationSelect from '../../shared/components/inputs/DesignationSelect/DesignationSelect';
import localStorage from '../../libs/storageHelper';
import { FormInput } from './components/FormInput';
import { PermissionsSelect } from './components/PermissionsSelect';

const privateCredExpl = `Email address is Username. Invited user sets own password after receiving email invitation.`;
const setCredExpl = `Admin sets Username and Password.`;

const defaultForm = {
  firstName: '',
  lastName: '',
  email: '',
  facility: null,
  designation: '',
  type: USER_TYPES.STAFF,
  username: '',
  phone: '',
  extension: '',
  password: '',
  privateCredentials: true,
};

const defaultErrors = {
  firstName: null,
  lastName: null,
  email: null,
  facility: null,
  designation: null,
  username: null,
  phone: null,
  extension: null,
  type: null,
  privateCredentials: null,
};

export default function StaffForm({
  handleSubmit,
  closeModal = () => {},
  onSubmit = () => {},
  onPrivateCredentialsChange = () => {},
}) {
  const [loggedUser] = useState(localStorage.getFromStorage('loggedUser').user);
  const [errors, setErrors] = useState({ ...defaultErrors });
  const [inputs, setForm] = useState({
    ...defaultForm,
    facility:
      loggedUser?.type === config.USER_TYPES.SUPER_ADMIN
        ? ''
        : loggedUser?.facility,
  });

  const onChangeFields = (e) => {
    const { name, type, checked, value: incValue } = e.target;

    const trimmedValue =
      typeof incValue === 'string' ? incValue.trim() : incValue;

    setForm((f) => ({
      ...f,
      [name]: type === 'checkbox' ? checked : trimmedValue,
    }));
    setErrors((err) => ({
      ...err,
      [name]: null,
    }));
  };

  const validateAndSubmit = () => {
    const staff = {
      ...inputs,
      username: inputs.privateCredentials ? inputs.email : inputs.username,
      password: inputs.privateCredentials
        ? generatePassword()
        : inputs.password,
    };

    const { errors, error } = validateStaffForm(staff);

    if (error) {
      return setErrors(errors);
    }

    staff.phone = staff.phone
      ? staff.phone.replace(/[^A-Z0-9]+/gi, '')
      : staff.phone;

    onSubmit(staff);
  };

  const handleSelectFacility = (facility) => {
    setForm((f) => ({
      ...f,
      facility,
    }));
  };

  useEffect(() => {
    setForm((f) => ({
      ...f,
      facility: isSuperAdmin(loggedUser) ? '' : loggedUser?.facility,
    }));
  }, [loggedUser]);

  return (
    <Card>
      <CardBody>
        <form className="form staff-form" onSubmit={handleSubmit}>
          <div className="container-fluid">
            {inputs.privateCredentials && (
              <>
                <div className="row">
                  <FormInput
                    wrapperClassName="col-md-6"
                    label="First Name"
                    name="firstName"
                    onChange={onChangeFields}
                    error={errors.firstName}
                  />
                  <FormInput
                    wrapperClassName="col-md-6"
                    label="Last Name"
                    name="lastName"
                    onChange={onChangeFields}
                    error={errors.lastName}
                  />
                </div>
                <div className="row">
                  {isSuperAdmin(loggedUser) && (
                    <div className="col-md-6">
                      <div className="form__form-group">
                        <InputLabel required>Facility</InputLabel>
                        <div className="form__form-group-field">
                          <FacilitySelect
                            onChange={handleSelectFacility}
                            value={inputs.facility}
                            error={errors.facility}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="col-md-6">
                    <DesignationSelect
                      onChange={onChangeFields}
                      value={inputs.designation}
                      error={errors.designation}
                      required={inputs.privateCredentials}
                    />
                  </div>
                  <div className="col-md-6">
                    <PermissionsSelect onChange={onChangeFields} />
                  </div>
                </div>
                <div className="row">
                  <FormInput
                    wrapperClassName="col-md-6"
                    label="Email"
                    name="email"
                    onChange={onChangeFields}
                    error={errors.email}
                    required={inputs.privateCredentials}
                    autoComplete="off"
                  />
                  <FormInput
                    wrapperClassName="col-md-6"
                    label="Username"
                    name="username"
                    onChange={onChangeFields}
                    value={inputs.email}
                    error={errors.username}
                    required={!inputs.privateCredentials}
                  />
                </div>
              </>
            )}

            {!inputs.privateCredentials && (
              <>
                <div className="row">
                  <FormInput
                    wrapperClassName="col-md-6"
                    label="Username"
                    name="username"
                    onChange={onChangeFields}
                    value={inputs.username}
                    error={errors.username}
                    required={!inputs.privateCredentials}
                  />
                  <FormInput
                    wrapperClassName="col-md-6"
                    label="Password"
                    name="password"
                    onChange={onChangeFields}
                    value={inputs.password}
                    error={errors.password}
                    required
                  />
                </div>
                <div className="row">
                  {isSuperAdmin(loggedUser) && (
                    <div className="col-md-6">
                      <div className="form__form-group">
                        <InputLabel required>Facility</InputLabel>
                        <div className="form__form-group-field">
                          <FacilitySelect
                            onChange={handleSelectFacility}
                            value={inputs.facility}
                            error={errors.facility}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <FormInput
                    wrapperClassName="col-md-6"
                    label="Name"
                    name="lastName"
                    value={inputs.username}
                    error={errors.lastName}
                    readOnly
                  />
                  <div className="col-md-6">
                    <PermissionsSelect onChange={onChangeFields} />
                  </div>
                </div>
              </>
            )}

            <div className="row">
              <div className="col-md-6 text-left">
                <div className="checkbox-wrapper">
                  <label className="checkbox-container terms-and-conditions">
                    <input
                      id="privateCredentials"
                      className="custom-checkbox"
                      type="checkbox"
                      name="privateCredentials"
                      checked={inputs.privateCredentials}
                      onChange={(e) => {
                        e.target.checked = true;
                        onPrivateCredentialsChange(true);
                        onChangeFields(e);
                      }}
                    />
                    <span className="checkmark" />
                    <label
                      className="form-check-label"
                      htmlFor="privateCredentials"
                    >
                      Private Credentials
                    </label>
                  </label>
                  <p>{privateCredExpl}</p>
                  <p />
                  <label className="checkbox-container terms-and-conditions">
                    <input
                      id="assignedCredentials"
                      className="custom-checkbox"
                      type="checkbox"
                      name="privateCredentials"
                      checked={!inputs.privateCredentials}
                      onChange={(e) => {
                        e.target.checked = false;
                        onPrivateCredentialsChange(false);
                        onChangeFields(e);
                      }}
                    />
                    <span className="checkmark" />
                    <label
                      className="form-check-label"
                      htmlFor="assignedCredentials"
                    >
                      Assigned Credentials
                    </label>
                  </label>
                  <p>{setCredExpl}</p>
                </div>
              </div>
              <div className="col-md-6" />
            </div>

            <div className="row">
              <div className="col-md-6"></div>
              <div className="col-md-6 text-right">
                <Button
                  className="clear"
                  color="secondary"
                  onClick={closeModal}
                >
                  Cancel
                </Button>
                <Button
                  className="save-update"
                  color="primary"
                  onClick={validateAndSubmit}
                >
                  {inputs.privateCredentials ? 'Save and Invite' : 'Save'}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </CardBody>
    </Card>
  );
}
