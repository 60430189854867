import React, { createContext, useContext } from 'react';

const SuccessModalContext = createContext({
  temporaryShowSuccessModal: () => {},
});

export const SuccessModalProvider = ({
  handleToggleSuccessModal,
  children,
}) => {
  const temporaryShowSuccessModal = () => {
    handleToggleSuccessModal('open');

    setTimeout(() => {
      handleToggleSuccessModal('close');
    }, 3000);
  };

  return (
    <SuccessModalContext.Provider value={{ temporaryShowSuccessModal }}>
      {children}
    </SuccessModalContext.Provider>
  );
};

export const useSuccessModalContext = () => {
  return useContext(SuccessModalContext);
};
