import React from 'react';
import { Button } from 'reactstrap';

export function FooterButtons({
  onClose,
  onSave,
  saveDisabled = false,
  showSaveButton = true,
  closeText = 'Close',
  saveText = 'Update Status',
}) {
  return (
    <footer className="buttons-group-wrapper">
      <div className="buttons-group">
        <Button className="clear" color="secondary" onClick={onClose}>
          {closeText}
        </Button>
        {showSaveButton && (
          <Button
            className="save-update"
            color="primary"
            disabled={saveDisabled}
            onClick={onSave}
          >
            {saveText}
          </Button>
        )}
      </div>
    </footer>
  );
}
