import React, { useMemo } from 'react';
import moment from 'moment';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { DateRange } from 'react-date-range';
import { DEFAULT_TIMESTAMP_FORMAT } from '../../../constants/constants';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

export default function DateRangePickerComponent({
  handleSelect = () => {},
  selectionRange = {},
  placeholder = 'Select Date Range',
}) {
  const startDate = moment(selectionRange.startDate).format(
    DEFAULT_TIMESTAMP_FORMAT,
  );
  const endDate = moment(selectionRange.endDate).format(
    DEFAULT_TIMESTAMP_FORMAT,
  );

  const isActive = useMemo(
    () =>
      selectionRange.selected === 'range' || selectionRange.selected === 'day',
    [selectionRange.selected],
  );

  return (
    <div
      className={`date-range-picker-wrapper clearfix ${
        isActive ? 'selected' : ''
      }`}
    >
      <UncontrolledDropdown className="date-range-calender float-left">
        <DropdownToggle>
          {isActive ? (
            <p className="float-left time-lable">
              {selectionRange.selected === 'range'
                ? `${startDate} - ${endDate}`
                : startDate}
            </p>
          ) : (
            <p className="float-left time-lable">{placeholder}</p>
          )}
        </DropdownToggle>
        <DropdownMenu>
          <DateRange
            ranges={[selectionRange]}
            onChange={handleSelect}
            rangeColors={['']}
          />
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
}
