import React, { Component } from 'react';
import StateItem from '../inputs/StateItem';
import { ClearIcon } from '../../../containers/common/InputWithSearchIcon';

export default class TableSearchableFilterComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allFilters: [],
      filteredList: [],
      isFilterDropdownVisible: false,
      tempSelectedFilters: [],
      allOptions: [],
      searchValue: '',
    };
  }

  componentDidMount() {
    this.props.onSearch?.(this.state.searchValue);
    this.setState({
      allFilters: this.props.allFilters,
      filteredList: this.props.allFilters,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { searchValue } = this.state;

    if (nextProps.allFilters) {
      let filteredList = nextProps.allFilters;
      if (searchValue && !nextProps.onSearch) {
        const dataList = Object.assign({}, ...filteredList);
        const filterData = [];
        Object.keys(dataList).forEach(function (key) {
          if (
            dataList[key].toLowerCase().startsWith(searchValue.toLowerCase())
          ) {
            const object = {};
            object[key] = dataList[key];
            filterData.push(object);
          }
        });
        filteredList = filterData;
      }
      this.setState({
        allFilters: nextProps.allFilters,
        filteredList,
      });
    }
  }

  UNSAFE_componentWillMount() {
    document.addEventListener('mousedown', this.handleOutsideClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick, false);
  }

  handleOutsideClick = (e) => {
    if (this.node && !this.node.contains(e.target)) {
      this.setState({ isFilterDropdownVisible: false });
    }
  };

  handleApplyStatesFilter = () => {
    const { tempSelectedFilters } = this.state;
    this.props.action(tempSelectedFilters);
  };

  handleSelectState = (state) => {
    const { tempSelectedFilters, allFilters, allOptions = [] } = this.state;

    const selectedIndex = tempSelectedFilters.indexOf(state);
    let nextOptions = [...allOptions];
    if (selectedIndex === -1) {
      tempSelectedFilters.push(state);
      nextOptions.push(allFilters.find((v) => v[state] !== undefined));
    } else {
      nextOptions = allOptions.filter((v) => !Object.keys(v).includes(state));
      tempSelectedFilters.splice(selectedIndex, 1);
    }

    this.setState({
      tempSelectedFilters,
      allOptions: nextOptions,
    });
    this.handleApplyStatesFilter();
  };

  getOptions = () => {
    const { allFilters = [], allOptions = [] } = this.state;

    const selList = new Map();
    allOptions.concat(...allFilters).forEach((v) => {
      selList.set(Object.keys(v)[0], v);
    });
    return Array.from(selList.values());
  };

  handleStateChangeFocus = () => {
    this.setState({
      isFilterDropdownVisible: !this.state.isFilterDropdownVisible,
    });
  };

  isStateSelected = (state) => {
    const { tempSelectedFilters } = this.state;
    return tempSelectedFilters.indexOf(state) !== -1;
  };

  handleSearch = (e) => {
    const { allFilters } = this.state;

    let { filteredList, searchValue } = this.state;
    searchValue = e.target.value;
    const dataList = Object.assign({}, ...allFilters);
    if (this.props.onSearch) {
      this.props.onSearch(searchValue);
    } else if (searchValue) {
      const filterData = [];
      Object.keys(dataList).forEach((key) => {
        if (dataList[key].toLowerCase().startsWith(searchValue.toLowerCase())) {
          const object = {};
          object[key] = dataList[key];
          filterData.push(object);
        }
      });
      filteredList = filterData;
    } else {
      const filterData = [];
      Object.keys(dataList).forEach((key) => {
        const object = {};
        object[key] = dataList[key];
        filterData.push(object);
      });
      filteredList = filterData;
    }
    this.setState({ searchValue, filteredList });
  };

  onClear = () => {
    const { allFilters } = this.state;
    const dataList = Object.assign({}, ...allFilters);
    const filterData = [];
    Object.keys(dataList).forEach((key) => {
      const object = {};
      object[key] = dataList[key];
      filterData.push(object);
    });

    this.setState({
      searchValue: '',
      filteredList: filterData,
    });
  };

  clearSelection = () => {
    this.setState(
      {
        tempSelectedFilters: [],
        allOptions: [],
        searchValue: '',
      },
      () => {
        this.props.onSearch();
        this.handleApplyStatesFilter();
      },
    );
  };

  render() {
    const {
      isFilterDropdownVisible,
      filteredList,
      searchValue,
      tempSelectedFilters,
    } = this.state;
    const { label, showSelectedOption } = this.props;

    let minHeight;
    minHeight = (filteredList.length ? filteredList.length : 2) * 40;
    if (minHeight < 120) {
      minHeight = 120;
    }
    if (minHeight > 200) {
      minHeight = 200;
    }

    const options = this.getOptions();

    return (
      <div
        className="filter-select-box-wrapper clearfix"
        ref={(node) => (this.node = node)}
      >
        <p>{label} </p>
        <span
          className="filter-icon"
          onClick={this.handleStateChangeFocus}
        ></span>
        {isFilterDropdownVisible && (
          <div className="states-result">
            <div
              style={{
                width: '100%',
                maxHeight: 300,
                minWidth: '180px',
              }}
            >
              <div
                className="blocks-wrapper"
                style={{
                  height: '100%',
                  overflowY: 'auto',
                  maxHeight: 'inherit',
                }}
              >
                <div
                  className="states-block sticky"
                  style={{ display: 'flex', top: 0 }}
                >
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                    ref="searchInput"
                    value={searchValue}
                    onChange={(e) => this.handleSearch(e)}
                  />

                  <ClearIcon
                    className="tableSearchClearIcon"
                    visible={!!searchValue}
                    onClick={this.onClear}
                  />
                </div>
                {showSelectedOption &&
                  options.map((value) => {
                    const filterKey = Object.keys(value)[0];

                    return (
                      <StateItem
                        key={filterKey}
                        value={value}
                        filterKey={filterKey}
                        isStateSelected={this.isStateSelected(filterKey)}
                        handleSelectState={() =>
                          this.handleSelectState(filterKey)
                        }
                      />
                    );
                  })}
                {tempSelectedFilters.length > 1 && (
                  <div
                    className="sticky btn btn-sm"
                    style={{ bottom: 0, margin: 0, width: '100%' }}
                    onClick={this.clearSelection}
                  >
                    clear selection
                    <ClearIcon
                      visible
                      style={{ right: 'unset', left: '8px' }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
