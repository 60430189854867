import React, { createContext, useCallback, useContext, useState } from 'react';

export const tab = Object.freeze({
  MAIN_INFO: 'Main Information',
  ATTACHMENTS: 'Attachments',
  INTERNAL_COMMENTS: 'Internal Comments',
});

const RefillRequestModalContext = createContext({
  isRefillRequestModalOpen: false,
  openRefillRequestModal: () => {},
  closeRefillRequestModal: () => {},
  refillRequestData: {},
  handleSetRefillRequestData: () => {},
  activeTab: tab.MAIN_INFO,
  setActiveTab: () => {},
});

export const RefillRequestModalProvider = ({ children }) => {
  const [isRefillRequestModalOpen, setIsRefillRequestModalOpen] =
    useState(false);
  const [refillRequestData, setRefillRequestData] = useState({});
  const [activeTab, setActiveTab] = useState(tab.MAIN_INFO);

  const openRefillRequestModal = useCallback((tabToOpen) => {
    setIsRefillRequestModalOpen(true);
    tabToOpen && setActiveTab(tabToOpen);
  }, []);

  const closeRefillRequestModal = useCallback(() => {
    setIsRefillRequestModalOpen(false);
    setRefillRequestData({});
    setActiveTab(tab.MAIN_INFO);
  }, []);

  const handleSetRefillRequestData = useCallback((data) => {
    const { date, time, ...rest } = data;
    setRefillRequestData({
      ...rest,
      dateTime: `${date} ${time}`,
    });
  }, []);

  return (
    <RefillRequestModalContext.Provider
      value={{
        isRefillRequestModalOpen,
        openRefillRequestModal,
        closeRefillRequestModal,
        refillRequestData,
        handleSetRefillRequestData,
        activeTab,
        setActiveTab,
      }}
    >
      {children}
    </RefillRequestModalContext.Provider>
  );
};

export const useRefillRequestModalContext = () => {
  return useContext(RefillRequestModalContext);
};
