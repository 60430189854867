import React, { Component } from 'react';
import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import { connect } from 'react-redux';
import HeadingText from '../../../shared/components/form/HeadingText';
import MessageModal from '../../../shared/components/Modals/MessageModal';
import { getParameterByName } from '../../../libs/commonHelper';
import {
  addNewParent,
  clearPetProfile,
  getProfile,
  updateParent,
  updateParentStatus,
} from '../../../redux/actions/petActions';
import { getSpecies } from '../../../redux/actions/breedAction';
import {
  clearUpdateProps,
  reinvite,
} from '../../../redux/actions/clientActions';
import { toggleLoading } from '../../../redux/actions/commonActions';
import UpdatePets from './Components/UpdatePets';
import { reset, validate } from '../../../libs/validationHelper';
import AddNewPet from './Components/AddNewPet';
import * as Constants from '../../../constants/constants';
import withRouter from '../../../shared/components/withRouter';
import ParentInformation from './Components/ParentInformation';
import { RefillRequests } from '../RefillRequests';
import { Appointments } from '../Appointments';
import {
  appointmentPageTypes,
  refillRequestPageTypes,
} from '../../../config/app.config';

const tabs = {
  1: Constants.QUERY_TAB_PARAMS.parent,
  2: Constants.QUERY_TAB_PARAMS.petInfo,
  3: Constants.QUERY_TAB_PARAMS.appointments,
  4: Constants.QUERY_TAB_PARAMS.refillRequests,
};

class ParentProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileId: this.props.params.parentId,
      profile: {},
      parentData: null,
      profileAvailable: true,
      profileUpdateEnable: false,
      refreshState: true,
      activeTab: '1',
      inputs: { firstName: '', lastName: '' },
      error: false,
      errors: {
        firstName: { error: null, display: 'first name' },
        lastName: { error: null, display: 'last name' },
      },
      confirmPopup: {
        showResponse: false,
        responseMessage: '',
        responseAlertType: '',
        responseType: '',
        responseTitle: '',
        pet: null,
        status: null,
        confirmType: null,
      },
      inputChanged: false,
      anyPetInputChanged: false,
      petInputChanged: [],
      newPetPopup: false,
      speciesList: [],
    };
  }

  newPetPopup = () => {
    this.setState({ newPetPopup: true });
  };

  closeNewPetPopup = () => {
    this.setState({ newPetPopup: false }, () =>
      this.props.getProfile(this.props.params.parentId),
    );
  };

  changeTab(tab) {
    if (this.state.activeTab !== tab) {
      const updatedParams = new URLSearchParams(this.props.searchParams);
      updatedParams.set('tab', tabs[tab]);
      this.setState({ activeTab: tab });
      this.props.navigate(`?${updatedParams.toString()}`);
    }
  }

  reInvite = async (params) => {
    this.props.toggleLoading(true);
    const { errorMessage, data } = await this.props.addNewParent({
      parent: {
        phoneEmail: params.phone,
        addToCurrentAccount: true,
      },
    });

    const confirmPopup = {
      showResponse: true,
      responseMessage: errorMessage || data?.message,
      responseType: errorMessage ? 'alert' : '',
      responseAlertType: 'success',
      footer: true,
      confirmType: 'change_status',
    };
    this.setState({ confirmPopup });
    this.props.toggleLoading(false);
  };

  closePopup = () => {
    const { confirmPopup } = this.state;
    let { refreshState } = this.state;
    confirmPopup.showResponse = false;
    refreshState = !refreshState;
    this.setState({ confirmPopup, refreshState });
    this.props.clearUpdateProps();
  };

  onSuccess = () => {
    const { confirmPopup, profile } = this.state;
    if (confirmPopup.confirmType === 'change_status') {
      this.props.toggleLoading(true);
      this.props.updateParentStatus({
        status: confirmPopup.status,
        id: profile._id,
      });
    }
    confirmPopup.showResponse = false;
    this.setState({ confirmPopup });
  };

  changeStatus = (status) => {
    const { profile } = this.state;
    let statusMessage = '';
    switch (status) {
      case 'active':
      default:
        statusMessage = `Are you sure you want to activate ${profile.firstName} ${profile.lastName}?`;
        break;
      case 'inactive':
        statusMessage = `Are you sure you want to deactivate ${profile.firstName} ${profile.lastName}?`;
        break;
    }
    const confirmPopup = {
      showResponse: true,
      responseMessage: statusMessage,
      responseType: 'confirm',
      responseAlertType: null,
      footer: true,
      confirmType: 'change_status',
      status,
    };
    this.setState({ confirmPopup });
  };

  onChangeFields = (e) => {
    const property = e.target.name;
    const value = e.target.value;
    const { profile } = this.state;
    let { inputs, refreshState, profileUpdateEnable, inputChanged } =
      this.state;
    inputChanged = false;
    refreshState = !refreshState;
    inputs[property] = value;
    profileUpdateEnable =
      inputs.firstName !== profile.firstName ||
      inputs.lastName !== profile.lastName;
    if (profileUpdateEnable) {
      inputChanged = true;
    }
    this.setState({
      inputs,
      refreshState,
      profileUpdateEnable,
      inputChanged,
    });
  };

  validate = (inputs) => {
    const { refreshState } = this.state;
    let { errors } = this.state;
    errors = reset(errors);

    const validationPetResponse = validate({}, inputs, errors);
    errors = validationPetResponse.errors;
    const error = validationPetResponse.error;
    this.setState({ errors, error, refreshState: !refreshState });
    return error;
  };

  updateParent = () => {
    const { inputs, profile } = this.state;
    if (!this.validate(inputs)) {
      this.props.toggleLoading(true);
      this.props.updateParent(inputs, profile._id);
    }
  };

  inputChangeStatus = (id, status) => {
    const { petInputChanged } = this.state;
    petInputChanged[id] = status;
    const valueList = Object.keys(petInputChanged).map(
      (key) => petInputChanged[key],
    );
    const anyPetInputChanged = valueList.includes(true);
    this.setState({ petInputChanged, anyPetInputChanged });
  };

  componentDidMount() {
    this.props.getProfile(this.props.params.parentId);
    this.props.getSpecies();
  }

  UNSAFE_componentWillReceiveProps(np) {
    if (np.pets.profile) {
      const { inputs } = this.state;
      let { profileAvailable, profileUpdateEnable } = this.state;
      const profile = np.pets.profile;
      inputs.firstName = profile.firstName;
      inputs.lastName = profile.lastName;
      if (np.pets.profile.error) {
        profileAvailable = false;
      }
      profileUpdateEnable = false;
      const parentData = {
        avatar: profile.avatar,
        email: profile.email,
        firstName: profile.firstName,
        invited: profile.invited,
        lastName: profile.lastName,
        loggedFirstTime: profile.loggedFirstTime,
        joined: profile.joined,
        loggedFirstTimeDate: profile.loggedFirstTimeDate,
        name: `${profile.firstName} ${profile.lastName}`,
        phone: profile.phone,
        status: profile.status,
        username: profile.username,
        _id: profile._id,
      };
      this.setState({
        profile,
        profileAvailable,
        inputs,
        profileUpdateEnable,
        parentData,
      });
      this.props.clearPetProfile();
    }
    if (np.client.statusUpdated) {
      const { confirmPopup, profile } = this.state;
      confirmPopup.showResponse = true;
      confirmPopup.responseMessage = `Invitation resent to ${profile.firstName} ${profile.lastName}`;
      confirmPopup.responseType = 'alert';
      confirmPopup.responseAlertType = 'success';
      confirmPopup.okText = 'OK';
      confirmPopup.cancelText = 'OK';
      confirmPopup.footer = true;
      confirmPopup.action = '';
      this.setState({ confirmPopup });
    }
    if (np.pets.parentUpdateStatus) {
      const { refreshState, confirmPopup, profile } = this.state;
      const _this = this;
      confirmPopup.showResponse = true;
      confirmPopup.responseMessage = `${profile.firstName} ${
        profile.lastName
      } ${
        confirmPopup.status === Constants.STATUS.ACTIVE
          ? 'activated'
          : 'deactivated'
      }`;
      confirmPopup.responseType = 'alert';
      confirmPopup.responseAlertType = 'success';
      if (np.pets.parentUpdateStatus.errorMessage) {
        confirmPopup.responseAlertType = 'danger';
        confirmPopup.responseMessage = np.pets.parentUpdateStatus.errorMessage;
      } else {
        profile.status = confirmPopup.status;
      }
      this.setState(
        { confirmPopup, profile, refreshState: !refreshState },
        () => {
          _this.props.clearPetProfile();
        },
      );
    }
    if (np.pets.parentProfileUpdateResponse) {
      const { refreshState, confirmPopup, profile, inputs } = this.state;
      let { profileUpdateEnable, inputChanged } = this.state;
      const _this = this;
      confirmPopup.showResponse = true;
      confirmPopup.responseMessage = 'Parent profile updated';
      confirmPopup.responseType = 'alert';
      confirmPopup.responseAlertType = 'success';
      if (np.pets.parentProfileUpdateResponse.errorMessage) {
        confirmPopup.responseAlertType = 'danger';
        confirmPopup.responseMessage =
          np.pets.parentProfileUpdateResponse.errorMessage;
      } else {
        inputChanged = false;
        profileUpdateEnable = false;
        profile.firstName = inputs.firstName;
        profile.lastName = inputs.lastName;
      }
      this.setState(
        {
          confirmPopup,
          refreshState: !refreshState,
          profileUpdateEnable,
          inputChanged,
          profile,
        },
        () => _this.props.clearPetProfile(),
      );
    }

    if (
      this.props.location &&
      this.props.location.activeTab &&
      !this.state.isSetActiveTab
    ) {
      this.setState({
        activeTab: this.props.location.activeTab,
        isSetActiveTab: true,
      });
    }
    if (this.props?.location?.search && !np?.location?.search) {
      this.setState({ activeTab: '1' });
    }
    if (np.breeds.species) {
      this.setState({
        speciesList: np.breeds.species.map((data) => ({
          _id: data._id,
          name: data.name,
        })),
      });
    }

    const tab = getParameterByName('tab', window.location.href);
    if (tab) {
      const activeTab = Object.keys(tabs).find((key) => tabs[key] === tab);
      this.setState({ activeTab, isSetActiveTab: true });
    }

    const pet = getParameterByName(Constants.QUERY_PARAMS.pet);
    if (pet) {
      const component = document.getElementById(pet);
      if (component) {
        window.scrollTo({
          left: window.scrollX,
          top: component.offsetTop + 65, // header height
          behavior: 'smooth',
        });
      }
    }
  }

  handleRedirect = (data) => {
    this.props.history.push(data);
  };

  render() {
    const {
      profile,
      confirmPopup,
      inputs,
      errors,
      profileAvailable,
      profileUpdateEnable,
      parentData,
      newPetPopup,
      speciesList,
      activeTab,
    } = this.state;

    const defaultAvatar = `${process.env.PUBLIC_URL}/img/default.png`;
    const parentImage = profile.avatar || defaultAvatar;

    const profileHeader =
      profile && profile.firstName
        ? `${profile.firstName} ${profile.lastName}'s Profile`
        : '';
    const parentName =
      profile && profile.firstName
        ? `${profile.firstName} ${profile.lastName}`
        : '';
    const parentNameUserSet =
      profile && profile.firstNameUserSet
        ? `${profile.firstNameUserSet} ${profile.lastNameUserSet}`
        : '';

    return (
      <div className="manage-patient">
        <Col
          md={12}
          lg={12}
          className="manage-patient-container admin-container"
        >
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <HeadingText text={profileHeader} />
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <div>
                    <button
                      className="btn btn-outline new-appointment-action"
                      onClick={this.newPetPopup}
                    >
                      <span className="lnr lnr-plus-circle" />
                      Add new pet
                    </button>
                  </div>
                </Col>
              </Row>
              {profileAvailable ? (
                <div className="content-wrapper">
                  <div className="row">
                    <div className="col-md-12">
                      <div className={'loyal-profile-tab-container'}>
                        <Nav tabs>
                          <NavItem>
                            <NavLink
                              className={
                                this.state.activeTab === '1' ? 'active' : ''
                              }
                              onClick={() => this.changeTab('1')}
                            >
                              Parent Information
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={
                                this.state.activeTab === '2' ? 'active' : ''
                              }
                              onClick={() => this.changeTab('2')}
                            >
                              Pet Information
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={
                                this.state.activeTab === '3' ? 'active' : ''
                              }
                              onClick={() => this.changeTab('3')}
                            >
                              Appointments
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={
                                this.state.activeTab === '4' ? 'active' : ''
                              }
                              onClick={() => this.changeTab('4')}
                            >
                              Refill Requests
                            </NavLink>
                          </NavItem>
                        </Nav>
                        <TabContent activeTab={this.state.activeTab}>
                          {activeTab === '1' && (
                            <TabPane tabId="1" className="default-wrapper">
                              <ParentInformation
                                parentImage={parentImage}
                                parentName={parentName}
                                parentNameUserSet={parentNameUserSet}
                                profile={profile}
                                profileUpdateEnable={profileUpdateEnable}
                                updateParent={this.updateParent}
                                inputs={inputs}
                                errors={errors}
                                reInvite={this.reInvite}
                                onChangeFields={this.onChangeFields}
                              />
                            </TabPane>
                          )}

                          {activeTab === '2' && (
                            <TabPane tabId="2">
                              <UpdatePets
                                profile={profile}
                                inputChangeStatus={this.inputChangeStatus}
                                parentId={this.state.profileId}
                              />
                            </TabPane>
                          )}

                          {activeTab === '3' && (
                            <TabPane tabId="3" className="default-wrapper">
                              <Row>
                                <Col sm="12">
                                  {parentData && (
                                    <Appointments
                                      type={appointmentPageTypes.clientPage}
                                      parentId={this.state.profileId}
                                    />
                                  )}
                                </Col>
                              </Row>
                            </TabPane>
                          )}
                          {activeTab === '4' && (
                            <TabPane tabId="4" className="default-wrapper">
                              <Row>
                                {parentData && (
                                  <RefillRequests
                                    type={refillRequestPageTypes.clientPage}
                                    parentId={this.state.profileId}
                                  />
                                )}
                              </Row>
                            </TabPane>
                          )}
                        </TabContent>
                      </div>
                    </div>
                    <AddNewPet
                      view={newPetPopup}
                      closeNewPetPopup={this.closeNewPetPopup}
                      parent={parentData}
                      speciesList={speciesList}
                    />
                  </div>
                </div>
              ) : (
                <div className="alert alert-danger fade show" role="alert">
                  <div className="alert__content">
                    <p>No parent record found.</p>
                  </div>
                </div>
              )}
            </CardBody>
          </Card>

          <MessageModal
            show={confirmPopup.showResponse}
            type={confirmPopup.responseType}
            alertType={confirmPopup.responseAlertType}
            footer
            onSuccess={this.onSuccess}
            onClose={this.closePopup}
            message={confirmPopup.responseMessage}
          />
        </Col>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = {
  getProfile,
  addNewParent,
  reinvite,
  toggleLoading,
  clearUpdateProps,
  updateParentStatus,
  updateParent,
  clearPetProfile,
  getSpecies,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ParentProfile),
);
