import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, Col, Label } from 'reactstrap';
import ClientTableComponent from './ClientTableComponent';
import Pagination from '../../shared/components/pagination/Pagination';
import config from '../../config/app.config';

import {
  getClientList,
  reinvite,
  getClientFacilities,
  saveClientFacility,
  clearInviteStore,
} from '../../redux/actions/clientActions';
import MessageModal from '../../shared/components/Modals/MessageModal';
import ActivateModal from './ActivateModal';
import HeadingText from '../../shared/components/form/HeadingText';
import InputWithSearchIcon from '../common/InputWithSearchIcon';
import { REGEX_DIGIT, REGEX_PHONE, STATUS } from '../../constants/constants';

const heads = [
  {
    key: 'fullName',
    name: 'Name',
    sortable: true,
    filterable: false,
    capitalize: true,
  },
  {
    key: 'phone',
    name: 'Mobile Number',
    sortable: false,
    filterable: false,
  },
  {
    key: 'email',
    name: 'Email Address',
    sortable: false,
    filterable: false,
  },
  {
    key: 'loyalName',
    name: 'Loyal Name',
    sortable: false,
    filterable: false,
  },
  {
    key: 'joinStatus',
    name: 'Join Status',
    sortable: false,
    filterable: true,
    filterValue: [{ joined: 'joined' }, { invited: 'invited' }],
    capitalize: true,
  },
  {
    key: 'joinedDate',
    name: 'Joined Date',
    sortable: false,
    filterable: false,
    capitalize: true,
  },
];

class Client extends Component {
  searchRef = createRef();

  constructor() {
    super();

    this.state = {
      rows: [],
      sortField: 'fullName',
      sortOrder: 1,
      filters: {},
      search: '',
      offset: 0,
      limit: config.DEFAULT_PAGINATION_LENGTH,
      total: 0,
      confirmPopup: {
        showResponse: false,
        responseMessage: '',
        responseType: '',
        responseTitle: '',
        clientId: null,
        status: null,
        confirmType: null,
      },
      activePopup: {
        show: false,
        data: [],
        status: STATUS.ACTIVE,
      },
      successResponse: null,
      errorResponse: null,
    };
    this.closeActivatePopup = this.closeActivatePopup.bind(this);
  }

  componentDidMount() {
    const { offset, limit } = this.state;
    this.props.getClientList({
      offset,
      limit,
      sort: { field: 'lastName', order: 1 },
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { offset, limit, sortField, sortOrder, search, filters } = this.state;

    if (nextProps.client) {
      if (nextProps.client.client) {
        this.setState({ total: nextProps.client.client.total });
        this.createRows(nextProps.client.client.clients);
      }

      if (nextProps.client.clientStatus) {
        const confirmPopup = {
          showResponse: true,
          responseMessage: 'Invitation sent successfully ',
          responseType: 'alert',
          responseAlertType: null,
          footer: true,
        };
        this.setState({ confirmPopup, showResponse: true });
        this.props.getClientList({
          offset,
          limit,
          sortField,
          sortOrder,
          search,
          filters,
        });
      }

      if (nextProps.client.facilityUpdated) {
        const notUpdateFacilities =
          nextProps.client.facilityUpdated.notUpdate.length;
        const totalFacility = nextProps.client.facilityUpdated.totalFacility;
        const status = nextProps.client.facilityUpdated.status;
        this.setState(
          (prevState) => ({
            activePopup: {
              show: false,
              data: [],
              status: prevState.activePopup.status,
            },
          }),
          () => {
            let message;
            if (status === STATUS.ACTIVE) {
              message = 'This profile is activated for selected facilities';
            } else {
              if (notUpdateFacilities === 0) {
                message = `This profile is successfully deactivated from ${totalFacility} ${
                  totalFacility === 1 ? 'facility' : 'facilities'
                }`;
              } else if (
                notUpdateFacilities > 0 &&
                notUpdateFacilities !== totalFacility
              ) {
                const successCount =
                  parseInt(totalFacility) - parseInt(notUpdateFacilities);
                message = `This profile is successfully deactivated from ${successCount} ${
                  successCount === 1 ? 'facility' : 'facilities'
                }. Deactivation unsuccessful in ${parseInt(
                  notUpdateFacilities,
                )} ${
                  parseInt(notUpdateFacilities) === 1
                    ? 'facility'
                    : 'facilities'
                } due to ongoing appointments`;
              } else {
                message = `Deactivation unsuccessful in ${notUpdateFacilities} ${
                  notUpdateFacilities === 1 ? 'facility' : 'facilities'
                } due to ongoing appointments.`;
              }
            }

            this.setState(
              {
                confirmPopup: {
                  showResponse: true,
                  responseMessage: message,
                  responseType: 'alert',
                  responseAlertType: null,
                  footer: true,
                },
              },
              () => this.props.clearInviteStore(),
            );
          },
        );
      }
    }
  }

  createRows = (data) => {
    this.setState({
      rows: data.map((row) => ({
        fullName: row.fullName,
        phone: row.phone
          .replace(REGEX_DIGIT, '')
          .replace(REGEX_PHONE, '($1) $2-$3'),
        email: row.email,
        loyalName: row.loyalName,
        joinStatus: row.loggedFirstTime ? 'Joined' : 'Invited',
        joinedDate: row.loggedFirstTimeDate || 'N/A',
        action: '',
        id: row._id,
      })),
    });
  };

  handleActivePopup = async (status, id, isReInvite = false) => {
    const { activePopup } = this.state;
    activePopup.status = status;
    activePopup.isReInvite = isReInvite;
    activePopup.popupWaiting = true;
    activePopup.id = id;

    const facilities = await getClientFacilities(id);

    const facilityData = facilities.reduce((result, facility) => {
      if (facility.status === activePopup.status) {
        result.push(facility.facilityId);
      }
      return result;
    }, []);

    activePopup.popupWaiting = false;
    activePopup.data = facilityData;
    activePopup.show = true;
    activePopup.data = facilityData;

    this.setState({ activePopup });
  };

  handleActiveStatus = (status, _id, joined, firstName) => {
    let statusMessage = '';
    switch (status) {
      case 'reinvite':
        statusMessage = `Are you sure you want to reinvite ${firstName}? `;
        break;
      case 'removed':
        statusMessage = 'Are you sure you want to remove this client profile? ';
        break;
      default:
        break;
    }

    const confirmType = status === 'reinvite' ? 'reinvite' : 'change_status';
    const confirmPopup = {
      showResponse: true,
      responseMessage: statusMessage,
      responseType: 'confirm',
      responseAlertType: null,
      clientId: _id,
      footer: true,
      confirmType,
      status,
    };
    this.setState({ confirmPopup, showResponse: true });
  };

  onSuccess = () => {
    const { confirmPopup } = this.state;

    if (confirmPopup.confirmType === 'reinvite') {
      this.props.reinvite({ id: confirmPopup.clientId });
    }
    confirmPopup.showResponse = false;

    this.setState({ showResponse: false, confirmPopup });
  };

  saveFacility = (facilityIds, isReInvite) => {
    const { activePopup } = this.state;
    const status =
      activePopup.status === STATUS.ACTIVE ? STATUS.INACTIVE : STATUS.ACTIVE;

    if (isReInvite) {
      this.setState((prevState) => ({
        ...prevState,
        activePopup: {
          show: false,
          data: [],
          status: prevState.activePopup.status,
        },
      }));
      this.props.reinvite({
        id: activePopup.id,
        facility: facilityIds[0],
      });
    } else {
      this.props.saveClientFacility({
        clientId: activePopup.id,
        status,
        facilities: facilityIds,
      });
    }
  };

  closePopup = () => {
    this.setState((prevState) => ({
      confirmPopup: {
        showResponse: false,
        confirmType: null,
        footer: prevState.confirmPopup.footer,
        responseType: prevState.confirmPopup.responseType,
        responseAlertType: prevState.confirmPopup.responseAlertType,
        responseMessage: '',
      },
    }));
  };

  closeActivatePopup = async () => {
    this.setState((prevState) => ({
      activePopup: {
        show: false,
        data: [],
        status: prevState.activePopup.status,
      },
    }));
  };

  nextPage = () => {
    let { offset, limit, sortField, sortOrder, search, filters } = this.state;
    offset = parseInt(offset + limit);
    this.setState({ offset }, () => {
      this.props.getClientList({
        offset,
        limit,
        sortField,
        sortOrder,
        search,
        filters,
      });
    });
  };

  prevPage = () => {
    let { offset, limit, sortField, sortOrder, search, filters } = this.state;
    offset = parseInt(offset - limit);
    this.setState({ offset }, () => {
      this.props.getClientList({
        offset,
        limit,
        sortField,
        sortOrder,
        search,
        filters,
      });
    });
  };

  gotoPage = (i) => {
    let { offset, limit, sortField, sortOrder, search, filters } = this.state;
    offset = parseInt(limit * i);
    this.setState({ offset }, () => {
      this.props.getClientList({
        offset,
        limit,
        sortField,
        sortOrder,
        search,
        filters,
      });
    });
  };

  handleGridSort = (field) => {
    let { limit, sortField, sortOrder, search, filters } = this.state;
    const order = sortOrder === 1 ? -1 : 1;
    sortField = field;
    sortOrder = order;

    this.setState({ sortField, sortOrder, offset: 0 }, () => {
      this.props.getClientList({
        offset: 0,
        limit,
        sortField: field,
        sortOrder: order,
        search,
        filters,
      });
    });
  };

  handleGridFilter = (header, value) => {
    const { limit, sortField, sortOrder, search, filters } = this.state;
    filters[header] = value;
    this.setState({ filters, offset: 0 }, () => {
      this.props.getClientList({
        offset: 0,
        limit,
        sortField,
        sortOrder,
        search,
        filters,
      });
    });
  };

  handleSearch = (e) => {
    const search = e.target.value;
    const { limit, sortField, sortOrder, filters } = this.state;

    this.setState({ search, offset: 0 }, () => {
      this.props.getClientList({
        offset: 0,
        limit,
        sortField,
        sortOrder,
        search,
        filters,
      });
    });
  };

  handleClearSearch = () => {
    const search = '';
    const { limit, sortField, sortOrder, filters } = this.state;

    this.setState({ search, offset: 0 }, () => {
      this.props.getClientList({
        offset: 0,
        limit,
        sortField,
        sortOrder,
        search,
        filters,
      });
    });
  };

  handlePageLimit = (perPage) => {
    const { sortField, sortOrder, search, filters } = this.state;
    this.setState({ limit: perPage, offset: 0 }, () => {
      this.props.getClientList({
        offset: 0,
        limit: perPage,
        sortField,
        sortOrder,
        search,
        filters,
      });
    });
  };

  render() {
    const {
      rows,
      total,
      offset,
      limit,
      confirmPopup,
      search,
      sortField,
      sortOrder,
      activePopup,
      successResponse,
      errorResponse,
    } = this.state;

    return (
      <Col md={12} lg={12} className="manage-staff-container admin-container">
        <Card>
          <CardBody>
            <div className="sticky-table">
              <HeadingText text="Manage Users" />
              <div className="row search form">
                <div className="col-sm-7">
                  <div className="form__form-group">
                    <Label for="exampleSelect" className="empty-label">
                      &nbsp;
                    </Label>

                    <InputWithSearchIcon
                      name="search"
                      id="search"
                      type="text"
                      onChange={this.handleSearch}
                      ref={this.searchRef}
                      value={search}
                      onClear={this.handleClearSearch}
                      placeholder="Search by first/last name, phone number or email"
                      autofocus
                    />
                  </div>
                </div>
              </div>
            </div>
            <ClientTableComponent
              sortField={sortField}
              sortOrder={sortOrder}
              heads={heads}
              rows={rows}
              handleGridSort={(field) => this.handleGridSort(field)}
              handleGridFilter={(header, field) =>
                this.handleGridFilter(header, field)
              }
              handleActiveStatus={(status, id, joined, firstName) =>
                this.handleActiveStatus(status, id, joined, firstName)
              }
              handleActivePopup={(status, id, isReInvite) =>
                this.handleActivePopup(status, id, isReInvite)
              }
            />
            <Pagination
              handlePageLimit={(limit) => this.handlePageLimit(limit)}
              nextPage={this.nextPage}
              prevPage={this.prevPage}
              gotoPage={(i) => this.gotoPage(i)}
              limit={limit}
              offset={offset}
              total={total}
            />
          </CardBody>
        </Card>
        <MessageModal
          show={confirmPopup.showResponse}
          type={confirmPopup.responseType}
          alertType={confirmPopup.responseAlertType}
          footer={confirmPopup.footer}
          onSuccess={this.onSuccess}
          onClose={this.closePopup}
          message={confirmPopup.responseMessage}
        />
        <ActivateModal
          show={activePopup.show}
          facilities={activePopup.data}
          status={activePopup.status}
          isReInvite={activePopup.isReInvite}
          errorResponse={errorResponse}
          successResponse={successResponse}
          closeActivatePopup={() => this.closeActivatePopup()}
          saveFacility={(facilityIds, isReInvite) =>
            this.saveFacility(facilityIds, isReInvite)
          }
        />
      </Col>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = {
  getClientList,
  reinvite,
  saveClientFacility,
  clearInviteStore,
};

export default connect(mapStateToProps, mapDispatchToProps)(Client);
