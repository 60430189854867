import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, Col } from 'reactstrap';
import Table from './table';
import Modal from './modal';
import { getAllFacilities } from '../../redux/actions/staffActions';
import {
  getAdmins,
  changeStatus,
  changePrimary,
  saveAndInvite,
  update,
  reinvite,
} from '../../redux/actions/adminActions';
import config from '../../config/app.config';

class Admin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
      facilityAll: [],
      tableData: null,
      successResponse: null,
      adminStatusUpdateResponse: null,
      filterData: {},
    };
  }

  componentDidMount() {
    this.props.getAllFacilities();
    this.props.getAdmins({
      sort: [
        { field: 'facilityDisplayName', order: '1' },
        { field: 'fullName', order: '1' },
      ],
    });
  }

  toggleMessageModal = (status) => {
    this.props.toggleMessageModel(status);
  };

  UNSAFE_componentWillReceiveProps(np) {
    const { rowData, filterData } = this.state;

    if (np.staff.facilityAll) {
      this.setState({ facilityAll: np.staff.facilityAll });
    }

    if (np.admin) {
      this.setState({
        tableData: np.admin.adminList,
        isPrimaryAdminExists: np.admin.isPrimaryAdminExists,
        invitationError: '',
        adminStatusUpdateResponse: null,
      });

      if (np.admin.adminStatusUpdated) {
        this.setState({
          adminStatusUpdateResponse: np.admin.adminStatusUpdated,
        });
        this.props.getAdmins(rowData);
      }

      if (np.admin.reinvited) {
        this.setState({
          adminStatusUpdateResponse: np.admin.reinvited,
        });
        this.props.getAdmins(rowData);
      }

      if (np.admin.updated) {
        this.setState({ successResponse: np.admin.updated });
        setTimeout(() => {
          this.setState({ successResponse: null, isModalOpen: false });
        }, config.POPUP_TIMEOUT_TIME);
        this.props.getAdmins(filterData);
      }

      if (np.admin.invited) {
        this.setState({ successResponse: np.admin.invited.message });
        setTimeout(() => {
          this.setState({ successResponse: null, isModalOpen: false });
        }, config.POPUP_TIMEOUT_TIME);
        this.props.getAdmins(filterData);
      }

      if (np.admin.inviteAdminError?.errorMessage) {
        this.setState({
          invitationError: np.admin.inviteAdminError.errorMessage,
        });
      }
    }
  }

  toggleModal = (state, data) => {
    this.setState({ isModalOpen: state, updateData: data || null });
  };

  getAdminList = (data) => {
    this.setState({ filterData: data });
    this.props.getAdmins(data);
  };

  changeStatus = (status, id, rowData) => {
    this.setState({ rowData });
    this.props.changeStatus({ status, id });
  };

  reinvite = (adminId, rowData) => {
    this.setState({ rowData });
    this.props.reinvite({ adminId });
  };

  onChangeCheckbox = (facilityId) => {
    this.props.changePrimary(facilityId);
  };

  saveAndInvite = (data) => {
    this.props.saveAndInvite(data);
  };

  update = (data) => {
    this.props.update(data);
  };

  render() {
    const {
      isModalOpen,
      facilityAll,
      tableData,
      updateData,
      isPrimaryAdminExists,
      invitationError,
      adminStatusUpdateResponse,
    } = this.state;

    return (
      <div className="manage-admin">
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <Table
                toggleModal={this.toggleModal}
                data={{
                  facilityAll,
                  tableData,
                  adminStatusUpdateResponse,
                }}
                getAdminList={this.getAdminList}
                changeStatus={this.changeStatus}
                reinvite={this.reinvite}
                onChangeCheckbox={this.onChangeCheckbox}
              />
              {isModalOpen && (
                <Modal
                  toggleMessageModel={this.toggleMessageModal}
                  toggleModal={this.toggleModal}
                  successResponse={this.state.successResponse}
                  data={{
                    facilityAll,
                    updateData,
                    isPrimaryAdminExists,
                    invitationError,
                  }}
                  saveAndInvite={this.saveAndInvite}
                  update={this.update}
                />
              )}
            </CardBody>
          </Card>
        </Col>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = {
  getAllFacilities,
  getAdmins,
  changeStatus,
  changePrimary,
  saveAndInvite,
  update,
  reinvite,
};

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
