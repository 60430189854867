import classNames from 'classnames';
import SearchIcon from 'mdi-react/SearchIcon';
import React, { forwardRef, useEffect } from 'react';

export const ClearIcon = ({
  onClick = () => {},
  visible,
  className = '',
  style = {},
}) => {
  return (
    <div
      className={classNames('clear-icon-wrapper', className)}
      onClick={onClick}
      style={{
        visibility: visible ? 'visible' : 'hidden',
        ...style,
      }}
    >
      <span className="clear-icon">+</span>
    </div>
  );
};

const InputWithSearchIcon = forwardRef(function InputWithSearchIcon(
  { value, onClear, autofocus, ...props },
  ref,
) {
  useEffect(() => {
    if (autofocus) {
      ref.current.focus();
    }
  }, [autofocus, ref]);

  return (
    <div className="form__form-group-field custom-shadow custom-border-radius">
      <div className="form__form-group-icon form__form-group-icon--left form__form-group-search">
        <SearchIcon />
      </div>

      <input
        {...props}
        value={value}
        ref={ref}
        className="form__custom-field form__custom-field--icon-left"
      />
      <ClearIcon visible={!!value} onClick={onClear} />
    </div>
  );
});

export default InputWithSearchIcon;
