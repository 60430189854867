import { handleRequest, handleUploadRequest } from '../services/APIService';

export const FETCH_USER_PROFILE = 'FETCH_USER_PROFILE';
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const UPDATE_USER_AVATAR = 'UPDATE_USER_AVATAR';
export const UPDATE_USER_AVATAR_ERROR = 'UPDATE_USER_AVATAR_ERROR';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const VERIFY_RESET_PASSWORD = 'VERIFY_RESET_PASSWORD';
export const VERIFY_RESET_PASSWORD_ERROR = 'VERIFY_RESET_PASSWORD_ERROR';
export const GET_ONBOARDING_INFO_ERROR = 'GET_ONBOARDING_INFO_ERROR';
export const GET_ONBOARDING_INFO = 'GET_ONBOARDING_INFO';
export const SIGNUP = 'SIGNUP';
export const GET_SUPPORTER_INFO = 'GET_SUPPORTER_INFO';
export const GET_SUPPORTER_INFO_ERROR = 'GET_SUPPORTER_INFO_ERROR';
export const ACCEPT_INVITATION = 'ACCEPT_INVITATION';
export const ACCEPT_INVITATION_ERROR = 'ACCEPT_INVITATION_ERROR';

export const getUserProfile = (id) => (dispatch) => {
  handleRequest('get', `user/profile/${id}`, true).then((results) => {
    return dispatch({
      type: FETCH_USER_PROFILE,
      payload: results.data,
    });
  });
};

export const updateUserProfile = (data) => (dispatch) => {
  handleRequest('put', 'user/profile/', true, data).then((results) => {
    return dispatch({
      type: UPDATE_USER_PROFILE,
      payload: true,
    });
  });
};

export const updatePassword = (data) => (dispatch) => {
  handleRequest('put', 'user/profile/', true, data).then((results) => {
    return dispatch({
      type: UPDATE_USER_PROFILE,
      payload: true,
      resetPassword: true,
    });
  });
};

export const updateAvatar = (file) => (dispatch) => {
  handleUploadRequest(file, 'profile')
    .then((results) => {
      return dispatch({
        type: UPDATE_USER_AVATAR,
        payload: results.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: UPDATE_USER_AVATAR_ERROR,
        payload: error,
      });
    });
};

export const login = (data) => (dispatch) => {
  handleRequest('post', 'login', null, data)
    .then((results) => {
      return dispatch({
        type: LOGIN,
        payload: results.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: LOGIN_ERROR,
        payload: error.response,
      });
    });
};
export const registerOnboarding = (data) => (dispatch) => {
  handleRequest('post', 'signup', null, data)
    .then((results) => {
      return dispatch({ type: SIGNUP });
    })
    .catch((error) => {
      return dispatch({
        type: LOGIN_ERROR,
        payload: JSON.parse(error.response),
      });
    });
};

export const getOnboardingInfo = (data) => (dispatch) => {
  handleRequest('get', `signup?token=${data}`)
    .then((results) => {
      if (!results.data.user) {
        return dispatch({
          type: GET_ONBOARDING_INFO_ERROR,
        });
      }
      return dispatch({
        type: GET_ONBOARDING_INFO,
        payload: results.data.user,
      });
    })
    .catch((error) => {
      return dispatch({
        type: LOGIN_ERROR,
        payload: error.response.data,
      });
    });
};

export const changePassword = (data) => (dispatch) => {
  handleRequest('post', 'change-password', null, data)
    .then((results) => {
      return dispatch({
        type: CHANGE_PASSWORD,
        payload: results,
      });
    })
    .catch((error) => {
      return dispatch({
        type: CHANGE_PASSWORD_ERROR,
        payload: error.response.data,
      });
    });
};

export const sendForgetPasswordLink = (data) => (dispatch) => {
  handleRequest('post', 'forgot-password', null, data)
    .then((results) => {
      return dispatch({
        type: FORGOT_PASSWORD,
        payload: results,
      });
    })
    .catch((error) => {
      return dispatch({
        type: FORGOT_PASSWORD_ERROR,
        payload: error.response.data,
      });
    });
};

export const resetPassword = (data) => (dispatch) => {
  handleRequest('post', 'reset-password', null, data)
    .then((results) => {
      return dispatch({
        type: RESET_PASSWORD,
        payload: results,
      });
    })
    .catch((error) => {
      return dispatch({
        type: RESET_PASSWORD_ERROR,
        payload: error.response?.data,
      });
    });
};

export const verifyResetPassword = (data) => (dispatch) => {
  handleRequest('post', 'verify-change-password', null, data)
    .then((results) => {
      return dispatch({
        type: VERIFY_RESET_PASSWORD,
        payload: results,
      });
    })
    .catch((error) => {
      return dispatch({
        type: VERIFY_RESET_PASSWORD_ERROR,
        payload: error.response.data,
      });
    });
};

export const logout = (data) => (dispatch) => {
  handleRequest('post', 'logout', true, data)
    .then((results) => {
      return dispatch({
        type: LOGOUT,
        payload: results,
      });
    })
    .catch(() => {
      return dispatch({
        type: LOGOUT,
        payload: null,
      });
    });
};

export const getSupporterInfo = (data) => (dispatch) => {
  handleRequest('get', `signup${data}`)
    .then((results) => {
      if (!results.data.user) {
        return dispatch({
          type: GET_SUPPORTER_INFO_ERROR,
        });
      }
      const user = results.data.user;
      delete user.password;
      return dispatch({
        type: GET_SUPPORTER_INFO,
        payload: results.data.user,
      });
    })
    .catch((error) => {
      return dispatch({
        type: LOGIN_ERROR,
        payload: JSON.parse(error.response),
      });
    });
};

export const acceptInvitation = (data) => (dispatch) => {
  handleRequest('post', `user/verify/${data.token}`, null, data.data, {}, true)
    .then((results) => {
      if (results && results.message === 'success' && results.data) {
        return dispatch({
          type: ACCEPT_INVITATION,
        });
      }
      return dispatch({
        type: ACCEPT_INVITATION_ERROR,
        payload: results.errorMessage,
      });
    })
    .catch((error) => {
      if (error.response) {
        const errorMessage = JSON.parse(error.response);
        return dispatch({
          type: ACCEPT_INVITATION_ERROR,
          payload: errorMessage.errorMessage,
        });
      }

      return dispatch({
        type: ACCEPT_INVITATION_ERROR,
        payload: 'Something went wrong.',
      });
    });
};
