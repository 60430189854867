import React, { Component, createRef } from 'react';
import { Card, CardBody, Col, Label } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import humanize from 'underscore.string/humanize';
import TableComponent from './TableComponent';
import Pagination from '../../../shared/components/pagination/Pagination';
import {
  clearUpdatePetStatus,
  getPatientList,
  updatePetStatus,
} from '../../../redux/actions/petActions';
import { getBreeds, getSpecies } from '../../../redux/actions/breedAction';
import { toggleLoading } from '../../../redux/actions/commonActions';
import MessageModal from '../../../shared/components/Modals/MessageModal';
import HeadingText from '../../../shared/components/form/HeadingText';
import { formatPhone } from '../../../libs/commonHelper';
import CreateAppointmentModal from '../CreateAppointment/Components/CreateAppoimentModal';
import withRouter from '../../../shared/components/withRouter';
import { heads } from './constants';
import { DEFAULT_TIMESTAMP_FORMAT, STATUS } from '../../../constants/constants';
import InputWithSearchIcon from '../../common/InputWithSearchIcon';
import { routes } from '../../../constants/routes';

class ManagePatients extends Component {
  searchRef = createRef();

  constructor() {
    super();

    this.state = {
      rows: [],
      refreshState: false,
      inputs: {
        search: '',
        sort: {
          field: 'parentName',
          order: 1,
        },
        filters: { timeFilter: null },
        page: 1,
        limit: 100,
        offset: 0,
        total: 0,
      },
      confirmPopup: {
        showResponse: false,
        responseMessage: '',
        responseAlertType: '',
        responseType: '',
        responseTitle: '',
        pet: null,
        status: null,
        confirmType: null,
      },
      isOpenCreateAppointment: false,
    };
  }

  componentDidMount() {
    const { inputs } = this.state;
    this.props.getSpecies();
    this.refreshBreeds();
    this.props.getPatientList(inputs);
  }

  refreshBreeds = (search) => {
    const { inputs } = this.state;

    this.props.getBreeds({
      search,
      filter: { species: inputs.filters.speciesName || [] },
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.pets.patientList) {
      this.setPatientData(nextProps.pets.patientList);
    }
    if (nextProps.breeds.species) {
      const { refreshState } = this.state;
      const speciesList = nextProps.breeds.species.map((species) => ({
        [species._id]: species.name,
      }));
      heads[2].filterValue = speciesList;
      this.setState({ refreshState: !refreshState });
    }
    if (nextProps.breeds.breeds) {
      const { refreshState } = this.state;
      const breedList = nextProps.breeds.breeds.map((breed) => ({
        [breed._id]: breed.name,
      }));
      heads[3].filterValue = breedList;
      this.setState({ refreshState: !refreshState });
    }
    if (nextProps.pets.petUpdateStatus) {
      const { refreshState, confirmPopup } = this.state;
      if (nextProps.pets.petUpdateStatus.errorMessage) {
        confirmPopup.showResponse = true;
        confirmPopup.responseType = 'alert';
        confirmPopup.responseAlertType = 'danger';
        confirmPopup.responseMessage =
          nextProps.pets.petUpdateStatus.errorMessage;
        this.setState({ confirmPopup, refreshState: !refreshState });
        this.props.clearUpdatePetStatus();
      } else {
        if (
          nextProps.pets.petUpdateStatus.status &&
          nextProps.pets.petUpdateStatus.status === 'confirm'
        ) {
          confirmPopup.showResponse = true;
          confirmPopup.responseMessage = nextProps.pets.petUpdateStatus.message;
          confirmPopup.responseType = 'confirm';
          confirmPopup.confirmType = 'request_confirmed';
          this.setState({ confirmPopup, refreshState: !refreshState });
          this.props.clearUpdatePetStatus();
        } else {
          confirmPopup.showResponse = true;
          confirmPopup.responseMessage = `${confirmPopup.pet.petName} ${
            confirmPopup.status === STATUS.ACTIVE ? 'activated' : 'deactivated'
          }`;
          confirmPopup.responseType = 'alert';
          confirmPopup.responseAlertType = 'success';
          this.setState({ confirmPopup, refreshState: !refreshState });
          this.reFreshTableData();
        }
      }
      this.setState({ confirmPopup, refreshState: !refreshState });
    }
  }

  setPatientData = (data) => {
    const { inputs } = this.state;
    inputs.total = data.total;
    this.setState({ inputs });
    this.createRows(data.data);
  };

  handleActiveStatus = (status, row) => {
    const statusMessage = `Are you sure you want to ${
      status === 'inactive' ? 'deactivate' : 'activate'
    } ${row.petName}?`;
    const confirmPopup = {
      showResponse: true,
      responseMessage: statusMessage,
      responseType: 'confirm',
      responseAlertType: null,
      pet: row,
      footer: true,
      confirmType: 'change_status',
      status,
    };
    this.setState({ confirmPopup });
  };

  onSuccess = () => {
    const { confirmPopup, refreshState } = this.state;
    if (confirmPopup.confirmType === 'change_status') {
      this.props.toggleLoading(true);
      this.props.updatePetStatus({
        status: confirmPopup.status,
        id: confirmPopup.pet.id,
      });
    }
    if (confirmPopup.confirmType === 'request_confirmed') {
      this.props.toggleLoading(true);
      this.props.updatePetStatus({
        status: confirmPopup.status,
        id: confirmPopup.pet.id,
        confirmed: true,
      });
    }
    confirmPopup.showResponse = false;
    this.setState({
      refreshState: !refreshState,
      confirmPopup,
    });
  };

  closePopup = () => {
    const { confirmPopup, refreshState } = this.state;
    confirmPopup.showResponse = false;
    confirmPopup.confirmType = null;
    this.setState({ confirmPopup, refreshState: !refreshState });
  };

  createRows = (data) => {
    const rows = data.map((row) => ({
      parentName: row.parentName,
      coParents: row.coParents,
      petName: row.petName,
      speciesName: row.speciesName,
      breedName: row.breedName || 'N/A',
      gender: humanize(row.gender) || 'N/A',
      phone: row.phone ? formatPhone(row.phone, '(###) ###-####') : '',
      email: row.email,
      createdAt:
        moment(row.createdAt).format(DEFAULT_TIMESTAMP_FORMAT) || 'N/A',
      lastAppointmentDate: row.lastAppointmentDate
        ? moment(row.lastAppointmentDate).format(DEFAULT_TIMESTAMP_FORMAT)
        : 'N/A',
      status: row.status,
      parentId: row.parentId,
      petId: row.petId,
      id: row._id,
    }));

    this.setState({ rows });
  };

  handleSearch = (e) => {
    const { inputs } = this.state;
    inputs.search = e.target.value;
    inputs.offset = 0;
    this.setState({ inputs }, () => {
      this.props.getPatientList(inputs);
    });
  };

  handleClearSearch = () => {
    const { inputs } = this.state;

    const newInputs = {
      ...inputs,
      search: '',
      offset: 0,
    };

    this.setState({ inputs: newInputs }, () =>
      this.props.getPatientList(newInputs),
    );
  };

  nextPage = () => {
    const { inputs } = this.state;
    inputs.offset = parseInt(inputs.offset + inputs.limit);
    this.setState({ inputs }, () => {
      this.props.getPatientList(inputs);
    });
  };

  prevPage = () => {
    const { inputs } = this.state;
    inputs.offset = parseInt(inputs.offset - inputs.limit);
    this.setState({ inputs }, () => {
      this.props.getPatientList(inputs);
    });
  };

  gotoPage = (i) => {
    const { inputs } = this.state;
    inputs.offset = parseInt(inputs.limit * i);
    this.setState({ inputs }, () => {
      this.props.getPatientList(inputs);
    });
  };

  handleGridSort = (field) => {
    const { inputs } = this.state;
    if (field === inputs.sort.field) {
      inputs.sort.order = inputs.sort.order === 1 ? -1 : 1;
    } else {
      inputs.sort.field = field;
    }
    inputs.offset = 0;
    this.setState({ inputs }, () => {
      this.props.getPatientList(inputs);
    });
  };

  handleGridFilter = (header, value) => {
    const { inputs } = this.state;
    inputs.filters[header] = value;
    inputs.offset = 0;
    this.setState({ inputs }, () => {
      this.props.getPatientList(inputs);
      if (header === 'speciesName') {
        this.refreshBreeds();
      }
    });
  };

  handlePageLimit = (perPage) => {
    const { inputs } = this.state;
    inputs.limit = perPage;
    inputs.offset = 0;
    this.setState({ inputs }, () => {
      this.props.getPatientList(inputs);
    });
  };

  reFreshTableData = () => {
    const { inputs } = this.state;
    this.props.getPatientList(inputs);
  };

  viewProfile = (selectedRow) => {
    this.props.history.push(`/client-list/profile/${selectedRow.parentId}`);
  };

  goToNewParent = (e) => {
    e.preventDefault();

    this.props.navigate({
      pathname: routes.manageClients.absolute,
      state: { addNewParent: true },
    });
  };

  goToAppointments = (e) => {
    e.preventDefault();

    this.props.navigate({
      pathname: routes.appointments.absolute,
    });
  };

  toggleOpenCreateAppointment = () => {
    const isOpenCreateAppointment = this.state.isOpenCreateAppointment;
    this.setState({
      isOpenCreateAppointment: !isOpenCreateAppointment,
    });
  };

  handleFilterSearch = (key, search) => {
    if (key === 'breedName') {
      this.refreshBreeds(search);
    }
  };

  render() {
    const { rows, confirmPopup, inputs } = this.state;

    return (
      <>
        <Col
          md={12}
          lg={12}
          className="manage-patient-container admin-container"
        >
          <Card>
            <CardBody>
              <div className="sticky-table">
                <HeadingText text="Clients" />
                <div className="row search form">
                  <Label for="exampleSelect" className="empty-label">
                    &nbsp;
                  </Label>
                  <div
                    className="col-sm-10 col-md-10"
                    style={{ display: 'flex' }}
                  >
                    <div className="form__form-group">
                      <InputWithSearchIcon
                        name="search"
                        id="search"
                        type="text"
                        onChange={this.handleSearch}
                        ref={this.searchRef}
                        placeholder="Search by Parent Name, Pet Name, or Mobile Number"
                        onClear={this.handleClearSearch}
                        value={this.state.inputs.search}
                        autofocus
                      />
                    </div>
                    <div
                      className="form__form-group"
                      style={{ marginLeft: '20px' }}
                    >
                      <div className="form__form-group-field">
                        <a
                          href={routes.manageClients.absolute}
                          onClick={this.goToNewParent}
                          className="btn loyal-btn-new-parent"
                        >
                          <span className="lnr lnr-plus-circle" />
                          Invite New Parent
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="row time-filter-wrapper">
                    <div className="form__form-group-field">
                      <a
                        href={routes.appointments.absolute}
                        onClick={this.goToAppointments}
                        className="btn loyal-btn-new-parent"
                      >
                        Appointments
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <TableComponent
                sortField={inputs.sort.field}
                sortOrder={inputs.sort.order}
                heads={heads}
                rows={rows}
                noActions
                viewProfile={(field) => this.viewProfile(field)}
                handleGridSort={(field) => this.handleGridSort(field)}
                handleFilterSearch={(key, search) =>
                  this.handleFilterSearch(key, search)
                }
                handleGridFilter={(header, field) =>
                  this.handleGridFilter(header, field)
                }
                handleActiveStatus={(status, id, joined, firstName) =>
                  this.handleActiveStatus(status, id, joined, firstName)
                }
                history={this.props.history}
              />
              <Pagination
                handlePageLimit={(limit) => this.handlePageLimit(limit)}
                nextPage={this.nextPage}
                prevPage={this.prevPage}
                gotoPage={(i) => this.gotoPage(i)}
                limit={inputs.limit}
                offset={inputs.offset}
                total={inputs.total}
              />
            </CardBody>
          </Card>
          <MessageModal
            show={confirmPopup.showResponse}
            type={confirmPopup.responseType}
            alertType={confirmPopup.responseAlertType}
            footer={confirmPopup.footer}
            onSuccess={this.onSuccess}
            onClose={this.closePopup}
            message={confirmPopup.responseMessage}
          />
        </Col>
        <CreateAppointmentModal
          isOpen={this.state.isOpenCreateAppointment}
          history={this.props.history}
          onClose={this.toggleOpenCreateAppointment}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = {
  getPatientList,
  getSpecies,
  getBreeds,
  updatePetStatus,
  toggleLoading,
  clearUpdatePetStatus,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ManagePatients),
);
