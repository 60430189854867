import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Table } from 'reactstrap';
import classNames from 'classnames';

import { toggleAppointmentRequestsModalClass } from '../../ParentProfile/utils/toggleAppointmentRequestsModalClass';
import TableFilterComponent from '../../../../shared/components/form/TableFilterComponent';
import { getStaffDoctorsByFacility } from '../../../../redux/actions/staffActions';
import { SortingField } from '../../../common/SortingCell';
import {
  getSortFieldName,
  getDefaultSortOrder,
} from '../../Common/SortBy/utils';

import {
  tab,
  formatPreferredDateRange,
  useAppointmentRequestModalContext,
} from '../utils';

const getFilterField = (key) => {
  return key === 'doctorFullName' ? 'doctorId' : key;
};

export function AppointmentRequestsTable({
  heads,
  rows,
  handleFilter,
  handleSort,
  filters,
  sortField,
  sortOrder,
  stickyTop,
}) {
  const dispatch = useDispatch();

  const doctors = useSelector(
    (state) => state.staff.staffDoctorsByFacility || [],
  );
  const facilityId = useSelector((state) => state.user?.profile?.facility?._id);
  const { petsAppointmentRequestsNumber, isPetAppointmentRequestsModalOpen } =
    useSelector((state) => state.pets);

  const {
    openAppointmentRequestModal,
    handleSetAppointmentRequestData,
    handleSetDoctors,
  } = useAppointmentRequestModalContext();

  useEffect(() => {
    if (facilityId) {
      dispatch(getStaffDoctorsByFacility());
    }
  }, [dispatch, facilityId]);

  const handleStatusFilter = (key, list) => {
    const filterField = getFilterField(key);

    handleFilter(filterField, list);
  };

  const handleOpenAppointmentModal = ({
    requestData,
    tabToOpen = tab.MAIN_INFO,
  }) => {
    // if it's the Clients page and the Pet tab then hide Pet Appointment Requests modal
    if (petsAppointmentRequestsNumber && isPetAppointmentRequestsModalOpen) {
      toggleAppointmentRequestsModalClass({
        action: 'add',
      });
    }

    openAppointmentRequestModal(tabToOpen);
    handleSetAppointmentRequestData(requestData);
    if (tabToOpen === tab.MAIN_INFO) handleSetDoctors(doctors);
  };

  const renderRowValues = (row) => {
    const attachmentsColumn = (head) => {
      const isButtonVisible = !!row.documents?.length || !!row.photos?.length;

      return (
        <td
          key={head.key}
          className="custom-no-over-flow-td"
          onClick={(e) => {
            isButtonVisible && e.stopPropagation();
          }}
          style={{ textAlign: 'center' }}
        >
          {isButtonVisible && (
            <a
              className="view-tab-button full-width"
              onClick={() =>
                handleOpenAppointmentModal({
                  requestData: row,
                  tabToOpen: tab.ATTACHMENTS,
                })
              }
            >
              View
            </a>
          )}
        </td>
      );
    };

    const preferredDatesColumn = (head, value) => {
      const formattedDates = value
        .map((dateRange) => formatPreferredDateRange(dateRange, 'string'))
        .join('\n');

      return (
        <td
          key={head.key}
          title={formattedDates}
          className="custom-no-over-flow-td"
        >
          {formattedDates}
        </td>
      );
    };

    const openButton = (head) => (
      <td
        key={head.key}
        className={head.className}
        onClick={(e) => e.stopPropagation()}
      >
        <Button
          className="open-appointment-request save-update"
          color="primary"
          onClick={() => handleOpenAppointmentModal({ requestData: row })}
        >
          Open
        </Button>
      </td>
    );

    const defaultColumn = (head, value, capitalizeClass) => {
      const sortKey = getSortFieldName(head.key);
      const isSortedField = sortField === sortKey;

      const tdClassName = classNames(
        'custom-no-over-flow-td',
        capitalizeClass,
        { 'sorted-column': isSortedField },
      );

      return (
        <td key={head.key} title={value} className={tdClassName}>
          {value}
        </td>
      );
    };

    return heads.map((head) => {
      const value = row[head.key];
      const capitalizeClass = head.capitalize ? 'text-capitalize' : '';

      if (head.key === 'attachments') {
        return attachmentsColumn(head);
      }

      if (head.key === 'preferredDate') {
        return preferredDatesColumn(head, value);
      }

      if (head.key === 'open') {
        return openButton(head);
      }

      return defaultColumn(head, value, capitalizeClass);
    });
  };

  const renderTableHeaders = () => {
    const renderSortableHeader = (head) => {
      const sortKey = getSortFieldName(head.key);
      const defaultOrder = getDefaultSortOrder(sortKey);
      const isSortedField = sortField === sortKey;

      const thClassName = classNames(head.className, {
        'sorted-column': isSortedField,
      });

      return (
        <th key={head.key} className={thClassName}>
          {sortField === sortKey ? (
            <SortingField
              icon={sortOrder === 1 ? 'triangle-up' : 'triangle-down'}
              name={head.name}
              onSort={() => handleSort({ order: sortOrder === 1 ? -1 : 1 })}
            />
          ) : (
            <SortingField
              name={head.name}
              icon="diamond-narrow"
              onSort={() => handleSort({ field: sortKey, order: defaultOrder })}
            />
          )}
        </th>
      );
    };

    const renderFilterableHeader = (head) => {
      const filterField = getFilterField(head.key);
      const filtersByKey = filters[filterField] ?? [];

      return (
        <th key={head.key} className={head.className ?? ''}>
          <TableFilterComponent
            allFilters={head.filterValue}
            filters={filtersByKey}
            action={(list) => {
              handleStatusFilter(head.key, list);
            }}
            label={head.name}
          />
        </th>
      );
    };

    const renderIconHeader = (head) => (
      <th key={head.key} className={head.className ?? ''}>
        <img src={head.imgSrc} alt={head.imgAlt} title={head.name} />
      </th>
    );

    const renderDefaultHeader = (head) => (
      <th key={head.key} className={head.className ?? ''}>
        <p>{head.name}</p>
      </th>
    );

    const renderHeader = (head) => {
      if (!head) return null;

      if (head.sortable) {
        return renderSortableHeader(head);
      } else if (head.filterable) {
        if (head.key === 'doctorFullName') {
          if (doctors.length === 0) return renderDefaultHeader(head);

          const filterValues = doctors.map(({ _id, name }) => ({
            [_id]: `Dr. ${name}`,
          }));

          filterValues.push({ NA: 'N/A' });

          head.filterValue = filterValues;
        }

        return renderFilterableHeader(head);
      } else if (head.imgSrc) {
        return renderIconHeader(head);
      } else {
        return renderDefaultHeader(head);
      }
    };

    return (
      <tr className="header-tr" style={stickyTop ? { top: stickyTop } : {}}>
        {heads.map((head) => renderHeader(head))}
      </tr>
    );
  };

  return (
    <div className="row table-row">
      <div className="col-md-12 table-wrapper">
        <Table striped className="custom-table loyal-table">
          <thead>{renderTableHeaders()}</thead>
          <tbody>
            {rows.map((row, index) => {
              const isCompletedRequest = !!row.appointmentInfo?.id;

              return (
                <tr
                  className={classNames('body-tr appointment-request-row', {
                    completed: isCompletedRequest,
                  })}
                  key={index}
                  onClick={() =>
                    handleOpenAppointmentModal({ requestData: row })
                  }
                >
                  {renderRowValues(row)}
                </tr>
              );
            })}
          </tbody>
        </Table>

        {rows.length === 0 ? (
          <div className="no-data-message">No Appointment Requests</div>
        ) : null}
      </div>
    </div>
  );
}
