import React, { useEffect, useState } from 'react';
import moment from 'moment';
import TopbarSidebarButton from './TopbarSidebarButton';
import { useDispatch, useSelector } from 'react-redux';
import { TOPBAR_DATE_FORMAT } from '../../../constants/constants';
import {
  changeMobileSidebarVisibility,
  changeSidebarVisibility,
} from '../../../redux/actions/sidebarActions';
import Logo from './Logo';
import { useLocation, useNavigate } from 'react-router-dom';
import { routes } from '../../../constants/routes';

function Topbar() {
  const [curTime, setCurTime] = useState('');
  const dispatch = useDispatch();
  const {
    name = '',
    utcOffset = 0,
    logo,
  } = useSelector((state) => state.user?.profile?.facility || {});

  useEffect(() => {
    if (!utcOffset) return;
    const setTime = () => {
      setCurTime(
        moment().utc().add(+utcOffset, 'minutes').format(TOPBAR_DATE_FORMAT),
      );
      return requestAnimationFrame(setTime);
    };
    const reqRef = setTime();
    return () => reqRef && cancelAnimationFrame(reqRef);
  }, [utcOffset]);

  return (
    <div className="topbar">
      <div className="topbar__wrapper">
        <div className="topbar__left">
          <TopbarSidebarButton
            changeSidebarVisibility={() => dispatch(changeSidebarVisibility())}
            changeMobileSidebarVisibility={() =>
              dispatch(changeMobileSidebarVisibility())
            }
          />
          <GoBack />
          <Logo />
        </div>
        <div className="topbar__right">
          <div>
            <p>{name}</p>
            {curTime}
          </div>
          {logo && (
            <img alt="icon" className="topbar_facility_image" src={logo} />
          )}
        </div>
      </div>
    </div>
  );
}

function GoBack() {
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname;

  return (
    <button
      className="container__navigation"
      onClick={() => navigate(-1)}
      disabled={from === '/' || from === routes.login.absolute}
    >
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="22px"
        height="20px"
        viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
          fill="#000000"
          stroke="none"
        >
          <path
            d="M1850 4257 c-27 -8 -212 -187 -813 -786 -466 -464 -787 -792 -800
            -816 -28 -54 -28 -136 0 -190 32 -59 1547 -1570 1595 -1590 177 -73 359 110
            279 280 -13 27 -205 225 -590 610 -314 313 -571 573 -571 577 0 4 859 9 1909
            10 l1909 3 44 30 c138 97 118 306 -34 370 -33 13 -245 15 -1933 15 -1042 0
            -1895 3 -1895 7 0 4 263 271 584 593 573 574 584 586 594 635 35 169 -112 302
            -278 252z"
          />
        </g>
      </svg>
    </button>
  );
}

export default Topbar;
