import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Table,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { connect } from 'react-redux';
import TableFilterComponent from '../../shared/components/form/TableFilterComponent';
import { toggleLoading } from '../../redux/actions/commonActions';
import { SortingField } from '../common/SortingCell';
import { STATUS } from '../../constants/constants';

class ClientTableComponent extends Component {
  static propTypes = {
    heads: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string,
        sortable: PropTypes.bool,
      }),
    ).isRequired,
    rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  UNSAFE_componentWillMount() {
    this.props.toggleLoading(true);
  }

  componentWillUnmount() {
    if (this.props.common.loading) {
      this.props.toggleLoading(false);
    }
  }

  handleStatusFilter = (list) => {
    this.props.handleGridFilter('status', list);
  };

  handleJoinStatusFilter = (list) => {
    this.props.handleGridFilter('joinStatus', list);
  };

  render() {
    const { rows, heads, sortField, sortOrder } = this.props;

    const headText = heads.map((head) => head.key);

    const capitalizedText = heads.filter((head) => {
      if (head.capitalize) {
        return head.key;
      }
      return false;
    });

    const tableRows = rows.map((row, key) => {
      const _row = Object.keys(row).map((value) => {
        if (headText.includes(value)) {
          const capitalizeClass = capitalizedText.includes(value)
            ? 'text-capitalize'
            : '';

          const path = `/client-list/profile/${row.id}`;

          return (
            <td key={value} className={capitalizeClass}>
              {value === 'fullName' ? (
                <Link to={path}>{row[value]}</Link>
              ) : (
                row[value]
              )}
            </td>
          );
        }

        return true;
      });

      return (
        <tr className="body-tr" key={key}>
          {_row}
          <td>
            <UncontrolledDropdown>
              <DropdownToggle>
                <span className="six-layers" />
              </DropdownToggle>
              <DropdownMenu className="dropdown__menu">
                <DropdownItem tag="div">
                  <a
                    onClick={() =>
                      this.props.handleActivePopup(STATUS.INACTIVE, row.id)
                    }
                  >
                    Activate
                  </a>
                </DropdownItem>
                <DropdownItem tag="div">
                  <a
                    onClick={() =>
                      this.props.handleActivePopup(STATUS.ACTIVE, row.id)
                    }
                  >
                    Deactivate
                  </a>
                </DropdownItem>
                {row.joinStatus !== 'Joined' && (
                  <DropdownItem tag="div">
                    <a
                      onClick={() =>
                        this.props.handleActivePopup(
                          STATUS.INACTIVE,
                          row.id,
                          true,
                        )
                      }
                    >
                      Reinvite
                    </a>
                  </DropdownItem>
                )}
              </DropdownMenu>
            </UncontrolledDropdown>
          </td>
        </tr>
      );
    });

    const headers = heads.map((head, key) => {
      const _sortField = head.key;
      return head.sortable && !head.filterable ? (
        <th key={key}>
          {sortField === head.key ? (
            <SortingField
              icon={sortOrder === 1 ? 'triangle-up' : 'triangle-down'}
              name={head.name}
              onSort={() => this.props.handleGridSort(_sortField)}
            />
          ) : (
            <SortingField
              name={head.name}
              icon="diamond-narrow"
              onSort={() => this.props.handleGridSort(_sortField)}
            />
          )}
        </th>
      ) : head.key === 'joinStatus' ? (
        <th key={key}>
          <TableFilterComponent
            allFilters={head.filterValue}
            action={(list) => this.handleJoinStatusFilter(list)}
            label={head.name}
          />
        </th>
      ) : head.key === 'status' ? (
        <th key={key}>
          <TableFilterComponent
            allFilters={head.filterValue}
            action={(list) => this.handleStatusFilter(list)}
            label={head.name}
          />
        </th>
      ) : (
        <th key={key}>
          <p>{head.name}</p>
        </th>
      );
    });

    return (
      <div className="row">
        <div className="col-md-12">
          <Table striped className="custom-table loyal-table">
            <thead>
              <tr className="header-tr" style={{ top: '180px' }}>
                {headers}
                <th className="text-center action">
                  <p className=" action">Action</p>
                </th>
              </tr>
            </thead>
            <tbody>{tableRows}</tbody>
          </Table>

          {tableRows.length === 0 && this.props.common.loading === false ? (
            <div className="no-data-message">No results found</div>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = {
  toggleLoading,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClientTableComponent);
