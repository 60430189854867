import React, { useEffect, useState } from 'react';
import { getFilteredFacilities } from '../../../../redux/actions/staffActions';
import CustomSelect from '../CustomSelect/CustomSelect';

export default function FacilitySelect({ value, onChange, error, ...props }) {
  const [facilities, setFacilities] = useState([]);
  const [search, setSearch] = useState(null);

  useEffect(() => {
    async function getFacilities() {
      const allFacilities = await getFilteredFacilities({ search });

      setFacilities(
        allFacilities.map((facility) => ({
          _id: facility._id,
          name: facility.name,
        })),
      );
    }

    getFacilities();
  }, [search]);

  const handleOptionSelect = (facilityId) => {
    onChange(facilityId);

    const value = facilities.find(
      (facility) => facility._id === facilityId,
    ).name;

    setSearch(value);
  };

  return (
    <CustomSelect
      value={value}
      options={facilities}
      search={search}
      setSearch={setSearch}
      handleOptionSelect={handleOptionSelect}
      error={error}
      placeholder="Select Facility"
      {...props}
    />
  );
}
