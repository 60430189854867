function generatePassword() {
  const lowercase = 'abcdefghijklmnopqrstuvwxyz';
  const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const digits = '0123456789';

  let password = '';

  password += lowercase[Math.floor(Math.random() * lowercase.length)];

  password += uppercase[Math.floor(Math.random() * uppercase.length)];

  password += digits[Math.floor(Math.random() * digits.length)];

  for (let i = 0; i < 5; i++) {
    const characters = lowercase + uppercase + digits;
    password += characters[Math.floor(Math.random() * characters.length)];
  }

  password = password
    .split('')
    .sort(() => 0.5 - Math.random())
    .join('');

  return password;
}

export default generatePassword;
