import { handleRequest } from '../services/APIService';

export const GET_DASHBOARD_DATA = 'GET_DASHBOARD_DATA';
export const GET_DASHBOARD_DATA_ERROR = 'GET_DASHBOARD_DATA_ERROR';
export const GET_PROCEDURE_UPDATE_MSG_DATA = 'GET_PROCEDURE_UPDATE_MSG_DATA';
export const GET_PROCEDURE_UPDATE_MSG_DATA_ERROR =
  'GET_PROCEDURE_UPDATE_MSG_DATA_ERROR';
export const getDashboardData = (data) => (dispatch) => {
  handleRequest('post', 'dashboard/', true, data)
    .then((results) => {
      return dispatch({
        type: GET_DASHBOARD_DATA,
        payload: results.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_DASHBOARD_DATA_ERROR,
        payload: error,
      });
    });
};

export const getProcedureUpdateMsgsData = (data) => (dispatch) => {
  handleRequest('post', 'get/procedure-update-messages', true, data)
    .then((results) => {
      return dispatch({
        type: GET_PROCEDURE_UPDATE_MSG_DATA,
        payload: results.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_PROCEDURE_UPDATE_MSG_DATA_ERROR,
        payload: error,
      });
    });
};

export const GET_DASHBOARD_USER_DATA = 'GET_DASHBOARD_USER_DATA';
export const GET_DASHBOARD_USER_DATA_ERROR = 'GET_DASHBOARD_USER_DATA_ERROR';
export const getDashboardNewUserData = (data) => (dispatch) => {
  handleRequest('post', 'dashboard/new-user-chart', true, data)
    .then((results) => {
      return dispatch({
        type: GET_DASHBOARD_USER_DATA,
        payload: results.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_DASHBOARD_USER_DATA_ERROR,
        payload: error,
      });
    });
};

export const GET_DASHBOARD_PROCEDURE_DETAILS_DATA =
  'GET_DASHBOARD_PROCEDURE_DETAILS_DATA';
export const GET_DASHBOARD_PROCEDURE_DETAILS_DATA_ERROR =
  'GET_DASHBOARD_PROCEDURE_DETAILS_DATA_ERROR';
export const getDashboardProcedureDetailsData = (data) => (dispatch) => {
  handleRequest('post', 'dashboard/procedure-details-chart', true, data)
    .then((results) => {
      return dispatch({
        type: GET_DASHBOARD_PROCEDURE_DETAILS_DATA,
        payload: results.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_DASHBOARD_PROCEDURE_DETAILS_DATA_ERROR,
        payload: error,
      });
    });
};

export const GET_DASHBOARD_AVERAGE_TIME_DATA =
  'GET_DASHBOARD_AVERAGE_TIME_DATA';
export const GET_DASHBOARD_AVERAGE_TIME_DATA_ERROR =
  'GET_DASHBOARD_AVERAGE_TIME_DATAERROR';
export const getDashboardAverageTimeData = (data) => (dispatch) => {
  handleRequest('post', 'dashboard/average-time-chart', true, data)
    .then((results) => {
      return dispatch({
        type: GET_DASHBOARD_AVERAGE_TIME_DATA,
        payload: results.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_DASHBOARD_AVERAGE_TIME_DATA_ERROR,
        payload: error,
      });
    });
};

export const subscribeToTopic = (data) => {
  return handleRequest('post', 'topic/subscribe', true, data).then(
    (results) => results.data,
  );
};

export const unsubscribeFromTopic = (data) => {
  return handleRequest('post', 'topic/unsubscribe', true, data).then(
    (results) => results.data,
  );
};
