import React, { useState, useCallback, useEffect } from 'react';
import { Button, Spinner } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import {
  getRefillRequestStaffComments,
  addRefillRequestStaffComments,
} from '../../../../redux/actions/refillRequestActions';
import { FooterButtons } from '../../../../shared/components/Modals/FooterButtons';

import { ModalTextArea } from '../Components';
import { useRefillRequestModalContext } from '../utils';

export function InternalCommentsTab() {
  const dispatch = useDispatch();

  const { refillRequestData, closeRefillRequestModal } =
    useRefillRequestModalContext();
  const userId = useSelector((state) => state.user?.profile?._id);
  const { refillRequestStaffComments } = useSelector(
    (state) => state.refillRequests,
  );

  const [inputValue, setInputValue] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const refillRequestId = refillRequestData.id;

  const getStaffComments = useCallback(async () => {
    setIsLoading(true);
    await dispatch(getRefillRequestStaffComments(refillRequestId));
    setIsLoading(false);
  }, [refillRequestId, dispatch]);

  useEffect(() => {
    if (refillRequestId && refillRequestData.statusId) {
      getStaffComments();
    }
  }, [refillRequestId, refillRequestData.statusId, getStaffComments]);

  const handleSubmit = async () => {
    setIsSaving(true);
    await dispatch(
      addRefillRequestStaffComments(userId, refillRequestId, inputValue),
    );
    setInputValue('');
    setIsSaving(false);
  };

  let content;
  if (isLoading) {
    content = <p>Loading...</p>;
  } else if (!refillRequestStaffComments?.length) {
    content = <p>No internal comments available.</p>;
  } else {
    content = refillRequestStaffComments.map(
      (
        { date, time, authorFullName, comment, authorId, designation },
        index,
      ) => (
        <div className="comment-item" key={index}>
          <p className="comment-info">
            <span>{date}</span>
            <span>{time}</span>
            <span>{userId === authorId ? 'You' : authorFullName}</span>
            {designation && <span>({designation})</span>}
          </p>
          <p className="comment-text">{comment}</p>
        </div>
      ),
    );
  }

  return (
    <>
      <section className="internal-comments-tab">
        {!refillRequestData.statusId ? (
          <p style={{ color: 'red' }}>
            Please update the status of this refill request before leaving
            comments.
          </p>
        ) : (
          <>
            <div className="input-wrapper">
              <ModalTextArea
                title="Your Comment"
                placeholder="Enter any internal comment regarding this refill request…"
                rows={1}
                inputValue={inputValue}
                setInputValue={setInputValue}
              />
              <Button
                className={classNames('btn-yes', { saving: isSaving })}
                onClick={handleSubmit}
                disabled={!inputValue.trim() || isSaving}
              >
                {isSaving ? <Spinner size="sm" /> : 'Submit'}
              </Button>
            </div>
            <h3>History of Internal Comments</h3>
            <div className="comments-list scrollable-modal-area">{content}</div>
          </>
        )}
      </section>

      <FooterButtons onClose={closeRefillRequestModal} showSaveButton={false} />
    </>
  );
}
