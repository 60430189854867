import React, { Component } from 'react';
import {
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import { connect } from 'react-redux';
import {
  updateLogo,
  addNewFacility,
  initiatePopups,
} from '../../redux/actions/facilityActions';
import { toggleLoading } from '../../redux/actions/commonActions';
import config from '../../config/app.config';
import { validate, reset } from '../../libs/validationHelper';
import {
  defaultFacilityErrors,
  defaultFacilityInputs,
  tabs,
  validationRules,
} from './constants';
import { AddFacilityErrorsView } from './components/AddFacilityErrors';
import { UploadLogoIcon } from './components/UploadLogoIcon';
import {
  FacilityBasicDetails,
  FacilityBillingInfo,
  FacilityContactDetails,
  FacilityPhysicalAddress,
} from './components/FacilityFormParts';

class AddFacility extends Component {
  constructor() {
    super();
    this.state = {
      modal: false,
      activeTab: '1',
      inputs: { ...defaultFacilityInputs },
      error: false,
      errors: { ...defaultFacilityErrors },
      successResponse: null,
      errorResponse: null,
      refreshState: true,
    };
  }

  onChangeFields = (e) => {
    const property = e.target.name;
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    let { inputs, refreshState } = this.state;
    refreshState = !refreshState;

    inputs[property] = value;
    if (inputs.billingSameAddress) {
      inputs.billingAddress = inputs.address;
      inputs.billingCity = inputs.city;
      inputs.billingState = inputs.state;
      inputs.billingZipCode = inputs.zipCode;
    }
    this.setState({ inputs, refreshState });
  };

  onSubmit = async () => {
    const { inputs } = this.state;

    if (this.inputsHaveErrors(inputs)) {
      return;
    }

    this.props.toggleLoading(true);

    const [logo, logoIcon] = await Promise.all([
      !inputs.logo?.includes('https://')
        ? this.props.updateLogo(inputs.logo, 'logo')
        : inputs.logo,
      !inputs.logoIcon?.includes('https://')
        ? this.props.updateLogo(inputs.logoIcon, 'logoIcon')
        : inputs.logoIcon,
    ]);
    const facility = { ...inputs, logo, logoIcon };
    this.setState({
      inputs: facility,
    });

    this.props.addNewFacility({ ...inputs, logo, logoIcon });
  };

  closeModal = () => {
    const { refreshState } = this.state;

    this.props.initiatePopups();

    this.setState({
      modal: false,
      error: false,
      errors: { ...defaultFacilityErrors },
      successResponse: null,
      errorResponse: null,
      activeTab: '1',
      inputs: { ...defaultFacilityInputs },
      refreshState: !refreshState,
    });
    this.props.closeFacilityPopup();
  };

  inputsHaveErrors = (inputs) => {
    const { refreshState } = this.state;
    let { errors, activeTab } = this.state;

    errors = reset(errors);
    const validationResponse = validate(validationRules, inputs, errors);
    const error = validationResponse.error;
    errors = validationResponse.errors;

    const keys = Object.keys(errors);
    let errorTab = null;
    keys.forEach((inputKey) => {
      if (errors[inputKey].error !== null && errorTab === null) {
        errorTab = errors[inputKey].tab;
      }
    });
    activeTab = errorTab ? errorTab.toString() : activeTab;
    this.setState({
      errors,
      error,
      activeTab,
      errorResponse: null,
      successResponse: null,
      refreshState: !refreshState,
    });

    return error;
  };

  toggle() {
    let { errors } = this.state;
    const error = false;
    errors = reset(errors);
    this.setState({
      modal: !this.state.modal,
      error,
      errors,
      successResponse: null,
      errorResponse: null,
      activeTab: '1',
    });
    this.props.closeFacilityPopup();
  }

  changeTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }

  getAutoCompleteAddress = ({ address, city, state, zipCode, placeId }) => {
    const { inputs, refreshState } = this.state;

    inputs.address = address || inputs.address;
    inputs.city = city || inputs.city;
    inputs.state = state || inputs.state;
    inputs.zipCode = zipCode || inputs.zipCode;
    inputs.placeId = placeId || inputs.placeId;

    if (inputs.billingSameAddress) {
      inputs.billingAddress = inputs.address;
      inputs.billingCity = inputs.city;
      inputs.billingState = inputs.state;
      inputs.billingZipCode = inputs.zipCode;
    }
    this.setState({ inputs, refreshState: !refreshState });
  };

  getAutoCompleteBillingAddress = ({ address, city, state, zipCode }) => {
    const { inputs, refreshState } = this.state;

    inputs.billingAddress = address || inputs.billingAddress;
    inputs.billingCity = city || inputs.billingCity;
    inputs.billingState = state || inputs.billingState;
    inputs.billingZipCode = zipCode || inputs.billingZipCode;

    this.setState({ inputs, refreshState: !refreshState });
  };

  onUploadError = (message) => {
    this.setState({ errorResponse: message });
  };

  onFileUpload = (file, imageType) => {
    this.setState({
      inputs: {
        ...this.state.inputs,
        [imageType]: file,
      },
    });
  };

  componentDidMount() {
    this.props.initiatePopups();
    this.setState({
      inputs: { ...defaultFacilityInputs },
      successResponse: null,
      errorResponse: null,
    });
  }

  UNSAFE_componentWillReceiveProps(np) {
    const { view, facility } = np;

    if (facility.newFacilityResponse) {
      this.setState({
        successResponse: 'Profile created successfully',
        errorResponse: null,
      });
      setTimeout(() => {
        this.props.initiatePopups();
        this.closeModal();
      }, config.POPUP_TIMEOUT_TIME);
    }

    if (facility.newFacilityErrorResponse) {
      if (facility.newFacilityErrorResponse.errorMessage) {
        this.setState({
          errorResponse: np.facility.newFacilityErrorResponse.errorMessage,
          successResponse: null,
        });
      }
    }

    if (view && !this.state.modal) {
      this.setState({
        modal: true,
        successResponse: null,
        errorResponse: null,
      });
    }
  }

  render() {
    const { inputs, errors, successResponse, errorResponse } = this.state;
    const { handleSubmit } = this.props;

    return (
      <Modal
        isOpen={this.state.modal}
        size="lg"
        backdrop="static"
        className="model-form modal-dialog-facility"
      >
        <ModalHeader className="header-form">Add New Facility</ModalHeader>
        <ModalBody>
          <Card>
            <CardBody>
              <AddFacilityErrorsView
                successResponse={successResponse}
                errorResponse={errorResponse}
              />
              <form className="form" onSubmit={handleSubmit} autoComplete="off">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-3">
                      <UploadLogoIcon
                        onUploadError={this.onUploadError}
                        upload={this.onFileUpload}
                        logo={inputs.logo}
                        icon={inputs.logoIcon}
                      />
                    </div>

                    <div className="col-md-9 loyal-facility-tab-container">
                      <div>
                        <Nav tabs>
                          {tabs.map(({ value, label }) => (
                            <NavItem key={value}>
                              <NavLink
                                onClick={() => this.changeTab(value)}
                                active={this.state.activeTab === value}
                              >
                                {label}
                              </NavLink>
                            </NavItem>
                          ))}
                        </Nav>
                        <TabContent activeTab={this.state.activeTab}>
                          <TabPane tabId="1">
                            <FacilityBasicDetails
                              onChangeFields={this.onChangeFields}
                              inputs={inputs}
                              errors={errors}
                            />
                          </TabPane>

                          <TabPane tabId="2">
                            <FacilityPhysicalAddress
                              onChangeFields={this.onChangeFields}
                              getAutoCompleteAddress={
                                this.getAutoCompleteAddress
                              }
                              inputs={inputs}
                              errors={errors}
                            />
                          </TabPane>

                          <TabPane tabId="3">
                            <FacilityBillingInfo
                              inputs={inputs}
                              errors={errors}
                              onChangeFields={this.onChangeFields}
                              getAutoCompleteBillingAddress={
                                this.getAutoCompleteBillingAddress
                              }
                            />
                          </TabPane>

                          <TabPane tabId="4"></TabPane>

                          <TabPane tabId="5">
                            <FacilityContactDetails
                              inputs={inputs}
                              errors={errors}
                              onChangeFields={this.onChangeFields}
                            />
                          </TabPane>
                        </TabContent>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <Button
                          className="clear"
                          color="secondary"
                          onClick={this.closeModal}
                        >
                          Cancel
                        </Button>
                        <Button
                          className="save-update"
                          color="primary"
                          onClick={this.onSubmit}
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = {
  toggleLoading,
  updateLogo,
  addNewFacility,
  initiatePopups,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddFacility);
