const splitDateRange = (dateRange) => {
  const [startDate, startTime, startDayPart] = dateRange.from.split(' ');
  const [, endTime, endDayPart] = dateRange.to.split(' ');

  return { startDate, startTime, startDayPart, endTime, endDayPart };
};

export const formatPreferredDateRange = (dateRange, type) => {
  const { startDate, startTime, startDayPart, endTime, endDayPart } =
    splitDateRange(dateRange);

  switch (type) {
    case 'string':
      return `${startDate} ${startTime} ${startDayPart} - ${endTime} ${endDayPart}`;
    case 'object':
      return {
        date: startDate,
        startDate: `${startTime} ${startDayPart}`,
        endDate: `${endTime} ${endDayPart}`,
      };
    default:
      throw new Error(`Unsupported format type: ${type}`);
  }
};
