export const getSortOptions = (sortField) => {
  if (sortField === 'createdAt') {
    return [
      { label: 'from New to Old', value: -1 },
      { label: 'from Old to New', value: 1 },
    ];
  }
  return [
    { label: 'from A to Z', value: 1 },
    { label: 'from Z to A', value: -1 },
  ];
};

export const getSortFieldName = (key) => {
  return key === 'date' ? 'createdAt' : key;
};

export const getDefaultSortOrder = (sortField) => {
  let defaultOrder;

  if (sortField === 'createdAt') {
    defaultOrder = -1; // Default to 'From New to Old'
  } else {
    defaultOrder = 1; // Default to 'From A to Z'
  }

  return defaultOrder;
};
