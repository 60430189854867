import constants from '../../../../constants/constants';
import {
  appointmentRequestPageTypes,
  appointmentTypes,
} from '../../../../config/app.config';

const attachmentIcon = `${process.env.PUBLIC_URL}/img/icons/file-attachment-icon.png`;

export function getAppointmentRequestsHeaders(
  type = appointmentRequestPageTypes.default,
) {
  const commonHeaders = [
    {
      key: 'date',
      name: 'Date',
      sortable: true,
      filterable: false,
      capitalize: true,
    },
    {
      key: 'time',
      name: 'Time',
      sortable: false,
      filterable: false,
      capitalize: true,
    },
    {
      key: 'type',
      name: 'Appointment Type',
      className: 'appointment-type',
      sortable: false,
      filterable: true,
      filterValue: [
        { [appointmentTypes.initialConsult]: 'Initial Consult' },
        { [appointmentTypes.reCheckExam]: 'Recheck Exam' },
        { [appointmentTypes.technicianAppointment]: 'Technician Appointment' },
        { [appointmentTypes.procedure]: 'Procedure' },
      ],
      capitalize: true,
    },
    {
      key: 'parentFullName',
      name: 'Parent Name',
      className: 'parent-name',
      sortable: true,
      filterable: false,
      capitalize: true,
      link: (row) =>
        `/client-list/profile/${row.parentId}?tab=${constants.QUERY_TAB_PARAMS.parent}`,
    },
    {
      key: 'petName',
      name: 'Pet Name',
      sortable: true,
      filterable: false,
      capitalize: true,
      link: (row) =>
        `/client-list/profile/${row.parentId}?tab=${constants.QUERY_TAB_PARAMS.petInfo}&${constants.QUERY_PARAMS.pet}=${row.petId}`,
    },
    {
      key: 'doctorFullName',
      name: 'Doctor',
      sortable: false,
      filterable: true,
      filterValue: [],
      capitalize: true,
    },
    {
      key: 'availabilityInfo',
      name: 'Availability Info',
      sortable: false,
      filterable: false,
      capitalize: false,
    },
    {
      key: 'preferredDate',
      name: 'Specific Date(s)',
      sortable: false,
      filterable: false,
      capitalize: false,
    },
    {
      key: 'condition',
      name: 'Pet Condition',
      sortable: false,
      filterable: false,
      capitalize: false,
    },
    {
      key: 'attachments',
      name: 'Attachments',
      imgSrc: attachmentIcon,
      imgAlt: 'File attachment icon',
      className: 'with-icon',
      sortable: false,
      filterable: false,
      capitalize: false,
    },
    {
      key: 'open',
      name: 'Action',
      className: 'with-button',
      sortable: false,
      filterable: false,
      capitalize: false,
    },
  ];

  const headersTypes = {
    [appointmentRequestPageTypes.default]: commonHeaders.filter(
      ({ key }) => key !== 'open',
    ),
    [appointmentRequestPageTypes.clientPageModal]: commonHeaders.filter(
      ({ key }) => key !== 'parentFullName',
    ),
  };

  return headersTypes[type];
}
