import constant from '../../../constants/constants';

export const defaultLanes = [
  {
    id: `${constant.appointmentPhases.expected.id}`,
    title: constant.appointmentPhases.expected.name,
    style: {
      borderTop: `5px solid ${constant.appointmentPhases.expected.color}`,
    },
    cards: [],
  },
  {
    id: `${constant.appointmentPhases.checkIn.id}`,
    title: constant.appointmentPhases.checkIn.name,
    style: {
      borderTop: `5px solid ${constant.appointmentPhases.checkIn.color}`,
    },
    cards: [],
  },
  {
    id: `${constant.appointmentPhases.diagnostics.id}`,
    title: constant.appointmentPhases.diagnostics.name,
    style: {
      borderTop: `5px solid ${constant.appointmentPhases.diagnostics.color}`,
    },
    cards: [],
  },
  {
    id: `${constant.appointmentPhases.preparation.id}`,
    title: constant.appointmentPhases.preparation.name,
    style: {
      borderTop: `5px solid ${constant.appointmentPhases.preparation.color}`,
    },
    cards: [],
  },
  {
    id: `${constant.appointmentPhases.procedure.id}`,
    title: constant.appointmentPhases.procedure.name,
    style: {
      borderTop: `5px solid ${constant.appointmentPhases.procedure.color}`,
    },
    cards: [],
  },
  {
    id: `${constant.appointmentPhases.dental.id}`,
    title: constant.appointmentPhases.dental.name,
    style: {
      borderTop: `5px solid ${constant.appointmentPhases.dental.color}`,
    },
    cards: [],
  },
  {
    id: `${constant.appointmentPhases.surgery.id}`,
    title: constant.appointmentPhases.surgery.name,
    style: {
      borderTop: `5px solid ${constant.appointmentPhases.surgery.color}`,
    },
    cards: [],
  },
  {
    id: `${constant.appointmentPhases.recovery.id}`,
    title: constant.appointmentPhases.recovery.name,
    style: {
      borderTop: `5px solid ${constant.appointmentPhases.recovery.color}`,
    },
    cards: [],
  },
  {
    id: `${constant.appointmentPhases.hospitalized.id}`,
    title: constant.appointmentPhases.hospitalized.name,
    style: {
      borderTop: `5px solid ${constant.appointmentPhases.hospitalized.color}`,
    },
    cards: [],
  },
  {
    id: `${constant.appointmentPhases.boarding.id}`,
    title: constant.appointmentPhases.boarding.name,
    style: {
      borderTop: `5px solid ${constant.appointmentPhases.boarding.color}`,
    },
    cards: [],
  },
  {
    id: `${constant.appointmentPhases.discharge.id}`,
    title: constant.appointmentPhases.discharge.name,
    style: {
      borderTop: `5px solid ${constant.appointmentPhases.discharge.color}`,
    },
    cards: [],
  },
  {
    id: `${constant.appointmentPhases.completed.id}`,
    title: constant.appointmentPhases.completed.name,
    style: {
      borderTop: `5px solid ${constant.appointmentPhases.completed.color}`,
    },
    cards: [],
  },
];
