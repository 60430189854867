import React, { Component } from 'react';
import { connect } from 'react-redux';
import LogInForm from './components/LogInForm';
import { login } from '../../../redux/actions/userActions';
import localStorage from '../../../libs/storageHelper';
import config from '../../../config/app.config';
import constants from '../../../constants/constants';
import withRouter from '../../../shared/components/withRouter';

const icon = `${process.env.PUBLIC_URL}/img/loyal-logo.png`;

const formType = () => {
  const { hostname } = window.location;

  return hostname.includes(process.env.LOYALPET_SUPER_ADMIN_SUBDOMAIN)
    ? constants.userRole.superAdmin
    : constants.userRole.staff;
};

export class Login extends Component {
  constructor(props) {
    super(props);
    this.login = this.login.bind(this);
    this.state = { user: {} };
  }

  componentDidMount() {
    const user = localStorage.getFromStorage('loggedUser');
    if (user) {
      switch (user.user.type) {
        case constants.userRole.admin:
          this.props.navigate('/client-list');
          break;
        case constants.userRole.superAdmin:
          this.props.navigate('/manage-users');
          break;
        case constants.userRole.staff:
        default:
          this.props.navigate('/tracking-board');
          break;
      }
    }
  }

  UNSAFE_componentWillReceiveProps(np) {
    if (np.user.loginData) {
      const userData = np.user.loginData;

      localStorage.addToStorage('loggedUser', userData);
      if (
        userData.user.type === config.USER_TYPES.ADMIN &&
        !userData.user.loggedFirstTime
      ) {
        this.props.navigate('/change-password');
      } else if (userData.user.type === config.USER_TYPES.ADMIN) {
        this.props.navigate('/client-list');
      } else if (userData.user.type === config.USER_TYPES.SUPER_ADMIN) {
        this.props.navigate('/manage-users');
      } else if (
        userData.user.type === config.USER_TYPES.STAFF &&
        userData.user.loggedFirstTime
      ) {
        this.props.navigate('/tracking-board');
      } else if (userData.user.type === config.USER_TYPES.STAFF) {
        if (userData.user.privateCredentials) {
          this.props.navigate('/change-password');
        } else {
          this.props.navigate('/user-profile');
        }
      }
    } else if (np.user.loginErrorData) {
      this.setState({ user: np.user });
    } else {
      this.setState({ user: {} });
    }
  }

  login(state) {
    this.props.login({
      email: state.username,
      password: state.password,
      formType: formType(),
    });
  }

  render() {
    return (
      <div className="account user-flow">
        <div className="account__wrapper">
          <div className="account__card">
            <div className="account__card--inner">
              <div className="account__head">
                <h2 className="account__title">
                  Welcome to
                  <span className="account__logo">
                    <img src={icon} alt="" />
                  </span>
                </h2>
              </div>
              <h3 className="account__form-heading subheading">
                {formType() !== constants.userRole.superAdmin &&
                  'Provider Login'}
              </h3>
              <h4 className="account__subhead subhead">
                Login to your account
              </h4>
              <LogInForm
                handleSubmit={this.login}
                user={this.state.user}
                formType={formType()}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = {
  login,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
