import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import TableStatusFilterComponent from '../../../shared/components/form/TableFilterComponent';
import TableSearchableFilterComponent from '../../../shared/components/form/TableSearchableFilterComponent';
import * as Constants from '../../../constants/constants';
import { SortingField } from '../../common/SortingCell';
import { Link } from 'react-router-dom';

export default class TableComponent extends Component {
  static propTypes = {
    heads: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string,
        sortable: PropTypes.bool,
      }),
    ).isRequired,
    rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  handleStatusFilter = (list, key) => {
    this.props.handleGridFilter(key, list);
  };

  goTo = (e, url) => {
    e.preventDefault();
    this.props.history.push(url);
  };

  render() {
    const {
      rows,
      heads,
      sortField,
      sortOrder,
      stickyTop = '250px',
    } = this.props;
    const capitalizedText = heads.filter((head) => {
      if (head.capitalize) {
        return head.key;
      }
      return false;
    });

    const hyperLinkOrValue = (
      row,
      fieldKey,
      colIndex,
      capitalizeClass,
      valuesWithHyperLinks,
    ) => {
      let dataToShow = row[fieldKey];

      if (valuesWithHyperLinks.includes(fieldKey)) {
        const path = `/client-list/profile/${row.parentId}`;

        return (
          <td
            key={colIndex}
            title={dataToShow}
            className={`custom-no-over-flow-td ${capitalizeClass}`}
          >
            {fieldKey === Constants.CLIENT_TABLE_FIELDS.parentName && (
              <Link to={`${path}?tab=${Constants.QUERY_TAB_PARAMS.parent}`}>
                {dataToShow}
              </Link>
            )}
            {fieldKey === Constants.CLIENT_TABLE_FIELDS.petName && (
              <Link
                to={`${path}?tab=${Constants.QUERY_TAB_PARAMS.petInfo}&${Constants.QUERY_PARAMS.pet}=${row.petId}`}
              >
                {dataToShow}
              </Link>
            )}
          </td>
        );
      }

      if (fieldKey === 'coParents') {
        dataToShow = row.coParents
          .map((coParent) => coParent.coParentFullName)
          .join('; ');
      }

      return (
        <td
          key={colIndex}
          title={dataToShow}
          className={`custom-no-over-flow-td ${capitalizeClass}`}
        >
          {dataToShow}
        </td>
      );
    };

    const tableRows = rows.map((row, rowIndex) => {
      const _row = heads.map((head, colIndex) => {
        const capitalizeClass = capitalizedText.includes(head.key)
          ? 'text-capitalize'
          : '';
        return hyperLinkOrValue(row, head.key, colIndex, capitalizeClass, [
          Constants.CLIENT_TABLE_FIELDS.petName,
          Constants.CLIENT_TABLE_FIELDS.parentName,
        ]);
      });

      return (
        <tr className="body-tr" key={rowIndex}>
          {_row}
          {!this.props.noActions && (
            <td>
              <UncontrolledDropdown>
                <DropdownToggle>
                  <span className="six-layers" />
                </DropdownToggle>
                <DropdownMenu className="dropdown__menu">
                  <DropdownItem tag="div">
                    <a onClick={() => this.props.viewProfile(row)}>
                      View Profile
                    </a>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </td>
          )}
        </tr>
      );
    });

    const sortFieldComponent = (head, icon, thisSortField) => (
      <SortingField
        name={head.name}
        icon={icon}
        onSort={() => this.props.handleGridSort(thisSortField)}
      />
    );

    const headers = heads.map((head, key) => {
      const thisSortField = head.key;
      return head.sortable && !head.filterable ? (
        <th key={key}>
          {sortField === head.key
            ? sortOrder === 1
              ? sortFieldComponent(head, 'triangle-up', thisSortField)
              : sortFieldComponent(head, 'triangle-down', thisSortField)
            : sortFieldComponent(head, 'diamond-narrow', thisSortField)}
        </th>
      ) : [
          'status',
          'speciesName',
          'breedName',
          'gender',
          'lastAppointmentDate',
        ].includes(head.key) ? (
        <th key={key}>
          {head.key === 'breedName' ? (
            <TableSearchableFilterComponent
              allFilters={head.filterValue}
              action={(list) => this.handleStatusFilter(list, head.key)}
              onSearch={(search) =>
                this.props.handleFilterSearch(head.key, search)
              }
              label={head.name}
              showSelectedOption
            />
          ) : (
            <TableStatusFilterComponent
              allFilters={head.filterValue}
              action={(list) => this.handleStatusFilter(list, head.key)}
              label={head.name}
            />
          )}
        </th>
      ) : (
        <th key={key}>
          <p>{head.name}</p>
        </th>
      );
    });

    return (
      <div className="row">
        <div className="col-md-12">
          <Table striped className="custom-table loyal-table">
            <thead>
              <tr className="header-tr" style={{ top: stickyTop }}>
                {headers}
                {!this.props.noActions && (
                  <th className="text-center action">
                    <p className=" action">Action</p>
                  </th>
                )}
              </tr>
            </thead>
            <tbody>{tableRows}</tbody>
          </Table>
          {rows.length === 0 && (
            <div className="no-data-message">No results found</div>
          )}
        </div>
      </div>
    );
  }
}
