export const TOGGLE_MESSAGE_MODAL = 'TOGGLE_MESSAGE_MODAL';
export const TOGGLE_LOADING = 'TOGGLE_LOADING';

export const toggleMessageModel = (status) => (dispatch) => {
  return dispatch({
    type: TOGGLE_MESSAGE_MODAL,
    payload: status,
  });
};

export const toggleLoading = (status) => (dispatch) => {
  return dispatch({
    type: TOGGLE_LOADING,
    payload: status,
  });
};
