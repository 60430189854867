import React, { Component } from 'react';
import { Button, Row, Col, Container } from 'reactstrap';
import config from '../../../config/app.config';
import { AvatarModal } from '../../../shared/components/Modals/AvatarModal';

const defautAvatar = `${process.env.PUBLIC_URL}/img/defaultAvatar.png`;

const SUPER_ADMIN = config.USER_TYPES.SUPER_ADMIN;
const STAFF = config.USER_TYPES.STAFF;

export default class AvatarSection extends Component {
  constructor(props) {
    super(props);

    this.state = { showImageCrop: false };
  }

  UNSAFE_componentWillReceiveProps(np) {
    if (np.profile) {
      const { type, firstName, lastName, designation, facility, avatar } =
        np.profile;
      this.setState({
        type,
        firstName,
        lastName,
        designation,
        facility,
        avatar,
      });
    }
  }

  selectFile = () => {
    this.setState({ showImageCrop: true });
  };

  closeUploadModal = () => {
    this.setState({ showImageCrop: false });
  };

  cropAndUpload = async (img) => {
    this.props.upload(img);
    this.setState({ showImageCrop: false });
  };

  render() {
    const {
      type,
      firstName,
      lastName,
      designation,
      facility,
      avatar,
      showImageCrop,
    } = this.state;
    let title = designation && designation.name;
    const hospital = facility && facility.name;

    if (type !== STAFF) {
      title = type === SUPER_ADMIN ? 'Super Admin' : 'Admin';
    }

    return (
      <div className="user-profile__avatar-sestion">
        <AvatarModal
          isOpen={showImageCrop}
          onCancel={this.closeUploadModal}
          onDone={this.cropAndUpload}
          src={avatar}
        />

        <Container>
          <Row>
            <Col xs="1">
              <div className="topbar__avatar-img ">
                <img src={avatar || defautAvatar} alt="avatar" />
              </div>
            </Col>
            <Col xs="10">
              <div className="user-profile__avatar-details-section">
                <p className="user-profile__avatar-details-section--name">
                  {firstName} {lastName}
                </p>
                <p className="user-profile__avatar-details-section--post">
                  {title}
                </p>
                {type === STAFF && (
                  <p className="user-profile__avatar-details-section--hospital">
                    {hospital}
                  </p>
                )}
                <Button
                  onClick={this.selectFile}
                  className="user-profile__avatar-details-section--upload-btn"
                  color="primary"
                >
                  <span className="lnr lnr-plus-circle" />{' '}
                  <span className="text">Update Avatar</span>
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
