import React from 'react';
import { FormGroup } from 'reactstrap';
import useComponentVisible from '../../../../hooks/useComponentVisible';
import Error from '../../form/Error';

export default function CustomSelect({
  value,
  options,
  search,
  setSearch,
  handleOptionSelect,
  error,
  ...props
}) {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const handleTextInputChange = (event) => {
    setSearch?.(event.target.value);
    setIsComponentVisible(true);
  };

  return (
    <FormGroup>
      <div
        className={`custom-select-box-wrapper clearfix ${
          isComponentVisible ? 'focused' : ''
        }`}
        style={{ width: '100%' }}
        ref={ref}
      >
        <input
          type="text"
          className="form-control form-select"
          value={
            search ?? options.find((option) => option._id === value)?.name ?? ''
          }
          onChange={handleTextInputChange}
          onFocus={() => setIsComponentVisible(true)}
          {...props}
        />

        {isComponentVisible && (
          <div className="states-result">
            <div style={{ width: '100%', height: 200, overflowY: 'auto' }}>
              {options.map((item) => (
                <div
                  className="states-block"
                  key={item._id}
                  onClick={() => {
                    handleOptionSelect(item._id);
                    setIsComponentVisible(false);
                  }}
                >
                  {item.name}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      {error && <Error text={error} />}
    </FormGroup>
  );
}
