import React from 'react';

export function SortingField({ icon, name, onSort }) {
  return (
    <div className="sort-field">
      <p onClick={onSort}>{name}</p>

      <div>
        <span className={icon} onClick={onSort} />
      </div>
    </div>
  );
}
