import Config from '../config/app.config';

export const isMobile = {
  Windows: function () {
    return /IEMobile/i.test(navigator.userAgent);
  },
  Android: function () {
    return /Android/i.test(navigator.userAgent);
  },
  BlackBerry: function () {
    return /BlackBerry/i.test(navigator.userAgent);
  },
  iOS: function () {
    return /iPhone|iPad|iPod|Mac/i.test(navigator.userAgent);
  },
  any: function () {
    return (
      isMobile.Android() ||
      isMobile.BlackBerry() ||
      isMobile.iOS() ||
      isMobile.Windows()
    );
  },
};

export const isSuperAdmin = (loggedUser = {}) =>
  loggedUser?.type === Config.USER_TYPES.SUPER_ADMIN;

export const isPrimaryAdmin = (loggedUser = {}) =>
  loggedUser?.type === Config.USER_TYPES.ADMIN && !!loggedUser?.isPrimary;

export const toTheSystemStoreApp = () => {
  switch (true) {
    case isMobile.Android():
      window.location.href =
        'https://play.google.com/store/apps/details?id=pet.loyal.client&hl=en&gl=US';
      break;
    case isMobile.iOS():
      window.location.href =
        'https://apps.apple.com/us/app/loyal-pet/id1479669611';
      break;
    case isMobile.Windows():
      console.log('On W');
      break;

    default:
      break;
  }
};
