import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { connect } from 'react-redux';
import TableAdminFilterComponent from '../../shared/components/form/TableFilterComponent';
import { toggleLoading } from '../../redux/actions/commonActions';
import { SortingField } from '../common/SortingCell';
import { FACILITY_STATUS } from './constants';

class FacilityTableComponent extends Component {
  static propTypes = {
    heads: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string,
        sortable: PropTypes.bool,
      }),
    ).isRequired,
    rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  UNSAFE_componentWillMount() {
    this.props.toggleLoading(true);
  }

  componentWillUnmount() {
    if (this.props.common.loading) {
      this.props.toggleLoading(false);
    }
  }

  handleStatusFilter = (list) => {
    this.props.handleGridFilter('status', list);
  };

  handleAdminFilter = (list) => {
    this.props.handleGridFilter('admin', list);
  };

  viewProfile = (data) => {
    this.props.viewProfile(data);
  };

  render() {
    const { rows, heads, sortField, sortOrder } = this.props;

    const headText = heads.map((head) => head.key);

    const capitalizedText = heads.map((head) =>
      head.capitalize ? head.key : null,
    );

    const tableRows = rows.map((row, key) => {
      const _row = Object.keys(row).map((value, key) => {
        if (headText.includes(value)) {
          const capitalizeClass = capitalizedText.includes(value)
            ? 'text-capitalize'
            : '';

          if (value === 'displayName') {
            return (
              <td
                key={key}
                className={`status-col ${capitalizeClass} lastName`}
                onClick={() => this.props.viewProfile(row)}
              >
                {row[value]}
              </td>
            );
          }

          return (
            <td key={key} className={`status-col ${capitalizeClass}`}>
              {row[value]}
            </td>
          );
        }

        return true;
      });

      return (
        <tr className="body-tr" key={key}>
          {_row}
          <td>
            <UncontrolledDropdown>
              <DropdownToggle>
                <span className="six-layers"></span>
              </DropdownToggle>
              <DropdownMenu className="dropdown__menu">
                <DropdownItem tag="div">
                  <a onClick={() => this.props.viewProfile(row)}>
                    View Profile
                  </a>
                </DropdownItem>
                <DropdownItem tag="div">
                  <a
                    onClick={() =>
                      this.props.handleActiveStatus(
                        row.status === FACILITY_STATUS.ACTIVE
                          ? FACILITY_STATUS.INACTIVE
                          : FACILITY_STATUS.ACTIVE,
                        row.id,
                      )
                    }
                  >
                    {row.status === FACILITY_STATUS.ACTIVE
                      ? 'Deactivate'
                      : 'Reactivate'}
                  </a>
                </DropdownItem>
                {row.removePossibility && (
                  <DropdownItem tag="div">
                    <a
                      onClick={() =>
                        this.props.handleActiveStatus(
                          FACILITY_STATUS.REMOVED,
                          row.id,
                        )
                      }
                    >
                      Remove
                    </a>
                  </DropdownItem>
                )}
              </DropdownMenu>
            </UncontrolledDropdown>
          </td>
        </tr>
      );
    });

    const headers = heads.map((head, key) => {
      const _sortField = head.key;
      const sortingFieldProps = {
        name: head.name,
        onSort: () => this.props.handleGridSort(_sortField),
      };

      const tableHeaderContent =
        head.sortable && !head.filterable ? (
          sortField === head.key ? (
            <SortingField
              icon={sortOrder === 1 ? 'triangle-up' : 'triangle-down'}
              {...sortingFieldProps}
            />
          ) : (
            <SortingField icon="diamond-narrow" {...sortingFieldProps} />
          )
        ) : head.key === 'admin' || head.key === 'status' ? (
          <TableAdminFilterComponent
            allFilters={head.filterValue}
            action={(list) =>
              head.key === 'admin'
                ? this.handleAdminFilter(list)
                : this.handleStatusFilter(list)
            }
            label={head.name}
          />
        ) : (
          <p>{head.name}</p>
        );

      return (
        <th key={key} style={{ width: head.width ?? 'auto' }}>
          {tableHeaderContent}
        </th>
      );
    });

    return (
      <div className="row">
        <div className="col-md-12">
          <Table striped className="custom-table loyal-table">
            <thead>
              <tr className="header-tr" style={{ top: '180px' }}>
                {headers}
                <th className="text-center action">
                  <p className=" action">Action</p>
                </th>
              </tr>
            </thead>
            <tbody>{tableRows}</tbody>
          </Table>

          {tableRows.length === 0 && this.props.common.loading === false && (
            <div className="no-data-message">No results found</div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = {
  toggleLoading,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FacilityTableComponent);
