import React, { useCallback } from 'react';
import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from 'react-google-places-autocomplete';
import config from '../../config/app.config';

function PlacesSearchAutoComplete({
  value,
  getAddress = () => null,
  disabled,
}) {
  const handleChange = useCallback(
    async (option) => {
      const { label: address } = option;
      const placeId = option.value.place_id;

      const [{ address_components: suggestions = [] }] = await geocodeByAddress(
        address,
      );
      const adressData = { address, placeId };

      for (const item of suggestions) {
        if (item.types.includes('locality')) {
          adressData.city = item.long_name;
        }
        if (item.types.includes('administrative_area_level_1')) {
          adressData.state = item.long_name;
        }
        if (item.types.includes('postal_code')) {
          adressData.zipCode = item.long_name;
        }
      }
      getAddress({ ...adressData });
    },
    [getAddress],
  );

  return (
    <GooglePlacesAutocomplete
      apiKey={config.GOOGLE_API_KEY}
      apiOptions={{
        libraries: ['places'],
      }}
      debounce={600}
      selectProps={{
        isDisabled: disabled,
        onChange: handleChange,
        name: 'address',
        placeholder: value || 'Type Address',
        styles: {
          control: (style) => ({
            ...style,
            border: 'unset',
            borderRadius: 0,
          }),
          singleValue: (style) => ({ ...style, fontSize: '12px' }),
          valueContainer: (style) => ({
            ...style,
            padding: 0,
            margin: 0,
            visibility: 'initial',
            paddingLeft: '6px',
            width: '200px',
          }),
          placeholder: (style) => ({
            ...style,
            overflow: 'hidden',
            color: value ? 'inherit' : style.color,
            fontSize: '12px',
            whiteSpace: 'nowrap',
          }),
          input: (style) => ({
            ...style,
            overflow: 'hidden',
            fontSize: '12px',
            padding: 0,
            margin: 0,
          }),
        },
      }}
    />
  );
}

export default PlacesSearchAutoComplete;
