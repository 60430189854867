import {
  FETCH_APPOINTMENT_REQUESTS,
  FETCH_APPOINTMENT_REQUESTS_ERROR,
  RESET_APPOINTMENT_REQUESTS,
  SET_STATUS_TOGGLE_STATE,
} from '../actions/appointmentRequestActions';

export const initialState = {
  appointmentRequestsList: [],
  appointmentRequestsListError: null,
  statusToggleState: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_APPOINTMENT_REQUESTS:
      return {
        ...state,
        appointmentRequestsList: action.payload,
        appointmentRequestsListError: null,
      };
    case FETCH_APPOINTMENT_REQUESTS_ERROR:
      return {
        ...state,
        appointmentRequestsList: [],
        appointmentRequestsListError: action.payload,
      };
    case RESET_APPOINTMENT_REQUESTS:
      return {
        ...state,
        appointmentRequestsList: [],
        appointmentRequestsListError: null,
      };
    case SET_STATUS_TOGGLE_STATE:
      return {
        ...state,
        statusToggleState: {
          ...state.statusToggleState,
          [action.payload.pageType]: action.payload.status,
        },
      };
    default:
      return state;
  }
}
