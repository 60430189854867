import React from 'react';
import CustomSelect from '../CustomSelect/CustomSelect';

const gendersOptions = [
  { _id: 'Female', name: 'Female' },
  { _id: 'Male', name: 'Male' },
  { _id: 'Male neutered', name: 'Male Neutered' },
  { _id: 'Female neutered', name: 'Female Neutered' },
  { _id: 'Unknown', name: 'Unknown' },
];

export default function GenderSelect({ value, onChange }) {
  return (
    <CustomSelect
      value={value}
      options={gendersOptions}
      handleOptionSelect={onChange}
      placeholder="Select Gender"
    />
  );
}
